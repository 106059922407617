import {
	Platform
} from 'react-native';

import {
	NativeRouter,
	Routes as NativeRoutes,
	Route as NativeRoute,
	useNavigate as NativeUseNavigate,
	NavigateFunction as NativeNavigateFunction,
	RouteProps as NativeRouteProps,
	Navigate as NativeNavigate,
	useLocation as NativeUseLocation,
	Location as NativeLocation,
	redirect as NativeRedirect,
	RedirectFunction as NativeRedirectFunction,
	useParams as NativeUseParams,
	useSearchParams as NativeUseSearchParams,
	SetURLSearchParams as NativeSetURLSearchParams,
} from 'react-router-native';

import {
	BrowserRouter as DomRouter,
	Routes as DomRoutes,
	Route as DomRoute,
	useNavigate as DomUseNavigate,
	NavigateFunction as DomNavigateFunction,
	RouteProps as DomRouteProps,
	Navigate as DomNavigate,
	useLocation as DomUseLocation,
	Location as DomLocation,
	redirect as DomRedirect,
	RedirectFunction as DomRedirectFunction,
	useParams as DomUseParams,
	useSearchParams as DomUseSearchParams,
	SetURLSearchParams as DomSetURLSearchParams,
} from 'react-router-dom';

export const Router = Platform.OS === 'web' ? DomRouter : NativeRouter;
export const Routes = Platform.OS === 'web' ? DomRoutes : NativeRoutes;
export const Route = Platform.OS === 'web' ? DomRoute : NativeRoute;
export const redirect = Platform.OS === 'web' ? DomRedirect : NativeRedirect;
export type RouteProps = DomRouteProps | NativeRouteProps;
export const Navigate = Platform.OS === 'web' ? DomNavigate: NativeNavigate;
export const useLocation = Platform.OS === 'web' ? DomUseLocation: NativeUseLocation;
export type Location = DomLocation | NativeLocation;
export const useNavigate = Platform.OS === 'web' ? DomUseNavigate : NativeUseNavigate;
export type NavigateFunction = NativeNavigateFunction | DomNavigateFunction;
export type RedirectFunction = NativeRedirectFunction | DomRedirectFunction;
export const useParams = Platform.OS === 'web' ? DomUseParams: NativeUseParams;
export const useSearchParams = Platform.OS === 'web' ? DomUseSearchParams: NativeUseSearchParams;
export type SetURLSearchParams = DomSetURLSearchParams | NativeSetURLSearchParams;
