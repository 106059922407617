import { paths } from '../../../enums';
import { serviceBase } from '../../../service';
import { CustomOptions } from '../../../service/api';
import { setAppStateStore, UIStoreInit } from '../../../store';

export class SupportService extends serviceBase {
	constructor(name: string, store: any, uiState: UIStoreInit) {
		super(name, store, uiState);

		this.responseData = this.responseData.bind(this);
		this.getSubmitData = this.getSubmitData.bind(this);
		this.transformData = this.transformData.bind(this);
	}

	/**
     * get api data before api call
     * @param options 
     * @returns 
     */
	getSubmitData(options: CustomOptions) {
		const { name } = options;
		return {};
	}

	/**
     * transform response function after api call 
     * @param data 
     * @param options 
     */
	transformData(data: any, options: CustomOptions) {
		const { name } = options;
		if (name === 'supportApi') {
            
			return data;
		}
		return data;
	}

	/**
     * onClick fn handler
     * @param value 
     * @param data 
     * @param props 
     */
	onClick(value: any, data: any, props: any) {
		const { name } = props;
		switch (name) {
		case 'submitBtn':
			this.submitSupport();
			break;
		default:
			break;
		}
	}
	
	async submitSupport() {
		// get email
		const email = this.store.getProperty('email');
		const description = this.store.getProperty('supportDescription');

		// clear 
		await this.api.postApiAsync(this.getUrl('support'), { email, description }, { name: 'support' });

		this.store.setProperty('email', '');
		this.store.setProperty('supportDescription', '');

		// notification
		this.successNotification('Thank you for your ticket! We will respond to you shortly with the email you provided!');
	}

	/**
     * response function after api call
     * @param data 
     * @param options 
     */
	responseData(data: any, options: CustomOptions): void {
		const { name } = options;
	}
}