import React, { useMemo } from 'react';
import { StyleSheet } from 'react-native';
import { FAChartProps } from './FAChartProps';
import * as util from '../../util';
// import { VictoryChart, VictoryLine, VictoryTheme } from 'victory-native';
import { FAHeader } from '../typography';
import { View } from 'native-base';

const VictoryChart: React.FC<any> = () => {
    return null;
};

const VictoryLine: React.FC<any> = () => {
    return null;
};
const VictoryTheme: any = () => {
    return {};
};
/**
 * chart
 * @param props 
 * @returns 
 */
export const FAChart: React.FC<FAChartProps> = (props: FAChartProps) =>{
	const {
		name,
        xAxis,
        yAxis,
        dataSource,
        height = '40vh',
        width,
        style,
        title,
	} = props;

    // required for this chart
    if (!xAxis || !yAxis || !util.isArray(dataSource)) {
        return null;
    }

    // build data
    const { victoryData } = useMemo(() => {
        const victoryData: { x: any, y: any }[] = [];
        // x should be sorted already
        dataSource.forEach((item, idx) => {
            const {
                [xAxis]: xValue,
                [yAxis]: yValue,
            } = item;
            
            const xData = util.formatDate(xValue, 'M/D');
            // if last one is same date, use higher value
            if (idx) {
                const {
                    x,
                    y,
                } = victoryData[victoryData.length - 1];
                if (x === xData) {
                    if (yValue > y) {
                        victoryData[victoryData.length - 1].y = yValue;
                    }
                    return;
                }
            }
            // if last one is
            victoryData.push({
                x: xData,
                y: yValue,
            });
        });
        return {
            victoryData,
        };
    }, [dataSource]);

    // build data
    const { min, max } = useMemo(() => {
        let min: number;
        let max: number;

        victoryData.forEach((item, idx) => {
            if (!min || item.y < min) {
                min = item.y;
            }
            if (!max || item.y > max) {
                max = item.y;
            }
        });
        let padding = (max - min) * .2;
        // at least 1
        if (padding < 1) {
            padding = 1;
        }
        return {
            min: min - padding < 0 ? 0: min - padding,
            max: max + padding, // add by 20%
        };
    }, [victoryData]);

	console.log('Render Chart', name);
    return (
        <View>
            <FAHeader name='title'>{title}</FAHeader>
            <VictoryChart
                theme={VictoryTheme.material}
                style={{
                    height: util.getDim(height),
                    width,
                    ...style,
                }}
            >
                <VictoryLine
                    domain={{ y: [min, max] }}
                    interpolation="natural"
                    style={{
                        data: { stroke: "#c43a31" },
                        parent: { border: "1px solid #ccc"},
                    }}
                    data={victoryData}
                />
            </VictoryChart>
        </View>
    );
};

const styles = StyleSheet.create({
})