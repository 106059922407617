import React from 'react';
import { Toast } from 'native-base';

export interface useNotificationType {
	error: (message: string) => any;
	warning: (message: string) => any;
	success: (message: string) => any;
}

/**
 * notification hook
 * @returns 
 */
export const useNotification = (): useNotificationType => {
	
	return {
		error: (message: any) => Toast.show({
			title: message,
			placement: 'top-right',
			bg: 'red.500'
		}),
		warning: (message: any) => Toast.show({
			title: message,
			placement: 'top-right',
			bg: 'yellow.500'
		}),
		success: (message: any) => Toast.show({
			title: message,
			placement: 'top-right',
			bg: 'green.500'
		}),
	}
};