export const StartWorkoutStore = {
	startWorkoutTest: 'startWorkoutTest',
	startWorkoutList: [],
	exerciseList: [],
	routineList: [],
	exerciseSelect: '',
	addExerciseTypeId: '',
	addExerciseWeight: 0,
	addComment: '',
	addExerciseReps: 0,
	editExerciseTypeId: '',
	editExerciseWeight: 0,
	editExerciseReps: 0,
	editComment: '',
	startWorkout_log_id: '',
	startWorkoutWorkingList: [],
	editWorkingList: [],
	exerciseSelectList: [],
	routineSelectList: [],
	routineId: '',
	username: '',
	dateMonth: '',
	dateHour: '',
	dateDay: '',
	grouped_workout_log_id: '',
	log_date: '',
	simpleList: [],
};
