import { Dimensions } from "react-native";

type DimType = `${number}vh` |  `${number}vw`;

/**
 * convert vh, vw to react native type
 */
export const getDim = (dim: DimType) => {
	if (dim.endsWith('vh')) {
		const number = parseInt(dim.replace('vh', ''));
		return (number / 100) * Dimensions.get('window').height;
	}
	if (dim.endsWith('vw')) {
		const number = parseInt(dim.replace('vw', ''));
		return (number / 100) * Dimensions.get('window').width;
	}
	return dim;
};