import React, { useEffect } from 'react';
import { View, StyleSheet } from 'react-native';
import { useExerciseChartService } from './service';
import { uiState } from './exerciseChart.ui';
import { ExerciseChartStore } from './exerciseChart.store';
import { FAStoreTypography, FAStoreInput, PageLayout, FAHeader, FAStoreGrid, FAStoreButton, FAStoreModal, FAStoreChart } from '../../components';
import { FAInput } from '../../components/input/FAInput';
import { useServiceRouter } from '../../service';
import { ThreeHumanModel } from '../../components/threejs';

export const ExerciseChart: React.FC = () => {
	const screenName = 'ExerciseChartPage';
	const service = useExerciseChartService(screenName, ExerciseChartStore, uiState);
	useServiceRouter(service);

	useEffect(() => {
		service.loadData();
	}, []);

	console.log('Render ExerciseChart screen');
	return (
		<PageLayout>
			<FAStoreInput name='exercise_type_id' />
			<FAStoreChart name='exerciseChartList' />
			<FAStoreGrid name='exerciseChartList'/>
		</PageLayout>
	);
};
