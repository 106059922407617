import { ComponentEnum } from '../../enums';
import { UIStoreInit } from '../../store';
import { addNewBtn, saveBtn, addBtn } from '../common';

export const uiState: UIStoreInit = {
	button: {
		addNewBtn,
		saveBtn,
		addBtn,
		editAddBtn: {
			name: 'editAddBtn',
			children: 'Add+',
			size: 'sm',
		},
		createExercise: {
			children: 'Or Create',
			size: 'sm',
		},
		createRoutine: {
			children: 'Or Create',
			size: 'sm',
		},
		startBtn: {
			children: 'Start',
			size: 'sm',
		},
	},
	input: {
		workoutTest: {
			componentType: ComponentEnum.InputTypeEnum.TextArea,
			name: 'workoutTest',
			placeholder: 'Workout Test',
			label: 'Workout Test',
			routes: 'workoutTest',
		},
		exerciseSelect: {
			componentType: ComponentEnum.InputTypeEnum.Select,
			name: 'exerciseSelect',
			placeholder: 'Workout Test',
			label: 'Workout Test',
			items: ['test'],
			routes: 'exerciseSelect',
		},
		addExerciseTypeId: {
			componentType: ComponentEnum.InputTypeEnum.Select,
			routes: 'addExerciseTypeId',
			placeholder: 'Exercise',
			label: 'Exercise',
			items: [],
		},
		addExerciseWeight: {
			routes: 'addExerciseWeight',
			placeholder: 'Weight',
			label: 'Weight',
			format: 'number',
		},
		addComment: {
			routes: 'addComment',
			placeholder: 'Comment',
			label: 'Comment',
		},
		addExerciseReps: {
			routes: 'addExerciseReps',
			placeholder: 'Reps',
			label: 'Reps',
			format: 'number',
		},
		editExerciseTypeId: {
			componentType: ComponentEnum.InputTypeEnum.Select,
			routes: 'editExerciseTypeId',
			placeholder: 'Exercise',
			label: 'Exercise',
			items: [],
		},
		editExerciseWeight: {
			routes: 'editExerciseWeight',
			placeholder: 'Weight',
			label: 'Weight',
			format: 'number',
		},
		editComment: {
			routes: 'editComment',
			placeholder: 'Comment',
			label: 'Comment',
		},
		editExerciseReps: {
			routes: 'editExerciseReps',
			placeholder: 'Reps',
			label: 'Reps',
			format: 'number',
		},
		routineId: {
			componentType: ComponentEnum.InputTypeEnum.Select,
			routes: 'routineId',
			placeholder: 'Routine',
			label: 'Routine',
			items: [],
		},
	},
	typography: {
		workoutTest: {
			componentType: ComponentEnum.TypographyTypeEnum.Text,
			name: 'workoutTest',
			placeholder: 'Workout Test',
			label: 'Workout Test',
			routes: 'workoutTest',
		},
	},
	layout: {
		menuEnabled: true,
		headerEnabled: true,
		// modelEnabled: true,
		header: 'Workout',
		// settingsEnabled: true,
		backEnabled: true,
		menuBackground: 'white',
	},
	grid: {
		workoutList: {
			routes: 'workoutList',
			titleData: 'workout_title',
			subtitleData: 'workout_subTitle',
			// subtitleData: 'workout_comment',
			// editBtn: true,
			idData: 'workout_log_id',
		},
		routineList: {
			routes: 'routineList',
			titleData: 'routine_name',
			subtitleData: 'routine_completed',
			editBtn: true,
			idData: 'routine_log_id',
		},
		workoutWorkingList: {
			routes: 'workoutWorkingList',
			titleData: 'set_reps',
			titleUnits: 'Reps',
			subtitleData: 'set_weight',
			subtitleUnits: 'lbs.',
		},
		editWorkingList: {
			routes: 'editWorkingList',
			titleData: 'set_reps',
			titleUnits: 'Reps',
			subtitleData: 'set_weight',
			subtitleUnits: 'lbs.',
		},
	},
	modal: {
		editModal: {
			componentType: ComponentEnum.ModalTypeEnum.Modal,
			title: 'Edit Workout',
			okText: 'Save Edit',
		},
		addModal: {
			title: 'Add Workout',
			okText: 'Add',
		},
	},
};