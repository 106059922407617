import { modelData } from '../../components/threejs/modelData';
import { ComponentEnum, icons, themeColors } from '../../enums';
import { UIStoreInit } from '../../store';

export const uiState: UIStoreInit = {
	button: {
		openMuscle: {
			name: 'openMuscle',
			componentType: ComponentEnum.ButtonTypeEnum.Icon,
		},
		settingsBtn: {
			name: 'settingsBtn',
			componentType: ComponentEnum.ButtonTypeEnum.Icon,
			icon: icons.menu,
		},
	},
	input: {
		muscleSelect: {
			name: 'muscleSelect',
			componentType: ComponentEnum.InputTypeEnum.Select,
			items: modelData.map((item) => item.id),
		}
	},
	typography: {
	},
	layout: {
		menuEnabled: true,
		menuBackground: 'white',
		header: 'Muscle Breakdown',
		settingsEnabled: false,
		headerEnabled: true,
	},
	modal: {
		muscleModal: {
			name: 'muscleModal',
			componentType: ComponentEnum.ModalTypeEnum.Menu,
		}
	},
	grid: {
		muscleList: {
			name: 'muscleList',
			routes: 'muscleList',
			titleData: 'muscle_id',
			subtitleData: 'subtitle',
			// subtitleData: 'workout_comment',
			editBtn: false,
			deleteBtn: false,
			idData: 'workout_log_id',
		},
	},
};