
export const RoutineDetailStore = {
	routine_description: '',
	routine_name: '',
	exercises: [],
	exerciseSelectList: [],
	exerciseTypeId: '',
	routine_id: '',
	repFrom: 0,
	repTo: 0,
	setCount: 0,
	editId: null,
	routine_frequency: '2/Wk, Tue, Thu',
	// days of the week
	monday: [], // { routine_name, routine_id }
	tuesday: [], // { routine_name, routine_id }
	wednesday: [],
	thursday: [],
	friday: [],
	saturday: [],
	sunday: [],
	// routine schedule modal store
	editSchedule: {
		monday: false,
		tuesday: false,
		wednesday: false,
		thursday: false,
		friday: false,
		saturday: false,
		sunday: false,
	},
	// api info
	routineSchedules: [],
};
