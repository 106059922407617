import { observer } from 'mobx-react-lite';
import { Pressable } from 'native-base';
import React, { useEffect, useState } from 'react';
import { StyleSheet, Dimensions, View } from 'react-native';
import { FAHeader, FAStoreButton, FAStoreInput, FAText } from '../../../components';
import { getService } from '../../../service';
import * as util from '../../../util';

export const RoutineScheduleModal: React.FC = observer(() => {

    const service = getService();

	useEffect(() => {
	}, []);

    const dayItem = (day: util.DateDayofWeek) => {

		let dayName = '';
		let storeName = '';

		if (day === util.DateDayofWeek.Monday) {
			dayName = 'M';
			storeName = 'monday';
		} else if (day === util.DateDayofWeek.Tuesday) {
			dayName = 'Tu';
			storeName = 'tuesday';
		} else if (day === util.DateDayofWeek.Wednesday) {
			dayName = 'W';
			storeName = 'wednesday';
		} else if (day === util.DateDayofWeek.Thursday) {
			dayName = 'Th';
			storeName = 'thursday';
		} else if (day === util.DateDayofWeek.Friday) {
			dayName = 'F';
			storeName = 'friday';
		} else if (day === util.DateDayofWeek.Saturday) {
			dayName = 'Sa';
			storeName = 'saturday';
		} else if (day === util.DateDayofWeek.Sunday) {
			dayName = 'Su';
			storeName = 'sunday';
		} else {
			return null;
		}

        const selected = service.store.getProperty(['editSchedule', storeName]);

        const toggleSelected = () => {
            service.store.setProperty(['editSchedule', storeName], !selected);
        }

        return (
            <Pressable
                bg={selected ? 'green.500' : 'gray.300'}
                flex={1}
                onPress={toggleSelected}
            >
                <FAText bold name='daySchedulee' color="white" fontSize="18" style={{ alignSelf: 'center' }}>{dayName}</FAText>
            </Pressable>
        );
    };

	console.log('Render RoutineScheduleModal');
	return (
        <View style={styles.container}>
            <FAText name='header' bold style={styles.item}>1. Choose a routine</FAText>
            <FAStoreInput name='routineId' />
            <FAText name='header' bold style={styles.item}>2. Schedule days for this routine</FAText>
            <View style={styles.days}>
                {dayItem(util.DateDayofWeek.Monday)}
                {dayItem(util.DateDayofWeek.Tuesday)}
                {dayItem(util.DateDayofWeek.Wednesday)}
                {dayItem(util.DateDayofWeek.Thursday)}
                {dayItem(util.DateDayofWeek.Friday)}
                {dayItem(util.DateDayofWeek.Saturday)}
                {dayItem(util.DateDayofWeek.Sunday)}
            </View>
            <FAText name='header' bold style={styles.item}>3. Save</FAText>
            <FAStoreButton name='saveBtn' style={styles.item} />
        </View>	
	);
});

const styles = StyleSheet.create({
    days: {
        display: 'flex',
        flexDirection: 'row',
        padding: 10,
    },
    container: {
        flex: 1,
        flexGrow: 1,
        display: 'flex',
        justifyContent: 'space-between',
        height: '100%',
    },
    item: {
        padding: 10,
    },
});