import { modelData } from '../../../components/threejs/modelData';
import { getMuscleColor } from '../../../enums/colors';
import { serviceBase } from '../../../service';
import { CustomOptions } from '../../../service/api';
import { getAppStateData, UIStoreInit } from '../../../store';
import * as util from '../../../util';
import { isArray } from '../../../util';

export class StartWorkoutMenuService extends serviceBase {
	constructor(name: string, store: any, uiState: UIStoreInit) {
		super(name, store, uiState);

		this.responseData = this.responseData.bind(this);
		this.getSubmitData = this.getSubmitData.bind(this);
		this.transformData = this.transformData.bind(this);

		this.loadData = this.loadData.bind(this);
		this.setStartWorkoutMenuInfo = this.setStartWorkoutMenuInfo.bind(this);
		this.addWorkingStartWorkoutMenu = this.addWorkingStartWorkoutMenu.bind(this);
		this.addEditStartWorkoutMenu = this.addEditStartWorkoutMenu.bind(this);
		this.deleteWorkingStartWorkoutMenu = this.deleteWorkingStartWorkoutMenu.bind(this);
		this.deleteEditStartWorkoutMenu = this.deleteEditStartWorkoutMenu.bind(this);
	}

	editStartWorkoutMenu() {
		const editExerciseTypeId = this.store?.getProperty('editExerciseTypeId');
		const editComment = this.store?.getProperty('editComment');
		const startWorkoutMenu_log_id = this.store?.getProperty('startWorkoutMenu_log_id');
		const startWorkoutMenu_sets = this.store?.getProperty('editWorkingList') || [];
		if (!editExerciseTypeId) {
			this.errorNotification('Choose an exercise');
			return;
		}
		if (!startWorkoutMenu_log_id) {
			this.errorNotification('Choose startWorkoutMenu to edit!');
			return;
		}
		this.api.patchApiAsync(this.getUrl('workout'), { exercise_type_id: editExerciseTypeId, startWorkoutMenu_comment: editComment, startWorkoutMenu_log_id, startWorkoutMenu_sets }, { name: 'editStartWorkoutMenu' });
	}

	addStartWorkoutMenu() {
		const addExerciseTypeId = this.store?.getProperty('addExerciseTypeId');
		const addComment = this.store?.getProperty('addComment');
		let startWorkoutMenu_sets = this.store?.getProperty('startWorkoutMenuWorkingList') || [];
		if (!addExerciseTypeId) {
			this.errorNotification('Please choose an exercise!');
			return;
		}
		if (!isArray(startWorkoutMenu_sets)) {
			this.errorNotification('Please add at least 1 rep!');
			return;
		}

		this.api.postApiAsync(this.getUrl('workout'), { exercise_type_id: addExerciseTypeId, startWorkoutMenu_sets, startWorkoutMenu_comment: addComment }, { name: 'addStartWorkoutMenu' });
	}

	addEditStartWorkoutMenu() {
		const reps = this.store?.getProperty('editExerciseReps');
		const weight = this.store?.getProperty('editExerciseWeight');
		if (!reps || !weight) {
			this.errorNotification('Add weight or reps!');
			return;
		}
		// set list new list
		this.store?.addGridRow('editWorkingList', [
			{
				set_weight: weight,
				set_reps: reps,
			}
		]);
	}

	addWorkingStartWorkoutMenu() {
		const reps = this.store?.getProperty('addExerciseReps');
		const weight = this.store?.getProperty('addExerciseWeight');
		if (!reps || !weight) {
			this.errorNotification('Add weight or reps!');
			return;
		}
		// set list new list
		this.store?.addGridRow('startWorkoutMenuWorkingList', [
			{
				set_weight: weight,
				set_reps: reps,
			},
		]);
		// clear working
		// this.store?.setProperty('addExerciseReps', 0);
		// this.store?.setProperty('addExerciseWeight', 0);
	}

	async initPage() {
		// load username into store
		const username = getAppStateData('username');
		this.store.setProperty('username', username);
		// set current date
		const today = util.today();
		const dateMonth = today.format('MM/DD/YYYY');
		const dateHour = today.format('hh:mm A');
		const dateDay = today.format('dd');
		const dateDayFull = today.format('dddd');
		this.store.setProperty('dateMonth', `${dateMonth} ${dateHour}`);
		this.store.setProperty('dateHour', dateHour);
		this.store.setProperty('dateDay', dateDay);
		this.store.setProperty('dateDayFull', `${dateDayFull}'s Workout`);

		await this.loadRoutineSchedules();
	}


	async loadRoutineSchedules() {
		// load exercise data for select list
		await this.api.getApiAsync(this.getUrl('routineSchedules'), { }, { name: 'loadRoutineSchedules' });
	}

	loadData(grouped_workout_log_id?: string) {
		grouped_workout_log_id = this.store.getProperty('grouped_workout_log_id');
		if (!grouped_workout_log_id) {
			return;
		}
		this.api.getApiAsync(this.getUrl('getGroup'), { grouped_workout_log_id }, { name: 'loadStartWorkoutMenu' });
	}

	async loadExercises() {
		// load exercise data for select list
		await this.api.getApiAsync(this.getUrl('exercise'), { }, { name: 'loadExercise' });
	}

	loadRoutine() {
		// load routine data for select list
		this.api.getApiAsync(this.getUrl('routine'), { }, { name: 'loadRoutine' });
	}

	loadRoutineData() {
		this.api.getApiAsync(this.getUrl('allroutine'), { }, { name: 'loadAllRoutine' });
	}
	

	deleteEditStartWorkoutMenu(item) {
		const {
			id,
		} = item;
		console.log('id', item);
		this.store.deleteGridRow('editWorkingList', id);
	}
	

	deleteWorkingStartWorkoutMenu(item) {
		const {
			id,
		} = item;
		console.log('id', item);
		this.store.deleteGridRow('startWorkoutMenuWorkingList', id);
	}


	deleteStartWorkoutMenu(item) {
		const {
			startWorkoutMenu_log_id,
		} = item;
		this.api.deleteApiAsync(this.getUrl('workout'), { startWorkoutMenu_log_id }, { name: 'deleteStartWorkoutMenu' });
	}

	/**
     * get api data before api call
     * @param options 
     * @returns 
     */
	getSubmitData(options: CustomOptions) {
		const { name } = options;
		return {};
	}

	calculateTitle(data) {
		const {
			exercise_duration,
			exercise_info,
			exercise_type_id,
			exercise_weight_unit,
			log_date,
			startWorkoutMenu_comment,
		} = data;

		const formattedDate = log_date;

		return `${formattedDate} - ${exercise_info}`;
	}

	/**
     * transform response function after api call 
     * @param data 
     * @param options 
     */
	transformData(data: any, options: CustomOptions) {
		const { name } = options;
		if (name === 'loadRoutineSchedules') {
			return data;
		}
		if (name === 'loginApi') {
            
			return data;
		}
		if (name === 'saveData' || name === 'loadStartWorkoutMenu') {
			const exerciseList = this.store.getProperty('exerciseSelectList');
			const {
				exercises = []
			} = data.data || {};
			if (util.isArray(exercises)) {
				exercises.forEach((row) => {
					const {
						exercise_type_id,
						// exercise_info,
						workout_sets,
					} = row;

					const exercise = (exerciseList as any[]).find((item) => item.value === exercise_type_id);
					if (exercise) {
						row.exercise_type_name = exercise.exercise_type_name;
						// find highest muscle intensity
						if (util.isArray(exercise.exercise_muscles)) {
							let highest;
							let highestIntensity = 0;
							exercise.exercise_muscles.forEach((item) => {
								if (item.intensity > highestIntensity) {
									highest = item.muscle_id;
								}
							});
							if (highest && util.isString(highest)) {
								row.description = util.capitalizeFirstLetter(highest);
							}
						}
						// row.exercise_muscles = exercise.exercise_muscles;
					}

					let infoStr: string;
					if (util.isArray(workout_sets)) {
						infoStr = workout_sets.map(({ set_weight: weight, set_reps: reps }) => `${reps} x ${weight}lbs.`).join('\n');
					}
					const date = util.formatDate(row.log_date);
					row.startWorkoutMenu_subTitle = date + '\n' + infoStr;
					
				});
			}
			return data;
		}
		if (name === 'loadAllRoutine') {
			if (util.isArray(data.data)) {
				data.data.forEach((row) => {
					const {
						log_date,
					} = row;
					if (log_date) row.log_date = util.formatDate(log_date);
				});
			}
		}
		return data;
	}

	/**
     * onClick fn handler
     * @param value 
     * @param data 
     * @param props 
     */
	onClick(value: any, data: any, props: any) {
		const { name } = props;
		switch (name) {
		case 'loadBtn':
			this.loadData();
			break;
		case 'startWorkoutMenuList':
			this.calculateMuscle(data);
			break;
		case 'startWorkoutMenuList-editbtn': {
			this.store?.setProperty('startWorkoutMenu_log_id', data.startWorkoutMenu_log_id);
			this.store?.setProperty('editExerciseTypeId', data.exercise_type_id);
			this.store?.setProperty('editExerciseWeight', data.exercise_weight);
			this.store?.setProperty('editExerciseReps', data.exercise_reps);
			this.store?.setProperty('editComment', data.startWorkoutMenu_comment);
			// construct array
			let array = [];
			if (util.isArray(data.startWorkoutMenu_sets)) {
				array = data.startWorkoutMenu_sets;
			}
			this.store.setGrid('editWorkingList', array);
			this.openModal('editModal');
			break;
		}
		case 'startWorkoutMenuList-btn':
			this.deleteStartWorkoutMenu(data);
			break;
		case 'startWorkoutMenuWorkingList-btn':
			this.deleteWorkingStartWorkoutMenu(data);
			break;
		case 'editWorkingList-btn':
			this.deleteEditStartWorkoutMenu(data);
			break;
		case 'addNewBtn':
			this.openModal('addModal');
			break;
		case 'addBtn':
			this.addWorkingStartWorkoutMenu();
			break;
		case 'editAddBtn':
			this.addEditStartWorkoutMenu();
			break;
		case 'saveBtn':
			this.addStartWorkoutMenu();
			break;
		case 'createExercise':
			this.redirectTo(this.getPath('exercise'));
			break;
		case 'createRoutine':
			this.redirectTo(this.getPath('routine'));
			break;
		case 'startBtn': {
			const routine_id = this.store.getProperty('routineId');
			this.redirectTo(this.getPath('routinestart'), { routine_id, addNew: true });
			break;
		}
		case 'routineList-editbtn': {
			const {
				routine_log_id,
				routine_id,
			} = data;
			this.redirectTo(this.getPath('routinestart'), { routine_log_id, routine_id });
			break;
		}
		case 'simpleList':
			this.selectRow(data);
			break;
		case 'btnAddSets': {
			const { id, gridName } = props;
			if (gridName && id) {
				this.addSets(id);
			}
			break;
		}
		case 'btnDeleteSet': {
			const { id, gridName, idx } = props;
			if (gridName && id && util.isNumber(idx)) {
				this.deleteSets(id, idx);
			}
			break;
		}
		case 'btnDeleteAllSet': {
			const { id, gridName, idx } = props;
			this.deleteAllSets(id);
			break;
		}
		case 'addNewRow': {
			this.addExercise();
			break;
		}
		case 'completeWorkout': {
			this.saveData();
			break;
		}
		case 'startEmptyBtn':
			this.redirectTo(this.getPath('startWorkout'));
			break;
		case 'addRoutineBtn':
			this.addRoutine();
			break;
		case 'startRoutineBtn':
			const {
				clickProps: {
					routine_id = '',	
				} = {},
			} = props;
			if (routine_id) {
				this.redirectTo(this.getPath('routinestart'), { routine_id, addNew: true });
			} 
			break;
		default:
			break;
		}
	}

	/**
	 * create component to select a routine, or create one
	 */
	addRoutine() {
		this.redirectTo(this.getPath('routine'));
	}

	async saveData() {
		// get list
		const simpleList = this.store.getProperty('simpleList') || [];
		const grouped_workout_log_id = this.store.getProperty('grouped_workout_log_id');

		if (grouped_workout_log_id) {
			await this.api.patchApiAsync(this.getUrl('patchGroup'), {
				exercises: simpleList,
				grouped_workout_log_id,
			}, {
				name: 'saveData',
			});
		} else {
			await this.api.postApiAsync(this.getUrl('postGroup'), {
				exercises: simpleList,
			}, {
				name: 'saveData',
			});
		}
	}

	addSets(id) {
		const simpleList = this.store.getProperty('simpleList');

		// update sets of that row
		const row = simpleList.find((item) => item.id === id);

		if (row) {
			if (!util.isStrictArray(row.workout_sets)) {
				row.workout_sets = [];
			}
			row.workout_sets.push({
				number: row.workout_sets.length + 1,
			});

			// rebuild and set for mobx purpose
			const newList = [...simpleList];
			this.store.setGrid('simpleList', newList);
		}
	}

	addExercise() {
		const simpleList = this.store.getProperty('simpleList');

		// update sets of that row
		simpleList.forEach((item) => {
			item.open = false;
		});

		this.store.addGridRow('simpleList', {
			routine_exercise_id: '',
			routine_exercise_name: '',
			description: 'new',
			open: true,
			sets: [],
		});
	}

	deleteSets(id, idx) {
		const simpleList = this.store.getProperty('simpleList');

		// update sets of that row
		const row = simpleList.find((item) => item.id === id);

		if (row) {
			if (!util.isStrictArray(row.workout_sets)) {
				row.workout_sets = [];
			}
			const newSets = row.workout_sets.filter((_, currentIdx) => currentIdx !== idx);
			row.workout_sets = newSets;
			row.workout_sets.forEach((item, idx) => item.number = idx + 1);

			// rebuild and set for mobx purpose
			const newList = [...simpleList];
			this.store.setGrid('simpleList', newList);
		}
	}

	deleteAllSets(id) {
		const simpleList = this.store.getProperty('simpleList');

		// update sets of that row
		const newList = simpleList.filter((item) => item.id !== id);
		this.store.setGrid('simpleList', newList);
	}

	selectRow(data) {
		const simpleList = this.store.getProperty('simpleList');

		const newSimpleList = util.isArray(simpleList) ? simpleList.map((item) => {
			if (item.id === data) {
				item.open = true;
			} else {
				item.open = false;
			}
			return item;
		}) : [];

		// set to store
		this.store.setGrid('simpleList', newSimpleList);
	}

	/**
     * response function after api call
     * @param data 
     * @param options 
     */
	responseData(data: any, options: CustomOptions): void {
		const { name } = options;
		if (name === 'loadRoutineSchedules') {
			if (data.success) {
				this.setRoutineScheduleInfo(data.data);
			} else {
				this.errorNotification('error!');
			}
		}
		if (name === 'loadStartWorkoutMenu') {
			if (data.success) {
				this.setStartWorkoutMenuInfo(data.data);
			} else {
				this.errorNotification('error!');
			}
		}
		if (name === 'loadAllRoutine') {
			if (data.success) {
				this.setAllRoutineInfo(data.data);
			} else {
				this.errorNotification('error!');
			}
		}
		if (name === 'loadExercise') {
			if (data.success) {
				this.setExerciseInfo(data.data);
			} else {
				this.errorNotification('error!');
			}
		}
		if (name === 'loadRoutine') {
			if (data.success) {
				this.setRoutineInfo(data.data);
			} else {
				this.errorNotification('error!');
			}
		}
		if (name === 'addStartWorkoutMenu') {
			if (data.success) {
				// reset list
				this.store?.setProperty('addComment', '');
				this.store?.setProperty('addExerciseWeight', 25);
				this.store?.setProperty('startWorkoutMenuWorkingList', []);
				this.loadData();
			}
		}
		if (name === 'editStartWorkoutMenu' || name === 'deleteStartWorkoutMenu') {
			if (data.success) {
				this.loadData();
			}
		}
		if (name === 'saveData') {
			if (data.success) {
				this.setStartWorkoutMenuInfo(data.data);
			}
		}
	}

	/**
	 * allow reset without pass data
	 * @param data 
	 */
	setRoutineScheduleInfo(data?: any[]) {
		data = data || this.store.getProperty('routineSchedules');
		if (util.isStrictArray(data)) {
			const todaysSchedules: any[] = [];
			const today = util.getDay(util.today());
			data.forEach((item) => {
				const {
					routine_name,
					schedule,
				} = item;
				// push each schedule into 

				if (util.isArray(schedule)) {
					schedule.forEach((single) => {
						if (single.schedule_start_date && single.recurring) {
							const dayOfWeek = util.getDay(single.schedule_start_date);

							if (dayOfWeek === today) {
								single.routine_name = routine_name;
								todaysSchedules.push(single);
							}
						}
					});
				}
			});

			// set inside store as well
			this.store.setProperty('todaysSchedules', todaysSchedules);
		} else {
			this.store.setProperty('todaysSchedules', []);
		}
	}

	setStartWorkoutMenuInfo(data: any) {
		const {
            log_date = '',
            user_id = '',
            grouped_workout_log_id = '',
            exercises = [],
		} = data || {};

		this.store.setProperty('log_date', log_date);
		this.store.setProperty('user_id', user_id);
		this.store.setProperty('grouped_workout_log_id', grouped_workout_log_id);
		this.store.setGrid('simpleList', exercises);
	}
	setAllRoutineInfo(data: any) {
		// set data to input
		if (util.isArray(data)) this.store.setProperty('routineList', data);
	}

	calculateMuscle(rowItem: any) {
		const {
			exercise_muscles
		} = rowItem;
		const hashMuscle: any = {};
		if (!exercise_muscles) return;

		if (util.isArray(exercise_muscles)) {
			exercise_muscles.forEach((muscleData) => {
				const {
					muscle_id: id,
				} = muscleData;
				if (id) {
					hashMuscle[id] = muscleData;
				}
			});
		}
		modelData.forEach((item) => {
			if (hashMuscle[item.id]) {
				const {
					intensity: value = 0,
				} = hashMuscle[item.id];
				if (util.isNumberNonStrict(value)) {
					this.updateModelColor(item.id, value, false);
				}
			}
		});
	}

	updateModelColor(id, value, moveCamera = true) {
		const color = getMuscleColor(value);
		this.callComponentHook('pageLayout', 'updateColor', id, color, moveCamera);
	}



	setExerciseInfo(data: any) {
		if (util.isArray(data)) {
			data.forEach((item) => {
				item.value = item.exercise_type_id;
				item.label = item.exercise_type_name;
			});
			// set to select store items ui name
			this.uiStore.setProperty(['input', 'addExerciseTypeId', 'items'], data);
			this.uiStore.setProperty(['input', 'editExerciseTypeId', 'items'], data);
			this.store.setProperty(['exerciseSelectList'], data);
		}
	}

	setRoutineInfo(data: any) {
		if (util.isArray(data)) {
			data.forEach((item) => {
				item.value = item.routine_id;
				item.label = item.routine_name;
			});
			// set to select store items ui name
			this.uiStore.setProperty(['input', 'routineId', 'items'], data);
			this.store.setProperty(['routineSelectList'], data);
		}
	}

	/**
     * onOk fn handler
     * @param value 
     * @param data 
     * @param props 
     */
	onOk(value: any, data: any, props: any) {
		const { name } = props;
		switch (name) {
		case 'editModal':
			this.editStartWorkoutMenu();
			break;
		default:
			break;
		}
	}

	/**
     * onCancel fn handler
     * @param value 
     * @param data 
     * @param props 
     */
	onCancel(value: any, data: any, props: any) {
		const { name } = props;
		switch (name) {
		case 'editModal':
			// clear
			this.store.setProperty('editExercise', '');
			this.store.setProperty('exercise_type_id', '');
			this.store.setProperty('exercise_type', '');
			break;
		case 'addModal':
			// clear
			this.store.setProperty('addExercise', '');
			break;
		default:
			break;
		}
	}
}