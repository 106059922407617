import React, { useEffect } from 'react';
import { View, StyleSheet } from 'react-native';
import { useResetPasswordService } from './service';
import { uiState } from './resetPassword.ui';
import { ResetPasswordStore } from './resetPassword.store';
import { FAHeader, FALink, FAStoreButton, FAStoreInput, FAStoreTypography, FAText, PageLayout } from '../../components';
import { useServiceRouter } from '../../service';

export const ResetPassword: React.FC = () => {
	const screenName = 'ResetPasswordPage';
	const service = useResetPasswordService(screenName, ResetPasswordStore, uiState);
	useServiceRouter(service);

	useEffect(() => {
		service.startPage();
	}, []);

	console.log('Render ResetPassword screen');
	return (
		<PageLayout>
			<View style={styles.layout}>
				<View style={styles.item}>
					<FAStoreTypography name='resetHeader'>Reset your email!</FAStoreTypography>
					<FAStoreTypography name='forgotHeader'>Forgot your password? Enter your email!</FAStoreTypography>
				</View>
				<View style={styles.item}>
					<FAStoreInput name='resetPassword' />
					<FAStoreInput name='email' />
				</View>
				<View style={styles.item}>
					<FAStoreInput name='confirmPassword' />
				</View>
				<View style={styles.item}>
					<FAStoreButton name='resetBtn' />
					<FAStoreButton name='submitBtn' />
				</View>
			</View>
		</PageLayout>
	);
};
const styles = StyleSheet.create({
	layout: {
		height: '100%',
		display: 'flex',
		flexDirection: 'column',
		margin: 5,
	},
	item: {
		padding: 5,
	},
});