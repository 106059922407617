import React, { useMemo, useState } from 'react';
import { FASelectProps } from './FASelectProps';
import { InputGroup, InputLeftAddon, Select, View } from 'native-base';
import * as util from '../../util';
import { StyleSheet } from 'react-native';
import { FAText } from '../typography';
import { FAButton } from '../button';
import { themeColors } from '../../enums';
import { textStyle } from '../../enums/styling';

export const FASelectButton: React.FC<FASelectProps> = (props: FASelectProps) => {
	const {
		name,
		items,

		// placeholder,
		// color,
		// placeholderTextColor,
		// _item,
		// _selectedItem,
		// selectedValue,
		// defaultValue,
		// label,
		// // onValueChange,
		// isDisabled,
		// isHovered,
		// isFocused,
		// isFocusVisible,
		// dropDownIcon,
		// dropdownOpenIcon,
		// dropDownCloseIcon,
		// variant,
		// onOpen,
		// onClose,
		// _actionSheet,
		// _actionSheetContent,
		// _actionSheetBody,
		// wrapperRef,
		value,
		setValue,
		getValue,
		defaultValue = '',
		style,
		onClick,
	} = props;
    
	const getStoreValue = () => {
		return (getValue && getValue(props)) || value;
	};

	const setStoreValue = (value: any) => {
		return setValue && setValue(value, props);
	};

	// const attributes: any = {
	// 	name, 
	// 	items,

	// 	placeholder,
	// 	color,
	// 	placeholderTextColor,
	// 	_item,
	// 	_selectedItem,
	// 	// selectedValue,
	// 	defaultValue,
	// 	// onValueChange,
	// 	isDisabled,
	// 	isHovered,
	// 	isFocused,
	// 	isFocusVisible,
	// 	dropDownIcon,
	// 	dropdownOpenIcon,
	// 	dropDownCloseIcon,
	// 	variant,
	// 	onOpen,
	// 	onClose,
	// 	_actionSheet,
	// 	_actionSheetContent,
	// 	_actionSheetBody,
	// 	wrapperRef
	// };

	const selectValue = getStoreValue();

	// const handleChange = (text: string) => {
	// 	setStoreValue(text);
	// 	// assume data is always same format inside
	// 	if (util.isArray(items)) {
	// 		// assume value is a unique value
	// 		const data = util.isString(items[0]) ? text : items.find((item) => item.value === text);
	// 		if (onClick) onClick(text, data, props);
	// 	}
	// };
	// attributes.onValueChange = handleChange;

	// const children = useMemo(() => {
	// 	if (util.isArray(items)) {
	// 		const children = items.map((item, idx)=>{
	// 			const {
	// 				label,
	// 				value,
	// 			} = util.isString(item) ? { label: item, value: item } : item;
	// 			return {
	// 				label,
	// 				value,
	// 			}
	// 		});
	// 		return children;
	// 	} return [];
	// }, [items]);
	
	const handleChange = (value: any) => {
		setStoreValue(value);
		if (onClick) onClick(value, value, props);
	};
    
	console.log('Render FA Select', name);
	return (
		<View style={[styles.container, style]}>
			{
				items.map((item, idx)=>{
					const {
						label,
						value,
					} = util.isString(item) ? { label: item, value: item } : item;
					
					const selected = selectValue === value;

					const clickButtonFn = () => {
						if (!selected) {
							// set this value
							handleChange(value);
						} else {
							// set undefined
							handleChange(defaultValue);
						}
					};

					return (
						<View key={value} style={styles.selectButton}>
							<FAButton
								name={value}
								onClick={clickButtonFn}
								style={styles.btn}
								children={label}
								backgroundColor={selected ? themeColors.primaryLight : 'transparent'}
								_text={{
									color:themeColors.trueBlack,
									...textStyle.h3,
									fontWeight: '500',
								}}
							/>
						</View>
					)
				})
			}
		</View>
	);
};

const styles = StyleSheet.create({
	container: {
		display: 'flex',
		flexDirection: 'column',
		width: '100%',
		padding: 4,
		borderRightWidth: 1,
		borderRightColor: themeColors.gray,
	},
	selectButton: {
		// margin: 5,
		// display: 'flex',
		// flexDirection: 'column',
		// gap: 8,
	},
	btn: {
		width: '100%',
		padding: '4px',
		paddingLeft: '10px',
		paddingRight: '10px',
		borderRadius: 16,
		display: 'flex',
		justifyContent: 'flex-start',
		marginBottom: 8
	},
	input: {
		flex: 1,
	},
	label: {
		margin: 5,
		justifyContent: 'center',
		alignSelf: 'center',
	},
});
