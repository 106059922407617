import React, { SVGProps } from 'react';
import { themeColors } from '../../enums';
import Svg, { Path } from 'react-native-svg';

// const menuStartIcon: React.FC<SVGProps<SVGAElement>> = ({
//     height = '2em',
//     width = '2em',
//     viewBox = '-1.1 -1 22 22',
//     color = '',
// }) => {
//     return (
//         <svg width={width} height={height} viewBox={viewBox} fill="none" xmlns="http://www.w3.org/2000/svg">
//             <path d="M13.2519 9.16795L10.0547 7.03647C9.39015 6.59343 8.5 7.06982 8.5 7.86852V12.1315C8.5 12.9302 9.39015 13.4066 10.0547 12.9635L13.2519 10.8321C13.8457 10.4362 13.8457 9.56377 13.2519 9.16795Z" stroke={color} stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
//             <path d="M19.5 10C19.5 14.9706 15.4706 19 10.5 19C5.52944 19 1.5 14.9706 1.5 10C1.5 5.02944 5.52944 1 10.5 1C15.4706 1 19.5 5.02944 19.5 10Z" stroke={color} stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
//         </svg>
//     );
// };
/**
 * FOR REACT NATIVE to work with style: need to use Svg, Path from react-native-svg
 */
const menuStartIcon = ({
    // em does not work, only number values, px, and dp values
    height = '30px',
    width = '30px',
    viewBox = '0 0 24 24',
    color = 'white',
}) => {
    return (
        <Svg width={width} height={height} viewBox={viewBox} fill={'none'}>
            <Path d="M13.2519 9.16795L10.0547 7.03647C9.39015 6.59343 8.5 7.06982 8.5 7.86852V12.1315C8.5 12.9302 9.39015 13.4066 10.0547 12.9635L13.2519 10.8321C13.8457 10.4362 13.8457 9.56377 13.2519 9.16795Z" stroke={color} stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
            <Path d="M19.5 10C19.5 14.9706 15.4706 19 10.5 19C5.52944 19 1.5 14.9706 1.5 10C1.5 5.02944 5.52944 1 10.5 1C15.4706 1 19.5 5.02944 19.5 10Z" stroke={color} stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
        </Svg>
    );
};

export default menuStartIcon;