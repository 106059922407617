import _ from 'lodash';

export type DeepPartial<T> = T extends object ? {
    [P in keyof T]?: DeepPartial<T[P]>;
} : T;

/**
* Check if target is null or undefined
* @param {any} target - object
* @return {bool} is function
*/
export const isNil = (target: any): boolean => {
    return (target == null || target === undefined);
};

/**
* Check if target is object and has properties
* @param {any} target - object
* @return {bool} is object with property
*/
export const isObject = (target: any): boolean => {
    return !isNil(target) && _.isObject(target) && (Object.keys(target)?.length > 0);
};

/**
* Check target is array and length > 0 
* @param {any} target - object
* @return {bool} is function
*/
export const isArray = (target: any): target is any[] => {
    return (
        !isNil(target) && _.isArray(target) && target.length > 0
    );
};

/**
* Check target is array
* @param {any} target - object
* @return {bool} is function
*/
export const isArrayNonStrict = (target: any): target is any[] => {
    return (
        !isNil(target) && _.isArray(target)
    );
};

/**
* Check target is array
* @param {any} target - object
* @return {bool} is function
*/
export const isNumber = (target: any): target is number => {
    return (
        !isNil(target) && _.isNumber(target)
    );
};

/**
 * check if undefined or null
 * @param target 
 * @returns 
 */
export const isFunction = (target: any) => {
	return _.isFunction(target);
};

/**
 * check if object and not check has keys
 * @param target 
 * @returns 
 */
export const isStrictObject = (target: any): target is any => {
	return !isNil(target) && _.isObject(target);
};

/**
 * check if array
 * @param target 
 * @returns 
 */
export const isStrictArray = (target: any): target is any[] => {
	return !isNil(target) && _.isArray(target);
};

/**
 * check if string
 * @param target 
 * @returns 
 */
export const isString = (target: any): target is string => {
	return !isNil(target) && _.isString(target);
};

export const isNumberNonStrict = (target: any): target is number => {
	return  _.isNumber(target);
};

export const getUuid = (prefix?: string) => {
	return _.uniqueId(prefix);
};
