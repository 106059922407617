import React, { SVGProps } from 'react';
import Svg, { Path } from 'react-native-svg';

// const menuMeIcon: React.FC<SVGProps<SVGAElement>> = ({
//     height = '1.5em',
//     width = '1.5em',
//     viewBox = '0 0 24 24',
//     color = ''
// }) => {
//     return (
//         <svg width={width} height={height} viewBox={viewBox} fill="none" xmlns="http://www.w3.org/2000/svg">
//             <path d="M17.1666 8.25199C17.1666 10.5532 15.3011 12.4187 12.9999 12.4187C10.6987 12.4187 8.83325 10.5532 8.83325 8.25199C8.83325 5.95081 10.6987 4.08533 12.9999 4.08533C15.3011 4.08533 17.1666 5.95081 17.1666 8.25199Z" fill="white"/>
//             <path d="M12.9999 13.6229C8.97284 13.6229 5.70825 16.8875 5.70825 20.9146H20.2916C20.2916 16.8875 17.027 13.6229 12.9999 13.6229Z" fill="white"/>
//         </svg>
//     );
// };
/**
 * FOR REACT NATIVE to work with style: need to use Svg, Path from react-native-svg
 */
const menuMeIcon = ({
    // em does not work, only number values, px, and dp values
    height = '100%',
    width = '100%',
    viewBox = '0 0 24 24',
    color = 'white',
}) => {
    return (
        <Svg width={width} height={height} viewBox={viewBox} fill={color} color={color}>
            <Path d="M17.1666 8.25199C17.1666 10.5532 15.3011 12.4187 12.9999 12.4187C10.6987 12.4187 8.83325 10.5532 8.83325 8.25199C8.83325 5.95081 10.6987 4.08533 12.9999 4.08533C15.3011 4.08533 17.1666 5.95081 17.1666 8.25199Z" fill="white"/>
            <Path d="M12.9999 13.6229C8.97284 13.6229 5.70825 16.8875 5.70825 20.9146H20.2916C20.2916 16.8875 17.027 13.6229 12.9999 13.6229Z" fill="white"/>
        </Svg>
    );
};

export default menuMeIcon;