import React from 'react';
import { FATextAreaProps } from './FATextAreaProps';
import { TextArea } from 'native-base';

export const FATextArea: React.FC<FATextAreaProps> = (props: FATextAreaProps) =>{
	const {
		name,

		totalLines,
		// service props
		value,
		setValue,
		getValue,
		size,
		placeholder,
	} = props;
    
	const getStoreValue = () => {
		return (getValue && getValue(props)) || value;
	};

	const setStoreValue = (value: any) => {
		return setValue && setValue(value, props);
	};

	const attributes: any = {
		name,
		placeholder,
		totalLines,
		size,
	};

	attributes.value = getStoreValue();

	const handleChange = (text: string) => {
		setStoreValue(text);
	};
	attributes.onChangeText = handleChange;

	console.log('Render FA TextArea', name);
	return <TextArea {...attributes}/>;
};