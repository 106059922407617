import React, { useEffect, useState } from 'react';
import { StyleSheet, Text } from 'react-native';
import { Pressable, View, theme } from 'native-base';
import { useStartWorkoutService } from './service';
import { uiState } from './startWorkout.ui';
import { StartWorkoutStore } from './startWorkout.store';
import { FAStoreTypography, FAStoreInput, PageLayout, FAHeader, FAStoreGrid, FAStoreButton, FAStoreModal, Tabs, FAButton, FAText } from '../../components';
import { getService, useService, useServiceRouter } from '../../service';
import { useComponentRefresh } from '../../hooks';
import { useStoreGridHooks } from '../../hooks/component';
import * as util from '../../util';
import { ComponentEnum, icons, themeColors } from '../../enums';
import { Exercise } from '../Exercise';
import { ExerciseSelect } from '../Exercise/Exercise';
import { btnStyle, textStyle } from '../../enums/styling';

const RenderSimpleItem: React.FC<any> = (props: any) => {
	const {
		index,
		item = {},
		item: {
			id = '',
		},
		seperators,
	} = props;

	const [refresh] = useComponentRefresh();
	

	const gridProps = useStoreGridHooks('simpleList', id);

	// get grid row
	const service = getService();

	// get row
	const row = service.store.getGridRow('simpleList', id);
	
	const {
		open = false,
		workout_sets = [],
	} = row || {};

	const onClick = (evt) => {
		if (open) {
			// already open
			return;
		}
		service.onClick(evt, id, { name: 'simpleList', rowClick: true });
	};

	if (open) {
		return (
			<Pressable
				style={styles.gridRowOpen}
				onPress={(evt) => onClick(evt)}
			>
				<View style={styles.gridRowOpenTitle}>
					<FAStoreTypography
						name='exercise_type_name'
						color={themeColors.primaryDark}
						fontWeight={600}
						style={textStyle.h2}
						{...gridProps}
	
					/>
					<FAStoreTypography
						name='description'
						color={themeColors.gray}
						fontWeight={500}
						style={textStyle.h3}
						{...gridProps}
						marginLeft={5}
					/>
					<FAStoreButton
						name='btnDeleteAllSet'
						children={'x'}
						backgroundColor={'transparent'}
						_text={{
							color: themeColors.secondaryDark,
							fontWeight: 600,
							fontSize: 20
						}}
						{...{
							// icon: icons.add
							// borderColor: 'gray.600',
							// borderWidth: 4,
							// borderRadius: 20,
							// padding: 0,
							width: 30,
							height: 30,
							marginLeft: 'auto'
						}}
						{...{
							...gridProps,
						}}
					/>
				</View>
				{/* <FAStoreInput name='test' {...gridProps} /> */}
					{util.isArrayNonStrict(workout_sets) && (
						<View style={styles.sets}>
							{/* header */}
							<View style={styles.singleSet} borderBottomColor={themeColors.gray} borderBottomWidth={1}>
								{/* <View style={styles.setNumber}> */}
									<FAText name='setNumber' style={[textStyle.h3, {color: themeColors.darkGray, width: '15%', textAlign: 'center'}]}>Set</FAText>
								{/* </View>
								<View style={styles.setPreviousWeight}> */}
									<FAText name='setNumber' style={[textStyle.h3, {color: themeColors.darkGray, width: '25%', textAlign: 'center'}]}>Previous</FAText>
								{/* </View>
								<View style={styles.setWeight}> */}
									<FAText name='setNumber' style={[textStyle.h3, {color: themeColors.darkGray, width: '25%', textAlign: 'center'}]}>lbs</FAText>
								{/* </View>
								<View style={styles.setReps}> */}
									<FAText name='setNumber' style={[textStyle.h3, {color: themeColors.darkGray, width: '25%', textAlign: 'center'}]}>Reps</FAText>
								{/* </View> */}
							</View>
							{workout_sets.map((set, idx) => {
								const {
									number,
									previous,
									// set_reps,
									// set_weight,
								} = set;
	
								return (
									<View style={styles.singleSet} key={`${number}-${idx}`}>
										{/* <View style={styles.setNumber}> */}
											<FAText name='setNumber' style={[textStyle.h2, {color: themeColors.trueBlack, width: '15%', textAlign: 'center', fontWeight: 600}]}>{number}</FAText>
										{/* </View>
										<View style={styles.setPreviousWeight}> */}
											<FAText name='setNumber' style={[textStyle.h2, {color: themeColors.trueBlack, width: '25%', textAlign: 'center', fontWeight: 600}]}>{previous}</FAText>
										{/* </View>
										<View style={styles.setWeight}> */}
											<FAStoreInput
												name='set_weight'
												getValue={(props) => set.set_weight}
												setValue={(value, props) => {
													set.set_weight = value;
													refresh();
												}}
												format='number'
												width='25%'
												borderRadius={10}
												margin={'2px'}
											/>
										{/* </View>
										<View style={styles.setReps}> */}
											<FAStoreInput
												name='set_reps'
												getValue={(props) => set.set_reps}
												setValue={(value, props) => {
													set.set_reps = value;
													refresh();
												}}
												format='number'
												width='25%'
												borderRadius={10}
											/>
										{/* </View> */}
										<View style={{width: '10%'}}>
											<FAStoreButton
												name='btnDeleteSet'
												children={'x'}
												backgroundColor={'transparent'}
												_text={{
													color: themeColors.secondaryDark,
													fontWeight: 600,
													fontSize: 16
												}}
												{...{
													// icon: icons.add
													// width: 30,
													// height: 30,
													// marginLeft: 'auto'
												}}
												{...{
													...gridProps,
													idx,
												}}
											/>
										</View>
									</View>	
								)
							})}
							<FAStoreButton
								name='btnAddSets'
								{...gridProps}
							/>
						</View>
					)}
			</Pressable>
		);
	}

	const maxWeight = util.isArray(workout_sets) ? Math.max(0, ...(workout_sets.map((set) => set.set_weight))) : 0;

	const isDone = util.isArray(workout_sets) ? workout_sets.every((item) => item.set_weight > 0) : false;
	
	return (
		<Pressable
			style={styles.gridRowClosed}
			backgroundColor={themeColors.gray}
			onPress={(evt) => onClick(evt)}
		>
			<View style={styles.gridRowClosedSection}>
				<FAStoreTypography
					name='exercise_type_name'
					color={themeColors.trueBlack}
					style={textStyle.h2}
					fontWeight={600}
					{...gridProps}

				/>
				<FAStoreTypography
					name='description'
					color={themeColors.secondaryBase}
					fontWeight={500}
					style={textStyle.h3}
					{...gridProps}
				/>
			</View>
			<View style={styles.gridRowClosedSection2}>
				<View style={styles.setNumber}>
					{maxWeight > 0 && (
						<FAText
							name='setNumber'
							color={themeColors.primaryBase}
							style={textStyle.h2}
							fontWeight={600}
							bold
						>
							{`${maxWeight} lbs`}
						</FAText>
					)}
				</View>
				<View style={styles.setNumber}>
					{workout_sets.length > 0 && (
						<FAText
							name='setNumber'
							color={themeColors.secondaryBase}
							style={textStyle.h2}
							fontWeight={500}
						>
							{`${workout_sets.length} sets`}
						</FAText>
					)}
				</View>
			</View>
			{/* <View style={styles.gridRowClosedSection2}>
				{isDone && (
					<FAText
						name='setNumber'
						color='blue.700'
						fontWeight={700}
					>
						DONE!
					</FAText>
				)}
			</View> */}
		</Pressable>
	);
};


export const StartWorkout: React.FC = () => {
	const screenName = 'StartWorkoutPage';
	const service = useStartWorkoutService(screenName, StartWorkoutStore, uiState);
	const [refresh] = useComponentRefresh();
	useServiceRouter(service);

	useEffect(() => {
		const run = async () => {
			await service.initPage();
		}
		run();
	}, []);

	const IconAdd = icons.add;

	console.log('Render StartWorkout screen');
	return (
		<PageLayout>
			{/* <FAButton name='t' onClick={refresh}>refresh</FAButton> */}
			<View style={styles.welcome}>
				<View style={styles.welcomeInside}>
					<FAHeader style={textStyle.title} fontWeight={600} name='letsGo'>
						Let's Go, 
					</FAHeader>
					<FAStoreTypography style={textStyle.title} fontWeight={600} name='username' />
					{/* <FAHeader name='!'>!</FAHeader> */}
				</View>
				{/* date */}
				<View style={styles.welcomeInside}>
					<FAStoreTypography 
						name='dateMonth'
						color='gray.500'
						fontWeight={500}
						style={textStyle.h2}
					/>
					<FAStoreTypography 
						name='dateHour'
						color='gray.500'
						fontWeight={500}
						style={textStyle.h2}
					/>
					<FAStoreTypography 
						name='dateDay'
						color='gray.500'
						fontWeight={500}
						style={textStyle.h2}
					/>
				</View>
			</View>
			{/* grid component */}
			<View style={styles.grid}>
				<FAStoreGrid
					name='simpleList'
					renderComponent={RenderSimpleItem}
					style={styles.gridContainer}
				/>
				<FAStoreButton
					name='addNewRow'
					componentType={ComponentEnum.ButtonTypeEnum.Icon}
					icon={icons.add}
					_icon={{
						borderColor: themeColors.secondaryBase,
						borderWidth: 2,
						padding: '10px',
						borderRadius: 100,
						borderStyle: 'solid',
						color: themeColors.secondaryBase,
					}}
					backgroundColor={themeColors.gray}
					borderRadius={10}
					padding={'8px'}
				/>
			</View>
			<View style={styles.bottom}>
				<FAStoreButton
					name='stopWorkout'
					backgroundColor='transparent'
					children='Stop Workout'
					_text={{
						color: 'white',
						...textStyle.btnText_Regular
					}}
					{...{
						borderRadius: 25,
						borderColor: 'white',
						borderWidth: 2,
					}}
				/>
				<FAStoreButton
					name='completeWorkout'
					style={btnStyle.accent}
					// backgroundColor={themeColors.accent}
					children='Complete'
					_text={{
						color: 'black',
						...textStyle.btnText_Regular
					}}
					// {...{
					// 	borderRadius: 30,
					// }}
				/>
			</View>
			<FAStoreModal
				name='selectExercise'
				component={ExerciseSelect}
				componentProps={{
					selectMode: true,
					navigate: service.routing.navigate,
					location: service.routing.location,
					params: service.routing.params,
					searchParams: service.routing.searchParams,
				}}
				onCancel={() => {
					useStartWorkoutService(screenName, StartWorkoutStore, uiState);
					refresh();
				}}
				onOk={(evt, data, props) => {
					useStartWorkoutService(screenName, StartWorkoutStore, uiState);
					refresh();
					service.onOk(evt, data, props);
				}}
			/>
		</PageLayout>
	);
};

const styles = StyleSheet.create({
	sets: {
		display: 'flex',
		flexDirection: 'column',
		width: '100%',
		borderRadius: 10,
		padding: 6,
		backgroundColor: 'rgba(255, 255, 255, 0.7)'
	},
	singleSet: {
		display: 'flex',
		flexDirection: 'row',
		justifyContent: 'flex-start',
		width: '100%',
		alignItems: 'center',
		// marginBottom: 4,
		gap: 1
	},
	setNumber: {
		flex: 1,
	},
	setPreviousWeight: {
		flex: 1,
	},
	setWeight: {
		flex: 1,
	},
	setReps: {
		flex: 1,
	},
	grid: {
		// margin: 5,
		flexGrow: 1,
		flexShrink: 1,
		flexBasis: 0,
		overflow: 'scroll',
		justifyContent: 'flex-start',
		padding: '20px'
	},
	gridContainer: {
		// height: 'fit-content',
		flex: 'none',
		// display: 'flex',
		// flexDirection: 'column',
		// gap: 8,
	},
	gridRowClosed: {
		display: 'flex',
		flexDirection: 'row',
		justifyContent: 'space-between',
		padding: 10,
		height: 68,
		marginBottom: 6,
		// margin: 10,
		borderRadius: 10,
	},
	gridRowClosedSection: {
		display: 'flex',
		flex: 'auto',
		flexDirection: 'column',
	},
	gridRowClosedSection2: {
		display: 'flex',
		// flex: 1,
		width: '80px',
		flexDirection: 'column',
		justifyContent: 'center',
		alignItems: 'center',
	},
	gridRowOpen: {
		display: 'flex',
		flexDirection: 'column',
		padding: 10,
		// margin: 10,
		borderRadius: 10,
		gap: 12,
		marginBottom: 6,
		backgroundColor: themeColors.accentLight,
	},
	gridRowOpenTitle: {
		display: 'flex',
		flexDirection: 'row',
		alignItems: 'center',
	},
	container: {
		display: 'flex',
		width: '100%'
	},
	welcome: {
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
		margin: 20,
	},
	welcomeInside: {
		display: 'flex',
		flexDirection: 'row',
		gap: 8,
	},
	formContainer: {
		display: 'flex',
		width: '100%',
		flexDirection: 'row',
	},
	exercise: {
		display: 'flex',
		flexDirection: 'row',
		flex: 1,
	},
	form: {
		flex: 4,
		margin: 5,
		justifyContent: 'space-between',
	},
	button: {
		flex: 1,
		justifyContent: 'space-between',
		width: 50,
		height: 50,
	},
	info: {
		display: 'flex',
		flexDirection: 'row',
		flex: 1,
	},
	infoGrow: {
		flex: 1,
		flexGrow: 1,
		flexDirection: 'row',
	},
	editComponent: {
		display: 'flex',
		flexDirection: 'column',
	},
	list: {
		maxHeight: 120,
	},
	listHeader: {
	},
	save: {
		display: 'flex',
		flexDirection: 'row',
		flex: 1,
		justifyContent: 'center',
		alignContent: 'center',
	},
	saveGrow: {
		flex: 1,
	},
	flex: {
		flex: 2,
	},
	flexBtn: {
		flex: 1,
		margin: 5,
	},
	bottom: {
		width:'100%',
		display: 'flex',
		flexDirection: 'row',
		justifyContent: 'space-between',
		padding: '20px',
		paddingBottom: '36px',
		borderTopLeftRadius: 20,
		borderTopRightRadius: 20,
		backgroundColor: themeColors.secondaryBase
	},
})
