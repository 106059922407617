import React from 'react';
import { FAInputProps } from './FAInputProps';
import { Input, InputGroup, InputLeftAddon, View } from 'native-base';
import { StyleSheet } from 'react-native';
import { FAText } from '../typography';
import { formatNumber } from '../../util';

export const FAInput: React.FC<FAInputProps> = (props: FAInputProps) =>{
	const {
		name,

		size = 'xl',
		variant,
		isHovered,
		isFocused,
		isInvalid,
		isDisabled,
		isRequired,
		isReadOnly,
		isFullWidth,
		inputLeftElement,
		leftElement,
		inputRightElement,
		rightElement,
		type,
		wrapperRef,
		_hover,
		_focus,
		_disabled,
		_readOnly,
		_invalid,
		_input,
		_stack,
		focusOutlineColor,
		invalidOutlineColor,
		placeholder,
		format,
		textAlign,
		label,
		style,
		width,
		borderRadius,
		// service props
		value,
		setValue,
		getValue,
		uiReadOnly,
	} = props;
    
	const getStoreValue = () => {
		let storeValue = (getValue && getValue(props)) || value;
		if (format === 'number') {
			storeValue = formatNumber(storeValue);
			storeValue = (storeValue).toString();
		}
		return storeValue;
	};

	const setStoreValue = (value: any) => {
		if (format === 'number') {
			value = formatNumber(value);
		}
		return setValue && setValue(value, props);
	};

	const readOnly = uiReadOnly ? uiReadOnly(name, props) : false;
	
	const attributes: React.ComponentProps<typeof Input> = {
		size,
		variant,
		isHovered,
		isFocused,
		isInvalid,
		isDisabled,
		isRequired,
		isReadOnly,
		isFullWidth,
		leftElement: leftElement as any,
		rightElement: rightElement as any,
		type,
		wrapperRef,
		_hover,
		_focus,
		_disabled,
		_readOnly,
		_invalid,
		_input,
		_stack,
		focusOutlineColor,
		invalidOutlineColor,
		placeholder,
		textAlign,
		width,
		borderRadius,
		style: {
			...style,
		},
		returnKeyType: 'done',
		InputLeftElement: label && (
			<FAText name='label' style={styles.label}>{label}</FAText>
		),
	};

	if (format === 'number') {
		attributes.keyboardType = 'numeric';
		attributes.textAlign = 'right';
	}

	attributes.value = getStoreValue();

	const handleChange = (text: string) => {
		setStoreValue(text);
	};
	attributes.onChangeText = handleChange;

	console.log('Render FA Input', name);
	return (
		<Input
			{...attributes}
			isReadOnly={readOnly}
			size={size}
		/>
	);
};

const styles = StyleSheet.create({
	container: {
		flex: 1,
	},
	group: {
		flex: 1,
	},
	input: {
		flex: 1,
	},
	label: {
		margin: 5,
	},
});
