import React, { useEffect } from 'react';
import { View, StyleSheet } from 'react-native';
import { useDeleteUserService } from './service';
import { uiState } from './deleteUser.ui';
import { DeleteUserStore } from './deleteUser.store';
import { FAHeader, FAStoreButton, FAStoreInput, FAStoreModal, PageLayout } from '../../components';
import { useServiceRouter } from '../../service';

export const DeleteUser: React.FC = () => {
	const screenName = 'DeleteUserPage';
	const service = useDeleteUserService(screenName, DeleteUserStore, uiState);
	useServiceRouter(service);

	useEffect(() => {
		service.startPage();
	}, []);

	console.log('Render DeleteUser screen');
	return (
		<PageLayout>
			<View style={styles.layout}>
				<View style={styles.deleteUser}>
					<FAStoreInput name='loginId' />
					<FAStoreInput name='password' />
					<FAStoreButton name='loginBtn' />
				</View>
			</View>
			<FAStoreModal name='confirmDelete' />
		</PageLayout>
	);
};
const styles = StyleSheet.create({
	layout: {
		height: '100%',
		justifyContent: 'center'
	},
	deleteUser: {
		padding: 20,
		gap: 10,
	},
});