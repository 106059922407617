import { StyleSheet, View } from 'react-native'
import { modelData } from './modelData'
import { ModelItem, PositionType } from './ModelItem'
import React, { createRef, Suspense, useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { Environment, Canvas, CanvasProps } from './modelExports'
import { Camera, PerspectiveCamera, Vector3 } from 'three'
import { observer } from 'mobx-react-lite'
import { getService, useServiceProps } from '../../service'
import { Button } from 'native-base'
import { ComponentServiceProps, themeColors } from '../../enums'
import { margin } from '../modal/FAModalMenu'
import { FAText } from '../typography'
import { getCache, getUuid, setCache } from '../../util'

const DEBUG_MODE = true;

const DebugComponent: React.FC<{ innerRef }> = ({ innerRef }) => {
  const [text, setText] = useState('');
  
  useEffect(() => {
    innerRef.current = {
      setText,
    };
  }, []);

  return (
    <View style={styles.debugContainer}>
      <FAText name='debug'>
        {text}
      </FAText>
    </View>
  );
}

// angle need be less than absolute value PI
const truncateRadian = (angle: number) => {
  if (Math.abs(angle) >= Math.PI) {
    angle = angle % (2 * Math.PI);
    if (Math.abs(angle) >= Math.PI) {
      const sign = angle < 0 ? -1 : 1;
      angle = (2 * Math.PI - Math.abs(angle)) * -sign;
    };
  };
  return angle
}

// make sure angle stays within max and min
const capAngle = (angle: number, maxAngle: number, minAngle: number, resetFn?: (angle: number) => void) => {
  if (angle < minAngle) {
    if (resetFn) resetFn(minAngle);
    return minAngle;
  }
  if (angle > maxAngle) {
    if (resetFn) resetFn(maxAngle);
    return maxAngle;
  }
  return angle;
}

// given two angles calculate position coords
const calculatePos = (angleX: number, angleY: number, radius: number) => {
  const x = Math.cos(angleX)*Math.cos(angleY)*radius;
  const z = Math.sin(angleX)*Math.cos(angleY)*radius;
  const y = Math.sin(angleY)*radius;
  return {
    x,
    y,
    z,
  };
}

interface CamProps {
  setText?: (angleX: any, angleY: any) => void;
  smoothMove?: boolean;
}

const useCamera = (props: CamProps) => {

  const last = useRef<{
    touchX?: number,
    touchY?: number,
    lastAngleX: number,
    lastAngleY: number,
  }>({ lastAngleX: Math.PI/2, lastAngleY: .08*Math.PI });

  const animation = useRef<string>();

  const camera = new PerspectiveCamera();
  const radius = 32;
  const factorDivX = 10000;
  const factorDivY = 15000;
  const maxY = Math.PI/3;
  const minY = -Math.PI/3;
  const time = 200;

  const setPos = (angleX: number, angleY: number) => {
    if (props.setText) props.setText(angleX, angleY);
    const {
      x,
      y,
      z,
    } = calculatePos(angleX, angleY, radius);
    camera.position.set(x, y, z);
    const lookAt = [-x, -y, -z];
    camera.lookAt(new Vector3(...lookAt));
    camera.updateProjectionMatrix();
  }

  const touchCamera = useCallback((posX: number, posY: number, type: 'start' | 'end' | 'move', factor: number) => {
    if (type === 'start') {
      last.current.touchX = posX;
      last.current.touchY = posY;
      if (animation.current) animation.current = '';
    }
    if (type === 'end') {
      // x
      const differenceX = (posX - last.current.touchX) * factor/factorDivX;
      const newAngleX = last.current.lastAngleX + differenceX;
      last.current.lastAngleX = newAngleX;
      // y
      const differenceY = (posY - last.current.touchY) * factor/factorDivY;
      const newAngleY = capAngle(last.current.lastAngleY + differenceY, maxY, minY);
      last.current.lastAngleY = newAngleY;
    }
    if (type === 'move') {
      // x
      const differenceX = (posX - last.current.touchX) * factor/factorDivX;
      const newAngleX = last.current.lastAngleX + differenceX;
      // y
      const differenceY = (posY - last.current.touchY) * factor/factorDivY;
      const newAngleY = capAngle(last.current.lastAngleY + differenceY, maxY, minY, (angle: number) => {
        // reset last angle
        last.current.lastAngleY = angle;
        // reset touch
        last.current.touchY = posY;
      });
      setPos(newAngleX, newAngleY);
    }
  }, []);

  useEffect(() => {
    setPos(last.current.lastAngleX, last.current.lastAngleY);
  }, [setPos]);

  const setPosFn = (angleX: number, angleY: number) => {
    last.current.lastAngleX = angleX;
    last.current.lastAngleY = capAngle(angleY, maxY, minY);
    setPos(angleX, last.current.lastAngleY);
  }

  const setPosHook = (angleX: number, angleY: number) => {
    if (props.smoothMove) {
      const animationUuid = getUuid();
      const increment = 20;
      const currentX = last.current.lastAngleX;
      const currentY = last.current.lastAngleY;
      animation.current = animationUuid;
      let xDif = truncateRadian(angleX - currentX);
      let yDif = truncateRadian(angleY - currentY);

      const xDifIncrement = xDif / increment;
      const yDifIncrement = yDif / increment;

      const timeDifIncrement = time / increment;

      for (let i = 1; i <= increment; i++) {
        const newAngleX = currentX + xDifIncrement * i; 
        const newAngleY = currentY + yDifIncrement * i; 
        setTimeout(() => {
          if (animation.current !== animationUuid) return;
          setPosFn(newAngleX, newAngleY);
        }, timeDifIncrement * (i - 1));
      }
    } else {
      setPosFn(angleX, angleY);
    }
  };

  const getPosHook = () => {
    const {
      lastAngleX,
      lastAngleY,
    } = last.current;

    return {
      angleX: lastAngleX,
      angleY: lastAngleY,
    };
  };

  return [camera, touchCamera, setPosHook, getPosHook] as const;
}

interface ModelType extends ComponentServiceProps {
  cameraPos?: PositionType;
  cameraRoute?: string;
  colorMode?: string;
  persistFocus?: boolean;
  smoothMove?: boolean;
  debugEnabled?: boolean;
  color?: string;
  modelColor?: string;
  // cache
  name?: string;
  getPosition?: () => Promise<any>;
  setPosition?: (value: any) => Promise<void>;
}

export type ModelRefType = React.MutableRefObject<{
    focusMuscle: (persist?: boolean) => void;
    unfocusMuscle: () => void;
    focused?: boolean; // focused state
    updateColor?: (color: string) => void;
}>;

export const ThreeHumanModel: React.FC<ModelType> = (props: ModelType) => {
  let {
    cameraPos,
    addComponentHook,
    colorMode, // use info to determine colors (dashboard) otherwise just use model to highlight
    persistFocus = false,
    smoothMove = true,
    debugEnabled = false,
    color: backgroundColor,
    getPosition,
    setPosition,
    modelColor,
    name = 'modelMenu',
  } = props;

  const key = getUuid();

  const muscleRefs = useRef<Record<string, ModelRefType>>({});
  const debugRef = useRef<any>({});
  const historyRef = useRef('');

  const setText = (angleX, angleY) => {
    if (debugRef.current.setText) {
      debugRef.current.setText(`${angleX/Math.PI} - ${angleY/Math.PI}`)
    }
  }
  
  const [camera, touchCamera, setPos, getPos] = useCamera({ setText, smoothMove });
  
  const cameraProps = useMemo(() => {
    if (!camera) return;
    return CanvasProps(camera, touchCamera);
  }, [camera, touchCamera]);

  // update camera based on positioning
  useEffect(() => {
    // attach setPos fn
    if (addComponentHook) {
      const setPosHook = (modelId, focus = true) => {
        const muscle = modelData.find((item) => item.id === modelId);
        if (muscle?.position) {
          setPos(muscle.position.angleX, muscle.position.angleY);
        }

        const {
          current: muscleInfo,
        } = muscleRefs.current[muscle.id];
        if (muscleInfo && focus) {
          const {
            focusMuscle,
            unfocusMuscle,
            focused,
          } = muscleInfo;

          if (focusMuscle) {
            if (persistFocus && focused) {
              unfocusMuscle();
              if (persistFocus) muscleInfo.focused = false;
            } else {
              focusMuscle(persistFocus);
              if (persistFocus) muscleInfo.focused = true;
            }
          }
        }
      };
      addComponentHook(name, 'setPos', setPosHook);
      addComponentHook(name, 'updateColor', (modelId, color, moveCamera = true) => {
        if (historyRef.current !== modelId && moveCamera) {
          historyRef.current = modelId;
          setPosHook(modelId, false);
        }
        // update color of model
        const {
          current,
        } = muscleRefs;
        const {
          [modelId]: {
            current: {
              updateColor
            },
          },
        } = current;
        if (updateColor) {
          updateColor(color);
        }
      });
    };
    // on mount
    if (getPosition) {
      const updatePosition = async () => {
        const pos = await getPosition() || {};
        const {
          angleX,
          angleY,
        } = pos;
        if (angleX && angleY) setPos(angleX, angleY);
      }
      updatePosition();
    }
    // on unmount
    return () => {
      if (setPosition) {
        const savePosition = async () => {
          const pos = getPos();
          if (pos) await setPosition(pos);
        }
        savePosition();
      }
    };
  }, [setPos, getPos]);

  modelColor = modelColor || '#90a4ae';
  backgroundColor = backgroundColor || themeColors.secondaryBase;


  console.log('Render ThreeHumanModel', name);
  return (
    <>
    {
      debugEnabled && DEBUG_MODE && <DebugComponent innerRef={debugRef} />
    }
    <Canvas {...cameraProps} camera={camera} key={key}>
      <ambientLight intensity={.3} />
      <directionalLight intensity={.6} position={[0, 10, 10]} />
      <directionalLight intensity={.6} position={[0, 10, -10]} />
      <Suspense fallback={null}>
        <>
          {
            modelData.map((item) => {
              if (!muscleRefs.current[item.id]) muscleRefs.current[item.id] = useRef<any>({});
              return <ModelItem {...item} key={item.id} color={colorMode ? item.color || modelColor : modelColor} innerRef={muscleRefs.current[item.id]} />
            })
          }
        </>
        {/* <Environment preset='sunset' background /> */}
      </Suspense>
    </Canvas>
    </>
  )
}

/**
 * Use store to control human model through store
 * used for exercise control
 */
export const StoreThreeHumanModel: React.FC<ModelType> = observer((props: ModelType) => {

  const {
    name
  } = props;
  
  const attributes: ModelType = useServiceProps({ ...props });

  if (name) {
    attributes.getPosition = () => getCache('model', name, {});
    attributes.setPosition = (value: any) => setCache('model', name, value);
  }

  return <ThreeHumanModel {...attributes} />
});

const styles = StyleSheet.create({
	debugContainer: {
    position: 'absolute',
    top: margin,
    left: margin,
    zIndex: 1000,
	},
});
