import { ComponentEnum } from '../../enums';
import { UIStoreInit } from '../../store';

export const uiState: UIStoreInit = {
	button: {
		openModal: {
			name: 'openModal',
			children: 'Open Manual',
		},
	},
	input: {
	},
	typography: {},
	layout: {
		header: 'How to use the app',
		headerEnabled: true,
		backEnabled: true,
		style: {
			display: 'flex',
			flexDirection: 'column',
			justifyContent: 'center',
			alignItems: 'center',
		},
	},
	modal: {
		viewManual: {
			name: 'viewManual',
			componentType: ComponentEnum.ModalTypeEnum.Full,
			cancelText: null,
		},
	},
};