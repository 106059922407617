import { UIStoreInit } from "../store";
import * as util from '../util';

// unique id, not anywhere else
export const defaultkey = 'default-version-10101';
export const dataVersionRoute = 'data-version-store';

export const getDataKey = (route: any): string | null => {
    const isString = util.isString(route);
    const isStringArray = !isString && util.isArray(route) && route.every((item) => util.isString(item));

    if (isString || isStringArray) {
        const arrayRoute = isString ? [route] : route;

        const dataKey = arrayRoute.join();
        
        return dataKey;
    }
    return defaultkey;
}

export const getDataVersionStore = (uiStateInit: UIStoreInit) => {
    const defaultStore = {
        [defaultkey]: 0,
    };

    if (uiStateInit.input) {
        Object.keys(uiStateInit.input).forEach((key) => {
            const inputDefinition = uiStateInit.input[key];
            const route = inputDefinition.routes;

            if (route) {
                const dataKey = getDataKey(route);
    
                if (dataKey) {
                    defaultStore[dataKey] = 0;
                }
            }
        });
    }
    if (uiStateInit.grid) {
        Object.keys(uiStateInit.grid).forEach((key) => {
            const gridDefinition = uiStateInit.grid[key];
            const route = gridDefinition.routes;

            if (route) {
                const dataKey = getDataKey(route);
    
                if (dataKey) {
                    defaultStore[dataKey] = 0;
                }
            }
        });
    }
    return {
        [dataVersionRoute]: defaultStore,
    };
};