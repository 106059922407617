export const OnboardingStore = {
	onboardingId: '',
	password: '',
	defaultRoutines: [],
	sharedRoutines: [],
	age: 0,
	height: 0,
	heightUnit: 'ft',
	weight: 0,
	weightUnit: 'lb',
	selectGoal: '',
	selectLevel: '',
};
