import React, { SVGProps } from 'react';
import Svg, { Path } from 'react-native-svg';

// const menuRoutineIcon: React.FC<SVGProps<SVGAElement>> = ({
//     height = '1.5em',
//     width = '1.5em',
//     viewBox = '0 0 24 24',
//     color = ''
// }) => {
//     return (
//         <svg width={width} height={height} viewBox={viewBox} fill="none" xmlns="http://www.w3.org/2000/svg">
//             <path d="M20.2916 19.7917V7.29167C20.2916 6.14107 19.3588 5.20833 18.2083 5.20833H16.1249C16.1249 6.35893 15.1922 7.29167 14.0416 7.29167H11.9583C10.8077 7.29167 9.87492 6.35893 9.87492 5.20833H7.79159C6.64099 5.20833 5.70825 6.14107 5.70825 7.29167V19.7917C5.70825 20.9423 6.64099 21.875 7.79158 21.875H18.2083C19.3588 21.875 20.2916 20.9423 20.2916 19.7917Z" fill="white"/>
//             <path d="M14.0416 3.125H11.9583C10.8077 3.125 9.87492 4.05774 9.87492 5.20833C9.87492 6.35893 10.8077 7.29167 11.9583 7.29167H14.0416C15.1922 7.29167 16.1249 6.35893 16.1249 5.20833C16.1249 4.05774 15.1922 3.125 14.0416 3.125Z" fill="white"/>
//             <path d="M9.87492 5.20833H7.79159C6.64099 5.20833 5.70825 6.14107 5.70825 7.29167V19.7917C5.70825 20.9423 6.64099 21.875 7.79159 21.875H18.2083C19.3588 21.875 20.2916 20.9423 20.2916 19.7917V7.29167C20.2916 6.14107 19.3588 5.20833 18.2083 5.20833H16.1249M9.87492 5.20833C9.87492 6.35893 10.8077 7.29167 11.9583 7.29167H14.0416C15.1922 7.29167 16.1249 6.35893 16.1249 5.20833M9.87492 5.20833C9.87492 4.05774 10.8077 3.125 11.9583 3.125H14.0416C15.1922 3.125 16.1249 4.05774 16.1249 5.20833M12.9999 12.5H16.1249M12.9999 16.6667H16.1249M9.87492 12.5H9.88534M9.87492 16.6667H9.88534" stroke="black" stroke-width="1.5" stroke-linecap="round"/>
//         </svg>
//     );
// };
/**
 * FOR REACT NATIVE to work with style: need to use Svg, Path from react-native-svg
 */
const menuRoutineIcon = ({
    // em does not work, only number values, px, and dp values
    height = '100%',
    width = '100%',
    viewBox = '0 0 24 24',
    color = 'white'
}) => {
    return (
        <Svg width={width} height={height} viewBox={viewBox} fill={color} color={color}>
            <Path d="M20.2916 19.7917V7.29167C20.2916 6.14107 19.3588 5.20833 18.2083 5.20833H16.1249C16.1249 6.35893 15.1922 7.29167 14.0416 7.29167H11.9583C10.8077 7.29167 9.87492 6.35893 9.87492 5.20833H7.79159C6.64099 5.20833 5.70825 6.14107 5.70825 7.29167V19.7917C5.70825 20.9423 6.64099 21.875 7.79158 21.875H18.2083C19.3588 21.875 20.2916 20.9423 20.2916 19.7917Z" fill="white"/>
            <Path d="M14.0416 3.125H11.9583C10.8077 3.125 9.87492 4.05774 9.87492 5.20833C9.87492 6.35893 10.8077 7.29167 11.9583 7.29167H14.0416C15.1922 7.29167 16.1249 6.35893 16.1249 5.20833C16.1249 4.05774 15.1922 3.125 14.0416 3.125Z" fill="white"/>
            <Path d="M9.87492 5.20833H7.79159C6.64099 5.20833 5.70825 6.14107 5.70825 7.29167V19.7917C5.70825 20.9423 6.64099 21.875 7.79159 21.875H18.2083C19.3588 21.875 20.2916 20.9423 20.2916 19.7917V7.29167C20.2916 6.14107 19.3588 5.20833 18.2083 5.20833H16.1249M9.87492 5.20833C9.87492 6.35893 10.8077 7.29167 11.9583 7.29167H14.0416C15.1922 7.29167 16.1249 6.35893 16.1249 5.20833M9.87492 5.20833C9.87492 4.05774 10.8077 3.125 11.9583 3.125H14.0416C15.1922 3.125 16.1249 4.05774 16.1249 5.20833M12.9999 12.5H16.1249M12.9999 16.6667H16.1249M9.87492 12.5H9.88534M9.87492 16.6667H9.88534" stroke="black" stroke-width="1.5" stroke-linecap="round"/>
        </Svg>
    );
};

export default menuRoutineIcon;