import { Image, View } from 'native-base';
import React, { useEffect, useState } from 'react';
import { Platform, Pressable, StyleSheet } from 'react-native';
import { FAHeader, FAIconButton, FAStoreButton, FAText } from '../../../components';
import * as util from '../../../util';
import { icons } from '../../../enums';
import { HomeService } from '../service';
const pullup = require('../../../assets/images/pullup.jpg');
const applestorebadge = require('../../../assets/images/apple-store-badge.png');
const googleplaybadge = require('../../../assets/images/google-play-badge.png');

interface ControlProps {
    count: number;
    page: number;
    decrement: any;
    increment: any;
}

const Control: React.FC<ControlProps> = (props: ControlProps) => {
    const {
        count = 1,
        page = 0,
        increment,
        decrement,
    } = props;
    
    
    return (
        <View style={styles.controlContainer}>
            {/* left button */}
            <FAIconButton
                onClick={decrement}
                name='left'
                icon={icons.arrowback}
                size={'lg'}
            />
            {
                (new Array(count + 1)).fill(0).map((item, idx) => (
                    page === idx ? <View style={styles.highlight} key={idx} /> : <View style={styles.dot} key={idx} />
                ))   
            }
            {/* right button */}
            <FAIconButton
                onClick={increment}
                name='right'
                icon={icons.arrowforward}
                size={'lg'}
            />
        </View>
    );
};

const Header = {
    [0]: 'Tailored fitness management: Our intuitive app schedules workouts and tracks progress for a personalized journey.',
    [1]: 'Customized workout recommendations: Analyzing your history, our app suggests exercises, empowering insights into targeted muscle groups.',
    [2]: 'Adaptable fitness approach: Our user-friendly app adjusts regimens, helping you achieve your best self.',
    [3]: 'Share your workouts with friends.',
}

export const Onboard: React.FC<{ service: HomeService, onboarded: any }> = (props: { service: HomeService, onboarded: any }) => {
    const {
        service,
        onboarded,
    } = props;
    const [page, setPage] = useState(0);
    const max = 4;
    const decrement = () => {
        if (page <= 0) {
            return;
        }
        setPage(page => page - 1);
    };
    const increment = () => {
        if (page >= max) {
            return;
        }
        setPage(page => page + 1);
    };
    const skip = () => {
        if (page >= max) {
            return;
        }
        setPage(page => max);
    };
	useEffect(() => {
		if (onboarded) {
            skip();
		}
	}, [onboarded]);
    return (
        <View
            style={styles.container}
        >
            {/* IMAGE */}
            <View
                style={styles.backgroundImage}
            >
                <Image
                    alt='Main Icon Image'
                    source={pullup}
                    height={util.getDim('100vh')}
                    width={util.getDim('100vw')}
                />
            </View>
            {/* skip button */}
            <View style={styles.skip}>
                <FAStoreButton name='btnSkip' />
            </View>
            {/* text */}
            <View style={styles.text}>
                <FAHeader 
                    name='onboardHeader'
                    color='white'
                    bold
                    style={{
                        padding: 10,
                    }}
                    textAlign={'center'}
                >
                    Exercise Routine and Guidance
                </FAHeader>
                <FAText
                    name='onboardDescription'
                    color='white'
                    bold
                    style={{
                        marginTop: 20,
                        padding: 10,
                    }}
                    textAlign={'center'}
                >
                    {
                        Header[page]
                    }
                </FAText>
            </View>
            {/* last one */}
            <View style={styles.signupContainer}>
                {
                    page === max && (
                        <>
                            <FAStoreButton name='redirectSignup' />
                            <View
                                style={styles.login}
                            >
                                <FAText name='login' color='white' style={{ marginRight: 5 }}>Already have an account?</FAText>
                                <FAStoreButton name='redirectLogin' />
                            </View>
                            {(Platform.OS === 'web') && (
                                <View style={styles.apps}>
                                    <Pressable
                                        onPress={() => {
                                            service.onClick(null, null, { name: 'redirectAndroid' });
                                        }}
                                    >
                                        <Image
                                            alt='googleplaybadge'
                                            source={googleplaybadge}
                                            width={160}
                                            height={68}
                                        />
                                    </Pressable>
                                    <Pressable
                                        style={{
                                            justifyContent: 'center'
                                        }}
                                        onPress={() => {
                                            service.onClick(null, null, { name: 'redirectIOS' });
                                        }}
                                    >
                                        <Image
                                            alt='applestorebadge'
                                            source={applestorebadge}
                                            width={140}
                                            height={45}
                                        />
                                    </Pressable>
                                    {/* <FAStoreButton name='redirectIOS' />
                                    <FAStoreButton name='redirectAndroid' /> */}
                                </View>
                            )}
                        </>
                    )
                }
            </View>
            {/* control */}
            <View style={styles.control}>
                <Control
                    page={page}
                    count={max}
                    increment={increment}
                    decrement={decrement}
                />
            </View>
        </View>
    );
};
const styles = StyleSheet.create({
    backgroundImage: {
        position: 'absolute',
        opacity: .5,
        // backgroundColor: 'black',
    },
    container: {
        // position: 'absolute',
        backgroundColor: 'black',
        height: '100%',
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-start',
        textAlign: 'center',
    },
    skip: {
        // position: 'absolute',
        justifyContent: 'flex-end',
        display: 'flex',
        flexDirection: 'row',
        marginVertical: util.getDim('5vh'),
        marginRight: util.getDim('5vh'),
    },
    text: {
        // position: 'absolute',
        width: '100%',
        justifyContent: 'center',
        flexGrow: 1,
        marginVertical: util.getDim('5vh'),
    },
    control: {
        // position: 'absolute',
        justifyContent: 'center',
        display: 'flex',
        flexDirection: 'row',
        marginVertical: util.getDim('5vh'),
    },
    controlContainer: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'center',
    },
    dot: {
        width: 20,
        height: 20,
        borderRadius: 20,
        backgroundColor: 'gray',
        marginHorizontal: 10,
    },
    highlight: {
        width: 25,
        height: 25,
        borderRadius: 25,
        backgroundColor: 'white',
        marginHorizontal: 10,
        marginBottom: 5,
    },
    login: {
        width: '100%',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        margin: 10,
    },
    signupContainer: {
        // position: 'absolute',
        justifyContent: 'center',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        marginVertical: util.getDim('5vh'),
        minHeight: util.getDim('10vh'),
    },
	apps: {
		display: 'flex',
		flexDirection: 'row',
		justifyContent: 'center',
		alignContent: 'center',
	},
});