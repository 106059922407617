import React, { useEffect } from 'react';
import { Center, Image, Pressable } from 'native-base';
import { View, StyleSheet, Platform } from 'react-native';
import { useHomeService } from './service';
import { uiState } from './home.ui';
import { HomeStore } from './home.store';
import { FAHeader, FAStoreButton, FAStoreInput, FAStoreTypography, FAText, PageLayout } from '../../components';
import { useServiceRouter } from '../../service';
import { observer } from 'mobx-react-lite';
import { Onboard } from './extra/Onboard';
const icon = require('../../assets/images/icon.png');
const applestorebadge = require('../../assets/images/apple-store-badge.png');
const googleplaybadge = require('../../assets/images/google-play-badge.png');

export const Home: React.FC = observer(() => {
	const screenName = 'HomePage';
	const service = useHomeService(screenName, HomeStore, uiState);
	useServiceRouter(service);
	const onboarded = Boolean(service.store.getProperty('onboarded'));
	const webPlatform = Platform.OS === 'web';
	console.log('Render Home screen');
	return (
		<PageLayout>
			<Onboard service={service} onboarded={onboarded} />
		</PageLayout>
	);
});
const styles = StyleSheet.create({
	home: {
		flex: 1,
		width: '100%',
	},
	icon: {
		display: 'flex',
		padding: 20,
		flex: 1,
		flexDirection: 'column',
		justifyContent: 'center',
		alignContent: 'center',
	},
	other: {
		flex: 1,
		paddingHorizontal: 20,
		paddingVertical: 80,
		flexDirection: 'column',
		justifyContent: 'center',
	},
	otherweb: {
		flex: 1,
		flexDirection: 'column',
		justifyContent: 'center',
	},
	apps: {
		display: 'flex',
		flexDirection: 'row',
		justifyContent: 'center',
		alignContent: 'center',
	},
});