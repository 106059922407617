import React from 'react';
import { ComponentEnum } from '../../enums';
import { useComponentProps } from '../../service';
import { observer } from 'mobx-react-lite';
import { FAGridProps } from './FAGridProps';
import { FAFlatList } from './FAFlatList';
import * as util from '../../util/typeUtil';
import { FAScrollList } from './FAScrollList';
import { FASimpleList } from './FASimpleList';
import { useDataVersion } from '../../store/hooks';


export const FAStoreGrid: React.FC<FAGridProps> = observer((props: FAGridProps) => {

	const attributes = useComponentProps(props, 'grid');
	const [dataVersionGrid, getDataVersionGrid, setDataVersionGrid] = useDataVersion(props.name);
	const [dataVersion, getDataVersion, setDataVersion] = useDataVersion();
	
	const {
		componentType,
		getValue,
		name,
	} = attributes;
    
	const getStoreValue = () => {
		const storeValue = getValue && getValue(props);
		if (util.isArray(storeValue)) {
			return storeValue;
		}
		return [];
	};

	// const setStoreValue = (value: any) => {
	// 	return setValue && setValue(value);
	// };

	let Component;
	switch (componentType) {
	case ComponentEnum.GridTypeEnum.FlatList:
		Component = FAFlatList;
		break;
	case ComponentEnum.GridTypeEnum.ScrollList:
		Component = FAScrollList;
		break;
	case ComponentEnum.GridTypeEnum.Simple:
		Component = FASimpleList;
		break;
	default:
		Component = FAScrollList;
		break;
	}

	attributes.dataSource = getStoreValue();

	console.log('Render FA Store Grid', name);
	return <Component {...attributes}/>;
});
