import { AddIcon } from 'native-base';
import { ComponentEnum, themeColors } from '../../enums';
import { btnStyle, textStyle } from '../../enums/styling';
import { UIStoreInit } from '../../store';
import { addNewBtn } from '../common';

export const uiState: UIStoreInit = {
	button: {
		addNewBtn: {
			componentType: ComponentEnum.ButtonTypeEnum.Button,
			name: 'addNewBtn',
			// color: 'indigo.600',
			style: {
				height: 40,
				borderRadius: 30,
				...btnStyle.info_blue
			},
			_text: {
				...textStyle.btnText_Regular,
				color: themeColors.secondaryBase,
			},
			icon: AddIcon,
			children: 'New',
		},
	},
	input: {
		exerciseTest: {
			componentType: ComponentEnum.InputTypeEnum.TextArea,
			name: 'exerciseTest',
			placeholder: 'Exercise Test',
			label: 'Exercise Test',
			routes: 'exerciseTest',
		},
		systemSwitch: {
			componentType: ComponentEnum.InputTypeEnum.Switch,
			name: 'systemSwitch',
			routes: 'systemSwitch',
		},
		editName: {
			name: 'editName',
			routes: 'editName',
			placeholder: 'Name',
		},
		editDescription: {
			name: 'editDescription',
			routes: 'editDescription',
			placeholder: 'Description',
		},
		addName: {
			name: 'addName',
			routes: 'addName',
			placeholder: 'Name',
		},
		addDescription: {
			name: 'addDescription',
			routes: 'addDescription',
			placeholder: 'Description',
		},
		search: {
			name: 'search',
			routes: 'search',
			placeholder: 'Search',
		},
		legend: {
			name: 'legend',
			componentType: ComponentEnum.InputTypeEnum.SelectButton,
			routes: 'legend',
			placeholder: 'Search',
			items: [
				{
					value: 'All',
					label: 'All',
				},
				{
					value: 'Arms',
					label: 'Arms',
				},
				{
					value: 'Core',
					label: 'Core',
				},
				{
					value: 'Back',
					label: 'Back',
				},
				{
					value: 'Chest',
					label: 'Chest',
				},
				{
					value: 'Legs',
					label: 'Legs',
				},
				{
					value: 'Shoulders',
					label: 'Shoulders',
				},
				{
					value: 'Cardio',
					label: 'Cardio',
				},
				{
					value: 'Full Body',
					label: 'Full Body',
				},
				{
					value: 'Other',
					label: 'Other',
				},
			],
		},
	},
	typography: {
		exerciseTest: {
			componentType: ComponentEnum.TypographyTypeEnum.Text,
			name: 'exerciseTest',
			routes: 'exerciseTest',
		},
	},
	layout: {
		// modelEnabled: true,
		// header: 'Exercise Page',
		menuBackground: '#FFFFFF',
		// settingsEnabled: true,
		// menuEnabled: true,
		// backEnabled: true,
	},
	grid: {
		exerciseList: {
			name: 'exerciseList',
			routes: 'exerciseList',
			titleData: 'exercise_type_name',
			subtitleData: 'exercise_type_description',
			editBtn: true,
			viewBtn: true,
			// infoBtn: true,
		},
	},
	modal: {
		editModal: {
			name: 'editModal',
			title: 'Edit Exercise',
			okText: 'Save Edit',
		},
		addModal: {
			name: 'addModal',
			title: 'Add Exercise',
			okText: 'Add',
		},
	},
};