import { useGLTF as useGL } from "@react-three/drei"
import { isArray } from "../../util";


export const useGLTF = (file: any, name: string) => {
    const gltf = useGL(file)
    return [gltf]
}
export { Canvas } from '@react-three/fiber';
export { Environment } from '@react-three/drei';

const info: any = { click: false };

export const CanvasProps = (camera, touchCamera) => {
    // factor from 10000
    const factor = 150;
    return ({
      onMouseDown: (evt: any) => {
        info.click = true;
        const x = evt.clientX || 0;
        const y = evt.clientY || 0;
        if (x || y) touchCamera(x, y, 'start', factor);
      },
      onMouseMove: (evt) => {
        if (!info.click) return;
        const x = evt.clientX || 0;
        const y = evt.clientY || 0;
        if (x || y) touchCamera(x, y, 'move', factor);
      },
      onMouseUp: (evt: any) => {
        info.click = false;
        const x = evt.clientX || 0;
        const y = evt.clientY || 0;
        if (x || y) touchCamera(x, y, 'end', factor);
      },
      onTouchStart: (evt: any) => {
        const x = evt.nativeEvent.touches[0].clientX || 0;
        const y = evt.nativeEvent.touches[0].clientY || 0;
        if (x || y) touchCamera(x, y, 'start', factor);
      },
      onTouchMove: (evt) => {
        const x = evt.nativeEvent.touches[0].clientX || 0;
        const y = evt.nativeEvent.touches[0].clientY || 0;
        if (x || y) touchCamera(x, y, 'move', factor);
      },
      onTouchEnd: (evt: any) => {
        const x = evt.nativeEvent.touches[0].clientX || 0;
        const y = evt.nativeEvent.touches[0].clientY || 0;
        if (x || y) touchCamera(x, y, 'end', factor);
      },
    });
  };