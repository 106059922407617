import React from 'react';
import { FAInputProps } from './FAInputProps';
import { FASelectProps } from './FASelectProps';
import { FASwitchProps } from './FASwitchProps';
import { FATextAreaProps } from './FATextAreaProps';
import { ComponentEnum } from '../../enums';
import { FATextArea } from './FATextArea';
import { FASwitch } from './FASwitch';
import { FASelect } from './FASelect';
import { FAInput } from './FAInput';
import { FADate } from './FADate';
import { useComponentProps } from '../../service';
import { observer } from 'mobx-react-lite';
import { FASlider } from './FASlider';
import { FASelectButton } from './FASelectButton';
import { useDataVersion } from '../../store/hooks';

interface StoreInputProps {
    componentType?: number,
}

export type FAStoreInputProps = (FAInputProps | FASelectProps | FASwitchProps | FATextAreaProps) & StoreInputProps;

export const FAStoreInput: React.FC<FAStoreInputProps> = observer((props: FAStoreInputProps) => {

	const attributes = useComponentProps(props, 'input');
	const [dataVersion, getDataVersion, setDataVersion] = useDataVersion();
	
	const {
		componentType,
		uiHide,
		name,
	} = attributes;

	// hide by name
	if (uiHide(name, props)) return null;

	let Component;
	switch (componentType) {
	case ComponentEnum.InputTypeEnum.Input:
		Component = FAInput;
		break;
	case ComponentEnum.InputTypeEnum.Select:
		Component = FASelect;
		break;
	case ComponentEnum.InputTypeEnum.SelectButton:
		Component = FASelectButton;
		break;
	case ComponentEnum.InputTypeEnum.Switch:
		Component = FASwitch;
		break;
	case ComponentEnum.InputTypeEnum.TextArea:
		Component = FATextArea;
		break;
	case ComponentEnum.InputTypeEnum.Date:
		Component = FADate;
		break;
	case ComponentEnum.InputTypeEnum.Slider:
		Component = FASlider;
		break;
	default:
		Component = FAInput;
		break;

	}

	console.log('Render FA Store Input', name);
	return <Component {...attributes}/>;
});
