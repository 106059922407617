import React from 'react';
import { View, StyleSheet } from 'react-native';
import { useSignupService } from './service';
import { uiState } from './signup.ui';
import { SignupStore } from './signup.store';
import { FAHeader, FALink, FAStoreButton, FAStoreInput, FAText, PageLayout } from '../../components';
import { useServiceRouter } from '../../service';
import { Image } from 'native-base';
const icon = require('../../assets/images/icon.png');

export const Signup: React.FC = () => {
	const screenName = 'SignupPage';
	const service = useSignupService(screenName, SignupStore, uiState);
	useServiceRouter(service);

	console.log('Render Signup screen');
	return (
		<PageLayout>
			<View style={styles.icon}>
				<Image
					alt='Main Icon Image'
					source={icon}
					width={200}
					height={200}
					
				/>
			</View>
			<View style={styles.login}>
				<FAHeader name='header' bold>Sign Up</FAHeader>
				<FAText name='header'>Welcome and fill the form to register</FAText>
				<FAStoreInput name='email' />
				<FAStoreInput name='username' />
				<FAStoreInput name='password' />
				<FAStoreInput name='confirmPassword' />
				<View style={styles.conditions}>
					<FAStoreInput name='conditions' />
					<View style={styles.conditionMessage}>
						<FAText name='agreeWith'>Agree with </FAText>
						<FAStoreButton name='termsAndCondition'>Terms & Conditions</FAStoreButton>
					</View>
				</View>
				<View
					style={styles.loginRedirect}
				>
					<FAText name='login' style={{ marginRight: 5 }}>Already have an account?</FAText>
					<FAStoreButton name='redirectLogin' />
				</View>
				<FAStoreButton name='signupBtn' />
			</View>
		</PageLayout>
	);
};
const styles = StyleSheet.create({
	layout: {
		height: '100%',
		justifyContent: 'center'
	},
    loginRedirect: {
        width: '100%',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        margin: 5,
    },
	login: {
		padding: 40,
		gap: 10,
		display: 'flex',
		flexDirection: 'column',
		justifyContent: 'center',
		alignItems: 'center',
	},
	icon: {
		width: '100%',
		justifyContent: 'center',
		display: 'flex',
		flexDirection: 'row',
	},
	conditions: {
		display: 'flex',
		flexDirection: 'row',
		width: '100%',
		justifyContent: 'space-between',
	},
	conditionMessage: {
		display: 'flex',
		flexDirection: 'row',
		gap: 10,
	},
});