import { serviceBase } from "../service";

enum ButtonTypeEnum {
    Button,
    Link,
    Icon
}

enum InputTypeEnum {
    Input,
    Select,
    Switch,
    TextArea,
    Date,
    Slider,
    SliderInput,
    SelectButton,
}

enum LayoutTypeEnum {

}

enum TypographyTypeEnum {
    Text,
    Header,
    Link,
}

enum GridTypeEnum {
    FlatList,
    ScrollList,
    SwitchGrid,
    Simple,
}

enum ChartTypeEnum {
    Chart,
}

enum ModalTypeEnum {
    Modal,
    ActionSheet,
    ActionSheetSpecial,
    Menu,
    Full,
}

export const ComponentEnum = {
	ButtonTypeEnum: ButtonTypeEnum,
	InputTypeEnum: InputTypeEnum,
	LayoutTypeEnum: LayoutTypeEnum,
	TypographyTypeEnum: TypographyTypeEnum,
	GridTypeEnum: GridTypeEnum,
	ChartTypeEnum: ChartTypeEnum,
	ModalTypeEnum: ModalTypeEnum,
};

type ValueOf<T> = T[keyof T];
type ComponentEnumValueType = ValueOf<{
  [K in keyof typeof ComponentEnum]: ValueOf<typeof ComponentEnum[K]>
}>;
export interface ComponentServiceProps {
    value?: any,
    getValue?: (props?: any) => any,
    setValue?: (value: any, props?: any) => void,
    onClick?: (value: any, data: any, props: any) => void,
    // onBlur?: (value: any, data: any, props: any) => void,
    onChange?: (value: any, data: any, props: any) => void,
    componentType?: ComponentEnumValueType,
    onOk?: (value: any, data: any, props: any) => void,
    onBlur?: (value: any, data: any, props: any) => void,
    onCancel?: (value: any, data: any, props: any) => void,
    addComponentHook?: (name: string, action: string, fn: any) => void,
    uiHide?: (name: string, data: any) => boolean;
    uiReadOnly?: (name: string, data: any) => boolean;
    getService?: () => serviceBase;
    routes?: string | string[];
}


