import { Center, HStack, Pressable, View, Text, theme, ScrollView } from 'native-base';
import React, { ReactNode, useState } from 'react';
import { StyleSheet, StyleProp, Dimensions } from 'react-native';
import { ComponentEnum, icons, themeColors } from '../../enums';
import { getService, useLayoutProps } from '../../service';
import { isArray } from '../../util';
import { FAIconButton, FAStoreButton } from '../button';
import { margin as menuMargin, width as menuWidth } from '../modal/FAModalMenu';
import { FAStoreModal } from '../modal/FAStoreModal';
import { StoreThreeHumanModel, ThreeHumanModel } from '../threejs';
import { FAHeader } from '../typography/FAHeader';
import { FAText } from '../typography/FAText';
import { menuData, MenuItemType } from './menuData';
import * as util from '../../util';

interface PageLayoutProps {
    children: ReactNode;
	menuEnabled?: boolean;
	menuBackground?: string;
	header?: string;
	modelEnabled?: boolean;
	settingsEnabled?: boolean;
	backEnabled?: boolean;
	headerEnabled?: boolean;
	btns?: string[];
	style?: any;
}

interface PageMenuItemCustomType {
	onPress: any;
	current: boolean;
	bg: string;
}

type PageMenuItemType = MenuItemType & PageMenuItemCustomType;

/**
 * menu item
 * @param props 
 * @returns 
 */
const PageLayoutMenuItem: React.FC<PageMenuItemType> = (menuItem: PageMenuItemType) => {
	const {
		bg,
		current,
		onPress,
		mainButton,
		icon,
		title,
	} = menuItem;
	// const [highlight, setHighlight] = useState(current);

	// const onHoverIn = () => {
	// 	if (current) return;
	// 	setHighlight(true);
	// }

	// const onHoverOut = () => {
	// 	if (current) return;
	// 	setHighlight(false);
	// }

	
	const IconComponent = icons[icon];
	return (
		<Pressable
			onPress={onPress} 
			style={mainButton ? [
				styles.mainBtn, 
				current && {
					backgroundColor: themeColors.primaryDark
				}
			]:[
				styles.menuItem, 
				current && {
					backgroundColor: themeColors.darkGray
				}
			]}
			// bg={highlight ? highlightColor : color} 
			// onHoverIn={onHoverIn}
			// onHoverOut={onHoverOut}
			// opacity={current ? 0.8 : 1} 
			py="2" 
			// bg={themeColors.black}
		>
			<Center height='100%'>
				{IconComponent && (
					<IconComponent
						name='menuIcon'
						color={mainButton && (current ? themeColors.white : themeColors.secondaryBase)}
						icon={icons[icon]}
					/>
				)}
				<FAText
					name='menutext'
					style={
						mainButton ? (
							current ?
							styles.mainBtnText_active
							: styles.mainBtnText
						)
						: styles.menuItemText
					}
				>
					{title}
				</FAText>
			</Center>
		</Pressable>
	);
};

/**
 * this menu component to be used in page layout
 * @param props 
 * @returns 
 */
const PageLayoutMenu: React.FC<any> = (props: any) => {
	const {
		bg = themeColors.black,
	} = props;
	const service = getService();
	return (
		<View style={[styles.menu, { backgroundColor: bg }]}>
			{
				menuData.map((menuItem) => {
					const redirectFn = () => {
						service.redirectTo(service.getPath(menuItem.link));
					}
					return (
						<PageLayoutMenuItem 
							{...menuItem} 
							bg={bg}
							onPress={redirectFn} 
							current={service.getPath(menuItem.link) === service.currentPath}
							key={`${menuItem.title}`}
						/>
					);
				})
			}
		</View>
	);
};

/**
 * this layout component to be used in each page and control certain qualities through ui store
 * @param props 
 * @returns 
 */
export const PageLayout: React.FC<PageLayoutProps> = (props: PageLayoutProps) => {
	const attributes = useLayoutProps(props);

	const service = getService();

	const {
		menuEnabled = false,
		menuBackground: backgroundColor,
		header,
		modelEnabled = false,
		settingsEnabled,
		backEnabled = false,
		headerEnabled = false,
		btns = [],
		style = {},
	} = attributes;
	
	const {
		children,
	} = props;
	return (
		<View style={styles.pageLayout}>
			{
				headerEnabled && (
					<View style={[styles.header, modelEnabled ? { height: 100 } : { maxHeight: 100 }, { backgroundColor }]}>
						{modelEnabled && (
							<FAStoreModal name='model' componentType={ComponentEnum.ModalTypeEnum.ActionSheetSpecial}
								component={() => {
									return <StoreThreeHumanModel name='pageLayout' color={backgroundColor} smoothMove={false} />
								}}
							/>
						)}
						{
							backEnabled && (
							<View style={styles.settings}>
								<FAIconButton 
									name='backBtn'
									icon={icons.arrowback}
									onClick={() => {
										service.goBack();
									}}
								/>
							</View>
							)
						}
						<View style={[styles.headerTitle, { backgroundColor }]}>
						{
							header && (
								<Center>
									<FAHeader name='header'>{header}</FAHeader>
								</Center>
						)}
						</View>
						{
							settingsEnabled && (
							<View style={styles.settings}>
								<FAIconButton 
									name='settingsBtn'
									icon={icons.menu}
									onClick={() => {
										service.redirectTo(service.getPath('settings'));
									}}
								/>
							</View>
							)
						}
						<View style={styles.btns}>
						{
							isArray(btns) && btns.map((btnName) => (
								<FAStoreButton 
									key={util.getUuid()}
									size={'lg'}
									name={btnName}
								/>
							))
						}
						</View>
					</View>
				)
			}
			<ScrollView style={styles.scrollLayout} contentContainerStyle={{ height: '100%' }}>
				<View style={[styles.childrenLayout, { backgroundColor }, style]} >
					{children}
				</View>
			</ScrollView>
			{menuEnabled && <PageLayoutMenu bg={themeColors.trueBlack} />}
		</View>
	);
};

const styles = StyleSheet.create({
	pageLayout: {
		flexDirection: 'column',
		flex: 1,
		// flex: 'auto' does not work in react native
		// flex: 'auto',
		maxHeight: Dimensions.get('window').height,
		flexGrow: 1,
	},
	btns: {
		display: 'flex',
		flexDirection: 'row',
		justifyContent: 'flex-start',
		padding: 10,
	},
	scrollLayout: {
		flex: 1,
		flexGrow: 1,
		display: 'flex',
		flexDirection: 'column',
		height: '100%',
	},
	childrenLayout: {
		// flex: 'auto',
		display: 'flex',
		// paddingHorizontal: 10,
		// paddingVertical: 2,
		height: '100%',
		flexGrow: 1,
	},
	header: {
		flexDirection: 'row',
		// height: 55,
		// paddingHorizontal: 10,
		// paddingTop: 10,
		// paddingBottom: 2,
	},
	settings: {
		width: menuWidth,
		marginRight: menuMargin,
		height: '100%',
		justifyContent: 'center',
		alignContent: 'center',
	},
	backBtn: {
		height: '100%',
		justifyContent: 'center',
		alignContent: 'center',
	},
	headerTitle: {
		flexGrow: 1,
		justifyContent: 'center',
		alignContent: 'center',
	},
	menu: {
		flexDirection: 'row',
		height: 65,
		alignItems: 'flex-end',
		padding: 8,
	},
	menuItem: {
		flex: 1,
		display: 'flex',
		justifyContent: 'center',
		alignContent: 'center',
		textAlign: 'center',
		height: '100%',
		minHeight: 20,
		padding: 0,
		borderRadius: 10,
		marginRight: 6,
		marginLeft: 6
	},
	mainBtn: {
		// flex: 'none',
		borderRadius: 200,
		height: 85,
		width: 85,
		backgroundColor: themeColors.accent,
	},
	menuItemText: {
		color: themeColors.white,
		fontSize: 10,
	},
	mainBtnText: {
		color: themeColors.secondaryBase,
		fontSize: 10,
		fontWeight: '700',
	},
	mainBtnText_active: {
		color: themeColors.white,
		fontSize: 10,
	},
});
