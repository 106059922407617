import React, { useEffect } from 'react';
import { View, StyleSheet, Dimensions } from 'react-native';
import { useExerciseDetailService } from './service';
import { uiState } from './exerciseDetail.ui';
import { ExerciseDetailStore } from './exerciseDetail.store';
import { FAStoreTypography, FAStoreInput, PageLayout, FAHeader, FAStoreGrid, FAStoreButton, FAStoreModal } from '../../components';
import { FAInput } from '../../components/input/FAInput';
import { useServiceRouter } from '../../service';
import { StoreThreeHumanModel, ThreeHumanModel } from '../../components/threejs';
import { ScrollView } from 'native-base';
import { FASwitchList } from '../../components/grid/FASwitchList';

export const ExerciseDetail: React.FC = () => {
	const screenName = 'ExerciseDetailPage';
	const service = useExerciseDetailService(screenName, ExerciseDetailStore, uiState);
	useServiceRouter(service);

	useEffect(() => {
		service.loadData();
	}, []);

	console.log('Render ExerciseDetail screen');
	return (
		<PageLayout>
			<View style={styles.input}>
			<FAStoreInput name='addName' />
			</View>
			<View style={styles.input}>
			<FAStoreInput name='addDescription' />
			</View>
			<View style={styles.switches}>
				<View style={styles.switch}>
					<FAStoreInput name='iscalisthenics' />
				</View>
				<View style={styles.switch}>
					<FAStoreInput name='isbarbell' />
				</View>
			</View>

			<View style={styles.model}>
				<StoreThreeHumanModel name='exercise' modelColor='#e8d8da' color='#e8d8da'/>
			</View>
			<View style={styles.other}>
				<FASwitchList name='exerciseMuscles' />
			</View>

		</PageLayout>
	);
};

const styles = StyleSheet.create({
	addNewBtn: {
		alignSelf: 'flex-end',
		width: '25px',
	},
	model: {
		height: Dimensions.get('window').height / 3,
	},
	other: {
		flex: 1,
		margin: 5,
	},
	input: {
		margin: 5,
	},
	switches: {
		display: 'flex',
		flexDirection: 'row',
	},
	switch: {
		margin: 5,
	},
});