import { ComponentEnum, icons } from '../../enums';
import { UIStoreInit } from '../../store';

export const uiState: UIStoreInit = {
	button: {
		updateUsernameBtn: {
			componentType: ComponentEnum.ButtonTypeEnum.Button,
			children: 'Update Username',
			width: '100%',
		},
		updateEmailBtn: {
			componentType: ComponentEnum.ButtonTypeEnum.Button,
			children: 'Update Email',
			width: '100%',
		},
		updatePassBtn: {
			componentType: ComponentEnum.ButtonTypeEnum.Button,
			children: 'Update Pass',
			width: '100%',
		},
		deleteAccountBtn: {
			componentType: ComponentEnum.ButtonTypeEnum.Button,
			children: 'Delete Account',
			width: '100%',
		},
	},
	input: {
		newUsername: {
			name: 'newUsername',
			componentType: ComponentEnum.InputTypeEnum.Input,
			routes: 'newUsername',
			placeholder: 'New username',
			label: 'New Username',
		},
		newEmail: {
			name: 'newEmail',
			componentType: ComponentEnum.InputTypeEnum.Input,
			routes: 'newEmail',
			placeholder: 'New email',
			label: 'New Email',
		},
		currentPassword: {
			name: 'currentPassword',
			componentType: ComponentEnum.InputTypeEnum.Input,
			type: 'password',
			routes: 'currentPassword',
			label: 'Current Password',
		},
		newPassword: {
			name: 'newPassword',
			componentType: ComponentEnum.InputTypeEnum.Input,
			type: 'newPassword',
			routes: 'newPassword',
			label: 'New Password',
		},
		confirmPassword: {
			name: 'confirmPassword',
			componentType: ComponentEnum.InputTypeEnum.Input,
			type: 'password',
			routes: 'confirmPassword',
			label: 'Confirm Password',
		},
	},
	typography: {
	},
	layout: {
		menuEnabled: true,
		header: 'System Settings Page',
		headerEnabled: true,
		backEnabled: true,
		menuBackground: 'white',
	},
	modal: {
		passModal: {
			title: 'Enter Password',
			okText: 'Update',
		},
	},
};