import React from 'react';
import { StyleSheet } from 'react-native';
import { useUpdateNewsService } from './service';
import { uiState } from './updateNews.ui';
import { UpdateNewsStore } from './updateNews.store';
import { FAHeader, FALink, FAStoreButton, FAStoreInput, FAText, PageLayout } from '../../components';
import { useServiceRouter } from '../../service';
import { icons } from '../../enums';
import { ScrollView, View } from 'native-base';
// import { settingButtonProps } from '../Settings/Settings';
const fitGuruUserManual = [
	{ text: "Fit Guru User Manual:", bold: true },
  
	{ text: "Schedule:", bold: true },
	{ text: "- Schedule your routines for specific days.", bold: false },
	{ text: "- Edit schedule for routines using the Schedule button.", bold: false },
	{ text: "- View routine schedules daily, weekly, or monthly.", bold: false },
	{ text: "- Start routines directly from the schedule.", bold: false },
  
	{ text: "Start Workout Page:", bold: true },
	{ text: "- Preloaded routine workouts based on scheduled routines.", bold: false },
	{ text: "- Options to add exercises not in the routine.", bold: false },
	{ text: "- Complete workouts and track progress.", bold: false },
	{ text: "- Start an Empty Workout for manual exercise selection.", bold: false },
  
	{ text: "Model Page:", bold: true },
	{ text: "- Visualize targeted muscles on a 3D model.", bold: false },
	{ text: "- Identify intensity of worked muscles.", bold: false },
	{ text: "- Select specific muscles to focus on the model.", bold: false },
  
	{ text: "Exercise Management:", bold: true },
	{ text: "- Add, edit, or delete exercises.", bold: false },
	{ text: "- Update exercise details like name, description, type, and muscle intensities.", bold: false },
	{ text: "- Some system exercises are non-editable.", bold: false },
  
	{ text: "Routines Management:", bold: true },
	{ text: "- Create, view, edit, or delete routines.", bold: false },
	{ text: "- Add exercises to routines and update routine details.", bold: false },
	{ text: "- Manage a collection of exercises as routines.", bold: false },
  
	{ text: "Routines Goal Management:", bold: true },
	{ text: "- Set goals for each exercise in a routine.", bold: false },
	{ text: "- Preload exercises based on goals during workouts.", bold: false },
	{ text: "- Choose between 'Use Last' for previous workout reps and 'Weight-Rep Progression' for structured sets.", bold: false },
	{ text: "- View Progression displays scheduled workouts based on goals.", bold: false },
  
	{ text: "Settings:", bold: true },
	{ text: "- Access other info pages and update personal settings like email and password.", bold: false },
	{ text: "- Option to delete your account.", bold: false }
  ];
  
  
  const summary = 'Fit Guru is a comprehensive fitness app designed for effective workout management. It allows users to schedule routines, track progress, and visualize muscle engagement on a 3D model. Features include customizable exercise and routine management, goal setting with progress tracking, and personal account settings. The app caters to both predefined and user-defined workouts, offering flexibility and insight into fitness routines.';
  

export const UpdateNews: React.FC = () => {
	const screenName = 'UpdateNewsPage';
	const service = useUpdateNewsService(screenName, UpdateNewsStore, uiState);
	useServiceRouter(service);

	console.log('Render UpdateNews screen');
	return (
		<PageLayout>
			<ScrollView>
				<View padding={10}>
					<FAText name='bold' bold padding={10}>{summary}</FAText>
					{
						fitGuruUserManual.map((item) => {
							if (item.bold) {
								return (
									<FAHeader name='bold' bold padding={10} key={item.text}>{item.text}</FAHeader>
								)
							}
							return (
								<FAText name='text' paddingX={10} key={item.text}>{item.text}</FAText>
							);
						})
					}
					
				</View>
			</ScrollView>
		</PageLayout>
	);
};
const styles = StyleSheet.create({
});