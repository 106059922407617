import { ComponentEnum } from '../../enums';
import { UIStoreInit } from '../../store';
import { addNewBtn } from '../common';

export const uiState: UIStoreInit = {
	button: {
		addNewBtn,
	},
	input: {
		weightTest: {
			componentType: ComponentEnum.InputTypeEnum.TextArea,
			name: 'weightTest',
			placeholder: 'Weight Test',
			label: 'Weight Test',
			routes: 'weightTest',
		},
		editWeight: {
			routes: 'editWeight',
			format: 'number',
		},
		addWeight: {
			routes: 'addWeight',
			format: 'number',
		},
	},
	typography: {
		weightTest: {
			componentType: ComponentEnum.TypographyTypeEnum.Text,
			name: 'weightTest',
			placeholder: 'Weight Test',
			label: 'Weight Test',
			routes: 'weightTest',
		},
	},
	layout: {
		menuEnabled: true,
		header: 'Weight Page',
		headerEnabled: true,
		settingsEnabled: true,
		menuBackground: 'white',
	},
	grid: {
		weightList: {
			routes: 'weightList',
			titleData: 'log_date',
			subtitleData: 'log_weight',
		},
	},
	chart: {
		weightList: {
			routes: 'weightList',
			xAxis: 'log_date',
			yAxis: 'log_weight',
			title: 'Weight Chart',
		},
	},
	modal: {
		editModal: {
			title: 'Edit Weight',
			okText: 'Save Edit',
		},
		addModal: {
			title: 'Add Weight',
			okText: 'Add',
		}
	},
};