export const ExerciseStore = {
	exerciseTest: 'exerciseTest',
	exerciseList: [],
	systemSwitch: false,
	editName: '',
	editDescription: '',
	addName: '',
	addDescription: '',
	exercise_type_id: '',
	exercise_type: '',
};
