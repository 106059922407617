import { useState } from "react";
import { ComponentServiceProps } from "../enums";
import { getService } from "../service/servicePool";
// import { getService } from "../service";

export const useComponentRefresh = () => {
    const [version, setVersion] = useState(1);
    
    const refreshFn = () => {
        setVersion((ver) => ver + 1);
    }

    return [refreshFn];
};


export const useStoreGridHooks = (gridName: string, id: string) => {
    // get value set value for a specific row

    return ({
        gridName,
        id,
        getValue: (props) => {
            const service = getService();
            if (!service) {
                return null;
            }
            // get from grid
            const row = service.store.getGridRow(gridName, id);
            if (!row) {
                return null;
            }
            const {
                name
            } = props || {};
            if (!name) {
                return null;
            }
            // get 
            return row[name];
        }, 
        setValue: (value, props) => {
            const service = getService();
            if (!service) {
                return;
            }
            // get from grid
            const row = service.store.getGridRow(gridName, id);
            if (!row) {
                return;
            }
            const {
                name
            } = props || {};
            if (!name) {
                return null;
            }
            // set
            row[name] = value;
        },
    });
};