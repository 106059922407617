import React, { useEffect, useState } from 'react';
import { FAModalProps } from './FAModalProps';
import { Button, Modal, View } from 'native-base';

export const FAModal: React.FC<FAModalProps> = (props: FAModalProps) =>{
	const {
		name,
		title,
		cancelText = 'Cancel',
		cancelColor='blueGray',
		okColor,
		okText = 'Ok',
		component: Component,
		componentProps = {},
		width,
		height,
		// service props
		// children = <></>,
		onOk,
		onCancel,
		addComponentHook,
		
		// 
		forceUmmount = true,
	} = props;

	const [isOpen, setIsOpen] = useState(false);

	const openModal = () => {
		setIsOpen(true);
	}

	const onOkFn = () => {
		onOk?.call(null, null, null, props);
		setIsOpen(false);
	};

	const onCancelFn = () => {
		onCancel?.call(null, null, null, props);
		setIsOpen(false);
	};

	useEffect(() => {
		// add component hook
		addComponentHook?.call(null, name, 'open', openModal);
	}, []);

	console.log('Render FA Modal', name);

	if (forceUmmount && !isOpen) {
		return null;
	}

	return (
		<Modal
			isOpen={isOpen}
			onClose={onCancelFn}
		>
			<Modal.Content
				width={width}
				height={height}
			>
				<Modal.CloseButton />
				<Modal.Header>{title}</Modal.Header>
				<Modal.Body>
					{Component && <Component {...componentProps} />}
				</Modal.Body>
				<Modal.Footer>
					<Button.Group space={2}>
						<Button 
							variant='ghost' 
							colorScheme={cancelColor}
							children={cancelText}
							onPress={onCancelFn}
						/>
						<Button 
							variant='ghost' 
							colorScheme={okColor}
							children={okText}
							onPress={onOkFn}
						/>
					</Button.Group>
				</Modal.Footer>
			</Modal.Content>
		</Modal>
	);
};
