import { ComponentEnum } from '../../enums';
import { UIStoreInit } from '../../store';

export const uiState: UIStoreInit = {
	button: {
		workoutPage: {
			name: 'workoutPage',
			children: 'redirect to workout',
		},
		signupBtn: {
			name: 'signupBtn',
			children: 'Create Account',
			style: {
				width: '100%',
				borderRadius: 20,
			},
		},
		termsAndCondition: {
			name: 'termsAndCondition',
			componentType: ComponentEnum.ButtonTypeEnum.Link,
		},
		redirectLogin: {
			componentType: ComponentEnum.ButtonTypeEnum.Link,
			children: 'Log In here',
			_text: {
				// color: 'white',
				// fontWeight: 600,
				// fontSize: '14pt',
			},
		},
	},
	input: {
		conditions: {
			componentType: ComponentEnum.InputTypeEnum.Switch,
			name: 'conditions',
			routes: 'conditions',
		},
		email: {
			componentType: ComponentEnum.InputTypeEnum.Input,
			name: 'email',
			placeholder: 'Email',
			label: 'Email',
			routes: 'email',
			width: '100%',
			borderRadius: 20,
		},
		username: {
			componentType: ComponentEnum.InputTypeEnum.Input,
			name: 'username',
			placeholder: 'Username',
			label: 'Username',
			routes: 'username',
			width: '100%',
			borderRadius: 20,
		},
		password: {
			componentType: ComponentEnum.InputTypeEnum.Input,
			placeholder: 'Password',
			name: 'password',
			label: 'Password',
			routes: 'password',
			type: 'password',
			width: '100%', 
			borderRadius: 20,
		},
		confirmPassword: {
			componentType: ComponentEnum.InputTypeEnum.Input,
			placeholder: 'Confirm Password',
			name: 'confirmPassword',
			label: 'Confirm Password',
			routes: 'confirmPassword',
			type: 'password', 
			width: '100%',
			borderRadius: 20,
		},
	},
	typography: {},
	layout: {
		// header: 'Create Account',
		backEnabled: false,
	},
	grid: {
		
	},
};