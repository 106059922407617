import React from 'react';
import { FAButtonProps } from './FAButtonProps';
import { Button } from 'native-base';
import { themeColors } from '../../enums';
import { useEffect } from 'react';
import { useState } from 'react';

export const FAButton: React.FC<FAButtonProps> = (props: FAButtonProps) => {
	let {
		name,

		// colorScheme = themeColors.secondaryColorScheme,
		variant,
		// isLoading,
		isHovered,
		isPressed,
		isFocused,
		isFocusVisible,
		size,
		startIcon,
		endIcon,
		isLoadingText,
		spinner,
		isDisabled,
		spinnerPlacement,
		rightIcon,
		leftIcon,
		backgroundColor,
		_text,
		_stack,
		_icon,
		_loading,
		_disabled,
		_spinner,
		_hover,
		_pressed,
		_focus,
		paddingRight = 5,
		style,
		fontSize,
		fontWeight,
		color,
		textColor,
		// service
		children: text,
		onClick,
		onBlur,
		onChange,
		onOk,
		onCancel,
		addComponentHook,
		uiReadOnly,
		clickProps = {},
		...buttonProps
	} = props;

	const [loading, setLoading] = useState(false);
    const readOnly = isDisabled || (uiReadOnly ? uiReadOnly(name, props) : isDisabled);

	// if (backgroundColor) {
	// 	style = {
	// 		...style,
	// 	};
	// }

	const attributes: any = {
		name,
		// colorScheme,
		variant,
		isLoading: loading, // start loading controlled through service
		isHovered,
		isPressed,
		isFocused,
		isFocusVisible,
		size,
		startIcon,
		endIcon,
		isLoadingText,
		spinner,
		isDisabled: readOnly,
		spinnerPlacement,
		rightIcon,
		leftIcon,
		style,
		backgroundColor,
		paddingTop: '12px',
		paddingBottom: '12px',
		paddingRight: '20px',
		paddingLeft: '20px',
		// fit content not work on react native
		// width: 'fit-content',
		_text,
		_stack,
		_icon,
		_loading,
		_disabled,
		_spinner,
		_hover,
		_pressed,
		_focus,
		fontSize,
		color,
		...buttonProps,
	};

	attributes.onPress = (evt: any) => {
		onClick?.call(null, evt, null, props);
	}

	attributes.onLongPress = (evt: any) => {
		onClick?.call(null, evt, null, { ...props, longPress: true });
	}

	const startLoading = () => {
		setLoading(true);
	};

	const endLoading = () => {
		setLoading(false);
	};

	useEffect(() => {
		// add component hook for loading
		if (addComponentHook) {
			addComponentHook(name, 'startLoading', startLoading);
			addComponentHook(name, 'endLoading', endLoading);
		}
	}, []);

	console.log('Render Button', name);
	return <Button {...attributes}>{text}</Button>;
};