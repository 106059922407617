import React, { useEffect, useState } from 'react';
import { View, StyleSheet, Text } from 'react-native';
import { useWorkoutService } from './service';
import { uiState } from './workout.ui';
import { WorkoutStore } from './workout.store';
import { FAStoreTypography, FAStoreInput, PageLayout, FAHeader, FAStoreGrid, FAStoreButton, FAStoreModal, Tabs, FAButton, FAText } from '../../components';
import { useServiceRouter } from '../../service';
import { useComponentRefresh } from '../../hooks';

export const Workout: React.FC = () => {
	const screenName = 'WorkoutPage';
	const service = useWorkoutService(screenName, WorkoutStore, uiState);
	useServiceRouter(service);

	useEffect(() => {
		const run = async () => {
			await service.loadExercises();
			service.loadData();
			service.loadRoutine();
			service.loadRoutineData();
		}
		run();
	}, []);

	console.log('Render Workout screen');
	return (
		<PageLayout>
			{/* <FAButton name='t' onClick={refresh}>refresh</FAButton> */}
			<Tabs 
				service={service}
				name='workout'
				size={'xs'}
				tabs={[
					{
						title: 'Workout',
						content: (
							<>
								{/* <View style={styles.container}>
									<View style={styles.formContainer}>
										<View style={styles.form}>
											<FAHeader name='message'>Log an Exercise</FAHeader>
										</View>
										<View style={styles.button}>
											<FAStoreButton name='createExercise' />
										</View>
									</View>
									<View style={styles.formContainer}>
										<View style={styles.form}>
											<FAStoreInput name='addExerciseTypeId' />
										</View>
									</View>
									<View style={styles.formContainer}>
										<View style={styles.flex}>
											<FAStoreInput name='addExerciseReps' />
										</View>
										<View style={styles.flex}>
											<FAStoreInput name='addExerciseWeight' />
										</View>
										<View style={styles.flexBtn}>
											<FAStoreButton name='addBtn' />
										</View>
									</View>
									<View style={styles.list}>
										<FAStoreGrid name='workoutWorkingList' />
									</View>
									<View style={styles.formContainer}>
										<View style={styles.form}>
											<FAStoreInput name='addComment' />
										</View>
										<View style={styles.button}>
											<FAStoreButton name='saveBtn' />
										</View>
									</View>
								</View> */}
								<FAHeader name='list' underline style={styles.listHeader}>Workout List</FAHeader>
								<FAStoreGrid name='workoutList'/>
							</>
						),
					},
					{
						title: 'Routine',
						content: (
							<>
								{/* <View style={styles.container}>
									<View style={styles.formContainer}>
										<View style={styles.form}>
											<FAHeader name='message'>Start a Routine</FAHeader>
										</View>
										<View style={styles.button}>
											<FAStoreButton name='createRoutine' />
										</View>
									</View>
									<FAStoreInput name='routineId' />
									<View style={styles.formContainer}>
										<View style={styles.button}>
											<FAStoreButton name='startBtn' />
										</View>
									</View>
								</View> */}
								<FAHeader name='list' underline style={styles.listHeader}>Routine List</FAHeader>
								<FAStoreGrid name='routineList'/>
							</>
						),
					}
				]}
			/>
			{/* <FAStoreModal name='editModal'
				component={() => {
					return (
						<View style={styles.container}>
							<View style={styles.formContainer}>
								<View style={styles.form}>
									<FAStoreInput name='editExerciseTypeId' />
									<View style={styles.exercise}>
										<View style={styles.flex}>
											<FAStoreInput name='editExerciseReps' />
										</View>
										<View style={styles.flex}>
											<FAStoreInput name='editExerciseWeight' />
										</View>
									</View>
								</View>
								<View style={[styles.button, { justifyContent: 'flex-end' }]}>
									<FAStoreButton name='editAddBtn' />
								</View>
							</View>
							<View style={styles.list}>
								<FAStoreGrid name='editWorkingList' />
							</View>
							<View style={styles.formContainer}>
								<View style={styles.form}>
									<FAStoreInput name='editComment' />
								</View>
							</View>
						</View>
					);
				}}
			/> */}
		</PageLayout>
	);
};

const styles = StyleSheet.create({
	container: {
		display: 'flex',
		width: '100%'
	},
	formContainer: {
		display: 'flex',
		width: '100%',
		flexDirection: 'row',
	},
	exercise: {
		display: 'flex',
		flexDirection: 'row',
		flex: 1,
	},
	form: {
		flex: 4,
		margin: 5,
		justifyContent: 'space-between',
	},
	button: {
		flex: 1,
		margin: 5,
		justifyContent: 'space-between',
	},
	info: {
		display: 'flex',
		flexDirection: 'row',
		flex: 1,
	},
	infoGrow: {
		flex: 1,
		flexGrow: 1,
		flexDirection: 'row',
	},
	editComponent: {
		display: 'flex',
		flexDirection: 'column',
	},
	list: {
		maxHeight: 120,
	},
	listHeader: {
	},
	save: {
		display: 'flex',
		flexDirection: 'row',
		flex: 1,
		justifyContent: 'center',
		alignContent: 'center',
	},
	saveGrow: {
		flex: 1,
	},
	flex: {
		flex: 2,
	},
	flexBtn: {
		flex: 1,
		margin: 5,
	},
})
