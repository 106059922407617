import React from 'react';
import { FASelectProps } from './FASelectProps';
import { InputGroup, InputLeftAddon, Select, View } from 'native-base';
import * as util from '../../util';
import { StyleSheet } from 'react-native';
import { FAText } from '../typography';

export const FASelect: React.FC<FASelectProps> = (props: FASelectProps) => {
	const {
		name,
		items,

		placeholder,
		color,
		placeholderTextColor,
		_item,
		_selectedItem,
		selectedValue,
		defaultValue,
		label,
		// onValueChange,
		isDisabled,
		isHovered,
		isFocused,
		isFocusVisible,
		dropDownIcon,
		dropdownOpenIcon,
		dropDownCloseIcon,
		variant,
		onOpen,
		onClose,
		_actionSheet,
		_actionSheetContent,
		_actionSheetBody,
		wrapperRef,
		value,
		setValue,
		getValue,
		onClick,
	} = props;
    
	const getStoreValue = () => {
		return (getValue && getValue(props)) || value;
	};

	const setStoreValue = (value: any) => {
		return setValue && setValue(value, props);
	};

	const attributes: any = {
		name, 
		items,

		placeholder,
		color,
		placeholderTextColor,
		_item,
		_selectedItem,
		// selectedValue,
		defaultValue,
		// onValueChange,
		isDisabled,
		isHovered,
		isFocused,
		isFocusVisible,
		dropDownIcon,
		dropdownOpenIcon,
		dropDownCloseIcon,
		variant,
		onOpen,
		onClose,
		_actionSheet,
		_actionSheetContent,
		_actionSheetBody,
		wrapperRef
	};

	attributes.selectedValue = getStoreValue();

	const handleChange = (text: string) => {
		setStoreValue(text);
		// assume data is always same format inside
		if (util.isArray(items)) {
			// assume value is a unique value
			const data = util.isString(items[0]) ? text : items.find((item) => item.value === text);
			if (onClick) onClick(text, data, props);
		}
	};
	attributes.onValueChange = handleChange;

	if (util.isArray(items)) {
		attributes.children = items.map((item, idx)=>{
			const {
				label,
				value,
			} = util.isString(item) ? { label: item, value: item } : item;
			return (
				<Select.Item key={`${JSON.stringify(label)}_${idx}`} label={label} value={value} />
			);
		})
	}
    
	console.log('Render FA Select', name);
	return (
		<View style={styles.group}>
			{label && <FAText name='label' style={styles.label}>{label}</FAText>}
			<View style={styles.input}>
				<Select {...attributes}  />
			</View>
		</View>
	);
};

const styles = StyleSheet.create({
	container: {
		flex: 1,
		flexDirection: 'column',
	},
	group: {
		flexDirection: 'row',
		justifyContent: 'center',
	},
	input: {
		flex: 1,
	},
	label: {
		margin: 5,
		justifyContent: 'center',
		alignSelf: 'center',
	},
});
