import React, { useCallback, useMemo } from 'react';
import { FAGridProps } from './FAGridProps';
import { FlatList, Pressable, View } from 'native-base';
import { FlatListProps, StyleSheet } from 'react-native';
import * as util from '../../util';


export const FASimpleList: React.FC<FAGridProps> = (props: FAGridProps) =>{
	const {
		name,
		dataSource,
		btnIcon = 'delete',
		// data info
		titleData,
		titleUnits,
		subtitleData,
		subtitleUnits,
		editBtn,
		deleteBtn,
		infoBtn = false,
		idData,
		style,
		renderComponent: RenderComponentItem,
		// service info
		onClick,
		uiHide,
		size,
	} = props;
	

	const keyExtractor = useCallback((item) => {
		return item.id;
	}, []);

	const attributes = useMemo(() => ({
		name,
		data: dataSource,
		renderItem: (props) => {
			if (!RenderComponentItem) {
				return null;
			}
			return (
				<RenderComponentItem 
					{...{
						...props,
						...{

						},
					}}
				/>
			);
		},
		keyExtractor,
		style,
	}), [
		name,
		dataSource,
		RenderComponentItem,
		keyExtractor,
	]);

	console.log('Render FlatList Simple', name);
	return <FlatList {...attributes} />;
};

const styles = StyleSheet.create({
});
