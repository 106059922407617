import React from 'react';
import { View, StyleSheet, Platform } from 'react-native';
import { useMarketingService } from './service';
import { uiState } from './marketing.ui';
import { MarketingStore } from './marketing.store';
import { FAHeader, FALink, FAStoreButton, FAStoreInput, FAStoreModal, FAStoreTypography, FAText, PageLayout } from '../../components';
import { useServiceRouter } from '../../service';
import { Image } from 'native-base';
import * as util from '../../util';
const imageIcon = require('../../assets/images/icon.png');
const userManual = require('../../assets/images/userManual.pdf');
import { WebView } from 'react-native-webview';

const googlefile = "1IS9LnWvlRRXOqLWzqr7NDNLHFTy3memKMmB5kDO4Pi4";
const link = `https://drive.google.com/uc?export=view&id=${googlefile}`;
const uri = 'https://docs.google.com/gview?embedded=true&url=https://docs.google.com/document/d/1IS9LnWvlRRXOqLWzqr7NDNLHFTy3memKMmB5kDO4Pi4/export?format=pdf';
const PdfViewer = () => {

  return (
    <View style={styles.container}>
		<FAHeader name='test'>Fit Guru Manual</FAHeader>
		<WebView 
			source={{ uri }} 
			style={styles.webview} 
			type
		/>
    </View>
  );
};


export const Marketing: React.FC = () => {
	const screenName = 'MarketingPage';
	const service = useMarketingService(screenName, MarketingStore, uiState);
	useServiceRouter(service);

	console.log('Render Marketing screen');
	return (
		<PageLayout>
			<Image
				alt='Main Icon Image'
				source={imageIcon}
				width={200}
				height={200}
				alignSelf={'center'}
			/>
			{
				Platform.OS === 'web' ? (
					<>
						<FAHeader name='test'>Download the User Manual by clicking below!</FAHeader>
						<a href={userManual}>Download the user manual by clicking here!</a>
					</>
				) : (
					<View>
						<FAStoreButton name='openModal' />
						<FAStoreModal
							name='viewManual'
							component={PdfViewer}
							componentProps={{
							}}
						/>
					</View>
				)
			}
		</PageLayout>
	);
};
const styles = StyleSheet.create({
	layout: {
		height: '100%',
		display: 'flex',
		flexDirection: 'column',
		margin: 5,
	},
	item: {
		padding: 5,
	},
	container: {
	  flex: 1,
	  justifyContent: 'center',
	  alignItems: 'center',
	},
	webview: {
	  width: util.getDim('80vw'),
	  height: util.getDim('75vh'),
	},
});