import { serviceBase } from '../../../service';
import { CustomOptions } from '../../../service/api';
import { getAppStateData, getAppStateStore, setAppStateStore, UIStoreInit } from '../../../store';

enum ModalTypeEnum {
	Username,
	Password,
	Email,
}

export class SystemSettingsService extends serviceBase {
	constructor(name: string, store: any, uiState: UIStoreInit) {
		super(name, store, uiState);

		this.responseData = this.responseData.bind(this);
		this.getSubmitData = this.getSubmitData.bind(this);
		this.transformData = this.transformData.bind(this);

		this.loadData = this.loadData.bind(this);
	}


	loadData() {
		// set app state data username to local store
		const username = getAppStateData('username');
		this.store.setProperty('username', username);
	}

	logout() {
		setAppStateStore('username', '');
		setAppStateStore('email', '');
		setAppStateStore('Authorization', '');
	}

	/**
     * get api data before api call
     * @param options 
     * @returns 
     */
	getSubmitData(options: CustomOptions) {
		return {};
	}

	/**
     * transform response function after api call 
     * @param data 
     * @param options 
     */
	transformData(data: any, options: CustomOptions) {
		const { name } = options;
		return data;
	}

	/**
     * onOk fn handler
     * @param value 
     * @param data 
     * @param props 
     */
	onOk(value: any, data: any, props: any) {
		const { name, routes } = props;
		switch (name) {
		case 'passModal':
			this.updateInfo();
			break;
		default:
			break;
		}
	}

	updateInfo() {
		// get modalType
		const modalType = this.store.getProperty('modalType');
		if (modalType === ModalTypeEnum.Username) {
			this.updateUsername();
		} else if (modalType === ModalTypeEnum.Password) {
			this.updatePassword();
		} else if (modalType === ModalTypeEnum.Email) {
			this.updateEmail();
		}
	}

	updateUsername() {
		// get parameters
		const newUsername = this.store.getProperty('newUsername');
		const password = this.store.getProperty('currentPassword');
		this.api.patchApiAsync(this.getUrl('updateUsername'), { newUsername, password }, { name: 'updateUsername' });
	}

	async updateUsernameCall() {
		// check if username entered
		const newUsername = this.store.getProperty('newUsername');
		if (!newUsername) {
			this.errorNotification('Please enter a username');
			return;
		}
		// check if username exists first
		const { data: exist, success } = await this.api.getApiAsync(this.getUrl('usernameExists'), { username: newUsername }, { name: 'usernameExists' });
		if (!success) {
			this.errorNotification('api error');
			return;
		}
		if (exist) {
			this.errorNotification('Username already exists');
			return;
		}
		// set modal type
		this.store.setProperty('modalType', ModalTypeEnum.Username);
		// open pass modal
		this.callComponentHook('passModal', 'open');
	}

	updatePassword() {
		// get parameters
		const newPassword = this.store.getProperty('newPassword');
		const password = this.store.getProperty('currentPassword');
		this.api.patchApiAsync(this.getUrl('updatePassword'), { newPassword, password }, { name: 'updatePassword' });
	}

	async updatePasswordCall() {
		// check if password entered
		const newPassword = this.store.getProperty('newPassword');
		const confirmPassword = this.store.getProperty('confirmPassword');
		if (!newPassword) {
			this.errorNotification('Please enter a password');
			return;
		}
		if (newPassword !== confirmPassword) {
			this.errorNotification('Passwords confirm do not match!');
			return;
		}
		// set modal type
		this.store.setProperty('modalType', ModalTypeEnum.Password);
		// open pass modal
		this.callComponentHook('passModal', 'open');
	}

	updateEmail() {
		// get parameters
		const newEmail = this.store.getProperty('newEmail');
		const password = this.store.getProperty('currentPassword');
		this.api.patchApiAsync(this.getUrl('updateEmail'), { newEmail, password }, { name: 'updateEmail' });
	}

	async updateEmailCall() {
		// check if email entered
		const newEmail = this.store.getProperty('newEmail');
		if (!newEmail) {
			this.errorNotification('Please enter a email');
			return;
		}
		// check if email exists first
		const { data: exist, success } = await this.api.getApiAsync(this.getUrl('emailExists'), { email: newEmail }, { name: 'emailExists' });
		if (!success) {
			this.errorNotification('api error');
			return;
		}
		if (exist) {
			this.errorNotification('Email already exists');
			return;
		}
		// set modal type
		this.store.setProperty('modalType', ModalTypeEnum.Email);
		// open pass modal
		this.callComponentHook('passModal', 'open');
	}

	/**
     * onClick fn handler
     * @param value 
     * @param data 
     * @param props 
     */
	onClick(value: any, data: any, props: any) {
		const { name, routes } = props;
		switch (name) {
		case 'updateUsernameBtn':
			this.updateUsernameCall();
			break;
		case 'updateEmailBtn':
			this.updateEmailCall();
			break;
		case 'updatePassBtn':
			this.updatePasswordCall();
			break;
		case 'deleteAccountBtn':
			this.redirectTo(this.getPath('deleteUser'));
			break;
		default:
			break;
		}
	}

	/**
     * response function after api call
     * @param data 
     * @param options 
     */
	responseData(data: any, options: CustomOptions): void {
		const { name } = options;
		if (name === 'updateUsername') {
			if (data.success) {
				this.successNotification('Success update username');
			} else {
				this.errorNotification(data.message);
			}
		} else if (name === 'updatePassword') {
			if (data.success) {
				this.successNotification('Success update password');
			} else {
				this.errorNotification(data.message);
			}
		} else if (name === 'updateEmail') {
			if (data.success) {
				this.successNotification('Success update email');
			} else {
				this.errorNotification(data.message);
			}
		}
	}

	resetData(data: any) {
		
	}
}