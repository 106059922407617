import React, { useMemo, useState } from 'react';
import { StyleSheet, Dimensions } from 'react-native';
import { FAHeader, FAStoreButton, FAText } from '../../../components';
import { Button, IconButton, Pressable, View } from 'native-base';
import * as util from '../../../util';
import { observer } from 'mobx-react-lite';
import { getService } from '../../../service';
import { icons, themeColors } from '../../../enums';
import moment from 'moment';

interface ScheduleProps {
	currentRoutine?: string;
}

export const Week: React.FC<ScheduleProps> = observer((props: ScheduleProps) => {
	const {
		currentRoutine
	} = props;
	const screenName = 'Week';

	const [current] = useState<util.DateDayofWeek>(util.getCurrentDay());
	const [open, setOpen] = useState<util.DateDayofWeek>(current);
	const [routine, setRoutine] = useState('');
	const [{
		week,
	}, setCurrent] = useState<{
		week: moment.Moment;
	}>({
		// get this week's monday
		week: util.today().startOf('week'),
	});
	const isRoutineStarted = routine && routine === currentRoutine;
    const service = getService();

	const dayItem = (day: util.DateDayofWeek) => {

		let dayName = '';
		let storeName = '';

		if (day === util.DateDayofWeek.Monday) {
			dayName = 'Monday';
			storeName = 'monday';
		} else if (day === util.DateDayofWeek.Tuesday) {
			dayName = 'Tuesday';
			storeName = 'tuesday';
		} else if (day === util.DateDayofWeek.Wednesday) {
			dayName = 'Wednesday';
			storeName = 'wednesday';
		} else if (day === util.DateDayofWeek.Thursday) {
			dayName = 'Thursday';
			storeName = 'thursday';
		} else if (day === util.DateDayofWeek.Friday) {
			dayName = 'Friday';
			storeName = 'friday';
		} else if (day === util.DateDayofWeek.Saturday) {
			dayName = 'Saturday';
			storeName = 'saturday';
		} else if (day === util.DateDayofWeek.Sunday) {
			dayName = 'Sunday';
			storeName = 'sunday';
		} else {
			return null;
		}

		const storeValue = service.store.getProperty(storeName);

		const isOpen = day === open;
		const isCurrent = day === current;

		const setRoutineId = (routine_id) => {
			// set selected routine id
			service.store.setProperty('routine_id', routine_id);
			// set component state as well
			setRoutine(routine_id);
		}

		const setDefaultRoutine = () => {

			// no routine set and day has first defined then set
			if (util.isArray(storeValue) && storeValue[0]?.routine_id) {
				setRoutineId(storeValue[0].routine_id);
			} else if (routine) {
				// else if set then there is none defined so clear
				setRoutineId('');
			}
		}

		const onPressFn = () => {
			if (!isOpen) {
				setDefaultRoutine();
				setOpen(day);
			}
		};

		if (isOpen && !routine) {
			setDefaultRoutine();
		}

		return (
			<Pressable
				style={styles.day}
				bg={themeColors.secondaryBase}
				opacity={isOpen ? 1 : .8}
				onPress= {onPressFn}
				flex={isOpen ? 8 : 2}
			>
				<View flexDir={'row'} justifyContent='space-between' alignContent='center'>
					<View style={isOpen ? styles.containerOpen : styles.containerClosed}>
						<FAText bold={isCurrent} name='day' color="white" fontSize="18" style={styles.dayText}>{dayName}</FAText>
						<View style={styles.containerClosed}>
							{util.isStrictArray(storeValue) && storeValue.map((item, idx) => {
								const {
									routine_name,
									routine_id
								} = item;


								const selected = routine === routine_id;
								
								return (
									<Pressable
										bg={themeColors.accent}
										opacity={selected ? 1 : .8}
										padding={1}
										marginLeft={1}
										borderRadius={5}
										onPress={() => {
											setRoutineId(routine_id);
										}}
										disabled={!isOpen}
										key={`${routine_id}_${idx}`}
									>
										<FAText bold name='routinename' color="white" fontSize={isOpen ? "14" : "8"} style={{ alignSelf: 'center' }}>{routine_name}</FAText>
									</Pressable>
								);
							})}
						</View>
					</View>
					{isOpen && (
						<FAStoreButton 
							name={isRoutineStarted ? 'continueRoutineBtn' : 'startRoutineBtn' }
							colorScheme={themeColors.thirdColorScheme}
						/>
					)}
				</View>
			</Pressable>
		);
	};

	const dateName = useMemo(() => {
		const monday = week.startOf('week').add(1, 'day').format('M/DD');
		const sunday = week.endOf('week').add(1, 'day').format('M/DD');

		return `${monday} - ${sunday}`;
	}, [week]);

	const IconLeft = icons.arrowback;
	const IconRight = icons.arrowforward;

	const decrementFn = () => {
		const next = util.subtractDays(week, 7);
		setCurrent({
			week: next,
		});
	};
	
	const incrementFn = () => {
		const next = util.addDays(week, 7);
		setCurrent({
			week: next,
		});
	};

	console.log('Render RoutineSchedule screen');
	return (
        <View style={styles.week}>
			{/* heder */}
			<View style={styles.modeContainer} backgroundColor={'gray.200'}>
				{/* go left button */}
				<View style={styles.iconMode}>
					<IconButton
						onPress={decrementFn}
					>
						<IconLeft
							style={styles.iconBtn}
						/>
					</IconButton>
				</View>
				{/* only 1 week show */}
				<View style={styles.mode}>
					<FAHeader
						name='title'
						textAlign={'center'}
					>
						{dateName}
					</FAHeader>
				</View>
				<View style={styles.iconMode}>
					<IconButton
						onPress={incrementFn}
					>
						<IconRight
							style={styles.iconBtn}
						/>
					</IconButton>
				</View>
			</View>
            {dayItem(util.DateDayofWeek.Monday)}
            {dayItem(util.DateDayofWeek.Tuesday)}
            {dayItem(util.DateDayofWeek.Wednesday)}
            {dayItem(util.DateDayofWeek.Thursday)}
            {dayItem(util.DateDayofWeek.Friday)}
            {dayItem(util.DateDayofWeek.Saturday)}
            {dayItem(util.DateDayofWeek.Sunday)}
        </View>
	);
});

const styles = StyleSheet.create({
	dayText: {
		display: 'flex',
		flexDirection: 'column',
		justifyContent: 'center',
		alignItems: 'flex-start'
	},
	week: {
		flex: 10,
	},
	day: {
		borderRadius: 20,
		justifyContent: 'center',
		padding: 15,
		// color: 'white',
		
		fontWeight: 500,
		margin: 5,
	},
	containerOpen: {
		
	},
	containerClosed: {
		display: 'flex',
		flexDirection: 'row',
	},
	iconBtn: {
		width: '80%',
		height: '80%',
	},
	btn: {
		width: '100%',
		height: '100%',
	},
	iconMode: {
		justifyContent: 'center',
		textAlign: 'center',
		borderRadius: 30,
		overflow: 'hidden',
		margin: 1,
	},
	mode: {
		flex: 1,
		flexGrow: 1,
		justifyContent: 'center',
		textAlign: 'center',
		borderRadius: 30,
		overflow: 'hidden',
		margin: 1,
	},
	modeContainer: {
		flex: 1,
		display: 'flex',
		flexDirection: 'row',
		justifyContent: 'flex-end',
		maxHeight: 50,
		minHeight: 50,
		overflow: 'hidden',
		borderRadius: 30,
		margin: 1,
	},
});