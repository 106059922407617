import React from 'react';
import { View, StyleSheet } from 'react-native';
import { useLoginService } from './service';
import { uiState } from './login.ui';
import { LoginStore } from './login.store';
import { FAHeader, FAStoreButton, FAStoreInput, FAText, PageLayout } from '../../components';
import { useServiceRouter } from '../../service';
import { Image } from 'native-base';
const icon = require('../../assets/images/icon.png');

export const Login: React.FC = () => {
	const screenName = 'LoginPage';
	const service = useLoginService(screenName, LoginStore, uiState);
	useServiceRouter(service);

	console.log('Render Login screen');
	return (
		<PageLayout>
			<View style={styles.icon}>
				<Image
					alt='Main Icon Image'
					source={icon}
					width={200}
					height={200}
					
				/>
			</View>
			<View style={styles.login}>
				<FAHeader name='header' bold>Sign In</FAHeader>
				<FAText name='subtitle'>Welcome back!</FAText>
				<FAStoreInput name='loginId' />
				<FAStoreInput name='password' />
				<View style={styles.forgotPassword}>
					<FAStoreButton name='forgotPasswordBtn' />
				</View>
				<FAStoreButton name='loginBtn' />
				<View
					style={styles.signupRedirect}
				>
					<FAText name='login' style={{ marginRight: 5 }}>Don't have an account?</FAText>
					<FAStoreButton name='redirectSignup' />
				</View>
			</View>
		</PageLayout>
	);
};
const styles = StyleSheet.create({
	forgotPassword: {
		display: 'flex',
		flexDirection: 'row',
		justifyContent: 'flex-end',
		width: '100%',
	},
	layout: {
		height: '100%',
		justifyContent: 'center'
	},
	icon: {
		width: '100%',
		justifyContent: 'center',
		display: 'flex',
		flexDirection: 'row',
	},
	login: {
		padding: 40,
		gap: 10,
		display: 'flex',
		flexDirection: 'column',
		justifyContent: 'center',
		alignItems: 'center',
	},
	signupRedirect: {
        width: '100%',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        margin: 5,
	},
});