import React from 'react';
import { StyleSheet } from 'react-native';
import { useUpdateAvailableService } from './service';
import { uiState } from './updateAvailable.ui';
import { UpdateAvailableStore } from './updateAvailable.store';
import { FAHeader, FALink, FAStoreButton, FAStoreInput, FAText, PageLayout } from '../../components';
import { useServiceRouter } from '../../service';
import { icons } from '../../enums';
import { View } from 'native-base';

const message = "Hey there! Just a heads-up: Fit Guru is getting some cool updates soon. We're tweaking the app to make it easier and more fun to use. Plus, you'll be able to share your workouts with your friends! How awesome is that? So, keep an eye out, and let's make our fitness journey even more enjoyable together. Can\'t wait for you to see what we\'ve got in store! 🎉🏃‍♂️🤳";


export const UpdateAvailable: React.FC = () => {
	const screenName = 'UpdateAvailablePage';
	const service = useUpdateAvailableService(screenName, UpdateAvailableStore, uiState);
	useServiceRouter(service);

	console.log('Render UpdateAvailable screen');
	return (
		<PageLayout>
			<View
				style={styles.buttonGroup}
				padding={10}
			>
				<FAText name='new' bold>{message}</FAText>
			</View>
		</PageLayout>
	);
};
const styles = StyleSheet.create({
	buttonGroup: {
		marginBottom: 20,
	},
});