import React from 'react';
import { View, StyleSheet } from 'react-native';
import { useAboutService } from './service';
import { uiState } from './about.ui';
import { AboutStore } from './about.store';
import { FAHeader, FALink, FAStoreButton, FAStoreInput, FAText, PageLayout } from '../../components';
import { useServiceRouter } from '../../service';
import { icons } from '../../enums';
// import { settingButtonProps } from '../Settings/Settings';

const ArrowForwardIcon = icons.arrowforward;

const settingButtonProps: Partial<React.ComponentProps<typeof FAStoreButton>> = {
	backgroundColor: 'white',
	_text: {
		color: 'black',
		fontWeight: 500,
	},
	_stack: {
		justifyContent: 'space-between',
		width: '100%',
	},
	// justifyContent: 'left',
	borderBottomColor: 'gray.300',
	borderBottomWidth: 1,
	endIcon: <ArrowForwardIcon color='black' /> as any,
	width: '100%',
}
export const About: React.FC = () => {
	const screenName = 'AboutPage';
	const service = useAboutService(screenName, AboutStore, uiState);
	useServiceRouter(service);

	console.log('Render About screen');
	return (
		<PageLayout>
			<View
				style={styles.buttonGroup}
			>
				<FAStoreButton
					name='updateNews'
					children={`What's New`}
					{...settingButtonProps}
				/>
				<FAStoreButton
					name='updateAvailable'
					children={'Update Available'}
					{...settingButtonProps}
				/>
				<FAStoreButton
					name='support'
					children={'Support'}
					{...settingButtonProps}
				/>
				<FAStoreButton
					name='manual'
					children={'Manual'}
					{...settingButtonProps}
				/>
				<FAStoreButton
					name='onboarding'
					children={'Onboarding'}
					{...settingButtonProps}
				/>
			</View>
		</PageLayout>
	);
};
const styles = StyleSheet.create({
	buttonGroup: {
		marginBottom: 20,
	},
});