import { ComponentEnum } from '../../enums';
import { UIStoreInit } from '../../store';
import { addNewBtn, saveBtn } from '../common';

export const uiState: UIStoreInit = {
	button: {
		addNewBtn,
		saveBtn: {
			name: 'saveBtn',
			componentType: ComponentEnum.ButtonTypeEnum.Button,
			children: 'Save',
		},
		notAllowedSaveBtn: {
			name: 'notAllowedSaveBtn',
			componentType: ComponentEnum.ButtonTypeEnum.Button,
			children: 'View',
			isDisabled: true,
		},
		progressionBtn: {
			name: 'progressionBtn',
			componentType: ComponentEnum.ButtonTypeEnum.Button,
			children: 'Goals',
		},
	},
	input: {
		iscalisthenics: {
			componentType: ComponentEnum.InputTypeEnum.Switch,
			name: 'iscalisthenics',
			label: 'Calisthenics',
			routes: 'iscalisthenics',
		},
		isbarbell: {
			componentType: ComponentEnum.InputTypeEnum.Switch,
			name: 'isbarbell',
			label: 'Barbell',
			routes: 'isbarbell',
		},
		exerciseTest: {
			componentType: ComponentEnum.InputTypeEnum.TextArea,
			name: 'exerciseTest',
			placeholder: 'ExerciseDetail Test',
			label: 'ExerciseDetail Test',
			routes: 'exerciseTest',
		},
		systemSwitch: {
			componentType: ComponentEnum.InputTypeEnum.Switch,
			name: 'systemSwitch',
			routes: 'systemSwitch',
		},
		editName: {
			routes: 'editName',
			placeholder: 'Name',
		},
		editDescription: {
			routes: 'editDescription',
			placeholder: 'Description',
		},
		addName: {
			routes: 'addName',
			placeholder: 'Name',
			label: 'Exercise Name',
		},
		addDescription: {
			routes: 'addDescription',
			placeholder: 'Description',
			label: 'Description',
		},
	},
	typography: {
		exerciseTest: {
			componentType: ComponentEnum.TypographyTypeEnum.Text,
			name: 'exerciseTest',
			placeholder: 'ExerciseDetail Test',
			label: 'ExerciseDetail Test',
			routes: 'exerciseTest',
		},
	},
	layout: {
		menuEnabled: false,
		// modelEnabled: true,
		// header: ' ',
		menuBackground: '#FFFFFF',
		backEnabled: true,
		headerEnabled: true,
		// settingsEnabled: true,
		btns: [
			'saveBtn',
			'notAllowedSaveBtn',
			// 'progressionBtn',
		],
	},
	grid: {
		exerciseList: {
			routes: 'exerciseList',
			titleData: 'exercise_type_name',
			subtitleData: 'exercise_type_description',
			editBtn: true,
		},
		exerciseMuscles: {
			componentType: ComponentEnum.GridTypeEnum.SwitchGrid,
			routes: 'exerciseMuscles',
			titleData: 'muscleName',
			disableVirtualization: true,
		}
	},
	modal: {
		editModal: {
			title: 'Edit ExerciseDetail',
			okText: 'Save Edit',
		},
		addModal: {
			title: 'Add ExerciseDetail',
			okText: 'Add',
		},
	},
};
