import React, { useEffect } from 'react';
import { View, StyleSheet } from 'react-native';
import { useModelService } from './service';
import { uiState } from './model.ui';
import { ModelStore } from './model.store';
import { FAButton, FAHeader, FAIconButton, FAStoreButton, FAStoreGrid, FAStoreInput, FAStoreModal, FAText, PageLayout } from '../../components';
import { ThreeHumanModel, StoreThreeHumanModel } from '../../components/threejs';
import { useServiceRouter } from '../../service';
import { margin as menuMargin, width as menuWidth, height as menuHeight } from '../../components/modal/FAModalMenu';
import { useComponentRefresh } from '../../hooks';
import * as util from '../../util';

export const Model: React.FC = () => {
	const screenName = 'ModelPage';
	const service = useModelService(screenName, ModelStore, uiState);
	useServiceRouter(service);

	useEffect(() => {
		service.loadData();
	}, []);

	// const [refresh] = useComponentRefresh();

	console.log('Render Model screen');
	return (
		<PageLayout>
		{/* <FAButton name='t' onClick={refresh}>refresh</FAButton> */}
			<View style={styles.model}>
				<StoreThreeHumanModel name='modelMenu' />
			</View>
			<FAHeader name='header'>Last 4 Days Muscles</FAHeader>
			<FAStoreGrid name='muscleList'/>
			<FAStoreModal name='muscleModal' />
		</PageLayout>
	);
};

const styles = StyleSheet.create({
	model: {
		height: util.getDim('40vh'),
		paddingHorizontal: '10%',
	},
	floating: {
		position: 'absolute',
		bottom: 25,
		right: 25,
	},
	container: {
		position: 'absolute',
		width: menuWidth,
		height: menuWidth,
		top: 0,
		right: menuMargin,
		display: 'flex',
		flexDirection: 'column',
		justifyContent: 'flex-end',
	},
	scroll: {
		borderRadius: 10,
	},
});
