import { getMuscleColor } from '../../../enums/colors';
import { ExerciseGoalType } from '../../../enums/enums';
import { serviceBase } from '../../../service';
import { CustomOptions } from '../../../service/api';
import { getAppStateData, UIStoreInit } from '../../../store';
import * as util from '../../../util';

export class ExerciseGoalService extends serviceBase {
	constructor(name: string, store: any, uiState: UIStoreInit) {
		super(name, store, uiState);

		this.responseData = this.responseData.bind(this);
		this.getSubmitData = this.getSubmitData.bind(this);
		this.transformData = this.transformData.bind(this);

		this.loadData = this.loadData.bind(this);
		this.setExerciseGoalInfo = this.setExerciseGoalInfo.bind(this);
	}

	async calculateProgression() {
		// save goal
		const routine_exercise_id = this.store?.getProperty('routine_exercise_id');
		
		if (!routine_exercise_id) {
			this.errorNotification('Choose exercise to edit!');
			return;
		}
		// startLoading
		this.startBtnLoading('viewProgression');
		await this.api.getApiAsync(this.getUrl('progressionRate'), { 
			routine_exercise_id,
		}, { name: 'progressionRate', otherOptions: {
			onErrorFn: () => {
				this.endBtnLoading('viewProgression');
			},
		} });
	}

	async loadProgressionPrediction() {
		const routine_exercise_id = this.store?.getProperty('routine_exercise_id');
		const numOfWorkouts = Math.floor(this.store?.getProperty('numOfWorkouts'));
		
		if (!(util.isNumber(numOfWorkouts) && numOfWorkouts >= 0)) {
			return;
		}

		if (!routine_exercise_id) {
			return;
		}
		// startLoading
		this.startBtnLoading('viewProgression');
		await this.api.getApiAsync(this.getUrl('progressionPrediction'), { 
			routine_exercise_id,
			count: numOfWorkouts,
		}, { name: 'progressionPrediction', otherOptions: {
			onErrorFn: () => {
				this.endBtnLoading('viewProgression');
			},
		} });
	}

	async saveDetail() {
		const routine_exercise_id = this.store?.getProperty('routine_exercise_id');
		const exercise_goal_id = this.store?.getProperty('exercise_goal_id');
		const exercise_weight_goal = this.store?.getProperty('exercise_weight_goal');
		const exercise_goal_type = this.store?.getProperty('exercise_goal_type');
		const exercise_weight_date = this.store?.getProperty('exercise_weight_date');
		const progression_rate = this.store?.getProperty('progression_rate');
		const uselast = this.store?.getProperty('uselast');
		// exercise info
		const repFrom = this.store?.getProperty('repFrom');
		const repTo = this.store?.getProperty('repTo');
		const setCount = this.store?.getProperty('setCount');
		const initialWeight = this.store?.getProperty('initialWeight');
		const increment = this.store?.getProperty('increment');
		const numOfWorkouts = this.store?.getProperty('numOfWorkouts');

		// this is saved inside of routine_exercise info
		const exercise_info = JSON.stringify({
			repFrom,
			repTo,
			setCount,
			initialWeight,
			increment,
			numOfWorkouts,
		});

		// exercise info
		
		if (!routine_exercise_id) {
			this.errorNotification('Choose exercise to edit!');
			return;
		}
		// startLoading
		this.startBtnLoading('saveGoal');
		await this.api.patchApiAsync(this.getUrl('updateExerciseGoal'), { 
			routine_exercise_id,
			exercise_goal_id,
			exercise_weight_goal,
			exercise_weight_date,
			progression_rate,
			uselast,
			exercise_goal_type,
			exercise_info,
		}, { name: 'editExerciseGoal', otherOptions: {
			onErrorFn: () => {
				this.endBtnLoading('saveGoal');
			},
		} });
	}

	startPage() {
		// get exercise type id
		const state = this.routing.location.state || {};
		const {
			routine_exercise_id,
		} = state;

		this.store.clear();
		if (routine_exercise_id) {
			this.store.setProperty('routine_exercise_id', routine_exercise_id);
		}
		this.loadData();
	}

	loadData() {
		const routine_exercise_id = this.store.getProperty('routine_exercise_id');
		if (routine_exercise_id) {
			this.api.getApiAsync(this.getUrl('loadExerciseGoal'), { routine_exercise_id }, { name: 'loadExerciseGoal', otherOptions: { routine_exercise_id } });
		}
	}


	deleteExerciseGoal(item) {
		const {
			routine_exercise_id,
			exercise_type,
		} = item;
		const url = exercise_type === 'SYSTEM' ? 'exerciseSystem' : 'exercise';
		this.api.deleteApiAsync(this.getUrl(url), { routine_exercise_id }, { name: 'deleteExerciseGoal' });
	}

	/**
     * get api data before api call
     * @param options 
     * @returns 
     */
	getSubmitData(options: CustomOptions) {
		const { name } = options;
		return {};
	}

	/**
     * transform response function after api call 
     * @param data 
     * @param options 
     */
	transformData(data: any, options: CustomOptions) {
		const { name } = options;
		if (name === 'loginApi') {
            
			return data;
		}
		return data;
	}

	/**
     * onChange fn handler
     * @param value 
     * @param data 
     * @param props 
     */
	onChange(value: any, data: any, props: any) {
		const { name } = props;
		switch (name) {
		case 'progression_rate':
			this.setProgressionRateMessage();
			break;
		default:
			break;
		}
	}

	/**
     * onClick fn handler
     * @param value 
     * @param data 
     * @param props 
     */
	onClick(value: any, data: any, props: any) {
		const { name } = props || {};
		switch (name) {
		case 'saveGoal':
			this.saveDetail();
			break;
		case 'viewProgression':
			this.openModal('viewProgressionModal');
			// this.calculateProgression();
			break;
		case 'uselast':
			this.onChangeUseLast();
			break;
		default:
			break;
		}
	}

	async onCalcuate() {
		
		// save data
		await this.saveDetail();
		
		// await this.calculateProgression();
	}

	onChangeUseLast() {
		// transform exercise goal, only 2 right now
		const uselast = this.store.getProperty('uselast');

		if (uselast) {
			this.store.setProperty('exercise_goal_type', ExerciseGoalType.UseLast);
		} else {
			this.store.setProperty('exercise_goal_type', ExerciseGoalType.WeightRep);
		}

	}

	/**
     * onCancel fn handler
     * @param value 
     * @param data 
     * @param props 
     */
	onCancel(value: any, data: any, props: any) {
		const { name } = props;
		switch (name) {
			default:
				break;
		}
	}

	/**
     * onOk fn handler
     * @param value 
     * @param data 
     * @param props 
     */
	onOk(value: any, data: any, props: any) {
		const { name } = props;
		switch (name) {
			default:
				break;
		}
	}

	/**
     * response function after api call
     * @param data 
     * @param options 
     */
	responseData(data: any, options: CustomOptions): void {
		const { name, otherOptions: { routine_exercise_id } = {} } = options;
		if (name === 'loadExerciseGoal') {
			if (data.success) {
				this.setExerciseGoalInfo(data.data, routine_exercise_id);
			} else {
				this.errorNotification('error!');
			}
		}
		if (name === 'progressionRate') {
			if (data.success) {
				this.setProgressionRate(data.data);
			}
			// end loading
			this.endBtnLoading('viewProgression');
		}
		if (name === 'progressionPrediction') {
			if (data.success) {
				this.setProgressionPrediction(data.data);
			}
			// end loading
			this.endBtnLoading('viewProgression');
		}
		if (name === 'addExerciseGoal' || name === 'editExerciseGoal') {
			if (data.success) {
				this.setExerciseGoalInfo(data.data, routine_exercise_id);
				this.successNotification('saved!');
			}
			// end loading
			this.endBtnLoading('saveGoal');
		}
		if (name === 'deleteExerciseGoal') {
			if (data.success) {
				this.successNotification('deleted!');
			}
			// end loading
			this.endBtnLoading('saveGoal');
		}
	}

	get showProgression() {
		return Boolean(this.getCache('last', 'last', false));
	}
	
	set showProgression(val: boolean) {
		this.setCache('last', 'last', val);
	}

	setExerciseGoalInfo(data: any, routine_exercise_idVal: string) {
		const {
			routine_exercise_id = routine_exercise_idVal,
			routine_exercise: {
				exercise_type: {
					exercise_type_name = 'Goal not created yet',
				} = {},
			} = {},
			exercise_goal_id,
			exercise_weight_goal = 220,
			exercise_weight_date = util.today().add(3, 'M').format('MM/DD/YYYY'),
			progression_rate = 0,
			exercise_goal_type = 0,
			exercise_info = '',
		} = data || {};
		// set store property
		const uselast = exercise_goal_type === ExerciseGoalType.UseLast;
		this.showProgression = !(uselast);
		this.store.setProperty('uselast', uselast);
		this.store?.setProperty('routine_exercise_id', routine_exercise_id);
		this.store?.setProperty('exerciseName', exercise_type_name);
		this.store?.setProperty('exercise_goal_id', exercise_goal_id);
		this.store?.setProperty('exercise_weight_goal', exercise_weight_goal);
		this.store?.setProperty('exercise_weight_date', exercise_weight_date);
		this.store?.setProperty('progression_rate', progression_rate);
		this.store?.setProperty('exercise_goal_type', exercise_goal_type);

		// exercise_info
		const parsed = util.tryParse(exercise_info, {});

		const {
			repFrom = 1,
			repTo = 10,
			setCount = 3,
			initialWeight = 10,
			increment = 10,
			numOfWorkouts = 1,
		} = parsed;
		
		this.store?.setProperty('repFrom', repFrom);
		this.store?.setProperty('repTo', repTo);
		this.store?.setProperty('setCount', setCount);
		this.store?.setProperty('initialWeight', initialWeight);
		this.store?.setProperty('increment', increment);
		this.store?.setProperty('numOfWorkouts', numOfWorkouts);
		this.store.setDataVersion();
		// exercise_info
		
		
		this.setProgressionRateMessage();
		this.loadProgressionPrediction();
	}

	setProgressionRate(data) {
		const {
			exercise_weight_date,
            exercise_weight_goal,
            progression_rate,
            numOfWorkouts,
            weightGain,
            ratePerWorkout,
		} = data;

		this.store.setProperty('progression_rate', progression_rate);
		this.store.setProperty('numOfWorkouts', numOfWorkouts);
		this.setProgressionRateMessage();
	}

	setProgressionPrediction(data) {
		if (!util.isStrictArray(data)) {
			data = [];
		}
		
		data.forEach((item) => {
			// convert to a string
			const {
				date,
				// exercise_info,
				sets,
			} = item;

			item.workout_title = util.formatDate(date);

			let infoStr: string = '';
			if (util.isArray(sets)) {
				infoStr = sets.map(({ set_weight: weight, set_reps: reps }) => `${reps} x ${weight}lbs.`).join('\n');
			}
			// const date = util.formatDate(item.log_date);
			item.workout_subTitle = infoStr;
		});

		this.store.setGrid('predictionList', data);
	}

	getProgressionRateMessage(progression_rate: number) {

		if (progression_rate > 2 || progression_rate <= 0) {
			return '';
		}

		// transform -> rate means pounds per day

		// increase, don't do more than 1 pound a day

		const daysPerPound = (1 / progression_rate as number).toFixed(1);

		return `1 pound every ${daysPerPound} days`;
	}

	setProgressionRateMessage() {
		// get 
		const progression_rate = this.store.getProperty('progression_rate');

		const message = this.getProgressionRateMessage(progression_rate);
		
		// set
		this.store.setProperty('progression_message', message);
	}

	uiHide(name: string, data: any): boolean {
		if (
			name === 'exercise_weight_goal'
			|| name === 'exercise_weight_date'
			|| name === 'progression_rate'
			|| name === 'initialWeight'
			|| name === 'repFrom'
			|| name === 'repTo'
			|| name === 'setCount'
			|| name === 'increment'
			|| name === 'description'
		) {
			const uselast = this.store.getProperty('uselast');
			if (uselast) {
				return true;
			}
		}

		if (name === 'viewProgression') {
			if (!this.showProgression) {
				return true;
			}
		}
		return false;
	}
}