import { paths } from '../../../enums';
import { serviceBase } from '../../../service';
import { CustomOptions } from '../../../service/api';
import { setAppStateStore, UIStoreInit } from '../../../store';

export class LoginService extends serviceBase {
	constructor(name: string, store: any, uiState: UIStoreInit) {
		super(name, store, uiState);

		this.responseData = this.responseData.bind(this);
		this.getSubmitData = this.getSubmitData.bind(this);
		this.transformData = this.transformData.bind(this);

		this.login = this.login.bind(this);
		this.setLoginInfo = this.setLoginInfo.bind(this);
	}


	login() {
		// validate first
		const loginId = this.store.getProperty('loginId');
		const password = this.store.getProperty('password');

		if (!loginId) {
			this.errorNotification('Please enter username/email');
			return;
		}

		if (!password) {
			this.errorNotification('Please enter password');
			return;
		}

		// startLoading
		this.startBtnLoading('loginBtn');
		this.api.postApiAsync(this.getUrl('login'), { loginId, password }, { name: 'loginApi', otherOptions: { onErrorFn: () => { this.endBtnLoading('loginBtn'); }} });
	}

	/**
     * get api data before api call
     * @param options 
     * @returns 
     */
	getSubmitData(options: CustomOptions) {
		const { name } = options;
		return {};
	}

	/**
     * transform response function after api call 
     * @param data 
     * @param options 
     */
	transformData(data: any, options: CustomOptions) {
		const { name } = options;
		if (name === 'loginApi') {
            
			return data;
		}
		return data;
	}

	/**
     * onClick fn handler
     * @param value 
     * @param data 
     * @param props 
     */
	onClick(value: any, data: any, props: any) {
		const { name } = props;
		switch (name) {
		case 'loginBtn':
			this.login();
			break;
		case 'workoutPage':
			this.redirectTo(paths.workout);
			break;
		case 'redirectSignup':
			this.redirectTo(paths.signup);
			break;
		case 'forgotPasswordBtn':
			this.redirectTo(this.getPath('resetPassword'));
			break;
		default:
			break;
		}
	}

	/**
     * response function after api call
     * @param data 
     * @param options 
     */
	responseData(data: any, options: CustomOptions): void {
		const { name } = options;
		if (name === 'loginApi') {
			if (data.success) {
				this.setLoginInfo(data.data);
			} else {
				this.errorNotification('error!');
			}
			// end loading
			this.endBtnLoading('loginBtn');
		}
	}

	setLoginInfo(data: any) {
		const {
			user: {
				token,
				user_email: email,
				user_name: username,
				user_type: type,
			}
		} = data;
		if (token) setAppStateStore('Authorization', token);
		if (email) setAppStateStore('email', email);
		if (username) setAppStateStore('username', username);
		if (type) setAppStateStore('type', type);
	}
}