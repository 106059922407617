import React from 'react';
import { ComponentEnum } from '../../enums';
import { useComponentProps } from '../../service';
import { observer } from 'mobx-react-lite';
import { FAText } from './FAText';
import { FAHeader } from './FAHeader';
import { FATypographyProps } from './FATypographyProps';
import { FATypographyLink } from './FATypographyLink';
import { useDataVersion } from '../../store/hooks';

interface StoreTypographyProps {
    componentType?: number,
}

export type FAStoreTypographyProps = (FATypographyProps) & StoreTypographyProps;

export const FAStoreTypography: React.FC<FAStoreTypographyProps> = observer((props: FAStoreTypographyProps) => {

	const attributes = useComponentProps(props, 'typography');
	const [dataVersion, getDataVersion, setDataVersion] = useDataVersion();
	
	const {
		name,
		componentType,
		uiHide,
	} = attributes;

	// hide by name
	if (uiHide(name, props)) return null;

	let Component;
	switch (componentType) {
	case ComponentEnum.TypographyTypeEnum.Text:
		Component = FAText;
		break;
	case ComponentEnum.TypographyTypeEnum.Link:
		Component = FATypographyLink;
		break;
	case ComponentEnum.TypographyTypeEnum.Header:
		Component = FAHeader;
		break;
	default:
		Component = FAText;
		break;

	}

	console.log('Render FA Store Input');
	return <Component {...attributes}/>;
});
