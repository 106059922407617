import { serviceBase } from './serviceBase';

interface ServicePool {
    current?: serviceBase;
    history?: serviceBase[];
}

const servicePool: ServicePool  = {};

/**
 * set service as current and add to pool
 * @param name 
 * @param service 
 */
export const useService = (name: string, service: serviceBase) => {
	if (!servicePool[name]) servicePool[name] = service;
	servicePool.current = servicePool[name];
};

/**
 * get current service or by name
 * @param name 
 * @returns 
 */
export const getService = (name?: string): serviceBase | undefined => {
	if (name) return servicePool[name];
	return servicePool.current;
};
