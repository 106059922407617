import _ from 'lodash';

/**
 * clone object
 * @param obj 
 * @returns 
 */
export const cloneDeep = (obj: any) => {
	return _.cloneDeep(obj);
};

/**
 * merge objectFrom to object - ignore undefined
 * @param object 
 * @param objectFrom 
 * @returns 
 */
export const merge = (object: any, ...objectFrom: any[]): any => {
	return _.extend(object, ...objectFrom);
};

/**
 * merge objectFrom to object - overwrite undefined
 * @param object 
 * @param objectFrom 
 * @returns 
 */
export const mergeDeep = (object: any, ...objectFrom: any[]): any => {
	return _.merge(object, ...objectFrom);
};