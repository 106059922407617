import React, { useEffect } from 'react';
import { StyleSheet } from 'react-native';
import { useSettingsService } from './service';
import { uiState } from './settings.ui';
import { SettingsStore } from './settings.store';
import { FAButton, FAHeader, FAStoreButton, FAStoreTypography, PageLayout } from '../../components';
import { useServiceRouter } from '../../service';
import { useComponentRefresh } from '../../hooks';
import { Image, View } from 'native-base';
import { icons } from '../../enums';
const icon = require('../../assets/images/icon.png');


const ArrowForwardIcon = icons.arrowforward;

export const settingButtonProps: Partial<React.ComponentProps<typeof FAStoreButton>> = {
	backgroundColor: 'white',
	_text: {
		color: 'black',
		fontWeight: 500,
	},
	_stack: {
		justifyContent: 'space-between',
		width: '100%',
	},
	// justifyContent: 'left',
	borderBottomColor: 'gray.300',
	borderBottomWidth: 1,
	endIcon: <ArrowForwardIcon color='black' /> as any,
	width: '100%',
}

export const Settings: React.FC = (props: any) => {
	const screenName = 'SettingsPage';
	const service = useSettingsService(screenName, SettingsStore, uiState);
	useServiceRouter(service);

	useEffect(() => {
		service.loadData();
	}, []);


	console.log('Render Settings screen');
	return (
		<PageLayout>
			<View style={styles.button}>
				{/* top part */}
				<View
					style={styles.topContainer}
					backgroundColor={'lightBlue.200'}
				>
					<View
						style={styles.top}
					>
						{/* icon */}
						<View style={styles.icon}>
							<Image
								alt='user image'
								source={icon}
								width={50}
								height={50}
								
							/>
						</View>
						{/* user info */}
						<View style={styles.userInfo}>
							<FAStoreTypography
								name='username'
							/>
							<FAStoreTypography
								name='email'
							/>
						</View>
					</View>
				</View>
				{/* button group */}
				<View
					style={styles.buttonGroup}
				>
					<FAStoreButton
						name='workoutHistory'
						children={'Workout History'}
						{...settingButtonProps}
					/>
					<FAStoreButton
						name='systemSettingsBtn'
						children={'Account Settings'}
						{...settingButtonProps}
					/>
					<FAStoreButton
						name='about'
						children={'About Fitguru'}
						{...settingButtonProps}
					/>
				</View>
				<FAStoreButton name='logoutBtn' />

				
				{/* <FAStoreButton name='routineBtn' />
				<FAStoreButton name='exerciseBtn' />
				<FAStoreButton name='workoutBtn' />
				<FAStoreButton name='onboardingBtn' />
				<FAStoreButton name='weightBtn' />
				<FAStoreButton name='routineScheduleBtn' />
				<FAStoreButton name='systemSettingsBtn' /> */}
			</View>
		</PageLayout>
	);
};

const styles = StyleSheet.create({
	button: {
		margin: 5,
	},
	top: {
		display: 'flex',
		flexDirection: 'row',
	},
	topContainer: {
		padding: 20,
	},
	icon: {
		justifyContent: 'center',
		display: 'flex',
		flexDirection: 'row',
		borderRadius: 25,
		marginRight: 10,
		overflow: 'hidden',
	},
	userInfo: {
		display: 'flex',
		flexDirection: 'column'
	},
	buttonGroup: {
		marginBottom: 20,
	},
});
