import React from 'react';
import { FAGridProps } from './FAGridProps';
import { FlatList, Pressable, Slider, View } from 'native-base';
import { StyleSheet } from 'react-native';
import { icons } from '../../enums';
import { FAHeader } from '../typography/FAHeader';
import { FAText } from '../typography/FAText';
import { FAIconButton } from '../button/FAIconButton';
import * as util from '../../util';
import { useComponentProps } from '../../service';
import { useComponentRefresh } from '../../hooks';
import { observer } from 'mobx-react-lite';

const RowItem: React.FC<any> = (props: any) => {
	const {
		index,
		data,
		title,
		subtitle,
		setValue,
		readOnly = false,
		refresh,
	} = props;

	// const [refresh] = useComponentRefresh();

	const getValue = () => {
		return data.value || 0;
	};

	const setValueFn: (value: number) => void = (value: number) => {
		setValue(value);
		// refresh this switch
		if (util.isFunction(refresh)) {
			refresh();
		}
	};
	
	const value = getValue();

	return (
		<View style={styles.row}>
			<FAText name='label' style={styles.label}>{title}</FAText>
			<View style={styles.infoSection}>
				<Slider 
					value={value}
					minValue={0}
					maxValue={100}
					accessibilityLabel="hello world"
					step={10}
					onChange={setValueFn}
					isReadOnly={readOnly}
				>
					<Slider.Track>
					<Slider.FilledTrack />
					</Slider.Track>
					<Slider.Thumb />
				</Slider>
			</View>
		</View>
	);
};

export const FASwitchList: React.FC<FAGridProps> = observer((props: FAGridProps) =>{

	props = useComponentProps(props, 'grid');
	const [refresh] = useComponentRefresh();
	
	const {
		componentType,
		getValue,
		// dataSource,
		btnIcon = 'delete',
		// data info
		titleData,
		titleUnits,
		subtitleData,
		subtitleUnits,
		editBtn,
		disableVirtualization,
		// service info
		onClick,
		uiHide,
		name,
		getService,
		routes,
		uiReadOnly,
	} = props;
    
	// const getStoreValue = () => {
	// 	const storeValue = getValue && getValue();
	// 	if (util.isArray(storeValue)) {
	// 		return storeValue;
	// 	}
	// 	return [];
	// };

	const dataSource = getValue?.call(null) || [];
	const readOnly = uiReadOnly(name, props);

	// const gridIcon = (util.isString(btnIcon) ? (icons[btnIcon]) : icons.delete) || icons.delete;

	const renderItem = (dataItem: any) => {
		const {
			item,
			index,
			separators,
		} = dataItem;
		
		const {
			[titleData]: title,
			[subtitleData]: subtitle,
			id,
		} = item;

		const setStoreValueFn = (value: number) => {
			const service = getService();
			service.store.updateGridRowColumn(routes, id, 'value', value);
			if (onClick) onClick(value, item, props);
		}

		return (
			<RowItem 
				data={item}
				title={title}
				subtitle={subtitle}
				index={index}
				setValue={setStoreValueFn}
				readOnly={readOnly}
				refresh={refresh}
			/>
		);
	}

	// datasource is numbers array
	const attributes: any = {
		name,
		data: dataSource,
		renderItem,
		disableVirtualization: true,
		style: styles.container,
	};

	console.log('Render SwitchList', name);
	return <FlatList {...attributes}/>;
});

const styles = StyleSheet.create({
	container: {
		margin: 10,

	},
	rowItem: {
		flex: 1,
		flexDirection: 'row',
		borderBottomColor: 'grey',
		borderBottomWidth: 1,
		justifyContent: 'center',
		alignContent: 'center',
	},
	infoSection: {
		flex: 1,
		marginHorizontal: 10,
		marginVertical: 4,
	},
	title: {
	},
	button: {
		justifyContent: 'center',
		alignContent: 'center',
	},
	subtitle: {
	},
	row: {
		flex: 1,
		flexDirection: 'row',
		padding: 5,
	},
	label: {
		minWidth: 60,
	},
})