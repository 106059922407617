import { paths } from '../../../enums';
import { serviceBase } from '../../../service';
import { CustomOptions } from '../../../service/api';
import { setAppStateStore, UIStoreInit } from '../../../store';

export class SignupService extends serviceBase {
	constructor(name: string, store: any, uiState: UIStoreInit) {
		super(name, store, uiState);

		this.responseData = this.responseData.bind(this);
		this.getSubmitData = this.getSubmitData.bind(this);
		this.transformData = this.transformData.bind(this);

		this.signup = this.signup.bind(this);
		this.setSignupInfo = this.setSignupInfo.bind(this);
	}


	async signup() {
		// validate first
		const email = this.store.getProperty('email');
		const username = this.store.getProperty('username');
		const password = this.store.getProperty('password');
		const confirmPassword = this.store.getProperty('confirmPassword');
		const conditions = this.store.getProperty('conditions');

		if (!email) {
			this.errorNotification('Please enter email');
			return;
		}
		if (!username) {
			this.errorNotification('Please enter username');
			return;
		}

		if (!password || !confirmPassword) {
			this.errorNotification('Please enter password');
			return;
		}

		if (password !== confirmPassword) {
			this.errorNotification('Passwords not matching');
			return;
		}

		if (!conditions) {
			this.errorNotification('Please agree to the terms and conditions');
			return;
		}

		// check username and email not taken
		const { data: exist1, success: success1 } = await this.api.getApiAsync(this.getUrl('emailExists'), { email }, { name: 'emailExists' });
		if (!success1) {
			this.errorNotification('api error');
			return;
		}
		if (exist1) {
			this.errorNotification('Email already exists');
			return;
		}
		// check if username exists first
		const { data: exist, success } = await this.api.getApiAsync(this.getUrl('usernameExists'), { username }, { name: 'usernameExists' });
		if (!success) {
			this.errorNotification('api error');
			return;
		}
		if (exist) {
			this.errorNotification('Username already exists');
			return;
		}

		// startLoading
		this.startBtnLoading('signupBtn');
		this.api.postApiAsync(this.getUrl('signup'), { email, username, password }, { name: 'signupApi' });
	}

	/**
     * get api data before api call
     * @param options 
     * @returns 
     */
	getSubmitData(options: CustomOptions) {
		const { name } = options;
		return {};
	}

	/**
     * transform response function after api call 
     * @param data 
     * @param options 
     */
	transformData(data: any, options: CustomOptions) {
		const { name } = options;
		if (name === 'signupApi') {
            
			return data;
		}
		return data;
	}

	/**
     * onClick fn handler
     * @param value 
     * @param data 
     * @param props 
     */
	onClick(value: any, data: any, props: any) {
		const { name } = props;
		switch (name) {
		case 'signupBtn':
			this.signup();
			break;
		case 'redirectLogin':
			this.redirectTo(paths.login);
			break;
		case 'workoutPage':
			this.redirectTo(paths.workout);
			break;
		case 'termsAndCondition':
			this.redirectTo(paths.privacy);
			break;
		default:
			break;
		}
	}

	/**
     * response function after api call
     * @param data 
     * @param options 
     */
	responseData(data: any, options: CustomOptions): void {
		const { name } = options;
		if (name === 'signupApi') {
			if (data.success) {
				this.setSignupInfo(data.data);
			} else {
				this.errorNotification('error!', data.message);
			}
			// endloading
			this.endBtnLoading('signupBtn');
		}
	}

	setSignupInfo(data: any) {
		const {
			user: {
				token,
				user_email: email,
				user_name: username,
				user_type: type,
			}
		} = data;
		if (token) setAppStateStore('Authorization', token);
		if (email) setAppStateStore('email', email);
		if (username) setAppStateStore('username', username);
		if (type) setAppStateStore('type', type);
	}
}