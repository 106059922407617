import React from 'react';
import { ComponentEnum } from '../../enums';
import { useComponentProps } from '../../service';
import { observer } from 'mobx-react-lite';
import { FAModalProps } from './FAModalProps';
import { FAModal } from './FAModal';
import * as util from '../../util';
import { FAActionSheetSpecial } from './FAActionSheetSpecial';
import { FAModalMenu } from './FAModalMenu';
import { FAActionSheet } from './FAActionSheet';
import { FAFullModal } from './FAFullModal';

export const FAStoreModal: React.FC<FAModalProps> = (props: FAModalProps) => {

	const attributes = useComponentProps(props, 'modal', false);
	
	const {
		componentType,
		children,
		name,
	} = attributes;
	// if (util.isArray(children)) {
	// 	children.forEach((item) => {
	// 		item.key = util.getUuid();
	// 	});
	// }

	let Component;
	switch (componentType) {
	case ComponentEnum.ModalTypeEnum.Modal:
		Component = FAModal;
		break;
	case ComponentEnum.ModalTypeEnum.ActionSheetSpecial:
		Component = FAActionSheetSpecial;
		break;
	case ComponentEnum.ModalTypeEnum.Menu:
		Component = FAModalMenu;
		break;
	case ComponentEnum.ModalTypeEnum.ActionSheet:
		Component = FAActionSheet;
		break;
	case ComponentEnum.ModalTypeEnum.Full:
		Component = FAFullModal;
		break;
	default:
		Component = FAModal;
		break;
	}

	console.log('Render FA Store Modal', name);
	return <Component {...attributes}/>;
};
