import AsyncStorage from '@react-native-async-storage/async-storage';
import { action, makeAutoObservable, runInAction } from 'mobx';
import { Platform } from 'react-native';
import * as util from '../util';
import { dataVersionRoute, getDataKey } from './dataVersion';

/**
 * Mobx store class component 
 * Set & get functionality 
 */
export class MobxStore {
	storeInit: any;
	store: any;
	name: string;
	persist: boolean;
	loaded: boolean; // initial false, will be true once loaded initial store (important for persist stores)

	constructor(name: string, storeInit: any = {}, persist = false, dataVersionStore = {}) {
		this.name = name;
		this.persist = persist;
		this.loaded = false;
		const storeInitial = util.merge(
			{},
			util.cloneDeep(storeInit),
			util.cloneDeep(dataVersionStore),
		);
		this.storeInit = util.cloneDeep(storeInitial);
		this.store = util.cloneDeep(storeInitial);
        
		if (!this.persist) this.loaded = true;
		else this.persistLoadStore();
		this.setProperty = this.setProperty.bind(this);
		this.setGrid = this.setGrid.bind(this);
		this.addGridRow = this.addGridRow.bind(this);
		this.updateGridRowColumn = this.updateGridRowColumn.bind(this);
		this.set = this.set.bind(this);
		this.deleteGridRow = this.deleteGridRow.bind(this);
		this.persistLoadStore = this.persistLoadStore.bind(this);
		this.loadStore = this.loadStore.bind(this);
		this.setDataVersion = this.setDataVersion.bind(this);
		this.clear = this.clear.bind(this);
		makeAutoObservable(this, {
			setProperty: action,
			setGrid: action,
			addGridRow: action,
			deleteGridRow: action,
			updateGridRowColumn: action,
			persistLoadStore: action,
			loadStore: action,
			set: action,
			setDataVersion: action,
			clear: action,
		});

		this.getProperty = this.getProperty.bind(this);
		this.useDataProperty = this.useDataProperty.bind(this);
		this.useDataVersion = this.useDataVersion.bind(this);

		this.getGridRow = this.getGridRow.bind(this);
		
		this.getPersistStore = this.getPersistStore.bind(this);
		this.setPersistStore = this.setPersistStore.bind(this);
	}

	async getPersistStore() {
		let JSONstring;
		if (Platform.OS === 'web') {
			JSONstring = localStorage.getItem(this.name);
		} else {
			JSONstring = await AsyncStorage.getItem(this.name);
		}
		if (!JSONstring) return {};
		return JSON.parse(JSONstring);
	}

	async setPersistStore() {
		// replace
		if (Platform.OS === 'web') {
			localStorage.setItem(this.name, JSON.stringify(this.store));
		} else {
			await AsyncStorage.setItem(this.name, JSON.stringify(this.store));
		}
	}

	/**
     * set entire object to route
     * @param routes path to data (destination should be object)
     * @param value set to this value (should be object)
     */
	set(routes: string | string[], value: any) {
		routes = util.isArray(routes) ? routes : [routes];
		const storeDest = routes[routes.length - 1];
		const currentStore = this.getCurrentStoreRoute(routes);

		if (util.isStrictObject(currentStore[storeDest]) && util.isObject(value)) {
			util.mergeDeep(currentStore[storeDest], value);
			if (this.persist) this.setPersistStore();
		}
	}

	/**
     * set property in store
     * @param routes path to data 
     * @param value set to this value
     */
	setProperty(routes: string | string[], value: any) {
		routes = util.isArray(routes) ? routes : [routes];
		const storeDest = routes[routes.length - 1];
		const currentStore = this.getCurrentStoreRoute(routes);

		currentStore[storeDest] = value;
		if (this.persist) this.setPersistStore();
	}

	/**
     * set grid
     * @param routes path to data 
     * @param value set to this value
     */
	setGrid(routes: string | string[], value: any[]) {
		routes = util.isStrictArray(routes) ? routes : [routes];
		const storeDest = routes[routes.length - 1];
		const currentStore = this.getCurrentStoreRoute(routes);

		if (util.isStrictArray(value)) {
			value.forEach((row) => {
				row.id = util.getUuid();
			});
			currentStore[storeDest] = value;
			if (this.persist) this.setPersistStore();
		}
	}

	/**
	 * update grid by row
	 */
	updateGridRowColumn(routes: string | string[], id: string, columnName: string, value: any) {
		const grid = this.getProperty(routes);
		if (util.isStrictArray(grid)) {
			const row = grid.find((item) => item.id === id);
			if (row) {
				row[columnName] = value;
			}
		}
	}

	/**
     * get grid row by id
     * @param routes path to data 
     */
	getGridRow(routes: string | string[], id: string): any | undefined {
		const grid = this.getProperty(routes);
		if (util.isStrictArray(grid)) {
			const row = grid.find((item) => item.id === id);
			return row;
		}
		return undefined;
	}

	/**
     * add grid row to existing grid
     * @param routes path to data 
     * @param value set to this value
     */
	addGridRow(routes: string | string[], value: any | any[]) {
		value = util.isStrictArray(value) ? value : [value];
		routes = util.isStrictArray(routes) ? routes : [routes];
		const storeDest = routes[routes.length - 1];
		const currentStore = this.getCurrentStoreRoute(routes);
		const gridValue = currentStore[storeDest];

		if (util.isStrictArray(gridValue) && util.isArray(value)) {
			value.forEach((row) => {
				row.id = util.getUuid();
			});
			currentStore[storeDest] = [...gridValue, ...value];
			if (this.persist) this.setPersistStore();
		}
	}
	
	/**
	 * delete rows by id
	 * @param routes 
	 * @returns 
	 */
	deleteGridRow(routes: string | string[], id: string | string[]) {
		id = util.isStrictArray(id) ? id : [id];
		routes = util.isStrictArray(routes) ? routes : [routes];
		const storeDest = routes[routes.length - 1];
		const currentStore = this.getCurrentStoreRoute(routes);
		const gridValue = currentStore[storeDest];

		if (util.isStrictArray(gridValue) && util.isArray(id)) {
			currentStore[storeDest] = gridValue.filter((itemRow) => {
				return !id.includes(itemRow.id);
			});
			if (this.persist) this.setPersistStore();
		}
	}

	/**
     * get property in store
     * @param routes path to data
     * @returns 
     */
	getProperty(routes: string | string[]) {
		routes = util.isArray(routes) ? routes : [routes];
		const storeDest = routes[routes.length - 1];
		const currentStore = this.getCurrentStoreRoute(routes);

		return currentStore[storeDest];
	}

	/**
     * return getter, setter and value
     * @param routes 
     * @returns [value, getter, setter]
     */
	useDataProperty(routes: string | string[]) {
		routes = util.isArray(routes) ? routes : [routes];
		const storeDest = routes[routes.length - 1];
		const currentStore = this.getCurrentStoreRoute(routes);

		const getter = () => {
			return currentStore[storeDest];
		};

		const setter = action((value: any) => {
			currentStore[storeDest] = value;
			if (this.persist) this.setPersistStore();
		});

		const value = currentStore[storeDest];
		return [value, getter, setter] as const;
	}

	/**
     * return getter, setter and value
     * @param routes 
     * @returns [value, getter, setter]
     */
	useDataVersion(routes?: string | string[]) {
		const dataKey = getDataKey(routes);

		routes = util.isArray(routes) ? routes : [routes];
		let dataVersionStore = this.store[dataVersionRoute];

		const getter = () => {
			if (util.isStrictObject(dataVersionStore)) {
				return dataVersionStore[dataKey];
			}
			return null;
		};

		const setter = action((value: any) => {
			if (util.isStrictObject(dataVersionStore)) {
				dataVersionStore[dataKey] = value;
				if (this.persist) this.setPersistStore();
			}
			return null;
		});

		const value = util.isStrictObject(dataVersionStore) ? dataVersionStore[dataKey] : null;
		return [value, getter, setter] as const;
	}

	setDataVersion(routes?: string | string[]) {
		const dataKey = getDataKey(routes);
		let dataVersionStore = this.store[dataVersionRoute];
		if (util.isStrictObject(dataVersionStore)) {
			if (util.isString(dataKey)) {
				const value = dataVersionStore[dataKey];
				if (util.isNumber(value)) {
					dataVersionStore[dataKey] = value + 1;
				}
			}
		}
	}

	/**
     * load persist load on async
     * @param storeValue 
     * @param routes - specify path to reset entire object in path
     */
	async persistLoadStore() {
		let storeValue = await this.getPersistStore();
		await new Promise((resolve) => setTimeout(() => resolve(true), 250))
		runInAction(() => {
			util.mergeDeep(this.store, storeValue);
			this.loaded = true;
		});
	}

	/**
     * assumption - storeValue values is a primitive or an object-primitive only
     * @param storeValue 
     * @param routes - specify path to reset entire object in path
     */
	loadStore(storeValue?: any) {
		storeValue = storeValue || this.storeInit;
		if (!util.isObject(storeValue)) return;
		
		this.store = util.cloneDeep(storeValue);
	}

	/**
     * store helper function - get object in store by path
     * @param routes 
     * @returns 
     */
	private getCurrentStoreRoute(routes: string | string[]) {
		routes = util.isArray(routes) ? routes : [routes];
		let currentStore = this.store;
		for(let i = 0; i < routes.length - 1; i++) {
			if (!util.isObject(currentStore[routes[i]])) currentStore[routes[i]] = {};
			currentStore = currentStore[routes[i]];
		}
		return currentStore;
	}

	/**
	 * clear back to initial
	 */
	clear() {
		util.merge(
			this.store,
			this.storeInit,
		);
	}
}
