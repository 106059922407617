import { serviceBase } from '../../../service';
import { CustomOptions } from '../../../service/api';
import { getAppStateData, UIStoreInit } from '../../../store';
import * as util from '../../../util';

export class WeightService extends serviceBase {
	constructor(name: string, store: any, uiState: UIStoreInit) {
		super(name, store, uiState);

		this.responseData = this.responseData.bind(this);
		this.getSubmitData = this.getSubmitData.bind(this);
		this.transformData = this.transformData.bind(this);

		this.loadData = this.loadData.bind(this);
		this.setWeightInfo = this.setWeightInfo.bind(this);
	}


	loadData() {
		this.api.getApiAsync(this.getUrl('weight'), { }, { name: 'loadWeight' });
	}

	editWeight() {
		const editWeight = this.store?.getProperty('editWeight');
		const log_id = this.store?.getProperty('log_id');
		if (!editWeight) {
			this.errorNotification('Enter Weight!');
			return;
		}
		if (!log_id) {
			this.errorNotification('Choose weight to edit!');
			return;
		}
		this.api.patchApiAsync(this.getUrl('editWeight'), { log_weight: parseInt(editWeight), log_id }, { name: 'editWeight' });
	}

	addWeight() {
		const addWeight = this.store?.getProperty('addWeight');
		if (!addWeight) {
			this.errorNotification('Enter Weight!');
			return;
		}
		this.api.postApiAsync(this.getUrl('addWeight'), { log_weight: parseInt(addWeight) }, { name: 'addWeight' });
	}


	deleteWeight(item) {
		const {
			log_id,
		} = item;
		this.api.deleteApiAsync(this.getUrl('deleteWeight'), { log_id }, { name: 'deleteWeight' });
	}

	/**
     * get api data before api call
     * @param options 
     * @returns 
     */
	getSubmitData(options: CustomOptions) {
		const { name } = options;
		return {};
	}

	/**
     * transform response function after api call 
     * @param data 
     * @param options 
     */
	transformData(data: any, options: CustomOptions) {
		const { name } = options;
		if (name === 'loadWeight') {
            if (util.isArray(data.data)) {
				data.data.forEach((item) => {
					item.log_date = util.formatDate(item.log_date);
					item.log_weight = util.formatNumber(item.log_weight);
				});
			}
			return data;
		}
		return data;
	}

	/**
     * onCancel fn handler
     * @param value 
     * @param data 
     * @param props 
     */
	onCancel(value: any, data: any, props: any) {
		const { name } = props;
		switch (name) {
		case 'editModal':
			// clear
			this.store.setProperty('editWeight', '');
			this.store.setProperty('log_id', '');
			break;
		case 'addModal':
			// clear
			this.store.setProperty('addWeight', '');
			break;
		default:
			break;
		}
	}

	/**
     * onOk fn handler
     * @param value 
     * @param data 
     * @param props 
     */
	onOk(value: any, data: any, props: any) {
		const { name } = props;
		switch (name) {
		case 'editModal':
			this.editWeight();
			break;
		case 'addModal':
			this.addWeight();
			break;
		default:
			break;
		}
	}

	/**
     * onClick fn handler
     * @param value 
     * @param data 
     * @param props 
     */
	onClick(value: any, data: any, props: any) {
		const { name } = props;
		switch (name) {
		case 'loadBtn':
			this.loadData();
			break;
		case 'weightList':
			this.store?.setProperty('log_id', data.log_id);
			this.store?.setProperty('editWeight', data.log_weight);
			this.openModal('editModal');
			break;
		case 'weightList-btn':
			this.deleteWeight(data);
			break;
		case 'addNewBtn':
			this.openModal('addModal');
			break;
		default:
			break;
		}
	}

	/**
     * response function after api call
     * @param data 
     * @param options 
     */
	responseData(data: any, options: CustomOptions): void {
		const { name } = options;
		if (name === 'loadWeight') {
			if (data.success) {
				this.setWeightInfo(data.data);
			} else {
				this.errorNotification('error!');
			}
		}
		if (name === 'editWeight') {
			if (data.success) {
				this.loadData();
			}
		}
		if (name === 'addWeight' || name === 'deleteWeight') {
			if (data.success) {
				this.loadData();
			}
		}
	}

	setWeightInfo(data: any) {
		// set data to input
		// this.store.setProperty('weightTest', JSON.stringify(data));
		if (util.isArray(data)) this.store.setProperty('weightList', data);
	}
}