import React, { useCallback, useMemo } from 'react';
import { FAGridProps } from './FAGridProps';
import { FlatList, Pressable, View } from 'native-base';
import { FlatListProps, StyleSheet } from 'react-native';
import { icons } from '../../enums';
import { FAHeader } from '../typography/FAHeader';
import { FAText } from '../typography/FAText';
import { FAIconButton } from '../button/FAIconButton';
import * as util from '../../util';

interface FlatListRenderProps {
    item: any;
    index: any;
    separators: any;
}

interface CustomProps {
    titleData: string;
    subtitleData: string;
    idData: string;
    onClick: any;
    props: any;
    titleUnits: any;
    subtitleUnits: any;
    editBtn: any;
    uiHide: any;
	name: string;
	deleteBtn: boolean;
	size: any;
	infoBtn: boolean;
}

type RenderProps = FlatListRenderProps & CustomProps;

const RenderItemComponent: React.FC<RenderProps> = (dataItem: RenderProps) => {
	const {
		item,
		index,
		separators,
		// passed props
		titleData,
		subtitleData,
		idData,
		onClick,
		props,
		titleUnits,
		subtitleUnits,
		editBtn,
		infoBtn,
		uiHide,
		name,
		deleteBtn = true,
		size,
	} = dataItem;
	
	const {
		[titleData]: title,
		[subtitleData]: subtitle,
		[idData]: idKey,
	} = item;

	const onClickFn = useCallback(() => {
		// value data props
		onClick?.call(null, item, item, props);
	}, [onClick, item, props]);

	const onClickFnBtn = useCallback(() => {
		onClick?.call(null, item, item, { ...props, name: `${name}-btn` });
	}, [onClick, item, props]);

	const onClickFnEditBtn = useCallback(() => {
		onClick?.call(null, item, item, { ...props, name: `${name}-editbtn` });
	}, [onClick, item, props]);

	const onClickFnInfoBtn = useCallback(() => {
		onClick?.call(null, item, item, { ...props, name: `${name}-infobtn` });
	}, [onClick, item, props]);

	// control button render through service

	const hideBtn = uiHide(`${name}-renderItem`, item);

	return (
		<Pressable onPress={onClickFn} key={util.getUuid()}>
			<View style={styles.rowItem}>
				<View style={styles.infoSection}>
					<View style={styles.title}>
						<FAHeader name='titleGrid' size={size}>
							{title + (titleUnits ? ` - ${titleUnits}` : '')}
						</FAHeader>
					</View>
					<View style={styles.subtitle}>
						<FAText name='subtitleGrid' italic fontSize={size}>
							{subtitle + (subtitleUnits ? ` - ${subtitleUnits}` : '')}
						</FAText>
					</View>
				</View>
				{
				!hideBtn && 
				<>
					{
					infoBtn && 
					<View style={styles.button}>
						<FAIconButton
							name='infoBtn'
							id={`${index}infoBtn`}
							icon={icons.info}
							onClick={onClickFnInfoBtn} 
							size={size}
						/>
					</View>
					}
					{
					editBtn && 
					<View style={styles.button}>
						<FAIconButton
							name='editBtn'
							id={`${index}editBtn`}
							icon={icons.edit}
							onClick={onClickFnEditBtn} 
							size={size}
						/>
					</View>
					}
					{
						deleteBtn &&

					<View style={styles.button}>
						<FAIconButton
							name='clickBtn'
							id={`${index}clickBtn`}
							icon={icons.delete}
							onClick={onClickFnBtn}
							size={size}
						/>
					</View>
					}
				</>
				}
			</View>
		</Pressable>
	);
};

const RenderItem = React.memo(RenderItemComponent);

const renderItemProps = (props: CustomProps): React.FC<FlatListRenderProps> => {
	return (renderProps: FlatListRenderProps) => {
		return (
			<RenderItem 
				{...props}
				{...renderProps}
			/>
		);
	}
};

export const FAFlatList: React.FC<FAGridProps> = (props: FAGridProps) =>{
	const {
		name,
		dataSource,
		btnIcon = 'delete',
		// data info
		titleData,
		titleUnits,
		subtitleData,
		subtitleUnits,
		editBtn,
		deleteBtn,
		infoBtn = false,
		idData,
		style,
		// service info
		onClick,
		uiHide,
		size,
	} = props;

	const RenderComponent = useMemo(() => {
		return renderItemProps({
			titleData,
			subtitleData,
			idData,
			onClick,
			props,
			titleUnits,
			subtitleUnits,
			editBtn,
			deleteBtn,
			infoBtn,
			uiHide,
			name,
			size,
		});
	}, []);

	const keyExtractor = useCallback((item) => {
		return item.id;
	}, []);

	const attributes = useMemo(() => ({
		name,
		data: dataSource,
		renderItem: (props) => {
			return <RenderComponent {...props} />
		},
		keyExtractor,
		style,
	}), [
		name,
		dataSource,
		RenderComponent,
		keyExtractor,
	]);

	console.log('Render FlatList', name);
	return <FlatList {...attributes} />;
};

const styles = StyleSheet.create({
	rowItem: {
		flex: 1,
		flexDirection: 'row',
		borderBottomColor: 'grey',
		borderBottomWidth: 1,
		justifyContent: 'center',
		alignContent: 'center',
	},
	infoSection: {
		flex: 1,
		flexDirection: 'column',
		height: '100%',
	},
	title: {
	},
	button: {
		justifyContent: 'center',
		alignContent: 'center',
	},
	subtitle: {
	},
})