import React, { useMemo, useState } from 'react';
import { StyleSheet, Dimensions } from 'react-native';
import { FAHeader, FAStoreButton, FAText } from '../../../components';
import { IconButton, Pressable, View } from 'native-base';
import * as util from '../../../util';
import { observer } from 'mobx-react-lite';
import { getService } from '../../../service';
import { icons, themeColors } from '../../../enums';
import moment from 'moment';
import { scheduleColors } from '../../../enums/colors';

interface ScheduleProps {
	currentRoutine?: string;
}


const storeHash = {
	[util.DateDayofWeek.Monday]: 'monday',
	[util.DateDayofWeek.Tuesday]: 'tuesday',
	[util.DateDayofWeek.Wednesday]: 'wednesday',
	[util.DateDayofWeek.Thursday]: 'thursday',
	[util.DateDayofWeek.Friday]: 'friday',
	[util.DateDayofWeek.Saturday]: 'saturday',
	[util.DateDayofWeek.Sunday]: 'sunday',
};

export const Month: React.FC<ScheduleProps> = observer((props: ScheduleProps) => {
	const {
		currentRoutine
	} = props;
	const screenName = 'Month';

	const [{
		month,
		year,
	}, setCurrent] = useState<{ month: number, year: number }>({
		month: util.getCurrentMonth(),
		year: util.getCurrentYear(),
	});
	// const [open, setOpen] = useState(current);
	// const [routine, setRoutine] = useState('');
    const service = getService();

	const order = [
		{
			dayName: 'Mo',
			storeName: 'monday',
			day: util.DateDayofWeek.Monday,
		},
		{
			dayName: 'Tu',
			storeName: 'tuesday',
			day: util.DateDayofWeek.Tuesday,
		},
		{
			dayName: 'We',
			storeName: 'wednesday',
			day: util.DateDayofWeek.Wednesday,
		},
		{
			dayName: 'Th',
			storeName: 'thursday',
			day: util.DateDayofWeek.Thursday,
		},
		{
			dayName: 'Fr',
			storeName: 'friday',
			day: util.DateDayofWeek.Friday,
		},
		{
			dayName: 'Sa',
			storeName: 'saturday',
			day: util.DateDayofWeek.Saturday,
		},
		{
			dayName: 'Su',
			storeName: 'sunday',
			day: util.DateDayofWeek.Sunday,
		},
	];

	const monday = service.store.getProperty('monday');
	const tuesday = service.store.getProperty('tuesday');
	const wednesday = service.store.getProperty('wednesday');
	const thursday = service.store.getProperty('thursday');
	const friday = service.store.getProperty('friday');
	const saturday = service.store.getProperty('saturday');
	const sunday = service.store.getProperty('sunday');


	const IconLeft = icons.arrowback;
	const IconRight = icons.arrowforward;

	const decrementFn = () => {
		const currentDate = moment({ month, year });

		const nextDate = currentDate.subtract(1, 'month');

		const nextMonth = nextDate.month();

		const nextYear = nextDate.year();
		
		setCurrent({
			month: nextMonth,
			year: nextYear,
		});
	};
	
	const incrementFn = () => {
		const currentDate = moment({ month, year });

		const nextDate = currentDate.add(1, 'month');

		const nextMonth = nextDate.month();

		const nextYear = nextDate.year();
		
		setCurrent({
			month: nextMonth,
			year: nextYear,
		});
	};

	const monthName = useMemo(() => {
		let name = '';
		switch (month) {
			case 0:
				name = 'January';
				break;
			case 1:
				name = 'February';
				break;
			case 2:
				name = 'March';
				break;
			case 3:
				name = 'April';
				break;
			case 4:
				name = 'May';
				break;
			case 5:
				name = 'June';
				break;
			case 6:
				name = 'July';
				break;
			case 7:
				name = 'August';
				break;
			case 8:
				name = 'September';
				break;
			case 9:
				name = 'October';
				break;
			case 10:
				name = 'November';
				break;
			case 11:
				name = 'December';
				break;
			default:
				break;
		}
		return name;
	}, [month]);

	// build each
	const {
		daysArray: dayInfo,
		routineHash,
	} = useMemo(() => {
		const momentObj = moment({ month: month });
		const days = momentObj.daysInMonth();
		const firstDay = momentObj.startOf('month');

		const routineHash: Record<string, { routine_name, color }> = {};

		const daysArray = [];
		let row = [];

		const getRoutines = (day: util.DateDayofWeek) => {
			const storeName = storeHash[day];
			if (storeName) {
				const storeValue = service.store.getProperty(storeName);
				if (util.isArray(storeValue)) {
					const ids = [];
					storeValue.forEach((item) => {
						const {
							routine_name,
							routine_id,
						} = item;
						if (routine_id) {
							if (!routineHash[routine_id]) {
								routineHash[routine_id] = {
									routine_name,
									color: scheduleColors[Object.keys(routineHash).length % scheduleColors.length],
								};
							}
						}
						ids.push(routine_id);
					});
					return ids;
				}
			}
			return [];
		};

		let currentDay = firstDay;
		// before month
		while (util.getDay(currentDay) !== util.DateDayofWeek.Monday) {
			currentDay = util.subtractDays(currentDay, 1);
			const day = util.getDay(currentDay);
			row.unshift({
				date: currentDay.format('D'),
				day,
				routineIds: getRoutines(day),
				inMonth: false,
			});
		}
		// add days
		currentDay = firstDay;
		for (let i = 0; i < days; i++) {
			const day = util.getDay(currentDay);
			row.push({
				date: currentDay.format('D'),
				day,
				routineIds: getRoutines(day),
				inMonth: true,
			});
			if (day === util.DateDayofWeek.Sunday) {
				daysArray.push(row);
				row = [];
				if (i === days - 1) {
					break;
				}
			}
			currentDay = util.addDays(currentDay, 1);
			
			if (i === days - 1 && util.getDay(currentDay) === util.DateDayofWeek.Sunday) {
				row.push({
					date: currentDay.format('D'),
					day,
					routineIds: getRoutines(day),
					inMonth: true,
				});
				daysArray.push(row);
				row = [];
			}
		}
		// after month
		while (util.getDay(currentDay) !== util.DateDayofWeek.Sunday) {
			const day = util.getDay(currentDay);
			row.push({
				date: currentDay.format('D'),
				day,
				routineIds: getRoutines(day),
				inMonth: false,
			});
			if (day === util.DateDayofWeek.Sunday) {
				daysArray.push(row);
				row = [];
			}
			currentDay = util.addDays(currentDay, 1);
			
			if (util.getDay(currentDay) === util.DateDayofWeek.Sunday) {
				row.push({
					date: currentDay.format('D'),
					day,
					routineIds: getRoutines(day),
					inMonth: false,
				});
				daysArray.push(row);
				row = [];
			}
		}
		// console.log(daysArray);
		return {
			daysArray,
			routineHash,
		};
	}, [
		month,
		monday,
		tuesday,
		wednesday,
		thursday,
		friday,
		saturday,
		sunday,
	]);

	console.log('Render RoutineSchedule screen');
	return (
        <View style={styles.monthContainer}>
			{/* header */}
			<View style={styles.header}>
				<View style={styles.title}>
					<FAHeader name='title'>{`${monthName} ${year}`}</FAHeader>	
				</View>
				{/* btns */}
				<View style={styles.btns}>
					<IconButton
						onPress={decrementFn}
					>
						<IconLeft
							style={styles.iconBtn}
						/>
					</IconButton>
					<IconButton
						onPress={incrementFn}
					>
						<IconRight
							style={styles.iconBtn}
						/>
					</IconButton>
				</View>
			</View>
			{/* month component */}
			<View style={styles.component}>
				{/* header */}
				<View style={styles.weekHeader}>
					{
						order.map((day) => {
							const {
								dayName,
								day: dayOfWeek,
								// inMonth,
							} = day;
							return (
								<View style={styles.dayHeader} key={`${dayName}-${dayOfWeek}`}>
									<FAText name={dayName} bold>
										{dayName}
									</FAText>
								</View>
							)
						})
					}
				</View>
				{/* days */}
				{
					dayInfo.map((days, idx) => {
						return (
							<View style={styles.week} key={`${util.getUuid()}`}>
								{
									days.map((day) => {
										const {
											date,
											day: dayOfWeek,
											inMonth,
											routineIds = [],
										} = day;
										return (
											<View 
												style={styles.day} 
												backgroundColor={!inMonth ? 'gray.200' : undefined}
												key={`${date}-${dayOfWeek}`}
											>
												<FAText name={date}>
													{date}
												</FAText>
												{/* colors */}
												<View style={styles.colors}>
													{
														routineIds.length ? (
															routineIds.map((id) => {
																const val = routineHash[id];
																if (val) {
																	// circle
																	return (
																		<View
																			key={`${id}`}
																			width={5}
																			height={5}
																			margin={1}
																			borderRadius={25}
																			backgroundColor={val.color}
																		/>	
																	);
																}
																return null;
															})
														) : (
															<View
																width={5}
																height={5}
																borderRadius={25}
																margin={1}
															/>	
														)
													}
												</View>
											</View>
										)
									})
								}
							</View>
						)
					})
				}
				{/* legend */}
				<View style={styles.legend}>
					{
						Object.keys((routineHash)).map((key) => {
							const def = routineHash[key];
							if (def) {
								const {
									routine_name,
									color,
								} = def;
								return (
									<View style={styles.singleLegend} key={key}>
										<View
											width={5}
											height={5}
											borderRadius={25}
											backgroundColor={color}
											marginRight={5}
										/>	
										<FAText name='routine_name'>{routine_name}</FAText>
									</View>
								)
							}
						})
					}
				</View>
			</View>
        </View>
	);
});


const styles = StyleSheet.create({
	legend: {
		display: 'flex',
		flexDirection: 'column',
		margin: 10,
	},
	singleLegend: {
		display: 'flex',
		flexDirection: 'row',
		margin: 5,
	},
	colors: {
		display: 'flex',
		flexDirection: 'row',
	},
	dayText: {
		display: 'flex',
		flexDirection: 'column',
		justifyContent: 'center',
		alignItems: 'flex-start'
	},
	header: {
		display: 'flex',
		flex: 1,
		flexDirection: 'row',
		width: '100%',
		margin: 5,
		padding: 5,
	},
	monthContainer: {
		display: 'flex',
		flexDirection: 'column',
		justifyContent: 'center',
		alignItems: 'flex-start'
	},
	component: {
		display: 'flex',
		flexDirection: 'column',
		width: '100%',
	},
	weekHeader: {
		display: 'flex',
		flexDirection: 'row',
		width: '100%',
	},
	week: {
		display: 'flex',
		flexDirection: 'row',
		width: '100%',
		borderLeftWidth: 1,
		borderLeftColor: 'gray',
	},
	day: {
		flex: 1,
		height: 50,
		// borderRight: '1px gray solid',
		borderRightWidth: 1,
		borderRightColor: 'gray',
		borderBottomWidth: 1,
		borderBottomColor: 'gray',
		justifyContent: 'center',
		alignContent: 'center',
		alignItems: 'center',
	},
	dayHeader: {
		flex: 1,
		height: 40,
		// borderRight: '1px gray solid',
		// borderRightWidth: 1,
		// borderRightColor: 'gray',
		borderBottomWidth: 1,
		borderBottomColor: 'gray',
		justifyContent: 'center',
		alignContent: 'center',
		alignItems: 'center',
	},
	title: {
		flex: 1,
		flexGrow: 1,
	},
	containerOpen: {
		
	},
	btns: {
		display: 'flex',
		flexDirection: 'row',
	},
	containerClosed: {
		display: 'flex',
		flexDirection: 'row',
	},
	iconBtn: {
		width: '80%',
		height: '80%',
	},
});