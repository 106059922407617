import React, { useEffect } from 'react';
import { StyleSheet, Dimensions, Platform } from 'react-native';
import { useRoutineStartService } from './service';
import { uiState } from './routineStart.ui';
import { RoutineStartStore } from './routineStart.store';
import { FAStoreTypography, FAStoreInput, PageLayout, FAHeader, FAStoreGrid, FAStoreButton, FAStoreModal, FAText } from '../../components';
import { FAInput } from '../../components/input/FAInput';
import { getService, useServiceRouter } from '../../service';
import { StoreThreeHumanModel, ThreeHumanModel } from '../../components/threejs';
import { Pressable, View } from 'native-base';
import { FASwitchList } from '../../components/grid/FASwitchList';
import { FAButtonList, ProgressStatus } from '../../components/grid/FAButtonList';
import * as util from '../../util';
import { useComponentRefresh } from '../../hooks';
import { useStoreGridHooks } from '../../hooks/component';
import { ComponentEnum, icons } from '../../enums';
import { ExerciseSelect } from '../Exercise/Exercise';

const RenderSimpleItem: React.FC<any> = (props: any) => {
	const {
		index,
		item = {},
		item: {
			id = '',
		},
		seperators,
	} = props;

	const [refresh] = useComponentRefresh();
	

	const gridProps = useStoreGridHooks('workoutWorkingList', id);

	// get grid row
	const service = getService();

	// get row
	const row = service.store.getGridRow('workoutWorkingList', id);
	
	const {
		open = false,
		workout_sets = [],
		isDone = false,
	} = row || {};

	const onClick = (evt) => {
		if (open) {
			// already open
			return;
		}
		service.onClick(evt, id, { name: 'workoutWorkingList', rowClick: true });
	};

	if (open) {
		return (
			<Pressable
				style={styles.gridRowOpen}
				backgroundColor='lightBlue.200'
				onPress={(evt) => onClick(evt)}
			>
				<View style={styles.gridRowOpenTitle}>
					<FAStoreTypography
						name='exercise_type_name'
						color='blue.700'
						{...gridProps}
	
					/>
					<FAStoreTypography
						name='description'
						color='gray.500'
						fontWeight={600}
						{...gridProps}
						marginLeft={5}
					/>
					<FAStoreButton
						name='btnDeleteAllSet'
						children={'x'}
						backgroundColor={'white'}
						_text={{
							color: 'gray.600'
						}}
						{...{
							// icon: icons.add
							borderColor: 'gray.600',
							borderWidth: 4,
							borderRadius: 20,
							width: 60,
							height: 60,
						}}
						{...{
							...gridProps,
						}}
					/>
				</View>
				{/* <FAStoreInput name='test' {...gridProps} /> */}
					{util.isArrayNonStrict(workout_sets) && (
						<View 
							style={styles.sets}
							backgroundColor={'white'}
							borderRadius={20}
							padding={5}
						>
							{/* header */}
							<View style={styles.singleSet} borderBottomColor={'gray.500'} borderBottomWidth={1}>
								<View style={styles.setNumber}>
									<FAText name='setNumber'>Set</FAText>
								</View>
								<View style={styles.setPreviousWeight}>
									<FAText name='setNumber'>Previous</FAText>
								</View>
								<View style={styles.setWeight}>
									<FAText name='setNumber'>lbs</FAText>
								</View>
								<View style={styles.setReps}>
									<FAText name='setNumber'>Reps</FAText>
								</View>
							</View>
							{workout_sets.map((set, idx) => {
								const {
									number,
									previous,
									// set_reps,
									// set_weight,
								} = set;
	
								return (
									<View style={styles.singleSet} key={`${number}-${idx}`}>
										<View style={styles.setNumber}>
											<FAText name='setNumber'>{number}</FAText>
										</View>
										<View style={styles.setPreviousWeight}>
											<FAText name='setNumber'>{previous}</FAText>
										</View>
										<View style={styles.setWeight}>
											<FAStoreInput
												name='set_weight'
												getValue={(props) => set.set_weight}
												setValue={(value, props) => {
													set.set_weight = value;
													refresh();
												}}
												format='number'
											/>
										</View>
										<View style={styles.setReps}>
											<FAStoreInput
												name='set_reps'
												getValue={(props) => set.set_reps}
												setValue={(value, props) => {
													set.set_reps = value;
													refresh();
												}}
												format='number'
											/>
										</View>
										<View style={styles.button}>
											<FAStoreButton
												name='btnDeleteSet'
												children={'x'}
												backgroundColor={'white'}
												_text={{
													color: 'gray.600'
												}}
												{...{
													// icon: icons.add
													borderColor: 'gray.600',
													borderWidth: 4,
													borderRadius: 20,
													width: 60,
													height: 60,
												}}
												{...{
													...gridProps,
													idx,
												}}
											/>
										</View>
									</View>	
								)
							})}
							<View
								style={{
									display: 'flex',
									flexDirection: 'row',
								}}
							>
								<FAStoreButton
									name='btnAddSets'
									{...gridProps}
								/>
								<FAStoreButton
									name='btnFinishSets'
									{...gridProps}
								/>
							</View>
						</View>
					)}
			</Pressable>
		);
	}

	const maxWeight = util.isArray(workout_sets) ? Math.max(0, ...(workout_sets.map((set) => set.set_weight))) : 0;

	// const isDone = util.isArray(workout_sets) ? workout_sets.every((item) => item.set_weight > 0) : false;
	
	return (
		<Pressable
			style={styles.gridRowClosed}
			backgroundColor={'gray.200'}
			onPress={(evt) => onClick(evt)}
		>
			<View style={styles.gridRowClosedSection}>
				<FAStoreTypography
					name='exercise_type_name'
					color='blue.700'
					{...gridProps}

				/>
				<FAStoreTypography
					name='description'
					color='gray.500'
					fontWeight={600}
					{...gridProps}
				/>
			</View>
			<View style={styles.gridRowClosedSection2}>
				<View style={styles.setNumber}>
					{maxWeight > 0 && (
						<FAText
							name='setNumber'
							color='blue.700'
							fontWeight={700}
							bold
						>
							{`${maxWeight} lbs`}
						</FAText>
					)}
				</View>
				<View style={styles.setNumber}>
					{workout_sets.length > 0 && (
						<FAText
							name='setNumber'
							color='gray.500'
							fontWeight={500}
						>
							{`${workout_sets.length} sets`}
						</FAText>
					)}
				</View>
			</View>
			<View style={styles.gridRowClosedSection2}>
				{isDone && (
					<FAText
						name='setNumber'
						color='blue.700'
						fontWeight={700}
					>
						DONE!
					</FAText>
				)}
			</View>
		</Pressable>
	);
};

export const RoutineStart: React.FC = () => {
	const screenName = 'RoutineStartPage';
	const service = useRoutineStartService(screenName, RoutineStartStore, uiState);
	const [refresh] = useComponentRefresh();
	useServiceRouter(service);

	useEffect(() => {
		const load = async () => {
			service.initialLoad();
		};
		load();
	}, []);

	console.log('Render RoutineStart screen');
	return (
		<PageLayout>
			<View style={styles.titleContainer}>
				<FAStoreTypography name='routine_name' />
				<FAStoreButton name='editRoutine' />
				{/* <FAStoreButton name='editGoal' /> */}
			</View>
			<FAStoreTypography name='routine_description' />
			{/* <View style={styles.titleContainer}>
				<FAStoreTypography name='exercise_type_name' />
				<FAStoreButton name='editExercise' />
			</View> */}
			{/* <View style={styles.formContainer}>
				<View style={styles.exercise}>
					<View style={styles.exerciseRow}>
						<View style={styles.input}>
							<FAStoreInput name='workoutExerciseWeight' />
						</View>
						<View style={styles.inputBtn}>
							<FAStoreButton name='loadLastBtn' />
						</View>
					</View>
					<View style={styles.exerciseRow}>
						<View style={styles.input}>
							<FAStoreInput name='workoutExerciseReps' />
						</View>
						<View style={styles.inputBtn}>
							<FAStoreButton name='workoutAddBtn' />
						</View>
					</View>
				</View>
			</View> */}
			<View style={styles.container}>
				{/* <View style={styles.containerItem}>
					<FAStoreInput name='workoutComment' />
				</View> */}
				<View style={styles.containerItem}>
					<FAStoreInput name='routineComment' />
				</View>
			</View>
			<View style={styles.grid}>
				{/* <FAButtonList name='progressList' /> */}
				{/* <FAStoreGrid name='workoutWorkingList' /> */}
				<FAStoreGrid
					name='workoutWorkingList'
					renderComponent={RenderSimpleItem}
				/>
				<FAStoreButton
					name='addNewRow'
					componentType={ComponentEnum.ButtonTypeEnum.Icon}
					icon={icons.add}
					backgroundColor={'gray.200'}
					borderRadius={25}
				/>
			</View>
			<View style={styles.bottom} backgroundColor={'indigo.400'}>
				<FAStoreButton
					name='stopWorkout'
					backgroundColor={'gray.400'}
					children='Stop Workout'
					_text={{
						color: 'white'
					}}
					{...{
						borderRadius: 25,
						borderColor: 'white',
						borderWidth: 2,
					}}
				/>
				<FAStoreButton
					name='completeWorkout'
					backgroundColor={'blue.600'}
					children='Complete'
					_text={{
						color: 'black'
					}}
					{...{
						borderRadius: 25,
					}}
				/>
			</View>
			{/* <View style={styles.topContainer}>
				<View style={styles.exerciseBtn}>
					<FAStoreButton name='prevExercise' />
					<FAText name='previous' bold style={{ padding: 5, textAlign: 'center' }}>Previous</FAText>
				</View>
				<View style={styles.progressList}>
					<FAStoreGrid name='workoutWorkingList' />
				</View>
				<View style={styles.exerciseBtn}>
					<FAStoreButton name='nextExercise' />
					<FAText name='next' bold style={{ padding: 5, textAlign: 'center' }}>Next</FAText>
				</View>
			</View> */}
			{/* <ProgressStatus name='progressList' /> */}
			{/* <FAStoreModal name='editModal'
				component={() => {
					return (
						<View style={styles.modalContainer}>
							<View style={styles.buttonflex}>
								<FAStoreInput name='editExerciseReps' />
							</View>
							<View style={styles.buttonflex}>
								<FAStoreInput name='editExerciseWeight' />
							</View>
						</View>
					);
				}}
			/> */}
			<FAStoreModal
				name='selectExercise'
				component={ExerciseSelect}
				componentProps={{
					selectMode: true,
					navigate: service.routing.navigate,
					location: service.routing.location,
					params: service.routing.params,
					searchParams: service.routing.searchParams,
				}}
				onCancel={() => {
					useRoutineStartService(screenName, RoutineStartStore, uiState);
					refresh();
				}}
				onOk={(evt, data, props) => {
					useRoutineStartService(screenName, RoutineStartStore, uiState);
					refresh();
					service.onOk(evt, data, props);
				}}
			/>
		</PageLayout>
	);
};

const styles = StyleSheet.create({
	modalContainer: {
		display: 'flex',
		width: '100%'
	},
	progressList: {
		flexDirection: 'row',
		flexGrow: 1,
		paddingLeft: 5,
		paddingRight: 5,
		height: util.getDim('35vh'),
	},
	titleLeft: {
		flexGrow: 1,
	},
	titleRight: {
		width: 50,
	},
	titleContainer: {
		display: 'flex',
		width: '100%',
		flexDirection: 'row',
	},
	model: {
		flexDirection: 'row',
	},
	buttonList: {
		flex: 1,
		height: '100%',
		overflow: Platform.OS === 'web' ? 'scroll' : undefined,
	},
	topContainer: {
		flexDirection: 'row',
		width: '100%',
		justifyContent: 'space-between',
		maxHeight: util.getDim('35vh'),
		flexGrow: 1,
	},
	threeModel: {
		width: util.getDim('35vw'),
	},
	other: {
		flex: 1,
	},
	formContainer: {
		display: 'flex',
		width: '100%',
		flexDirection: 'row',
	},
	form: {
		flex: 4,
		margin: 5,
		justifyContent: 'space-between',
	},
	button: {
		flex: 1,
		margin: 5,
		flexDirection: 'row',
		justifyContent: 'space-between',
	},
	exerciseBtn: {
		width: 80,
		height: '100%',
		borderColor: 'black',
		borderWidth: 2,
		borderRadius: 10,
		justifyContent: 'center',
	},
	inputBtn: {
		width: 100,
		height: '100%',
		display: 'flex',
		flexDirection: 'row'
	},
	container: {
		display: 'flex',
		width: '100%',
		marginTop: 5,
		marginBottom: 5,
	},
	containerItem: {
		margin: 5,
	},
	exercise: {
		display: 'flex',
		flexDirection: 'column',
		flex: 1,
	},
	exerciseRow: {
		flex: 1,
		display: 'flex',
		flexDirection: 'row',
		margin: 5,
	},
	input: {
		flexGrow: 1,
		justifyContent: 'center',
		marginRight: 20,
	},
	flex: {
		flex: 3,
	},
	amounts: {
		flex: 6,
	},
	buttons: {
		flex: 2,
	},
	buttonflex: {
		flex: 2,
	},
	list: {
		
	},

	// simple workout grid
	sets: {
		display: 'flex',
		flexDirection: 'column',
		width: '100%',
	},
	singleSet: {
		display: 'flex',
		flexDirection: 'row',
	},
	setNumber: {
		flex: 1,
	},
	setPreviousWeight: {
		flex: 1,
	},
	setWeight: {
		flex: 1,
	},
	setReps: {
		flex: 1,
	},
	grid: {
		margin: 5,
		flexGrow: 1,
		flexShrink: 1,
		flexBasis: 0,
		overflow: 'scroll',
	},
	gridRowClosed: {
		display: 'flex',
		flexDirection: 'row',
		padding: 10,
		margin: 10,
		borderRadius: 10,
	},
	gridRowClosedSection: {
		display: 'flex',
		flex: 1,
		flexDirection: 'column',
	},
	gridRowClosedSection2: {
		display: 'flex',
		flex: 1,
		flexDirection: 'column',
		justifyContent: 'center',
		alignItems: 'center',
	},
	gridRowOpen: {
		display: 'flex',
		flexDirection: 'column',
		padding: 10,
		margin: 10,
		borderRadius: 10,
	},
	gridRowOpenTitle: {
		display: 'flex',
		flexDirection: 'row',
		alignItems: 'center',
	},
	welcome: {
		display: 'flex',
		flexDirection: 'column',
	},
	welcomeInside: {
		justifyContent: 'center',
		display: 'flex',
		flexDirection: 'row',
	},
	info: {
		display: 'flex',
		flexDirection: 'row',
		flex: 1,
	},
	infoGrow: {
		flex: 1,
		flexGrow: 1,
		flexDirection: 'row',
	},
	editComponent: {
		display: 'flex',
		flexDirection: 'column',
	},
	listHeader: {
	},
	save: {
		display: 'flex',
		flexDirection: 'row',
		flex: 1,
		justifyContent: 'center',
		alignContent: 'center',
	},
	saveGrow: {
		flex: 1,
	},
	flexBtn: {
		flex: 1,
		margin: 5,
	},
	bottom: {
		width:'100%',
		display: 'flex',
		flexDirection: 'row',
		justifyContent: 'space-between',
		padding: 30,
		borderTopLeftRadius: 20,
		borderTopRightRadius: 20,
	},
});