import React, { useEffect } from 'react';
import { StyleSheet, Dimensions } from 'react-native';
import { useExerciseGoalService } from './service';
import { uiState } from './exerciseGoal.ui';
import { ExerciseGoalStore } from './exerciseGoal.store';
import { FAStoreTypography, FAStoreInput, PageLayout, FAHeader, FAStoreGrid, FAStoreButton, FAStoreModal, FAText } from '../../components';
import { FAInput } from '../../components/input/FAInput';
import { useServiceRouter } from '../../service';
import { StoreThreeHumanModel, ThreeHumanModel } from '../../components/threejs';
import { ScrollView, View } from 'native-base';
import { FASwitchList } from '../../components/grid/FASwitchList';
import * as util from '../../util';

export const ExerciseGoal: React.FC = () => {
	const screenName = 'ExerciseGoalPage';
	const service = useExerciseGoalService(screenName, ExerciseGoalStore, uiState);
	useServiceRouter(service);

	useEffect(() => {
		service.startPage();
	}, []);

	console.log('Render ExerciseGoal screen');
	return (
		<PageLayout>
			<View style={styles.container}>
				<View style={styles.formContainer}>
					<FAStoreTypography name='exerciseName' />
					<FAStoreInput name='uselast' />
					<FAStoreTypography
						name='description'
						children={
							`Go from Initial Weight to Goal Weight by your Goal Date.`
						}
						underline
						bold
					/>
					<View style={styles.row}>
						<View style={styles.flex}>
							<FAStoreInput name='initialWeight' />
						</View>
						<View style={styles.flex}>
							<FAStoreInput name='exercise_weight_goal' />
						</View>
					</View>
					<View style={styles.row}>
						<FAStoreInput name='exercise_weight_date' />
					</View>
					<FAStoreTypography
						name='description'
						children={
							`Increase reps from 'Rep From' to 'Rep To', then up weight and restart cycle.`
						}
						underline
						bold
					/>
					<View style={styles.row}>
						<View style={styles.flex}>
							<FAStoreInput name='repFrom' />
						</View>
						<View style={styles.flex}>
							<FAStoreInput name='repTo' />
						</View>
					</View>
					<View style={styles.row}>
						<View style={styles.flex}>
							<FAStoreInput name='setCount' />
						</View>
						{/* <View style={styles.flex}>
							<FAStoreInput name='increment' />
						</View> */}
					</View>
				</View>
				{/* <FAStoreInput name='progression_rate' /> */}
				<FAStoreTypography name='progression_message' />
			</View>
			<View
				style={styles.middle}
			>
			</View>
			<FAStoreModal
				name='viewProgressionModal'
				component={() => {
					return (
						<FAStoreGrid name='predictionList'/>
					);
				}}
				componentProps={{
				}}
			/>
			<View style={styles.bottom} backgroundColor={'indigo.400'}>
				<FAStoreButton
					name='viewProgression'
				/>
				<FAStoreButton
					name='saveGoal'
				/>
			</View>
		</PageLayout>
	);
};

const styles = StyleSheet.create({
	container: {
		display: 'flex',
		width: '100%'
	},
	bottom: {
		width:'100%',
		display: 'flex',
		flexDirection: 'row',
		justifyContent: 'space-between',
		padding: 30,
		borderTopLeftRadius: 20,
		borderTopRightRadius: 20,
	},
	addNewBtn: {
		alignSelf: 'flex-end',
		width: '25px',
	},
	model: {
		height: Dimensions.get('window').height / 3,
	},
	other: {
		flex: 1,
	},
	middle: {
		flexGrow: 1,
	},
	formContainer: {
		display: 'flex',
		flexDirection: 'column',
		margin: 5,
	},
	row: {
		display: 'flex',
		flexDirection: 'row',
		margin: 5,
	},
	flex: {
		flex: 2,
	},
});