import { ComponentEnum } from '../../enums';
import { UIStoreInit } from '../../store';

export const uiState: UIStoreInit = {
	button: {
		workoutPage: {
			name: 'workoutPage',
			children: 'redirect to workout',
		},
		loginBtn: {
			name: 'loginBtn',
			children: 'Sign In',
			style: {
				width: '100%',
				borderRadius: 20,
			},
		},
		forgotPasswordBtn: {
			componentType: ComponentEnum.ButtonTypeEnum.Link,
			name: 'forgotPasswordBtn',
			children: 'Forgot Password?',
		},
		redirectSignup: {
			componentType: ComponentEnum.ButtonTypeEnum.Link,
			children: 'Sign up',
			_text: {
				// color: 'white',
				// fontWeight: 600,2
				// fontSize: '14pt',
			},
		},
	},
	input: {
		loginId: {
			componentType: ComponentEnum.InputTypeEnum.Input,
			name: 'loginId',
			placeholder: 'Login ID',
			label: 'Login ID',
			routes: 'loginId',
			size: 'lg',
			width: '100%',
			borderRadius: 20,
		},
		password: {
			componentType: ComponentEnum.InputTypeEnum.Input,
			placeholder: 'Password',
			name: 'password',
			label: 'Password',
			routes: 'password',
			btnName: 'Login',
			size: 'lg',
			type: 'password', 
			width: '100%',
			borderRadius: 20,
		},
		passwordBtn: {
			name: 'passwordBtn',
			label: 'passwordBtn',
			routes: 'passwordBtn',
			btnName: 'Login',
			type: 'passwordBtn', 
			width: '100%',
			borderRadius: 20,
		},
	},
	typography: {},
	layout: {
		// header: 'Login',
		// backEnabled: true,
	},
};