import { ComponentEnum, themeColors } from '../../enums';
import { btnStyle } from '../../enums/styling';
import { UIStoreInit } from '../../store';
import { addNewBtn, saveBtn, addBtn } from '../common';

export const uiState: UIStoreInit = {
	button: {
		startRoutineBtn: {
			name: 'startRoutineBtn',
			children: 'Start',
			// size: 'sm',
			_text:{
				fontSize: 24,
				fontWeight: 600,
				color: themeColors.trueBlack,
			},
			style: btnStyle.primary,
		},
		addRoutineBtn: {
			name: 'addRoutineBtn',
			children: 'Create A Routine',
			// size: 'sm',
			_text:{
				fontSize: 20,
				fontWeight: 600,
				color: themeColors.trueBlack,
			},
			style: btnStyle.primary,
		},
		startEmptyBtn: {
			name: 'startEmptyBtn',
			children: 'Start an Empty Workout',
			// size: 'sm',
			_text:{
				fontSize: 20,
				fontWeight: 600,
				color: themeColors.trueBlack,
			},
			style: btnStyle.primary,
		},
	},
	input: {
		startWorkoutMenuTest: {
			componentType: ComponentEnum.InputTypeEnum.TextArea,
			name: 'startWorkoutMenuTest',
			placeholder: 'StartWorkoutMenu Test',
			label: 'StartWorkoutMenu Test',
			routes: 'startWorkoutMenuTest',
		},
		exerciseSelect: {
			componentType: ComponentEnum.InputTypeEnum.Select,
			name: 'exerciseSelect',
			placeholder: 'StartWorkoutMenu Test',
			label: 'StartWorkoutMenu Test',
			items: ['test'],
			routes: 'exerciseSelect',
		},
		addExerciseTypeId: {
			componentType: ComponentEnum.InputTypeEnum.Select,
			routes: 'addExerciseTypeId',
			placeholder: 'Exercise',
			label: 'Exercise',
			items: [],
		},
		addExerciseWeight: {
			routes: 'addExerciseWeight',
			placeholder: 'Weight',
			label: 'Weight',
			format: 'number',
		},
		addComment: {
			routes: 'addComment',
			placeholder: 'Comment',
			label: 'Comment',
		},
		addExerciseReps: {
			routes: 'addExerciseReps',
			placeholder: 'Reps',
			label: 'Reps',
			format: 'number',
		},
		editExerciseTypeId: {
			componentType: ComponentEnum.InputTypeEnum.Select,
			routes: 'editExerciseTypeId',
			placeholder: 'Exercise',
			label: 'Exercise',
			items: [],
		},
		editExerciseWeight: {
			routes: 'editExerciseWeight',
			placeholder: 'Weight',
			label: 'Weight',
			format: 'number',
		},
		editComment: {
			routes: 'editComment',
			placeholder: 'Comment',
			label: 'Comment',
		},
		editExerciseReps: {
			routes: 'editExerciseReps',
			placeholder: 'Reps',
			label: 'Reps',
			format: 'number',
		},
		routineId: {
			componentType: ComponentEnum.InputTypeEnum.Select,
			routes: 'routineId',
			placeholder: 'Routine',
			label: 'Routine',
			items: [],
		},
	},
	typography: {
		startWorkoutMenuTest: {
			componentType: ComponentEnum.TypographyTypeEnum.Text,
			name: 'startWorkoutMenuTest',
			placeholder: 'StartWorkoutMenu Test',
			label: 'StartWorkoutMenu Test',
			routes: 'startWorkoutMenuTest',
		},
		username: {
			componentType: ComponentEnum.TypographyTypeEnum.Header,
			routes: 'username',
		},
		dateMonth: {
			componentType: ComponentEnum.TypographyTypeEnum.Text,
			routes: 'dateMonth',
		},
		dateHour: {
			componentType: ComponentEnum.TypographyTypeEnum.Text,
			routes: 'dateHour',
		},
		dateDay: {
			componentType: ComponentEnum.TypographyTypeEnum.Text,
			routes: 'dateDay',
		},
		dateDayFull: {
			componentType: ComponentEnum.TypographyTypeEnum.Header,
			routes: 'dateDayFull',
		},
		// GRID TYPOGRAPHY
		routine_exercise_name: {
			componentType: ComponentEnum.TypographyTypeEnum.Header,
			routes: 'routine_exercise_name',
			// placeholder: 'Reps',
			label: 'Name',
		},
	},
	layout: {
		menuEnabled: true,
		// modelEnabled: true,
		// header: 'StartWorkoutMenu',
		// settingsEnabled: true,
		// backEnabled: true,
		bg: 'indigo.400',
	},
	grid: {
		simpleList: {
			componentType: ComponentEnum.GridTypeEnum.Simple,
			routes: 'simpleList',
			idData: 'startWorkoutMenu_log_id',
		},
		startWorkoutMenuList: {
			routes: 'startWorkoutMenuList',
			titleData: 'startWorkoutMenu_title',
			subtitleData: 'startWorkoutMenu_subTitle',
			// subtitleData: 'startWorkoutMenu_comment',
			editBtn: true,
			idData: 'startWorkoutMenu_log_id',
		},
		routineList: {
			routes: 'routineList',
			titleData: 'routine_name',
			subtitleData: 'routine_completed',
			editBtn: true,
			idData: 'routine_log_id',
		},
		startWorkoutMenuWorkingList: {
			routes: 'startWorkoutMenuWorkingList',
			titleData: 'set_reps',
			titleUnits: 'Reps',
			subtitleData: 'set_weight',
			subtitleUnits: 'lbs.',
		},
		editWorkingList: {
			routes: 'editWorkingList',
			titleData: 'set_reps',
			titleUnits: 'Reps',
			subtitleData: 'set_weight',
			subtitleUnits: 'lbs.',
		},
	},
	modal: {
		editModal: {
			componentType: ComponentEnum.ModalTypeEnum.Modal,
			title: 'Edit StartWorkoutMenu',
			okText: 'Save Edit',
		},
		addModal: {
			title: 'Add StartWorkoutMenu',
			okText: 'Add',
		},
	},
};