import { ComponentEnum } from '../../enums';
import { UIStoreInit } from '../../store';

export const uiState: UIStoreInit = {
	button: {
	},
	input: {
	},
	typography: {},
	layout: {
		header: 'What\'s new',
		headerEnabled: true,
		backEnabled: true,
	},
};