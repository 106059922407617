import { ComponentEnum } from '../../enums';
import { UIStoreInit } from '../../store';

export const uiState: UIStoreInit = {
	button: {
		workoutPage: {
			name: 'workoutPage',
			children: 'redirect to workout',
		},
		aboutBtn: {
			name: 'aboutBtn',
			children: 'LOGIN',
			size: 'lg',
		},
	},
	input: {
		aboutId: {
			componentType: ComponentEnum.InputTypeEnum.Input,
			name: 'aboutId',
			placeholder: 'About ID',
			label: 'About ID',
			routes: 'aboutId',
			size: 'lg',
		},
	},
	typography: {},
	layout: {
		header: 'About',
		backEnabled: true,
		headerEnabled: true,
	},
};