import { ComponentEnum } from '../../enums';
import { UIStoreInit } from '../../store';
import { addNewBtn } from '../common';

export const uiState: UIStoreInit = {
	button: {
		addNewBtn,
	},
	input: {
		// one input to select an exercise
		exercise_type_id: {
			name: 'exercise_type_id',
			componentType: ComponentEnum.InputTypeEnum.Select,
			routes: 'exercise_type_id',
			placeholder: 'Exercise',
			label: 'Exercise',
			items: [],
		},
	},
	typography: {
		exerciseChartTest: {
			componentType: ComponentEnum.TypographyTypeEnum.Text,
			name: 'exerciseChartTest',
			placeholder: 'ExerciseChart Test',
			label: 'ExerciseChart Test',
			routes: 'exerciseChartTest',
		},
	},
	layout: {
		menuEnabled: true,
		modelEnabled: false,
		header: 'Exercise Chart Page',
		menuBackground: '#FFFFFF',
		settingsEnabled: true,
		backEnabled: true,
		headerEnabled: true,
	},
	grid: {
		exerciseChartList: {
			routes: 'exerciseChartList',
			titleData: 'titleData',
			subtitleData: 'subtitleData',
			editBtn: false,
			deleteBtn: false,
		},
	},
	chart: {
		exerciseChartList: {
			routes: 'exerciseChartList',
			xAxis: 'log_date',
			yAxis: 'set_weight',
			title: 'Exercise Weight Over Time'
		},
	},
	modal: {
	},
};