enum ExerciseGoalType {
    // always use last
    UseLast = 0,
    // use weight and reps control
    WeightRep = 1,
    // use reps
    Rep = 2,
}

export {
    ExerciseGoalType,
};
