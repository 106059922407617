export function isEmailOrUsername(loginId: string): 'User' | 'Email' {
    if (validateEmail(loginId)) {
        return 'Email';
    }
    return 'User';
}

export const validateEmail = (email: string) => {
    const regexValidate = String(email)
      .toLowerCase()
      .match(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      );
    if (regexValidate) {
        return true;
    }
    return false;
};


export const capitalizeFirstLetter = (word: string) => {
    return word.charAt(0).toUpperCase() + word.slice(1);
};

