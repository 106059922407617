import { ComponentEnum, icons } from '../../enums';
import { UIStoreInit } from '../../store';
import { addNewBtn, saveBtn, addBtn } from '../common';
import * as util from '../../util';
import { Dimensions } from 'react-native';

export const uiState: UIStoreInit = {
	button: {
		saveBtn,
		saveScheduleBtn: {
			name: 'saveScheduleBtn',
			componentType: ComponentEnum.ButtonTypeEnum.Button,
			children: 'Save Schedule',
			size: 'lg',
		},
		addBtn,
		goalBtn: {
			name: 'addBtn',
			componentType: ComponentEnum.ButtonTypeEnum.Button,
			children: 'Goal',
			size: 'sm',
		},
		backBtn: {
			name: 'backBtn',
			componentType: ComponentEnum.ButtonTypeEnum.Icon,
			icon: icons.arrowback,
		},
		editRoutineBtn: {
			name: 'editRoutineBtn',
			componentType: ComponentEnum.ButtonTypeEnum.Icon,
			icon: icons.edit,
		},
		editGoalBtn: {
			name: 'editGoalBtn',
			componentType: ComponentEnum.ButtonTypeEnum.Icon,
			icon: icons.edit,
		},
		editFrequencyBtn: {
			name: 'editFrequencyBtn',
			componentType: ComponentEnum.ButtonTypeEnum.Icon,
			icon: icons.edit,
		},
		btnDelete: {
			name: 'btnDelete',
			componentType: ComponentEnum.ButtonTypeEnum.Icon,
			icon: icons.delete,
		},
		btnEdit: {
			name: 'btnEdit',
			componentType: ComponentEnum.ButtonTypeEnum.Icon,
			icon: icons.edit,
		},
		goalIcon: {
			name: 'goalIcon',
			componentType: ComponentEnum.ButtonTypeEnum.Icon,
			icon: icons.play,
		},
		saveRow: {
			name: 'saveRow',
			componentType: ComponentEnum.ButtonTypeEnum.Icon,
			icon: icons.check,
		},
		cancelRow: {
			name: 'cancelRow',
			componentType: ComponentEnum.ButtonTypeEnum.Icon,
			icon: icons.close,
		},
	},
	input: {
		routine_name: {
			routes: 'routine_name',
			placeholder: 'Name',
			// label: 'Name',
		},
		routine_frequency: {
			routes: 'routine_frequency',
			placeholder: 'Frequency',
			// label: 'Name',
		},
		routine_description: {
			routes: 'routine_description',
			placeholder: 'Description',
			// label: 'Description',
		},
		exerciseTypeId: {
			componentType: ComponentEnum.InputTypeEnum.Select,
			routes: 'exerciseTypeId',
			placeholder: 'Exercise',
			label: 'Exercise',
			items: [],
		},
		repFrom: {
			componentType: ComponentEnum.InputTypeEnum.Input,
			name: 'repFrom',
			routes: 'repFrom',
			format: 'number',
			label: 'Rep From',
		},
		repTo: {
			componentType: ComponentEnum.InputTypeEnum.Input,
			name: 'repTo',
			routes: 'repTo',
			format: 'number',
			label: 'Rep To',
		},
		setCount: {
			componentType: ComponentEnum.InputTypeEnum.Input,
			name: 'setCount',
			routes: 'setCount',
			format: 'number',
			label: 'Set Count',
		},
	},
	typography: {
		routine_name: {
			componentType: ComponentEnum.TypographyTypeEnum.Header,
			routes: 'routine_name',
		},
		routine_description: {
			componentType: ComponentEnum.TypographyTypeEnum.Text,
			routes: 'routine_description',
		},
		routine_frequency: {
			componentType: ComponentEnum.TypographyTypeEnum.Text,
			routes: 'routine_frequency',
		},
		exercise_type_description: {
			componentType: ComponentEnum.TypographyTypeEnum.Text,
			routes: 'exercise_type_description',
		},
		setCount: {
			componentType: ComponentEnum.TypographyTypeEnum.Text,
			routes: 'setCount',
		},
		reps: {
			componentType: ComponentEnum.TypographyTypeEnum.Text,
			routes: 'reps',
		},
		exercise_weight_goal: {
			componentType: ComponentEnum.TypographyTypeEnum.Text,
			routes: 'exercise_weight_goal',
		},
	},
	layout: {
		menuEnabled: true,
		// modelEnabled: true,
		// header: ' ',
		menuBackground: '#FFFFFF',
		// backEnabled: true,
		// settingsEnabled: true,
		// btns: [
		// 	'saveBtn'
		// ],
	},
	grid: {
		exercises: {
			componentType: ComponentEnum.GridTypeEnum.Simple,
			routes: 'exercises',
			titleData: 'exercise_type_name',
			subtitleData: 'exercise_type_description',
			editBtn: true,
			infoBtn: true,
		},
	},
	modal: {
		exerciseInfo: {
			title: 'Exercise Setting',
			width: util.getDim('100vw'),
			height: util.getDim('100vh'),
		},
		addSchedule: {
			name: 'addSchedule',
			title: 'Add Schedule',
			width: Dimensions.get('window').width * .9,
			height: Dimensions.get('screen').height,
		},
		selectExercise: {
			componentType: ComponentEnum.ModalTypeEnum.Full,
			forceUmmount: true,
			height: Dimensions.get('window').height,
			style: {
				borderColor: 'black',
				borderWidth: 1,
			}
		},
	},
};
