import { ColorType } from 'native-base/lib/typescript/components/types';
import { icons, paths } from '../../enums';

export interface MenuItemType {
	title?: string;
	color: ColorType;
	highlightColor: ColorType;
	icon?: keyof typeof icons;
	link: keyof typeof paths;
	mainButton?: true;
}

export const menuData: MenuItemType[] = [
	{
		title: 'Routine',
		link: 'routineschedule',
		color: 'amber.200',
		highlightColor: 'amber.400',
		icon: 'menuRoutine',
	},
	{
		title: 'Exercises',
		link: 'exercise',
		color: 'amber.200',
		highlightColor: 'amber.400',
		icon: 'menuExercise',
	},
	{
		title: 'Start Workout',
		link: 'startWorkoutMenu',
		color: 'green.200',
		highlightColor: 'green.400',
		mainButton: true,
		icon: 'menuStart',
	},
	{
		title: 'Stats',
		link: 'model',
		color: 'yellow.200',
		highlightColor: 'yellow.400',
		icon: 'menuStats',
	},
	{
		title: 'Me',
		link: 'settings',
		color: 'amber.200',
		highlightColor: 'amber.400',
		icon: 'menuMe',
	},
];
