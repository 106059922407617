export const urlRoutes = {
	'load': '/example/load',
	'login': '/user/login',
	'authtoken': '/user/verify',
	'signup': '/user',
	'workout': '/workout',
	'exercise': '/exercise',
	'exerciseSystem': '/exercise/system',
	'routine': '/routine/username',
	'weight': '/weight/username',
	'addWeight': '/weight',
	'editWeight': '/weight',
	'deleteWeight': '/weight',
	'editExercise': '/exercise',
	'addExercise': '/exercise',
	'editExerciseSystem': '/exercise/system',
	'addExerciseSystem': '/exercise/system',
	'loadExerciseDetail': '/exercise/id',
	'routineDetail': '/routine/id',
	'allroutine': '/workout/allroutine',
	'deleteroutine': '/routine/routine',
	'routineStart': '/workout/routinedetail',
	'routineExercises': '/routine/exercise',
	'routineSchedules': '/routine/schedules',
	'saveRoutineSchedules': '/routine/routineschedule',
	'muscleBreakdown': '/exercise/muscle',
	'lastRoutine': '/analysis/routine',
	'exerciseChart': '/analysis/exerciseSets',
	'updateUsername': '/user/username',
	'updateEmail': '/user/email',
	'updatePassword': '/user/password',
	'usernameExists': '/user/username',
	'emailExists': '/user/email',
	'progression': '/workout/progression',
	'progressionAll': '/workout/progressionAll',
	'progressionRate': '/workout/progressionRate',
	'progressionPrediction': '/workout/progressionPrediction',
	'loadExerciseGoal': '/exercise/goal',
	'updateExerciseGoal': '/exercise/goal',
	'isOnboarded': '/settings/onboarding',
	'onboardingRoutines': '/routine/onboarding',
	'downloadRoutine': '/routine/download',
	'finishOnboarding': '/settings/onboarding',
	'deleteUser': '/user',
	'sendPasswordResetEmail': '/user/sendPasswordEmail',
	'resetPasswordWithToken': '/user/resetPasswordEmailToken',

	'patchGroup': '/workout/groupdetail',
	'postGroup': '/workout/groupdetail',
	'getGroup': '/workout/groupdetail',

	'loadExerciseGoalMultiple': '/exercise/goalmultiple',

	'support': '/user/support',
};