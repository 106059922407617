import { modelData } from '../../../components/threejs/modelData';
import { getMuscleColor } from '../../../enums/colors';
import { getService, serviceBase } from '../../../service';
import { CustomOptions } from '../../../service/api';
import { getAppStateData, UIStoreInit } from '../../../store';
import * as util from '../../../util';
import { isArray } from '../../../util';
import { ExerciseService } from '../../Exercise/service';

export class StartWorkoutService extends serviceBase {
	constructor(name: string, store: any, uiState: UIStoreInit) {
		super(name, store, uiState);

		this.responseData = this.responseData.bind(this);
		this.getSubmitData = this.getSubmitData.bind(this);
		this.transformData = this.transformData.bind(this);

		this.loadData = this.loadData.bind(this);
		this.setStartWorkoutInfo = this.setStartWorkoutInfo.bind(this);
		this.addWorkingStartWorkout = this.addWorkingStartWorkout.bind(this);
		this.addEditStartWorkout = this.addEditStartWorkout.bind(this);
		this.deleteWorkingStartWorkout = this.deleteWorkingStartWorkout.bind(this);
		this.deleteEditStartWorkout = this.deleteEditStartWorkout.bind(this);
	}

	editStartWorkout() {
		const editExerciseTypeId = this.store?.getProperty('editExerciseTypeId');
		const editComment = this.store?.getProperty('editComment');
		const startWorkout_log_id = this.store?.getProperty('startWorkout_log_id');
		const startWorkout_sets = this.store?.getProperty('editWorkingList') || [];
		if (!editExerciseTypeId) {
			this.errorNotification('Choose an exercise');
			return;
		}
		if (!startWorkout_log_id) {
			this.errorNotification('Choose startWorkout to edit!');
			return;
		}
		this.api.patchApiAsync(this.getUrl('workout'), { exercise_type_id: editExerciseTypeId, startWorkout_comment: editComment, startWorkout_log_id, startWorkout_sets }, { name: 'editStartWorkout' });
	}

	addStartWorkout() {
		const addExerciseTypeId = this.store?.getProperty('addExerciseTypeId');
		const addComment = this.store?.getProperty('addComment');
		let startWorkout_sets = this.store?.getProperty('startWorkoutWorkingList') || [];
		if (!addExerciseTypeId) {
			this.errorNotification('Please choose an exercise!');
			return;
		}
		if (!isArray(startWorkout_sets)) {
			this.errorNotification('Please add at least 1 rep!');
			return;
		}

		this.api.postApiAsync(this.getUrl('workout'), { exercise_type_id: addExerciseTypeId, startWorkout_sets, startWorkout_comment: addComment }, { name: 'addStartWorkout' });
	}

	addEditStartWorkout() {
		const reps = this.store?.getProperty('editExerciseReps');
		const weight = this.store?.getProperty('editExerciseWeight');
		if (!reps || !weight) {
			this.errorNotification('Add weight or reps!');
			return;
		}
		// set list new list
		this.store?.addGridRow('editWorkingList', [
			{
				set_weight: weight,
				set_reps: reps,
			}
		]);
	}

	addWorkingStartWorkout() {
		const reps = this.store?.getProperty('addExerciseReps');
		const weight = this.store?.getProperty('addExerciseWeight');
		if (!reps || !weight) {
			this.errorNotification('Add weight or reps!');
			return;
		}
		// set list new list
		this.store?.addGridRow('startWorkoutWorkingList', [
			{
				set_weight: weight,
				set_reps: reps,
			},
		]);
		// clear working
		// this.store?.setProperty('addExerciseReps', 0);
		// this.store?.setProperty('addExerciseWeight', 0);
	}

	async initPage() {
		// load username into store
		const username = getAppStateData('username');
		this.store.setProperty('username', username);
		// set current date
		const today = util.today();
		const dateMonth = today.format('MM/DD/YYYY');
		const dateHour = today.format('hh:mm A');
		const dateDay = today.format('dd');
		this.store.setProperty('dateMonth', dateMonth);
		this.store.setProperty('dateHour', dateHour);
		this.store.setProperty('dateDay', dateDay);

		await this.loadExercises();
		this.loadData();
	}

	loadData(grouped_workout_log_id?: string) {
		grouped_workout_log_id = this.store.getProperty('grouped_workout_log_id');
		if (!grouped_workout_log_id) {
			return;
		}
		this.api.getApiAsync(this.getUrl('getGroup'), { grouped_workout_log_id }, { name: 'loadStartWorkout' });
	}

	async loadExercises() {
		// load exercise data for select list
		await this.api.getApiAsync(this.getUrl('exercise'), { }, { name: 'loadExercise' });
	}

	loadRoutine() {
		// load routine data for select list
		this.api.getApiAsync(this.getUrl('routine'), { }, { name: 'loadRoutine' });
	}

	loadRoutineData() {
		this.api.getApiAsync(this.getUrl('allroutine'), { }, { name: 'loadAllRoutine' });
	}
	

	deleteEditStartWorkout(item) {
		const {
			id,
		} = item;
		console.log('id', item);
		this.store.deleteGridRow('editWorkingList', id);
	}
	

	deleteWorkingStartWorkout(item) {
		const {
			id,
		} = item;
		console.log('id', item);
		this.store.deleteGridRow('startWorkoutWorkingList', id);
	}


	deleteStartWorkout(item) {
		const {
			startWorkout_log_id,
		} = item;
		this.api.deleteApiAsync(this.getUrl('workout'), { startWorkout_log_id }, { name: 'deleteStartWorkout' });
	}

	/**
     * get api data before api call
     * @param options 
     * @returns 
     */
	getSubmitData(options: CustomOptions) {
		const { name } = options;
		return {};
	}

	calculateTitle(data) {
		const {
			exercise_duration,
			exercise_info,
			exercise_type_id,
			exercise_weight_unit,
			log_date,
			startWorkout_comment,
		} = data;

		const formattedDate = log_date;

		return `${formattedDate} - ${exercise_info}`;
	}

	/**
     * transform response function after api call 
     * @param data 
     * @param options 
     */
	transformData(data: any, options: CustomOptions) {
		const { name } = options;
		if (name === 'loginApi') {
            
			return data;
		}
		if (name === 'saveData' || name === 'loadStartWorkout') {
			const exerciseList = this.store.getProperty('exerciseSelectList');
			const {
				exercises = []
			} = data.data || {};
			if (util.isArray(exercises)) {
				exercises.forEach((row) => {
					const {
						exercise_type_id,
						// exercise_info,
						workout_sets,
					} = row;

					const exercise = (exerciseList as any[]).find((item) => item.value === exercise_type_id);
					if (exercise) {
						row.exercise_type_name = exercise.exercise_type_name;
						// find highest muscle intensity
						if (util.isArray(exercise.exercise_muscles)) {
							let highest;
							let highestIntensity = 0;
							exercise.exercise_muscles.forEach((item) => {
								if (item.intensity > highestIntensity) {
									highest = item.muscle_id;
								}
							});
							if (highest && util.isString(highest)) {
								row.description = util.capitalizeFirstLetter(highest);
							}
						}
						// row.exercise_muscles = exercise.exercise_muscles;
					}

					let infoStr: string;
					if (util.isArray(workout_sets)) {
						infoStr = workout_sets.map(({ set_weight: weight, set_reps: reps }) => `${reps} x ${weight}lbs.`).join('\n');
					}
					const date = util.formatDate(row.log_date);
					row.startWorkout_subTitle = date + '\n' + infoStr;
					
				});
			}
			return data;
		}
		if (name === 'loadAllRoutine') {
			if (util.isArray(data.data)) {
				data.data.forEach((row) => {
					const {
						log_date,
					} = row;
					if (log_date) row.log_date = util.formatDate(log_date);
				});
			}
		}
		return data;
	}

	/**
     * onClick fn handler
     * @param value 
     * @param data 
     * @param props 
     */
	onClick(value: any, data: any, props: any) {
		const { name } = props;
		switch (name) {
		case 'loadBtn':
			this.loadData();
			break;
		case 'startWorkoutList':
			this.calculateMuscle(data);
			break;
		case 'startWorkoutList-editbtn': {
			this.store?.setProperty('startWorkout_log_id', data.startWorkout_log_id);
			this.store?.setProperty('editExerciseTypeId', data.exercise_type_id);
			this.store?.setProperty('editExerciseWeight', data.exercise_weight);
			this.store?.setProperty('editExerciseReps', data.exercise_reps);
			this.store?.setProperty('editComment', data.startWorkout_comment);
			// construct array
			let array = [];
			if (util.isArray(data.startWorkout_sets)) {
				array = data.startWorkout_sets;
			}
			this.store.setGrid('editWorkingList', array);
			this.openModal('editModal');
			break;
		}
		case 'startWorkoutList-btn':
			this.deleteStartWorkout(data);
			break;
		case 'startWorkoutWorkingList-btn':
			this.deleteWorkingStartWorkout(data);
			break;
		case 'editWorkingList-btn':
			this.deleteEditStartWorkout(data);
			break;
		case 'addNewBtn':
			this.openModal('addModal');
			break;
		case 'addBtn':
			this.addWorkingStartWorkout();
			break;
		case 'editAddBtn':
			this.addEditStartWorkout();
			break;
		case 'saveBtn':
			this.addStartWorkout();
			break;
		case 'createExercise':
			this.redirectTo(this.getPath('exercise'));
			break;
		case 'createRoutine':
			this.redirectTo(this.getPath('routine'));
			break;
		case 'startBtn': {
			const routine_id = this.store.getProperty('routineId');
			this.redirectTo(this.getPath('routinestart'), { routine_id, addNew: true });
			break;
		}
		case 'routineList-editbtn': {
			const {
				routine_log_id,
				routine_id,
			} = data;
			this.redirectTo(this.getPath('routinestart'), { routine_log_id, routine_id });
			break;
		}
		case 'simpleList':
			this.selectRow(data);
			break;
		case 'btnAddSets': {
			const { id, gridName } = props;
			if (gridName && id) {
				this.addSets(id);
			}
			break;
		}
		case 'btnDeleteSet': {
			const { id, gridName, idx } = props;
			if (gridName && id && util.isNumber(idx)) {
				this.deleteSets(id, idx);
			}
			break;
		}
		case 'btnDeleteAllSet': {
			const { id, gridName, idx } = props;
			this.deleteAllSets(id);
			break;
		}
		case 'addNewRow': {
			this.selectExercise();
			break;
		}
		case 'completeWorkout': {
			this.saveData();
			break;
		}
		case 'stopWorkout': {
			this.redirectTo(this.getPath('startWorkoutMenu'));
			break;
		}
		default:
			break;
		}
	}

	async saveData() {
		// get list
		const simpleList = this.store.getProperty('simpleList') || [];
		const grouped_workout_log_id = this.store.getProperty('grouped_workout_log_id');

		if (grouped_workout_log_id) {
			await this.api.patchApiAsync(this.getUrl('patchGroup'), {
				exercises: simpleList,
				grouped_workout_log_id,
			}, {
				name: 'saveData',
			});
		} else {
			await this.api.postApiAsync(this.getUrl('postGroup'), {
				exercises: simpleList,
			}, {
				name: 'saveData',
			});
		}
	}

	addSets(id) {
		const simpleList = this.store.getProperty('simpleList');

		// update sets of that row
		const row = simpleList.find((item) => item.id === id);

		if (row) {
			if (!util.isStrictArray(row.workout_sets)) {
				row.workout_sets = [];
			}
			row.workout_sets.push({
				number: row.workout_sets.length + 1,
			});

			// rebuild and set for mobx purpose
			const newList = [...simpleList];
			this.store.setGrid('simpleList', newList);
		}
	}

	selectExercise() {
		// open modal to select an exercise
		this.openModal('selectExercise');
	}

	addExercises(exercises: any[]) {
		const simpleList = this.store.getProperty('simpleList');

		// update sets of that row
		simpleList.forEach((item) => {
			item.open = false;
		});

		const addExercises: any[] = [];

		exercises.forEach((item) => {
			const {
				exercise_type_id,
				exercise_type_name,
				// description,
				// open,
				// sets,
			} = item;
			if (!exercise_type_id) {
				return;
			}
			addExercises.push({
				exercise_type_id,
				exercise_type_name,
				sets: [],
			});
		});

		if (addExercises.length) {
			addExercises[addExercises.length - 1].open = true;
			this.store.addGridRow('simpleList', addExercises);
		}
	}

	deleteSets(id, idx) {
		const simpleList = this.store.getProperty('simpleList');

		// update sets of that row
		const row = simpleList.find((item) => item.id === id);

		if (row) {
			if (!util.isStrictArray(row.workout_sets)) {
				row.workout_sets = [];
			}
			const newSets = row.workout_sets.filter((_, currentIdx) => currentIdx !== idx);
			row.workout_sets = newSets;
			row.workout_sets.forEach((item, idx) => item.number = idx + 1);

			// rebuild and set for mobx purpose
			const newList = [...simpleList];
			this.store.setGrid('simpleList', newList);
		}
	}

	deleteAllSets(id) {
		const simpleList = this.store.getProperty('simpleList');

		// update sets of that row
		const newList = simpleList.filter((item) => item.id !== id);
		this.store.setGrid('simpleList', newList);
	}

	selectRow(data) {
		const simpleList = this.store.getProperty('simpleList');

		const newSimpleList = util.isArray(simpleList) ? simpleList.map((item) => {
			if (item.id === data) {
				item.open = true;
			} else {
				item.open = false;
			}
			return item;
		}) : [];

		// set to store
		this.store.setGrid('simpleList', newSimpleList);
	}

	/**
     * response function after api call
     * @param data 
     * @param options 
     */
	responseData(data: any, options: CustomOptions): void {
		const { name } = options;
		if (name === 'loadStartWorkout') {
			if (data.success) {
				this.setStartWorkoutInfo(data.data);
			} else {
				this.errorNotification('error!');
			}
		}
		if (name === 'loadAllRoutine') {
			if (data.success) {
				this.setAllRoutineInfo(data.data);
			} else {
				this.errorNotification('error!');
			}
		}
		if (name === 'loadExercise') {
			if (data.success) {
				this.setExerciseInfo(data.data);
			} else {
				this.errorNotification('error!');
			}
		}
		if (name === 'loadRoutine') {
			if (data.success) {
				this.setRoutineInfo(data.data);
			} else {
				this.errorNotification('error!');
			}
		}
		if (name === 'addStartWorkout') {
			if (data.success) {
				// reset list
				this.store?.setProperty('addComment', '');
				this.store?.setProperty('addExerciseWeight', 25);
				this.store?.setProperty('startWorkoutWorkingList', []);
				this.loadData();
			}
		}
		if (name === 'editStartWorkout' || name === 'deleteStartWorkout') {
			if (data.success) {
				this.loadData();
			}
		}
		if (name === 'saveData') {
			if (data.success) {
				this.setStartWorkoutInfo(data.data);
			}
		}
	}

	setStartWorkoutInfo(data: any) {
		const {
            log_date = '',
            user_id = '',
            grouped_workout_log_id = '',
            exercises = [],
		} = data || {};

		this.store.setProperty('log_date', log_date);
		this.store.setProperty('user_id', user_id);
		this.store.setProperty('grouped_workout_log_id', grouped_workout_log_id);
		this.store.setGrid('simpleList', exercises);
	}
	setAllRoutineInfo(data: any) {
		// set data to input
		if (util.isArray(data)) this.store.setProperty('routineList', data);
	}

	calculateMuscle(rowItem: any) {
		const {
			exercise_muscles
		} = rowItem;
		const hashMuscle: any = {};
		if (!exercise_muscles) return;

		if (util.isArray(exercise_muscles)) {
			exercise_muscles.forEach((muscleData) => {
				const {
					muscle_id: id,
				} = muscleData;
				if (id) {
					hashMuscle[id] = muscleData;
				}
			});
		}
		modelData.forEach((item) => {
			if (hashMuscle[item.id]) {
				const {
					intensity: value = 0,
				} = hashMuscle[item.id];
				if (util.isNumberNonStrict(value)) {
					this.updateModelColor(item.id, value, false);
				}
			}
		});
	}

	updateModelColor(id, value, moveCamera = true) {
		const color = getMuscleColor(value);
		this.callComponentHook('pageLayout', 'updateColor', id, color, moveCamera);
	}



	setExerciseInfo(data: any) {
		if (util.isArray(data)) {
			data.forEach((item) => {
				item.value = item.exercise_type_id;
				item.label = item.exercise_type_name;
			});
			// set to select store items ui name
			this.uiStore.setProperty(['input', 'addExerciseTypeId', 'items'], data);
			this.uiStore.setProperty(['input', 'editExerciseTypeId', 'items'], data);
			this.store.setProperty(['exerciseSelectList'], data);
		}
	}

	setRoutineInfo(data: any) {
		if (util.isArray(data)) {
			data.forEach((item) => {
				item.value = item.routine_id;
				item.label = item.routine_name;
			});
			// set to select store items ui name
			this.uiStore.setProperty(['input', 'routineId', 'items'], data);
			this.store.setProperty(['routineSelectList'], data);
		}
	}

	/**
     * onOk fn handler
     * @param value 
     * @param data 
     * @param props 
     */
	onOk(value: any, data: any, props: any) {
		const { name } = props;
		switch (name) {
		case 'editModal':
			this.editStartWorkout();
			break;
		case 'selectExercise':
			this.onSelectExercise();
			break;
		default:
			break;
		}
	}

	onSelectExercise() {
		// grab exercises service
		const service = getService('ExercisePage') as ExerciseService;

		if (!service) {
			return;
		}
		const selectedExercises = service.retrieveSelectedExercises();

		if (util.isArray(selectedExercises)) {
			this.addExercises(selectedExercises);
		}
	}

	/**
     * onCancel fn handler
     * @param value 
     * @param data 
     * @param props 
     */
	onCancel(value: any, data: any, props: any) {
		const { name } = props;
		switch (name) {
		case 'editModal':
			// clear
			this.store.setProperty('editExercise', '');
			this.store.setProperty('exercise_type_id', '');
			this.store.setProperty('exercise_type', '');
			break;
		case 'addModal':
			// clear
			this.store.setProperty('addExercise', '');
			break;
		default:
			break;
		}
	}
}