import { ModelItemType } from './ModelItem';

export const modelData: ModelItemType[] = [
  {
    id: 'abs',
    file: require('../../assets/models/abs.glb'),
    color: '#e67388',
    position: {
      angleX: Math.PI * .5,
      angleY: 0,
    },
  },
  {
    id: 'biceps',
    file: require('../../assets/models/biceps.glb'),
    color: '#ff5e5e',
    position: {
      angleX: Math.PI * .13,
      angleY: Math.PI * .1,
    },
  },
  {
    id: 'calves',
    file: require('../../assets/models/calves.glb'),
    color: '#ff815e',
    position: {
      angleX: Math.PI * -.48,
      angleY: Math.PI * .05,
    },
  },
  {
    id: 'chest',
    file: require('../../assets/models/chest.glb'),
    color: '#ffa45e',
    position: {
      angleX: Math.PI * .5,
      angleY: Math.PI * .16,
    },
  },
  {
    id: 'forearms',
    file: require('../../assets/models/forearms.glb'),
    color: '#ffc75e',
    position: {
      angleX: Math.PI * .86,
      angleY: Math.PI * .07,
    },
  },
  {
    id: 'glutes',
    file: require('../../assets/models/glutes.glb'),
    color: '#ffda5e',
    position: {
      angleX: Math.PI * 1.25,
      angleY: Math.PI * -.04,
    },
  },
  {
    id: 'groin',
    file: require('../../assets/models/groin.glb'),
    color: '#ffeb5f',
    position: {
      angleX: Math.PI * .6,
      angleY: Math.PI * -0.04
    },
  },
  {
    id: 'head',
    file: require('../../assets/models/head.glb'),
    notMuscle: true,
  },
  {
    id: 'hamstrings',
    file: require('../../assets/models/hamstrings.glb'),
    position: {
      angleX: Math.PI * -.75,
      angleY: Math.PI * -.02,
    },
  },
  {
    id: 'hands',
    file: require('../../assets/models/hands.glb'),
    notMuscle: true,
  },
  {
    id: 'lowerback',
    file: require('../../assets/models/lowerback.glb'),
    position: {
      angleX: -Math.PI * .5,
      angleY: Math.PI * .06
    },
  },
  {
    id: 'quads',
    file: require('../../assets/models/quads.glb'),
    position: {
      angleX: Math.PI * .25,
      angleY: Math.PI * -.02,
    },
  },
  {
    id: 'shoulders',
    file: require('../../assets/models/shoulders.glb'),
    position: {
      angleX: Math.PI * -.05,
      angleY: Math.PI * .17,
    },
  },
  {
    id: 'traps',
    file: require('../../assets/models/traps.glb'),
    position: {
      angleX: Math.PI * -.5,
      angleY: Math.PI * .2,
    },
  },
  {
    id: 'triceps',
    file: require('../../assets/models/triceps.glb'),
    position: {
      angleX: -Math.PI * -.95,
      angleY: Math.PI * .14,
    },
  },
  {
    id: 'upperback',
    file: require('../../assets/models/upperback.glb'),
    position: {
      angleX: -Math.PI * .5,
      angleY: Math.PI * .15,
    },
  },
];