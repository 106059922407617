import { ComponentEnum } from '../../enums';
import { UIStoreInit } from '../../store';

export const uiState: UIStoreInit = {
	button: {
		addNewBtn: {
			componentType: ComponentEnum.ButtonTypeEnum.Button,
			name: 'addNewBtn',
			// color: 'indigo.600',
			style: {
				width: 100,
				height: 40,
				borderRadius: 40,
			},
			backgroundColor: 'gray.400',
			_text: {
				color: 'black',
				fontWeight: 600,
			},
			// icon: AddIcon,
			children: 'Add',
		},
		btnEditRoutine: {
			name: 'btnEditRoutine',
			children: 'Edit',
			backgroundColor: 'white',
			_text: {
				color: 'gray.600'
			},
			borderRadius: 20,
		},
	},
	input: {
		routineTest: {
			componentType: ComponentEnum.InputTypeEnum.TextArea,
			name: 'routineTest',
			placeholder: 'Routine Test',
			label: 'Routine Test',
			routes: 'routineTest',
		},
	},
	typography: {
		routineTest: {
			name: 'routineTest',
			componentType: ComponentEnum.TypographyTypeEnum.Text,
			routes: 'routineTest',
		},
	},
	layout: {
		menuEnabled: true,
		// modelEnabled: true,
		// header: 'Routine Page',
		menuBackground: '#FFFFFF',
		// settingsEnabled: true,
		// backEnabled: true,
	},
	grid: {
		routineList: {
			name: 'routineList',
			componentType: ComponentEnum.GridTypeEnum.Simple,
			routes: 'routineList',
			titleData: 'routine_name',
			subtitleData: 'routine_description',
			btnIcon: 'arrowforward',
			editBtn: true,
		},
	}
};