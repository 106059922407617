import { ComponentEnum, icons } from "../../enums";

export const addNewBtn = {
    componentType: ComponentEnum.ButtonTypeEnum.Button,
    name: 'addNewBtn',
    // color: 'indigo.600',
    style: {
        width: 60,
        height: 40,
        borderRadius: 40,
    },
    backgroundColor: 'gray.400',
    _text: {
        color: 'black',
        fontWeight: 600,
    },
    // icon: AddIcon,
    children: 'Add'
};