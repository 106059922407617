export const HomeStore = {
	input1: '',
	input2: '',
	headerFields: {
		header1: 'header1',
		header2: 'header2',
	},
	headerInput: {
		input1: 'input',
		input2: 'blah',
		testInput: {
			triple: 'triple',
		}
	},
	testRoute: 'test',
	test2: {
		test2: 'test2',
		test3: 'test1'
	},
	inputStore: {
		input: 'input',
		select: 'select',
		switch: true,
		textarea: 'textarea',
	},
	typography: {
		header: 'header',
		text: 'text',
	},
	onboarded: false,
};
