import React, { useEffect } from 'react';
import { StyleSheet } from 'react-native';
import { useRoutineService } from './service';
import { uiState } from './routine.ui';
import { RoutineStore } from './routine.store';
import { FAStoreTypography, FAStoreInput, PageLayout, FAHeader, FAStoreGrid, FAButton, FAStoreButton } from '../../components';
import { getService, useServiceRouter } from '../../service';
import { Pressable, View } from 'native-base';
import * as util from '../../util';
import { useComponentRefresh } from '../../hooks';
import { useStoreGridHooks } from '../../hooks/component';

const RenderSimpleItem: React.FC<any> = (props: any) => {
	const {
		index,
		item = {},
		item: {
			id = '',
		},
		seperators,
	} = props;

	const [refresh] = useComponentRefresh();
	

	const gridProps = useStoreGridHooks('routineList', id);

	// get grid row
	const service = getService();

	// get row
	const row = service.store.getGridRow('routineList', id);
	
	const {
		open = false,
	} = row || {};

	const onClick = (evt) => {
		service.onClick(evt, id, { name: 'routineList', rowClick: true });
	};
	return (
		<View
			style={styles.gridRow}
			backgroundColor='lightBlue.200'
		>
			<View style={styles.infoSection}>
				<View style={styles.gridRowTitle}>
					<FAStoreTypography
						name='routine_name'
						color='blue.700'
						{...gridProps}
					/>
					<FAStoreTypography
						name='num_of_exercises'
						color='blue.700'
						{...gridProps}
					/>
				</View>
				<View style={styles.gridRowInfo}>
					<FAStoreTypography
						name='routine_frequency'
						color='blue.700'
						{...gridProps}
					/>
				</View>
				<View style={styles.gridRowGoal}>
					<FAStoreTypography
						name='routine_goal_text'
						color='blue.700'
						{...gridProps}
					/>
				</View>
			</View>
			<View style={styles.rowBtn}>
				<FAStoreButton
					name='btnEditRoutine'
					{...{
						...gridProps,
					}}
				/>
			</View>
		</View>
	);
};

export const Routine: React.FC = () => {
	const screenName = 'RoutinePage';
	const service = useRoutineService(screenName, RoutineStore, uiState);
	useServiceRouter(service);

	useEffect(() => {
		service.loadData();
	}, []);

	console.log('Render Routine screen');
	return (
		<PageLayout>
			<View style={styles.title}>
				<FAHeader name='header' textAlign={'center'}>Edit Routine</FAHeader>
			</View>
			<View style={styles.subtitle}>
				<View style={styles.text}>
					<FAHeader name='subtitle'>All Routines</FAHeader>
				</View>
				<View style={styles.btn}>
					<FAStoreButton name='addNewBtn' />
				</View>
			</View>
			<FAStoreGrid
				name='routineList'
				renderComponent={RenderSimpleItem}
			/>
			{/* <FAStoreGrid name='routineList'/> */}
		</PageLayout>
	);
};

const styles = StyleSheet.create({
	text: {
		flexGrow: 1,
	},
	btn: {

	},
	subtitle: {
		display: 'flex',
		flexDirection: 'row',
		width: '100%',
	},
	title: {
		display: 'flex',
		flexDirection: 'column',
		justifyContent: 'center'
	},
	// list item
	gridRow: {
		display: 'flex',
		flexDirection: 'row',
		width: '100%',
		padding: 5,
	},
	infoSection: {
		display: 'flex',
		flexDirection: 'column',
		flex: 4,
	},
	rowBtn: {
		flex: 1,
		display: 'flex',
		flexDirection: 'column',
		justifyContent: 'center',
	},
	gridRowTitle: {
		display: 'flex',
		flexDirection: 'row',
	},
	gridRowInfo: {
		display: 'flex',
		flexDirection: 'row',
	},
	gridRowGoal: {
		display: 'flex',
		flexDirection: 'row',
	},
});
