import React from 'react';
import { FALinkProps } from './FALinkProps';
import { Link } from 'native-base';

export const FALink: React.FC<FALinkProps> = (props: FALinkProps) => {
	const {
		name,
		href,

		size,
		isUnderlined,
		isHovered,
		// onPress,
		isExternal,
		_hover,
		wrapperRef,
		onClick,
		onBlur,
		onChange,
		onOk,
		onCancel,
		children,
		style,
		_text,
		color,
		clickProps = {},
		...otherProps
	} = props;

	const attributes: any = {
		name,
		href,

		size,
		isUnderlined,
		isHovered,
		isExternal,
		_hover,
		wrapperRef,
		style,
		_text,
		color,
		...otherProps
	};

	attributes.onPress = (evt: any) => {
		onClick?.call(null, evt, null, props);
	}

	console.log('Render Link', name);
	return <Link {...attributes}>{children}</Link>;
};