import { modelData } from '../../../components/threejs/modelData';
import { getMuscleColor } from '../../../enums/colors';
import { serviceBase } from '../../../service';
import { CustomOptions } from '../../../service/api';
import { getAppStateData, getAppStateStore, setAppStateStore, UIStoreInit } from '../../../store';
import * as util from '../../../util';

const modelDataIds = modelData.map((item) => item.id);

export class ModelService extends serviceBase {
	constructor(name: string, store: any, uiState: UIStoreInit) {
		super(name, store, uiState);

		this.responseData = this.responseData.bind(this);
		this.getSubmitData = this.getSubmitData.bind(this);
		this.transformData = this.transformData.bind(this);

		this.loadData = this.loadData.bind(this);
	}


	loadData() {
		this.api.getApiAsync(this.getUrl('muscleBreakdown'), {}, { name: 'muscleBreakdown' });
	}

	/**
     * get api data before api call
     * @param options 
     * @returns 
     */
	getSubmitData(options: CustomOptions) {
		return {};
	}

	/**
     * transform response function after api call 
     * @param data 
     * @param options 
     */
	transformData(data: any, options: CustomOptions) {
		const { name } = options;
		if (util.isArray(data.data)) {
			data.data.forEach((item) => {
				const {
					exercises
				} = item;
				// build subtitle
				let subtitle = '';
				if (util.isArray(exercises)) {
					exercises.forEach((e) => {
						let eLine = ''
						const {
							exercise_type_name,
							exercise_type_id,
							sets,
						} = e;
						if (!exercise_type_name) return;
						eLine += exercise_type_name + '\n';
						if (util.isArray((sets))) {
							sets.forEach((s) => {
								const {
									set_weight = 0,
									set_reps = 0,
								} = s;
								eLine += '\t' + `${set_weight} x ${set_reps}` + '\n';
							});
						}
						subtitle += eLine;
					});
				}
				item.subtitle = subtitle;
			});
		}
		return data;
	}

	/**
     * onBlur fn handler
     * @param value 
     * @param data 
     * @param props 
     */
	onBlur(value: any, data: any, props: any) {
		const { name, routes } = props;
		switch (name) {
			default:
				break;
		}
	}

	/**
     * onClick fn handler
     * @param value 
     * @param data 
     * @param props 
     */
	onClick(value: any, data: any, props: any) {
		const { name, routes } = props;
		if (modelDataIds.includes(name)) {
			this.callComponentHook('modelMenu', 'setPos', name);
		}
		switch (name) {
			case 'openMuscle':
				this.openModal('muscleModal');
				break;
			case 'settingsBtn':
				this.redirectTo('settings');
				break;
			default:
				break;
		}
	}

	/**
     * response function after api call
     * @param data 
     * @param options 
     */
	responseData(data: any, options: CustomOptions): void {
		const { name } = options;
		if (name === 'muscleBreakdown') {
			if (data.success) {
				this.resetData(data.data);
			}
		}
	}

	resetData(data: any) {
		if (util.isArray(data)) {
			data.forEach((item) => {
				const {
					intensity,
					muscle_id,
				} = item;
				if (intensity && muscle_id) {
					const color = getMuscleColor(intensity);
					this.callComponentHook('modelMenu', 'updateColor', muscle_id, color, false);
				}
			});
			// set data
			this.store.setGrid('muscleList', data);
		}
	}
}