import AsyncStorage from '@react-native-async-storage/async-storage';
export const tryParse = (str: string, backup: any = []) => {
	try {
		return JSON.parse(str);
	} catch (err) {
		return backup;
	}
}

// cache utilities

/**
 * get cache from async storage
 * constraint only store objects and list
 * @param cacheType a cache id attached per type
 * @param cacheName a cache id attached per instance
 */
export const getCache = async (cacheType: string, cacheName: string, backup: any = []) => {
	const key = `cache-${cacheType}-${cacheName}`;
	const value = await AsyncStorage.getItem(key);
	return tryParse(value, backup);
};

/**
 * set cache for async storage
 * constraint only store objects and list
 * @param cacheType a cache id attached per type
 * @param cacheName a cache id attached per instance
 * @param cacheValue value
 */
export const setCache = async (cacheType: string, cacheName: string, cacheValue: any) => {
	const key = `cache-${cacheType}-${cacheName}`;
	const value = JSON.stringify(cacheValue);
	await AsyncStorage.setItem(key, value);
};
