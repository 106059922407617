import { modelData } from '../../../components/threejs/modelData';
import { getMuscleColor } from '../../../enums/colors';
import { serviceBase } from '../../../service';
import { CustomOptions } from '../../../service/api';
import { getAppStateData, UIStoreInit } from '../../../store';
import * as util from '../../../util';

export class ExerciseChartService extends serviceBase {
	constructor(name: string, store: any, uiState: UIStoreInit) {
		super(name, store, uiState);

		this.responseData = this.responseData.bind(this);
		this.getSubmitData = this.getSubmitData.bind(this);
		this.transformData = this.transformData.bind(this);

		this.loadData = this.loadData.bind(this);
		this.setExerciseChartInfo = this.setExerciseChartInfo.bind(this);
	}


	loadData() {
		// get exercise type id
		const state = this.routing.location.state || {};
		const {
			exercise_type_id,
		} = state;
		if (exercise_type_id) {
			this.store.setProperty('exercise_type_id', exercise_type_id);
		}
		this.loadExercises();
		this.loadChart();
	}

	loadExercises() {
		this.api.getApiAsync(this.getUrl('exercise'), { }, { name: 'loadExercise' });
	}

	loadChart() {
		const exercise_type_id = this.store.getProperty('exercise_type_id');
		if (exercise_type_id) {
			this.api.getApiAsync(this.getUrl('exerciseChart'), { exercise_type_id }, { name: 'loadExerciseChart' });
		}
	}

	/**
     * get api data before api call
     * @param options 
     * @returns 
     */
	getSubmitData(options: CustomOptions) {
		const { name } = options;
		return {};
	}

	/**
     * transform response function after api call 
     * @param data 
     * @param options 
     */
	transformData(data: any, options: CustomOptions) {
		const { name } = options;
		if (name === 'loadExerciseChart') {
            if (util.isArray(data.data)) {
				data.data.forEach((item) => {
					const {
						log_date,
						set_reps,
						set_weight,
					} = item;

					item.titleData = `${set_weight} lb`;
					const date = util.formatDate(log_date);
					item.subtitleData = `${date} (${set_reps} Reps)`;
				});
			}
			return data;
		}
		return data;
	}

	/**
     * onClick fn handler
     * @param value 
     * @param data 
     * @param props 
     */
	onClick(value: any, data: any, props: any) {
		const { name } = props;
		switch (name) {
			// one handler for choosing another exercise
			case 'exercise_type_id':
				this.updateExercise();
				break;
			default:
				break;
		}
	}

	/**
     * onCancel fn handler
     * @param value 
     * @param data 
     * @param props 
     */
	onCancel(value: any, data: any, props: any) {
		const { name } = props;
		switch (name) {
			default:
				break;
		}
	}

	/**
     * onOk fn handler
     * @param value 
     * @param data 
     * @param props 
     */
	onOk(value: any, data: any, props: any) {
		const { name } = props;
		switch (name) {
			default:
				break;
		}
	}

	/**
     * response function after api call
     * @param data 
     * @param options 
     */
	responseData(data: any, options: CustomOptions): void {
		const { name } = options;
		if (name === 'loadExerciseChart') {
			if (data.success) {
				this.setExerciseChartInfo(data.data);
			} else {
				this.errorNotification('error!');
			}
		}
		if (name === 'loadExercise') {
			if (data.success) {
				this.setExerciseInfo(data.data);
			} else {
				this.errorNotification('error!');
			}
		}
	}

	setExerciseInfo(data: any) {
		if (util.isArray(data)) {
			this.store.setProperty(['exerciseSelectList'], data);
			data.forEach((item) => {
				item.value = item.exercise_type_id;
				item.label = item.exercise_type_name;
			});
			// set to select store items ui name
			this.uiStore.setProperty(['input', 'exercise_type_id', 'items'], data);
		}
	}

	setExerciseChartInfo(data: any) {
		if (util.isArray(data)) {
			this.store.setProperty('exerciseChartList', data);
		}
	}

	updateExercise() {
		// reload chart
		this.loadChart();
	}

	uiHide(name: string, data: any): boolean {
		return false;
	}
}