import { ComponentEnum } from '../../enums';
import { UIStoreInit } from '../../store';
import { addNewBtn, saveBtn } from '../common';

export const uiState: UIStoreInit = {
	button: {
		addNewBtn,
		saveBtn: {
			name: 'saveBtn',
			componentType: ComponentEnum.ButtonTypeEnum.Button,
			children: 'Save Exercise',
		},
		btnCalculate: {
			name: 'btnCalculate',
			componentType: ComponentEnum.ButtonTypeEnum.Button,
			children: 'Calculate',
		},
		saveGoal: {
			name: 'saveGoal',
			componentType: ComponentEnum.ButtonTypeEnum.Button,
			backgroundColor: 'blue.600',
			children: 'Save Goal',
			color: 'black',
			borderRadius: 25,
		},
		viewProgression: {
			name: 'viewProgression',
			backgroundColor: 'gray.400',
			children: 'View Progression',
			color: 'white',
			borderRadius: 25,
			borderColor: 'white',
			borderWidth: 2,
		},
	},
	input: {
		uselast: {
			componentType: ComponentEnum.InputTypeEnum.SelectButton,
			name: 'uselast',
			label: 'Use Last',
			routes: 'uselast',
			items: [
				{
					label: 'Use Last',
					value: true,
				},
				{
					label: 'Weight-Rep Progression',
					value: false,
				},
			],
			style: {
				flexDirection: 'row',
			},
		},
		exercise_weight_goal: {
			componentType: ComponentEnum.InputTypeEnum.Input,
			name: 'exercise_weight_goal',
			label: 'Goal Weight',
			routes: 'exercise_weight_goal',
			format: 'number',
		},
		exercise_weight_date: {
			componentType: ComponentEnum.InputTypeEnum.Date,
			name: 'exercise_weight_date',
			placeholder: 'Weight Date',
			label: 'Goal Date',
			routes: 'exercise_weight_date',
		},
		progression_rate: {
			componentType: ComponentEnum.InputTypeEnum.Slider,
			name: 'progression_rate',
			label: 'Progression Rate',
			routes: 'progression_rate',
			format: 'number',
			minValue: 0,
			maxValue: 1.2,
			step: .01
		},
		repFrom: {
			componentType: ComponentEnum.InputTypeEnum.Input,
			name: 'repFrom',
			label: 'Rep From',
			routes: 'repFrom',
			format: 'number',
		},
		repTo: {
			componentType: ComponentEnum.InputTypeEnum.Input,
			name: 'repTo',
			label: 'Rep To',
			routes: 'repTo',
			format: 'number',
		},
		setCount: {
			componentType: ComponentEnum.InputTypeEnum.Input,
			name: 'setCount',
			label: 'Set Count',
			routes: 'setCount',
			format: 'number',
		},
		initialWeight: {
			componentType: ComponentEnum.InputTypeEnum.Input,
			name: 'initialWeight',
			label: 'Initial Weight',
			routes: 'initialWeight',
			format: 'number',
		},
		increment: {
			componentType: ComponentEnum.InputTypeEnum.Input,
			name: 'increment',
			label: 'Increment',
			routes: 'increment',
			format: 'number',
		},
	},
	typography: {
		exerciseTest: {
			componentType: ComponentEnum.TypographyTypeEnum.Text,
			name: 'exerciseTest',
		},
		exerciseName: {
			componentType: ComponentEnum.TypographyTypeEnum.Header,
			name: 'exerciseName',
			routes: 'exerciseName',
			bold: true,
		},
		progression_message: {
			name: 'progression_message',
			routes: 'progression_message',
		},
	},
	layout: {
		menuEnabled: true,
		// modelEnabled: true,
		header: 'Progression',
		headerEnabled: true,
		menuBackground: '#FFFFFF',
		backEnabled: true,
		settingsEnabled: true,
	},
	grid: {
		predictionList: {
			name: 'predictionList',
			routes: 'predictionList',
			titleData: 'workout_title',
			subtitleData: 'workout_subTitle',
			// subtitleData: 'workout_comment',
			editBtn: true,
			idData: 'date',
		},
		exerciseList: {
			name: 'exerciseList',
			routes: 'exerciseList',
			titleData: 'exercise_type_name',
			subtitleData: 'exercise_type_description',
			editBtn: true,
		},
		exerciseMuscles: {
			name: 'exerciseMuscles',
			componentType: ComponentEnum.GridTypeEnum.SwitchGrid,
			routes: 'exerciseMuscles',
			titleData: 'muscleName',
			disableVirtualization: true,
		}
	},
	modal: {
		editModal: {
			name: 'editModal',
			title: 'Edit ExerciseGoal',
			okText: 'Save Edit',
		},
		addModal: {
			name: 'addModal',
			title: 'Add ExerciseGoal',
			okText: 'Add',
		},
		viewProgressionModal: {
			name: 'viewProgressionModal',
			componentType: ComponentEnum.ModalTypeEnum.Full,
			cancelText: null,
		},
	},
};
