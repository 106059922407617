import React, { useEffect, useState } from 'react';
import { FAModalProps } from './FAModalProps';
import { Button, Modal, View } from 'native-base';
import * as util from '../../util';
import { themeColors } from '../../enums';
import { btnStyle, textStyle } from '../../enums/styling';

export const FAFullModal: React.FC<FAModalProps> = (props: FAModalProps) =>{
	const {
		name,
		title,
		cancelText = 'Cancel',
		cancelColor='blueGray',
		okColor,
		okText = 'Select',
		component: Component,
		componentProps = {},
		width,
		height,
		// service props
		// children = <></>,
		onOk,
		onCancel,
		addComponentHook,
		
		// 
		forceUmmount = true,
	} = props;

	const [isOpen, setIsOpen] = useState(false);

	const openModal = () => {
		setIsOpen(true);
	}

	const onOkFn = () => {
		onOk?.call(null, null, null, props);
		setIsOpen(false);
	};

	const onCancelFn = () => {
		onCancel?.call(null, null, null, props);
		setIsOpen(false);
	};

	useEffect(() => {
		// add component hook
		addComponentHook?.call(null, name, 'open', openModal);
	}, []);

	console.log('Render FA Modal', name);

	if (forceUmmount && !isOpen) {
		return null;
	}

	return (
		<Modal
			isOpen={isOpen}
			onClose={onCancelFn}
		>
			<Modal.Content
				width={util.getDim('100vw')}
				height={util.getDim('100vh')}
				minW={util.getDim('100vw')}
				minH={util.getDim('100vh')}
			>
				<View
					width={'100%'}
					height={util.getDim('80vh')}
				>
				{	Component && <Component {...componentProps} />}
				</View>
				<View
					display={'flex'}
					flexDir={'row'}
					paddingRight={'36px'}
					paddingLeft={'36px'}
					paddingTop={'16px'}
					width={'100%'}
					height={'100%'}
					borderTopRadius={10}
					justifyContent={'space-between'}
					backgroundColor={themeColors.secondaryBase}
				>
					{cancelText && (
						<Button 
							variant='solid' 
							// colorScheme={cancelColor}
							children={cancelText}
							onPress={onCancelFn}
							size={'lg'}
							// flex={1}
							style={btnStyle.info_white}
							height={50}
							width={100}
							_text={{
								// color: 'black',
								...textStyle.btnText_Regular
							}}
						/>
					)}
					{okText && (
						<Button 
							variant='solid' 
							// colorScheme={okColor}
							children={okText}
							onPress={onOkFn}
							size={'lg'}
							// flex={1}
							style={btnStyle.accent}
							height={50}
							width={100}
							_text={{
								color: 'black',
								...textStyle.btnText_Regular
							}}
						/>
					)}
				</View>
			</Modal.Content>
		</Modal>
	);
};
