import { getService, useService } from '../../../service';
import { UIStoreInit } from '../../../store';
import { AboutService } from './AboutService';

/**
 * get service - create if not created yet
 * @param name 
 * @param store 
 * @param uiState 
 * @returns 
 */
export const useAboutService = (name: string, store: any, uiState: UIStoreInit) => {
	const service = getService(name) || new AboutService(name, store, uiState);
	useService(name, service);
	return service as AboutService;
};
