import { getMuscleColor } from '../../../enums/colors';
import { serviceBase } from '../../../service';
import { CustomOptions } from '../../../service/api';
import { getAppStateData, UIStoreInit } from '../../../store';
import * as util from '../../../util';

export class ExerciseDetailService extends serviceBase {
	constructor(name: string, store: any, uiState: UIStoreInit) {
		super(name, store, uiState);

		this.responseData = this.responseData.bind(this);
		this.getSubmitData = this.getSubmitData.bind(this);
		this.transformData = this.transformData.bind(this);

		this.loadData = this.loadData.bind(this);
		this.setExerciseDetailInfo = this.setExerciseDetailInfo.bind(this);
		this.updateModelColor = this.updateModelColor.bind(this);

		this.uiReadOnly = this.uiReadOnly.bind(this);
	}

	saveDetail() {
		const addName = this.store?.getProperty('addName');
		const addDescription = this.store?.getProperty('addDescription');
		const exercise_type_id = this.store?.getProperty('exercise_type_id');
		const exercise_type = this.store?.getProperty('exercise_type');
		const iscalisthenics = this.store?.getProperty('iscalisthenics');
		const isbarbell = this.store?.getProperty('isbarbell');
		let exerciseMuscles = (this.store?.getProperty('exerciseMuscles') || []).map((item) => {
			const {
				value,
				id,
			} = item;

			return {
				intensity: value,
				muscle_id: id,
			};
		});
		
		if (!addName) {
			this.errorNotification('Enter Exercise Name!');
			return;
		}
		if (!addDescription) {
			this.errorNotification('Enter Exercise Description!');
			return;
		}
		if (!exercise_type_id) {
			this.errorNotification('Choose exercise to edit!');
			return;
		}
		// startLoading
		this.startBtnLoading('saveBtn');
		const url = exercise_type === 'SYSTEM' ? 'editExerciseSystem' : 'editExercise';
		this.api.patchApiAsync(this.getUrl(url), { 
			exercise_type_id,
			exercise_type_name: addName,
			exercise_type_description: addDescription,
			exercise_muscles: exerciseMuscles,
			iscalisthenics,
			isbarbell,
		}, { name: 'editExerciseDetail' });
	}

	addExerciseDetail() {
		const addName = this.store?.getProperty('addName');
		const addDescription = this.store?.getProperty('addDescription');
		const systemSwitch = this.store?.getProperty('systemSwitch');
		if (!addName) {
			this.errorNotification('Enter ExerciseDetail Name!');
			return;
		}
		if (!addDescription) {
			this.errorNotification('Enter ExerciseDetail Description!');
			return;
		}
		// const url = systemSwitch ? 'addExerciseDetailSystem' : 'addExerciseDetail';
		// this.api.postApiAsync(this.getUrl(url), { exercise_type_name: addName, exercise_type_description: addDescription }, { name: 'addExerciseDetail' });
	}


	loadData() {
		// get exercise type id
		const state = this.routing.location.state || {};
		const {
			exercise_type_id,
		} = state;
		// const systemSwitch = this.store?.getProperty('systemSwitch');
		// const url = systemSwitch ? 'exerciseSystem' : 'exercise';
		if (exercise_type_id) {
			this.api.getApiAsync(this.getUrl('loadExerciseDetail'), { exercise_type_id }, { name: 'loadExerciseDetail' });
		}
	}


	deleteExerciseDetail(item) {
		const {
			exercise_type_id,
			exercise_type,
		} = item;
		const url = exercise_type === 'SYSTEM' ? 'exerciseSystem' : 'exercise';
		this.api.deleteApiAsync(this.getUrl(url), { exercise_type_id }, { name: 'deleteExerciseDetail' });
	}

	/**
     * get api data before api call
     * @param options 
     * @returns 
     */
	getSubmitData(options: CustomOptions) {
		const { name } = options;
		return {};
	}

	/**
     * transform response function after api call 
     * @param data 
     * @param options 
     */
	transformData(data: any, options: CustomOptions) {
		const { name } = options;
		if (name === 'loginApi') {
            
			return data;
		}
		return data;
	}

	/**
     * onClick fn handler
     * @param value 
     * @param data 
     * @param props 
     */
	onClick(value: any, data: any, props: any) {
		const { name } = props;
		switch (name) {
		case 'loadBtn':
			this.loadData();
			break;
		case 'addNewBtn':
			this.openModal('addModal');
			break;
		case 'systemSwitch':
			this.loadData();
			break;
		case 'exerciseMuscles': {
			const {
				id,	
			} = data;
			this.updateModelColor(id, value);
			break;
		}
		case 'saveBtn':
			this.saveDetail();
			break;
		case 'progressionBtn':
			this.openProgression();
			break;
		default:
			break;
		}
	}

	openProgression() {
		const exercise_type_id = this.store.getProperty('exercise_type_id');

		if (exercise_type_id) {
			this.redirectTo(this.getPath('exerciseGoal'), { exercise_type_id });
		}
	}

	updateModelColor(id, value, moveCamera = true) {
		const color = getMuscleColor(value);
		this.callComponentHook('exercise', 'updateColor', id, color, moveCamera);
	}

	/**
     * onCancel fn handler
     * @param value 
     * @param data 
     * @param props 
     */
	onCancel(value: any, data: any, props: any) {
		const { name } = props;
		switch (name) {
			default:
				break;
		}
	}

	/**
     * onOk fn handler
     * @param value 
     * @param data 
     * @param props 
     */
	onOk(value: any, data: any, props: any) {
		const { name } = props;
		switch (name) {
			default:
				break;
		}
	}

	/**
     * response function after api call
     * @param data 
     * @param options 
     */
	responseData(data: any, options: CustomOptions): void {
		const { name } = options;
		if (name === 'loadExerciseDetail') {
			if (data.success) {
				this.setExerciseDetailInfo(data.data);
			} else {
				this.errorNotification('error!');
			}
		}
		if (name === 'addExerciseDetail' || name === 'editExerciseDetail' || name === 'deleteExerciseDetail') {
			if (data.success) {
				this.loadData();
				this.successNotification('saved!');
			}
			// end loading
			this.endBtnLoading('saveBtn');
		}
	}

	setExerciseDetailInfo(data: any) {
		const {
			exercise_type_description,
			exercise_type_id,
			exercise_type_name,
			exercise_type,
			exercise_muscles,
			iscalisthenics = false,
			isbarbell = false,
		} = data;
		// set store property
		this.store.setProperty('iscalisthenics', iscalisthenics || false);
		this.store.setProperty('isbarbell', isbarbell || false);
		this.store?.setProperty('exercise_type_id', exercise_type_id);
		this.store?.setProperty('addName', exercise_type_name);
		this.store?.setProperty('addDescription', exercise_type_description);
		this.store?.setProperty('exercise_type', exercise_type);
		// muscles parse
		const musclesSettings = exercise_muscles;
		const asyncFunctions: any = [];
		const hashMuscle: any = {};
		if (util.isArray(musclesSettings)) {
			// create hash map -> id to value
			musclesSettings.forEach((item) => {
				const {
					muscle_id: id,
				} = item;
				if (id) {
					hashMuscle[id] = item;
				}
			});
		}
		let muscleData = this.store.getProperty('exerciseMuscles') || [];
		if (util.isArray(muscleData)) {
			muscleData = muscleData.map((item) => {
				const {
					id,
				} = item;
				if (hashMuscle[id]) {
					const {
						intensity: value,
					} = hashMuscle[id];
					if (util.isNumber(value)) {
						asyncFunctions.push(() => {
							this.updateModelColor(id, value, false);
						});
					}
					return {
						...item,
						...hashMuscle[id],
						value,
					};
				}
				return {
					...item,
					value: 0,
				};
			});
			// set store data
			this.store.setProperty('exerciseMuscles', muscleData);
		}
		if (asyncFunctions.length) {
			setTimeout(() => {
				asyncFunctions.forEach((itemFn) => {
					itemFn();
				})
			}, 1000);
		}
		this.store.setDataVersion();
	}

	get isAdmin() {
		const userType = getAppStateData('type');
		if (userType === 'ADMIN') {
			return true;
		}
		return false;
	}

	uiHide(name: string, data: any): boolean {
		if (name === 'exerciseList-renderItem') {
			const {
				exercise_type
			} = data;
			if (exercise_type === 'SYSTEM') {
				if (!this.isAdmin) {
					return true;
				}
			}
		}
		if (
			name === 'notAllowedSaveBtn'
			|| name === 'saveBtn'
		) {
			const exercise_type = this.store.getProperty('exercise_type');
			return (name === 'saveBtn' === (exercise_type === 'SYSTEM' && !this.isAdmin));
		}
		return false;
	}


	uiReadOnly(name: string, data: any): boolean {
		if (name === 'progressionBtn') {
			return false;
		}
		if (
			name === 'exerciseMuscles'
			|| name === 'iscalisthenics'
			|| name === 'isbarbell'
		) {
			const exercise_type = this.store.getProperty('exercise_type');
			if (exercise_type === 'SYSTEM' && !this.isAdmin) {
				return true;
			}
		}
		return false;
	}
}