import React, { ReactNode } from 'react';
import { View, StyleSheet } from 'react-native';

interface LayoutProps {
    children: ReactNode;
}

export const Layout: React.FC<LayoutProps> = (props: LayoutProps) => {
	const {
		children,
	} = props;
	return (
		<View style={styles.layout}>
			{children}
		</View>
	);
};
const styles = StyleSheet.create({
	layout: {
		flex: 1,
	},
});
