import { useLocation, useNavigate, useParams, useSearchParams } from "../layout/Routing";
import { serviceBase } from "./serviceBase";

/**
* attach router to service
* @param {serviceBase} service - current service
*/
export const useServiceRouter = (service: serviceBase) => {
    let location = useLocation();
    let navigate = useNavigate();
    let params = useParams();
    let searchParams = useSearchParams();
    service?.useRouter(navigate, location, params, searchParams);
    if (location.pathname) service.currentPath = location.pathname;
};