import { modelData } from '../../../components/threejs/modelData';
import { getMuscleColor } from '../../../enums/colors';
import { serviceBase } from '../../../service';
import { CustomOptions } from '../../../service/api';
import { getAppStateData, UIStoreInit } from '../../../store';
import * as util from '../../../util';

export class ExerciseService extends serviceBase {
	selectMode: boolean;
	constructor(name: string, store: any, uiState: UIStoreInit) {
		super(name, store, uiState);

		this.responseData = this.responseData.bind(this);
		this.getSubmitData = this.getSubmitData.bind(this);
		this.transformData = this.transformData.bind(this);

		this.loadData = this.loadData.bind(this);
		this.setExerciseInfo = this.setExerciseInfo.bind(this);

		this.selectMode = false;
	}

	editExercise() {
		const editName = this.store?.getProperty('editName');
		const editDescription = this.store?.getProperty('editDescription');
		const exercise_type_id = this.store?.getProperty('exercise_type_id');
		const exercise_type = this.store?.getProperty('exercise_type');
		if (!editName) {
			this.errorNotification('Enter Exercise Name!');
			return;
		}
		if (!editDescription) {
			this.errorNotification('Enter Exercise Description!');
			return;
		}
		if (!exercise_type_id) {
			this.errorNotification('Choose weight to edit!');
			return;
		}
		const url = exercise_type === 'SYSTEM' ? 'editExerciseSystem' : 'editExercise';
		this.api.patchApiAsync(this.getUrl(url), { exercise_type_id, exercise_type_name: editName, exercise_type_description: editDescription }, { name: 'editExercise' });
	}

	addExercise() {
		const addName = this.store?.getProperty('addName');
		const addDescription = this.store?.getProperty('addDescription') || '';
		const systemSwitch = this.store?.getProperty('systemSwitch');
		if (!addName) {
			this.errorNotification('Enter Exercise Name!');
			return;
		}
		const url = systemSwitch ? 'addExerciseSystem' : 'addExercise';
		this.api.postApiAsync(this.getUrl(url), { exercise_type_name: addName, exercise_type_description: addDescription }, { name: 'addExercise' });
	}


	loadData() {
		const systemSwitch = this.store?.getProperty('systemSwitch');
		const url = systemSwitch ? 'exerciseSystem' : 'exercise';
		this.api.getApiAsync(this.getUrl(url), { }, { name: 'loadExercise' });
	}


	deleteExercise(item) {
		const {
			exercise_type_id,
			exercise_type,
		} = item;
		const url = exercise_type === 'SYSTEM' ? 'exerciseSystem' : 'exercise';
		this.api.deleteApiAsync(this.getUrl(url), { exercise_type_id }, { name: 'deleteExercise' });
	}

	/**
     * get api data before api call
     * @param options 
     * @returns 
     */
	getSubmitData(options: CustomOptions) {
		const { name } = options;
		return {};
	}

	/**
     * transform response function after api call 
     * @param data 
     * @param options 
     */
	transformData(data: any, options: CustomOptions) {
		const { name } = options;
		if (name === 'loginApi') {
            
			return data;
		}
		return data;
	}

	/**
     * onClick fn handler
     * @param value 
     * @param data 
     * @param props 
     */
	onClick(value: any, data: any, props: any) {
		const { name } = props;
		switch (name) {
		case 'loadBtn':
			this.loadData();
			break;
		// case 'exerciseList':
		// 	this.store?.setProperty('exercise_type_id', data.exercise_type_id);
		// 	this.store?.setProperty('exercise_type', data.exercise_type);
		// 	this.store?.setProperty('editName', data.exercise_type_name);
		// 	this.store?.setProperty('editDescription', data.exercise_type_description);
		// 	this.openModal('editModal');
		// 	break;
		case 'exerciseList-btn':
			this.deleteExercise(data);
			break;
		case 'exerciseList-editbtn': {
			this.redirectTo(this.getPath('exercisedetail'), { exercise_type_id: data.exercise_type_id });
			break;
		}
		case 'exerciseList-infobtn': {
			this.redirectTo(this.getPath('exerciseChart'), { exercise_type_id: data.exercise_type_id });
			break;
		}
		case 'addNewBtn':
			this.openModal('addModal');
			break;
		case 'systemSwitch':
			this.loadData();
			break;
		case 'exerciseList':
			this.calculateMuscle(data);
			break;
		default:
			break;
		}
	}

	/**
     * onCancel fn handler
     * @param value 
     * @param data 
     * @param props 
     */
	onCancel(value: any, data: any, props: any) {
		const { name } = props;
		switch (name) {
		case 'editModal':
			// clear
			this.store.setProperty('editExercise', '');
			this.store.setProperty('exercise_type_id', '');
			this.store.setProperty('exercise_type', '');
			break;
		case 'addModal':
			// clear
			this.store.setProperty('addExercise', '');
			break;
		default:
			break;
		}
	}

	/**
     * onOk fn handler
     * @param value 
     * @param data 
     * @param props 
     */
	onOk(value: any, data: any, props: any) {
		const { name } = props;
		switch (name) {
		case 'editModal':
			this.editExercise();
			break;
		case 'addModal':
			this.addExercise();
			break;
		default:
			break;
		}
	}

	/**
     * response function after api call
     * @param data 
     * @param options 
     */
	responseData(data: any, options: CustomOptions): void {
		const { name } = options;
		if (name === 'loadExercise') {
			if (data.success) {
				this.setExerciseInfo(data.data);
			} else {
				this.errorNotification('error!');
			}
		}
		if (name === 'addExercise' || name === 'editExercise' || name === 'deleteExercise') {
			if (data.success) {
				this.loadData();
				const {
					exercise_type_id,
				} = data.data || {};
				if (exercise_type_id) {
					this.redirectTo(this.getPath('exercisedetail'), { exercise_type_id });
				}
			}
		}
	}

	setExerciseInfo(data: any) {
		// set data to input
		// const array = JSON.stringify(data);
		if (util.isArray(data)) {
		
			// set muscle data
			data.forEach((item) => {
				// item.muscle = 'Core';
				// calculate muscle
				let muscle = '';
				const intensityLimit = 20;
				const qty = 5;
				
				if (util.isArray(item.exercise_muscles)) {
					let currentOver = 0;
					let highestMuscle = '';
					let highestValue = 0;
					(item.exercise_muscles as any[]).forEach((item) => {
						const {
							intensity,
							muscle_id,
						} = item;

						if (intensity >= intensityLimit) {
							currentOver++;
						}
						if (intensity > highestValue) {
							highestMuscle = muscle_id;
							intensity;
						}
					});
					if (currentOver >= qty) {
						muscle = 'Full Body';
					} else if (highestMuscle) {
						switch (highestMuscle) {
							case 'abs':
								muscle = 'Core';
								break;
							case 'biceps':
								muscle = 'Arms';
								break;
							case 'calves':
								muscle = 'Legs';
								break;
							case 'chest':
								muscle = 'Chest';
								break;
							case 'forearms':
								muscle = 'Arms';
								break;
							case 'glutes':
								muscle = 'Legs';
								break;
							case 'groin':
								muscle = 'Legs';
								break;
							case 'hamstrings':
								muscle = 'Legs';
								break;
							case 'lowerback':
								muscle = 'Back';
								break;
							case 'quads':
								muscle = 'Legs';
								break;
							case 'shoulders':
								muscle = 'Shoulders';
								break;
							case 'traps':
								muscle = 'Back';
								break;
							case 'triceps':
								muscle = 'Arms';
								break;
							default:
								break;
						}
					}
				}

				item.muscle = muscle;
				// console.log(item);
			});

			this.store.setGrid('exerciseList', data);
		}
		// this.store.setProperty('exerciseTest', JSON.stringify(data));
	}

	calculateMuscle(rowItem: any) {
		const {
			exercise_muscles,
		} = rowItem;
		const hashMuscle: any = {};
		if (!exercise_muscles) return;
		
		if (util.isArray(exercise_muscles)) {
			exercise_muscles.forEach((muscleData) => {
				const {
					muscle_id: id,
				} = muscleData;
				if (id) {
					hashMuscle[id] = muscleData;
				}
			});
		}
		modelData.forEach((item) => {
			if (hashMuscle[item.id]) {
				const {
					intensity: value = 0,
				} = hashMuscle[item.id];
				if (util.isNumberNonStrict(value)) {
					this.updateModelColor(item.id, value, false);
				}
			}
		});
	}

	updateModelColor(id, value, moveCamera = true) {
		const color = getMuscleColor(value);
		this.callComponentHook('pageLayout', 'updateColor', id, color, moveCamera);
	}

	uiHide(name: string, data: any): boolean {
		if (name === 'exerciseList-deleteItem') {
			const {
				exercise_type
			} = data;
			if (exercise_type === 'SYSTEM') {
				// make sure user is system too
				const userType = getAppStateData('type');
				if (userType !== 'ADMIN') {
					return true;
				}
			}
		}
		if (name === 'exerciseList-viewItem') {
			const {
				exercise_type
			} = data;
			if (exercise_type !== 'SYSTEM') {
				return true;
			}
		}
		if (name === 'exerciseList-editItem') {
			const {
				exercise_type
			} = data;
			if (exercise_type === 'SYSTEM') {
				return true;
			}
		}
		if (name === 'systemSwitch') {
			// make sure user is system too
			const userType = getAppStateData('type');
			if (userType !== 'ADMIN') {
				return true;
			}
		}
		return false;
	}

	retrieveSelectedExercises() {
		// get grid
		const grid = this.store.getProperty('exerciseList');
		if (!util.isArray(grid)) {
			return [];
		}
		const selected: any[] = [];
		grid.forEach((item) => {
			if (item.selected) {
				selected.push(item);
				// clear
				item.selected = false;
			}
		});
		return selected;
	}
}