
export const RoutineStartStore = {
	routine_comment: '',
	routine_description: '',
	routine_name: '',
	exercises: [],
	exerciseSelectList: [],
	exercise_type_id: '',
	exercise_type_name: '',
	routine_exercise_id: '',
	routine_id: '',
	routine_log_id: '',
	// exercises will have every exercise -> will only add to payload if exercise_info is defined
	// store exercise_info as list and parse deparse pattern
	routineExercisesList: [],
	progressList: [],
	workoutExerciseWeight: 0,
	workoutExerciseReps: 0,
	workoutComment: '',
	workoutWorkingList: [],
	currentExerciseIndex: 0,
	// modal
	editExerciseReps: 0,
	editExerciseWeight: 0,
	editId: '',
};
