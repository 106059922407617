export const ExerciseChartStore = {
	exerciseChartTest: 'exerciseChartTest',
	exerciseChartList: [],
	systemSwitch: false,
	editName: '',
	editDescription: '',
	addName: '',
	addDescription: '',
	exerciseChart_type_id: '',
	exerciseChart_type: '',
};
