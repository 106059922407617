import React, { useEffect, useMemo, useState } from 'react';
import { StyleSheet, Dimensions } from 'react-native';
import { FAHeader, FAStoreButton, FAText } from '../../../components';
import { Button, IconButton, Pressable, View } from 'native-base';
import * as util from '../../../util';
import { observer } from 'mobx-react-lite';
import { getService } from '../../../service';
import { icons, themeColors } from '../../../enums';

interface ScheduleProps {
	currentRoutine?: string;
}

export const Day: React.FC<ScheduleProps> = observer((props: ScheduleProps) => {
	const {
		currentRoutine
	} = props;
	const screenName = 'Day';

	// const [current] = useState<util.DateDayofWeek>(util.getCurrentDay());
	const [currentDay, setCurrentDay] = useState(util.today());
	// const [open, setOpen] = useState<util.DateDayofWeek>(current);
	const [routine, setRoutine] = useState('');
	const isRoutineStarted = routine && routine === currentRoutine;
    const service = getService();

	const days = useMemo(() => {
		const listDays = [
			// 2 days before
			util.subtractDays(currentDay, 2),
			util.subtractDays(currentDay, 1),
			// current
			currentDay,
			// 2 days after
			util.addDays(currentDay, 1),
			util.addDays(currentDay, 2),
		];
		return listDays;
	}, [currentDay]);

	
	const {
		dayName,
		storeName
	} = useMemo(() => {
		let dayName = '';
		let storeName = '';

		const day = util.getDay(currentDay);

		if (day === util.DateDayofWeek.Monday) {
			dayName = 'Monday';
			storeName = 'monday';
		} else if (day === util.DateDayofWeek.Tuesday) {
			dayName = 'Tuesday';
			storeName = 'tuesday';
		} else if (day === util.DateDayofWeek.Wednesday) {
			dayName = 'Wednesday';
			storeName = 'wednesday';
		} else if (day === util.DateDayofWeek.Thursday) {
			dayName = 'Thursday';
			storeName = 'thursday';
		} else if (day === util.DateDayofWeek.Friday) {
			dayName = 'Friday';
			storeName = 'friday';
		} else if (day === util.DateDayofWeek.Saturday) {
			dayName = 'Saturday';
			storeName = 'saturday';
		} else if (day === util.DateDayofWeek.Sunday) {
			dayName = 'Sunday';
			storeName = 'sunday';
		} else {
			return null;
		}
		return {
			dayName,
			storeName,
		};
	}, [currentDay]);

	
	const storeValue = service.store.getProperty(storeName);


	const setRoutineId = (routine_id) => {
		// set selected routine id
		service.store.setProperty('routine_id', routine_id);
		// set component state as well
		setRoutine(routine_id);
	};
	
	const setDefaultRoutine = () => {

		// no routine set and day has first defined then set
		if (util.isArray(storeValue) && storeValue[0]?.routine_id) {
			setRoutineId(storeValue[0].routine_id);
		} else if (routine) {
			// else if set then there is none defined so clear
			setRoutineId('');
		}
	}

	useEffect(() => {
		if (util.isArray(storeValue)) {
			const exist = storeValue.find((item) => item.routine_id === routine);
			if (!exist) {
				setDefaultRoutine();
			}
		}
	}, [storeValue]);

	const IconLeft = icons.arrowback;
	const IconRight = icons.arrowforward;

	const decrementFn = () => {
		const before = util.subtractDays(currentDay, 1);
		setCurrentDay(before);
	};
	
	const incrementFn = () => {
		const before = util.addDays(currentDay, 1);
		setCurrentDay(before);
	};

	const dayOfWeek = util.getDay(currentDay);

	console.log('Render RoutineSchedule screen');
	return (
        <View style={styles.week}>
			{/* heder */}
			<View style={styles.modeContainer} backgroundColor={'gray.200'}>
				{/* go left button */}
				<View style={styles.iconMode}>
					<IconButton
						onPress={decrementFn}
					>
						<IconLeft
							style={styles.iconBtn}
						/>
					</IconButton>
				</View>
				{
					days.map((item) => {
						const date = item.format('MM/D');
						return (
							<View style={styles.mode} key={date}>
								<Button
									padding={0}
									margin={0}
									backgroundColor={currentDay.format('MM/D') === date ? 'blue.200' : 'gray.200'}
									_text={{
										color: 'black',
										fontWeight: 600,
									}}
									style={styles.btn}
									onPress={() => {
										setCurrentDay(item);
									}}
								>
									{date}
								</Button>
							</View>
						)
					})
				}
				<View style={styles.iconMode}>
					<IconButton
						onPress={incrementFn}
					>
						<IconRight
							style={styles.iconBtn}
						/>
					</IconButton>
				</View>
			</View>
			{/* day section */}
			<View style={styles.dayContainer}>
				<FAText bold name='day' color="white" fontSize="18" style={styles.dayText}>{dayName}</FAText>
				{util.isArray(storeValue) ? (
					<View style={styles.routinesHeader}>
						{
							storeValue.map((item, idx) => {
								const {
									routine_name,
									routine_id
								} = item;
			
			
								const selected = routine === routine_id;
								
								return (
									<Pressable
										bg={'blue.200'}
										opacity={selected ? 1 : .8}
										padding={1}
										marginLeft={1}
										borderRadius={5}
										onPress={() => {
											setRoutineId(routine_id);
										}}
										key={`${routine_id}_${idx}`}
										style={{
											width: 100,
											height: 100,
										}}
									>
										<FAText bold name='routinename' color="black" fontSize={"14"} style={{ alignSelf: 'center', wordBreak: 'break1' }}>{routine_name}</FAText>
									</Pressable>
								);
							})
						}
						<FAStoreButton 
							name={isRoutineStarted ? 'continueRoutineBtn' : 'startRoutineBtn'}
							colorScheme={themeColors.thirdColorScheme}
						/>
					</View>
				) : (
					// no routines
					<>
						<FAHeader name='noRoutine'>You do not have any routine.</FAHeader>
						<FAHeader name='noRoutine'>Add routine right now!</FAHeader>
						<FAStoreButton name='addIconBtn' />
					</>
				)}
			</View>
        </View>
	);
});

const styles = StyleSheet.create({
	dayContainer: {

	},
	headerIcon: {

	},
	routinesHeader: {

	},
	header: {
		display: 'flex',
		flexDirection: 'row',
		flex: 1,
		border: '1pt black solid',
		maxHeight: 50,
		minHeight: 50,
		justifyContent: 'center',
		textAlign: 'center',
		overflow: 'hidden',
		borderRadius: 30,
		margin: 3,
	},
	headerDay: {
		display: 'flex',
		flexDirection: 'row',
		justifyContent: 'center',
		textAlign: 'center',
		overflow: 'hidden',
		borderRadius: 30,
		margin: 3,
		flex: 1,
		border: '1pt black solid',
	},
	dayText: {
		display: 'flex',
		flexDirection: 'column',
		justifyContent: 'center',
		alignItems: 'flex-start'
	},
	week: {
		flex: 10,
	},
	day: {
		borderRadius: 20,
		justifyContent: 'center',
		padding: 15,
		// color: 'white',
		
		fontWeight: 500,
		margin: 5,
	},
	containerOpen: {
		
	},
	containerClosed: {
		display: 'flex',
		flexDirection: 'row',
	},
	modeContainer: {
		flex: 1,
		display: 'flex',
		flexDirection: 'row',
		justifyContent: 'flex-end',
		maxHeight: 50,
		minHeight: 50,
		overflow: 'hidden',
		borderRadius: 30,
		margin: 1,
	},
	mode: {
		flex: 1,
		flexGrow: 1,
		justifyContent: 'center',
		textAlign: 'center',
		borderRadius: 30,
		overflow: 'hidden',
		margin: 1,
	},
	iconMode: {
		justifyContent: 'center',
		textAlign: 'center',
		borderRadius: 30,
		overflow: 'hidden',
		margin: 1,
	},
	iconBtn: {
		width: '80%',
		height: '80%',
	},
	btn: {
		width: '100%',
		height: '100%',
	},
});