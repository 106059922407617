import { modelData } from '../../../components/threejs/modelData';
import { getMuscleColor } from '../../../enums/colors';
import { serviceBase } from '../../../service';
import { CustomOptions } from '../../../service/api';
import { getAppStateData, UIStoreInit } from '../../../store';
import * as util from '../../../util';

export class RoutineScheduleService extends serviceBase {
	addMode: boolean;

	constructor(name: string, store: any, uiState: UIStoreInit) {
		super(name, store, uiState);

		this.responseData = this.responseData.bind(this);
		this.getSubmitData = this.getSubmitData.bind(this);
		this.transformData = this.transformData.bind(this);

		this.loadData = this.loadData.bind(this);
		this.setRoutineScheduleInfo = this.setRoutineScheduleInfo.bind(this);
		this.updateModelColor = this.updateModelColor.bind(this);
	}

	saveDetail() {
		const routine_id = this.store?.getProperty('routineId');
		if (!routine_id) {
			this.errorNotification('choose a routine!');
			return;
		}
		const {
			monday,
			tuesday,
			wednesday,
			thursday,
			friday,
			saturday,
			sunday,
		} = this.store?.getProperty('editSchedule') || {};
		const schedules = [];
		const addSchedule = (date: string) => {
			schedules.push({
				schedule_start_date: date,
				recurring: true,
			});
		}

		if (monday) {
			addSchedule(util.getDate(util.DateDayofWeek.Monday));
		}
		if (tuesday) {
			addSchedule(util.getDate(util.DateDayofWeek.Tuesday));
		}
		if (wednesday) {
			addSchedule(util.getDate(util.DateDayofWeek.Wednesday));
		}
		if (thursday) {
			addSchedule(util.getDate(util.DateDayofWeek.Thursday));
		}
		if (friday) {
			addSchedule(util.getDate(util.DateDayofWeek.Friday));
		}
		if (saturday) {
			addSchedule(util.getDate(util.DateDayofWeek.Saturday));
		}
		if (sunday) {
			addSchedule(util.getDate(util.DateDayofWeek.Sunday));
		}

		// startLoading
		this.startBtnLoading('saveBtn');
		this.api.patchApiAsync(this.getUrl('saveRoutineSchedules'), { routine_id, schedules }, { name: 'editRoutineSchedule' });
	}

	addRoutineSchedule() {
		const routine_name = this.store?.getProperty('routine_name');
		const routine_description = this.store?.getProperty('routine_description');
		const exercises = this.store?.getProperty('exercises');
		if (!routine_name) {
			this.errorNotification('Enter Routine Name!');
			return;
		}
		if (!routine_description) {
			this.errorNotification('Enter Routine Description!');
			return;
		}
		if (!util.isArray(exercises)) {
			this.errorNotification('Please choose at least 1 exercise!');
			return;
		}
		this.api.postApiAsync(this.getUrl('routineSchedules'), { routine_name, routine_description, exercises }, { name: 'addRoutineSchedule' });
	}


	async initialLoad() {
		const state = this.routing.location.state || {};
		const {
			goBack,
			is_onboarded = false,
		} = state;
		
		if (is_onboarded || goBack) {
			this.store.setProperty('is_onboarded', is_onboarded || goBack);
		}
		// disable this for now
		// this.loadIsOnboarded();
		this.loadRoutine();
		this.loadRoutineSchedules();
	}

	loadIsOnboarded() {
		const is_onboarded = this.store.getProperty('is_onboarded');
		if (!is_onboarded) {
			this.api.getApiAsync(this.getUrl('isOnboarded'), {}, { name: 'isOnboarded' });
		}
	}

	loadData() {
		// get exercise type id
		const routine_id = this.store.getProperty('routine_id');
		if (!routine_id) return;
		this.api.getApiAsync(this.getUrl('routineSchedules'), { routine_id }, { name: 'loadRoutineSchedules' });
	}


	deleteRoutineSchedule(item) {
		const {
			exercise_type_id,
			exercise_type,
		} = item;
		const url = exercise_type === 'SYSTEM' ? 'exerciseSystem' : 'exercise';
		this.api.deleteApiAsync(this.getUrl(url), { exercise_type_id }, { name: 'deleteRoutineSchedule' });
	}

	/**
     * get api data before api call
     * @param options 
     * @returns 
     */
	getSubmitData(options: CustomOptions) {
		const { name } = options;
		return {};
	}

	/**
     * transform response function after api call 
     * @param data 
     * @param options 
     */
	transformData(data: any, options: CustomOptions) {
		const { name } = options;
		if (name === 'loginApi') {
            
			return data;
		}
		if (name === 'loadRoutineSchedules' || name === 'isOnboarded') {
			return data;
		}
		return data;
	}

	/**
     * onClick fn handler
     * @param value 
     * @param data 
     * @param props 
     */
	onClick(value: any, data: any, props: any) {
		const { name } = props;
		switch (name) {
		case 'loadBtn':
			this.loadData();
			break;
		case 'exercises-btn':
			const {
				id
			} = data;
			if (id) {
				this.store?.deleteGridRow('exercises', id);
				this.calculateMuscles();
			}
			break;
		case 'addIconBtn':
		case 'editBtn':
			this.redirectTo(this.getPath('routine'));
			break;
		case 'addBtn': 
			// clear data
			this.store.setProperty('editSchedule', {
				monday: false,
				tuesday: false,
				wednesday: false,
				thursday: false,
				friday: false,
				saturday: false,
				sunday: false,
			});
			this.store.setProperty('routineId', '');
			this.callComponentHook('addSchedule', 'open');
			break;
		case 'continueRoutineBtn':
		case 'startRoutineBtn': {
			const routine_id = this.store?.getProperty('routine_id');
			if (!routine_id) {
				// do warning -> instruct user to edit schedule
				this.callComponentHook('scheduleModal', 'open');
				return;
			}
			const addNew = name === 'startRoutineBtn';
			// redirect to routine start page
			this.redirectTo(this.getPath('routinestart'), { routine_id, addNew });
			break;
		}
		case 'saveBtn':
			this.saveDetail();
			break;
		case 'routineId':
			this.updateEditScheduleInfo(value);
			break;
		default:
			break;
		}
	}

	updateEditScheduleInfo(routineId: string) {
		// based on routine id
		// update editSchedule info
		// get schedule info from store
		const scheduleInfo = this.store.getProperty('routineSchedules');
		if (util.isArray(scheduleInfo)) {
			const scheduleItem = scheduleInfo.find((item) => item.routine_id === routineId);
			if (scheduleItem) {
				// set from here
				const {
					schedule,
				} = scheduleItem;
				if (util.isStrictArray(schedule)) {
					// check each if each day of week exists

					schedule.forEach((item) => {
						const {
							schedule_start_date,
						} = item;
						// get day
						item.dayOfWeek = util.getDay(schedule_start_date);
					});

					const checkDayofWeek = (day: util.DateDayofWeek) => {
						const exist = schedule.findIndex((item) => item.dayOfWeek === day) !== -1;

						let storeName = '';
						switch (day) {
							case util.DateDayofWeek.Monday:
								storeName = 'monday';
								break;
							case util.DateDayofWeek.Tuesday:
								storeName = 'tuesday';
								break;
							case util.DateDayofWeek.Wednesday:
								storeName = 'wednesday';
								break;
							case util.DateDayofWeek.Thursday:
								storeName = 'thursday';
								break;
							case util.DateDayofWeek.Friday:
								storeName = 'friday';
								break;
							case util.DateDayofWeek.Saturday:
								storeName = 'saturday';
								break;
							case util.DateDayofWeek.Sunday:
								storeName = 'sunday';
								break;
							default:
								break;
						}
						if (storeName) {
							this.store.setProperty(['editSchedule', storeName], exist);
						}
					}

					checkDayofWeek(util.DateDayofWeek.Monday);
					checkDayofWeek(util.DateDayofWeek.Tuesday);
					checkDayofWeek(util.DateDayofWeek.Wednesday);
					checkDayofWeek(util.DateDayofWeek.Thursday);
					checkDayofWeek(util.DateDayofWeek.Friday);
					checkDayofWeek(util.DateDayofWeek.Saturday);
					checkDayofWeek(util.DateDayofWeek.Sunday);
				}
			}
		}
	}

	updateModelColor(id, value, moveCamera = true) {
		const color = getMuscleColor(value);
		this.callComponentHook('routineSchedules', 'updateColor', id, color, moveCamera);
	}

	/**
     * onCancel fn handler
     * @param value 
     * @param data 
     * @param props 
     */
	onCancel(value: any, data: any, props: any) {
		const { name } = props;
		switch (name) {
			default:
				break;
		}
	}

	/**
     * onOk fn handler
     * @param value 
     * @param data 
     * @param props 
     */
	onOk(value: any, data: any, props: any) {
		const { name } = props;
		switch (name) {
			case 'scheduleModal':
				// open schedule routine
				this.callComponentHook('addSchedule', 'open');
				break;
			default:
				break;
		}
	}

	/**
     * response function after api call
     * @param data 
     * @param options 
     */
	responseData(data: any, options: CustomOptions): void {
		const { name } = options;
		if (name === 'loadRoutineSchedules') {
			if (data.success) {
				this.setRoutineScheduleInfo(data.data);
			} else {
				this.errorNotification('error!');
			}
		}
		if (name === 'isOnboarded') {
			if (data.success) {
				this.handleOnboarding(data.data);
			}
		}
		if (name === 'addRoutineSchedule') {
			if (data.success) {
				const {
					routine_id,
				} = data.data || {};
				this.store.setProperty('routine_id', routine_id);
				// change to edit mode
				this.addMode = false;
				this.loadData();
			}
		}
		if (name === 'loadRoutine') {
			if (data.success) {
				this.setRoutineInfo(data.data);
			} else {
				this.errorNotification('error!');
			}
		}
		if (name === 'editRoutineSchedule') {
			if (data.success) {
				this.successNotification('success');
				this.loadRoutineSchedules();
			} else {
				this.errorNotification('error!');
			}
			// end loading
			this.endBtnLoading('saveBtn');
		}
		if (name === 'loadRoutineExercises') {
			if (data.success) {
				this.setRoutineExercises(data.data);
			} else {
				this.errorNotification('error!');
			}
		}
	}

	resetSingleSchedule(data) {
		const {
			routine_id
		} = data;
		if (!routine_id) {
			return;
		}
		const current = util.cloneDeep(this.store.getProperty('routineSchedules'));
		// replace that idx with this data
		if (util.isArray(current)) {
			const idx = current.findIndex((item) => item.routine_id === routine_id);
			if (idx !== -1) {
				util.mergeDeep(current[idx], data);
				this.store.setProperty('routineSchedules', current);
				// update 7 day
				this.setRoutineScheduleInfo();
			}
		}
	}


	async loadRoutineSchedules() {
		// load exercise data for select list
		await this.api.getApiAsync(this.getUrl('routineSchedules'), { }, { name: 'loadRoutineSchedules' });
	}

	loadRoutine() {
		// load routine data for select list
		this.api.getApiAsync(this.getUrl('routine'), { }, { name: 'loadRoutine' });
	}

	setRoutineExercises(data: any) {
		if (util.isArray(data)) {
			data.forEach((item) => {
				item.value = item.exercise_type_id;
				item.label = item.exercise_type_name;
			});
			// set to select store items ui name
			this.uiStore.setProperty(['input', 'exerciseTypeId', 'items'], data);
		}
	}

	setRoutineInfo(data: any) {
		if (util.isArray(data)) {
			data.forEach((item) => {
				item.value = item.routine_id;
				item.label = item.routine_name;
			});
			// set to select store items ui name
			this.uiStore.setProperty(['input', 'routineId', 'items'], data);
			this.store.setProperty(['routineSelectList'], data);
		}
	}

	/**
	 * allow reset without pass data
	 * @param data 
	 */
	setRoutineScheduleInfo(data?: any[]) {
		data = data || this.store.getProperty('routineSchedules');
		if (util.isStrictArray(data)) {
			// set initial
			this.store.setProperty('monday', []);
			this.store.setProperty('tuesday', []);
			this.store.setProperty('wednesday', []);
			this.store.setProperty('thursday', []);
			this.store.setProperty('friday', []);
			this.store.setProperty('saturday', []);
			this.store.setProperty('sunday', []);
			data.forEach((item) => {
				const {
					schedule,
					routine_name,
					routine_id,
				} = item;
				// push each schedule into 

				if (util.isArray(schedule)) {
					schedule.forEach((single) => {
						if (single.schedule_start_date && single.recurring) {
							const dayOfWeek = util.getDay(single.schedule_start_date);

							let storeName = '';
							switch (dayOfWeek) {
								case util.DateDayofWeek.Monday:
									storeName = 'monday';
									break;
								case util.DateDayofWeek.Tuesday:
									storeName = 'tuesday';
									break;
								case util.DateDayofWeek.Wednesday:
									storeName = 'wednesday';
									break;
								case util.DateDayofWeek.Thursday:
									storeName = 'thursday';
									break;
								case util.DateDayofWeek.Friday:
									storeName = 'friday';
									break;
								case util.DateDayofWeek.Saturday:
									storeName = 'saturday';
									break;
								case util.DateDayofWeek.Sunday:
									storeName = 'sunday';
									break;
								default:
									break;
							}

							// add to store
							this.store.addGridRow(storeName, {
								routine_name,
								routine_id,
							});
						}
					});
				}
			});

			// set inside store as well
			this.store.setProperty('routineSchedules', data);
		}
	}

	calculateMuscles() {
		// get exercises
		const exercises = this.store.getProperty('exercises');
		// muscles parse
		const asyncFunctions: any = [];
		const hashMuscle: any = {};
		if (util.isArray(exercises)) {
			// create hash map -> id to value
			exercises.forEach((exercise) => {
				const {
					exercise_type_definition,
				} = exercise;
				if (!exercise_type_definition) return;
				const item = util.tryParse(exercise_type_definition, []);
				if (util.isArray(item)) {
					item.forEach((muscleData) => {
						const {
							id,
							value,
						} = muscleData;
						if (id && (!hashMuscle[id] || hashMuscle[id].value < value)) {
							hashMuscle[id] = muscleData;
						}
					});
				}
			});
		}
		modelData.forEach((item) => {
			if (hashMuscle[item.id]) {
				const {
					value,
				} = hashMuscle[item.id];
				if (util.isNumber(value)) {
					asyncFunctions.push(() => {
						this.updateModelColor(item.id, value, false);
					});
				}
			}
		});
		if (asyncFunctions.length) {
			setTimeout(() => {
				asyncFunctions.forEach((itemFn) => {
					itemFn();
				})
			}, 500);
		}
	}

	handleOnboarding(data: any) {
		const {
			is_onboarded = false,
		} = data;
		
		if (!is_onboarded) {
			this.redirectTo(this.getPath('onboarding'), { });
		}
	}

	uiHide(name: string, data: any): boolean {
		return false;
	}
}