
import { BASE_URL } from '../../enums';
import { getAppStateData } from '../../store/appStateStore';
import * as util from '../../util';
import { serviceBase } from '../serviceBase';

/**
 * add object query to url string
 */
const addQueryParameters = (url: string, query: any) => {
	if (util.isObject(query)) {
		const keys = Object.keys(query);
		keys.forEach((key, index) => {
			if (index === 0) {
				url += '?';
			}
			url += `${key}=${query[key]}`;
			if (index !== keys.length - 1) {
				url += '&';
			}
		});
	}
	return url;
};

/**
 * api type 
 * name required
 * other optional
 */
export interface CustomOptions {
    name: string; // identifier
    method?: 'POST' | 'GET' | 'DELETE' | 'PATCH';
	otherOptions?: any;
}

/**
 * api class handles api from start to finish
 * connected to serviceBase
 */
export class ApiClass {

	service: serviceBase;
	constructor(service: serviceBase) {
		this.service = service;

		this.getApiAsync = this.getApiAsync.bind(this);
		this.deleteApiAsync = this.deleteApiAsync.bind(this);
		this.postApiAsync = this.postApiAsync.bind(this);
		this.patchApiAsync = this.patchApiAsync.bind(this);
	}

	/**
     * 
     * @param url 
     * @param postData 
     * @param options 
     * @returns 
     */
	private async callPostApiAsync(url: string, postData: any, options: CustomOptions) {
		const { method = 'POST' } = options;

		// build url
		url = BASE_URL + url;

		// get token 
		const Authorization = getAppStateData('Authorization');

		try {
			const res = await fetch(url, {
				method,
				headers: {
					'Content-Type': 'application/json',
					Authorization,
				},
				body: JSON.stringify(postData),
			});
			if (!res.ok) {
				const response = await res.json();
				this.service.responseError(response, options);
				return;
			}
			return res;
		}
		catch(err) {
			this.service.responseError(err, options);
		}
	}

	/**
     * 
     * @param url 
     * @param query 
     * @param options 
     * @returns 
     */
	private async callGetApiAsync(url: string, query: any, options: CustomOptions) {
		const { method = 'GET' } = options;
		url = addQueryParameters(url, query);

		// build url
		url = BASE_URL + url;

		// get token 
		const Authorization = getAppStateData('Authorization');
		try {
			return await fetch(url, {
				method: method,
				headers: {
					'Content-Type': 'application/json',
					Authorization,
				},
			});
		} catch(err) {
			this.service.responseError(err, options);
		}
	}

	/**
     * get and delete
     * @param url 
     * @param query 
     * @param options 
     */
	async getApiAsync(url: string, query: any, options: CustomOptions) {
		// call api
		return await this.callGetApiAsync(url, query, options).then(async (callData: Response) => {
			if (!callData) return;
			let dataJson = await callData.json();
			// transform data
			dataJson = this.service.transformData(dataJson, options);

			// call response function
			this.service.responseData(dataJson, options);
			return dataJson;
		});
	}

	/**
     * delete
     * @param url 
     * @param query 
     * @param options 
     */
	async deleteApiAsync(url, query: any, options: CustomOptions) {
		options.method = 'DELETE';
		await this.getApiAsync(url, query, options);
	}

	/**
     * post and patch
     * @param url 
     * @param data 
     * @param options 
     */
	async postApiAsync(url: string, data: any, options: CustomOptions) {
		// get data first 
		const postData = { ...data, ...this.service.getSubmitData(options) };
		// call api
		return await this.callPostApiAsync(url, postData, options).then(async (callData: Response) => {
			if (!callData) return;
			let dataJson = await callData.json();
			// transform data
			dataJson = this.service.transformData(dataJson, options);
    
			// call response function
			this.service.responseData(dataJson, options);
			return dataJson;
		});
	}

	/**
     * patch
     * @param url 
     * @param data 
     * @param options 
     */
	async patchApiAsync(url, data: any, options: CustomOptions) {
		options.method = 'PATCH';
		await this.postApiAsync(url, data, options);
	}
} 
