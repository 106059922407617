import React from 'react';
import {FAIconButtonProps} from './FAIconButtonProps';
import { createIcon, Icon, IconButton } from 'native-base';
import * as util from '../../util';
import { getUuid } from '../../util';
import { themeColors } from '../../enums';

export const FAIconButtonComponent: React.FC<FAIconButtonProps> = (props: FAIconButtonProps) => {
	let {
		name,

		colorScheme = themeColors.secondaryColorScheme,
		variant,
		size,
		isDisabled,
		icon: IconComponent,
		_icon,
		_hover,
		_pressed,
		_focus,
		_text,
		color,
		mb,
		svg,
		// service
		onClick,
		onBlur,
		onChange,
		onOk,
		onCancel,

		style,
		id,
		backgroundColor,
		clickProps = {},
		...otherProps
	} = props;

	id = id || getUuid();

	const attributes: any = {
		name,
		icon: IconComponent && <IconComponent />,
		colorScheme,
		variant,
		size,
		isDisabled,
		_icon,
		_hover,
		_pressed,
		_focus,
		_text,
		color,
		mb,
		d: svg,
		style,
		backgroundColor,
		key: id,
		...otherProps,
	};

	attributes.onPress = (evt: any) => {
		onClick?.call(null, evt, null, props);
	}

	console.log('Render IconButton', name);
	return <IconButton {...attributes} />;
};

export const FAIconButton = React.memo(FAIconButtonComponent);