import React from 'react';
import { ComponentEnum } from '../../enums';
import { useChartProps, useComponentProps } from '../../service';
import { observer } from 'mobx-react-lite';
import * as util from '../../util/typeUtil';
import { FAChart } from './FAChart';
import { FAChartProps } from './FAChartProps';
import { useDataVersion } from '../../store/hooks';


export const FAStoreChart: React.FC<FAChartProps> = observer((props: FAChartProps) => {

	const attributes = useChartProps(props);
	const [dataVersion, getDataVersion, setDataVersion] = useDataVersion();
	
	const {
		componentType,
		getValue,
		name,
	} = attributes;
    
	const getStoreValue = () => {
		const storeValue = getValue && getValue(props);
		if (util.isArray(storeValue)) {
			return storeValue;
		}
		return [];
	};

	let Component;
	switch (componentType) {
	case ComponentEnum.ChartTypeEnum.Chart:
		Component = FAChart;
		break;
	default:
		Component = FAChart;
		break;
	}

	attributes.dataSource = getStoreValue();

	console.log('Render FA Store Chart', name);
	return <Component {...attributes}/>;
});
