import { ComponentEnum } from '../../enums';
import { UIStoreInit } from '../../store';

export const uiState: UIStoreInit = {
	button: {
		submitBtn: {
			name: 'submitBtn',
			children: 'Submit',
			size: 'lg',
		},
		resetBtn: {
			name: 'resetBtn',
			children: 'Reset Password',
			size: 'lg',
		},
	},
	input: {
		email: {
			componentType: ComponentEnum.InputTypeEnum.Input,
			name: 'email',
			placeholder: 'Email',
			label: 'Email',
			routes: 'email',
			size: 'lg',
		},
		resetPassword: {
			componentType: ComponentEnum.InputTypeEnum.Input,
			name: 'resetPassword',
			placeholder: 'Reset Password',
			label: 'Reset Password',
			routes: 'resetPassword',
			type: 'password', 
		},
		confirmPassword: {
			componentType: ComponentEnum.InputTypeEnum.Input,
			name: 'confirmPassword',
			placeholder: 'Confirm Password',
			label: 'Confirm Password',
			routes: 'confirmPassword',
			type: 'password', 
		},
	},
	typography: {
		resetHeader: {
			name: 'resetHeader',
			componentType: ComponentEnum.TypographyTypeEnum.Header,
		},
		forgotHeader: {
			name: 'forgotHeader',
			componentType: ComponentEnum.TypographyTypeEnum.Header,
		},
	},
	layout: {
		header: 'Reset Email',
		backEnabled: true,
		headerEnabled: true,
	},
};