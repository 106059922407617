import { ComponentEnum, icons } from '../../enums';
import { UIStoreInit } from '../../store';

export const uiState: UIStoreInit = {
	button: {
		button: {
			name: 'button',
			componentType: ComponentEnum.ButtonTypeEnum.Button,
		},
		iconbutton: {
			name: 'iconbutton',
			componentType: ComponentEnum.ButtonTypeEnum.Icon,
			icon: icons.delete,
		},
		link: {
			name: 'link',
			componentType: ComponentEnum.ButtonTypeEnum.Link,
		},
		btnSkip: {
			name: 'btnSkip',
			componentType: ComponentEnum.ButtonTypeEnum.Link,
			children: 'Skip',
			_text: {
				color: 'white',
				fontWeight: 600,
				fontSize: 14,
			},
		},
		test: {
			name: 'test',
			componentType: ComponentEnum.ButtonTypeEnum.Button,
			children: 'Redirect to login!',
		},
		redirectSignup: {
			name: 'redirectSignup',
			componentType: ComponentEnum.ButtonTypeEnum.Button,
			children: "Let's Get Started",
			style: {
				borderRadius: 20,
				marginHorizontal: 30,
			},
		},
		redirectLogin: {
			name: 'redirectLogin',
			componentType: ComponentEnum.ButtonTypeEnum.Link,
			children: 'Log In here',
			_text: {
				color: 'white',
				fontWeight: 600,
				fontSize: 14,
			},
		},
		redirectIOS: {
			name: 'redirectIOS',
			componentType: ComponentEnum.ButtonTypeEnum.Button,
			children: 'IOS',
			style: {
				marginTop: 20,
				marginLeft: 20,
				marginRight: 20,
			},
			isDisabled: true,
		},
		redirectAndroid: {
			name: 'redirectAndroid',
			componentType: ComponentEnum.ButtonTypeEnum.Button,
			children: 'Android',
			style: {
				marginTop: 20,
				marginLeft: 20,
				marginRight: 20,
			},
			isDisabled: true,
		},
		finishOnboard: {
			name: 'finishOnboard',
			componentType: ComponentEnum.ButtonTypeEnum.Button,
			children: 'Finish',
			style: {
				marginTop: 20,
				marginLeft: 20,
				marginRight: 20,
			},
			isDisabled: true,
		},
	},
	input: {
		test: {
			routes: 'testRoute',
			attr1: 'test',
			attr2: 'test',
			label: 'Redirect To Login!',
		},
		input: {
			componentType: ComponentEnum.InputTypeEnum.Input,
			routes: ['inputStore', 'input'],
		},
		select: {
			componentType: ComponentEnum.InputTypeEnum.Select,
			routes: ['inputStore', 'select'],
			items: [
				'test',
				'test2',
				'tset3',
				'select',
			]
		},
		switch: {
			componentType: ComponentEnum.InputTypeEnum.Switch,
			routes: ['inputStore', 'switch'],
		},
		textarea: {
			componentType: ComponentEnum.InputTypeEnum.TextArea,
			routes: ['inputStore', 'textarea'],
		},
	},
	typography: {
		header: {
			componentType: ComponentEnum.TypographyTypeEnum.Header,
			routes: ['typography', 'header'],
		},
		text: {
			componentType: ComponentEnum.TypographyTypeEnum.Text,
			routes: ['typography', 'text'],
		},
		onboardHeader: {
			componentType: ComponentEnum.TypographyTypeEnum.Header,
			color: '#FFF',
		}
	},
	layout: {
		// header: 'Strength Pro (In development)',
	},
};