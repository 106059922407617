import React from 'react';
import { FAInputProps } from './FAInputProps';
import { Input, InputGroup, InputLeftAddon, View } from 'native-base';
import { StyleSheet } from 'react-native';
import { FAText } from '../typography';
import * as util from '../../util';

export const FADate: React.FC<FAInputProps> = (props: FAInputProps) =>{
	const {
		name,
		placeholder,
		format,
		label,
		style,
		// service props
		value,
		setValue,
		getValue,
		uiReadOnly,
	} = props;
    
	const getStoreValue = () => {
		let storeValue = (getValue && getValue(props)) || value;
		const formatted = util.formatDate(storeValue, 'YYYY-MM-DD');
		return formatted;
	};

	const setStoreValue = (value: any) => {
		return setValue && setValue(value, props);
	};

	const readOnly = uiReadOnly ? uiReadOnly(name, props) : false;
	
	const attributes = {
		placeholder,
		style: {
			...style,
		},
		type: "date",
		id: "start",
		name: "trip-start",
		value: "2018-07-22",
		min: util.today().toString(),
		// max: "2018-12-31",
		readOnly,
	};

	attributes.value = getStoreValue();

	const handleChange = (evt: any) => {
		const value = evt.target.value;
		setStoreValue(value);
		console.log(value);
		console.log(evt);
	};

	console.log('Render FA Date', name);
	return (
		<div>
			<FAText name='label' style={styles.label}>{label}</FAText>
			<input
				onChange={handleChange}
				type="date"
				{...attributes}
			/>
		</div>
	);
};

const styles = StyleSheet.create({
	container: {
		flex: 1,
	},
	group: {
		flex: 1,
	},
	input: {
		flex: 1,
	},
	label: {
		margin: 5,
	},
});
