import { ComponentEnum, icons } from '../../enums';
import { UIStoreInit } from '../../store';

export const uiState: UIStoreInit = {
	button: {
		routineBtn: {
			componentType: ComponentEnum.ButtonTypeEnum.Button,
			icon: icons.arrowback,
			children: 'Routine',
		},
		exerciseBtn: {
			componentType: ComponentEnum.ButtonTypeEnum.Button,
			icon: icons.check,
			children: 'Exercise',
		},
		workoutBtn: {
			componentType: ComponentEnum.ButtonTypeEnum.Button,
			icon: icons.info,
			children: 'Workout',
		},
		onboardingBtn: {
			componentType: ComponentEnum.ButtonTypeEnum.Button,
			icon: icons.info,
			children: 'Onboarding',
		},
		weightBtn: {
			componentType: ComponentEnum.ButtonTypeEnum.Button,
			icon: icons.arrowforward,
			children: 'Weight',
		},
		routineScheduleBtn: {
			componentType: ComponentEnum.ButtonTypeEnum.Button,
			icon: icons.favourite,
			children: 'Schedule',
		},
		systemSettingsBtn: {
			componentType: ComponentEnum.ButtonTypeEnum.Button,
			icon: icons.favourite,
			children: 'System Settings',
		},
		logoutBtn: {
			componentType: ComponentEnum.ButtonTypeEnum.Button,
			children: 'Logout',
			style: {
				width: '100%',
				borderRadius: 20,
			},
		},
	},
	input: {
	},
	typography: {
		username: {
			componentType: ComponentEnum.TypographyTypeEnum.Header,
			routes: 'username',
		},
		email: {
			componentType: ComponentEnum.TypographyTypeEnum.Text,
			routes: 'email',
		},
	},
	layout: {
		menuEnabled: true,
		// header: 'Settings Page',
		menuBackground: 'white',
	},
};