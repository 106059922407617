import { modelData } from '../../../components/threejs/modelData';
import { getMuscleColor } from '../../../enums/colors';
import { serviceBase } from '../../../service';
import { CustomOptions } from '../../../service/api';
import { getAppStateData, UIStoreInit } from '../../../store';
import * as util from '../../../util';
import { isArray } from '../../../util';

export class WorkoutService extends serviceBase {
	constructor(name: string, store: any, uiState: UIStoreInit) {
		super(name, store, uiState);

		this.responseData = this.responseData.bind(this);
		this.getSubmitData = this.getSubmitData.bind(this);
		this.transformData = this.transformData.bind(this);

		this.loadData = this.loadData.bind(this);
		this.setWorkoutInfo = this.setWorkoutInfo.bind(this);
		this.addWorkingWorkout = this.addWorkingWorkout.bind(this);
		this.addEditWorkout = this.addEditWorkout.bind(this);
		this.deleteWorkingWorkout = this.deleteWorkingWorkout.bind(this);
		this.deleteEditWorkout = this.deleteEditWorkout.bind(this);
	}

	editWorkout() {
		const editExerciseTypeId = this.store?.getProperty('editExerciseTypeId');
		const editComment = this.store?.getProperty('editComment');
		const workout_log_id = this.store?.getProperty('workout_log_id');
		const workout_sets = this.store?.getProperty('editWorkingList') || [];
		if (!editExerciseTypeId) {
			this.errorNotification('Choose an exercise');
			return;
		}
		if (!workout_log_id) {
			this.errorNotification('Choose workout to edit!');
			return;
		}
		this.api.patchApiAsync(this.getUrl('workout'), { exercise_type_id: editExerciseTypeId, workout_comment: editComment, workout_log_id, workout_sets }, { name: 'editWorkout' });
	}

	addWorkout() {
		const addExerciseTypeId = this.store?.getProperty('addExerciseTypeId');
		const addComment = this.store?.getProperty('addComment');
		let workout_sets = this.store?.getProperty('workoutWorkingList') || [];
		if (!addExerciseTypeId) {
			this.errorNotification('Please choose an exercise!');
			return;
		}
		if (!isArray(workout_sets)) {
			this.errorNotification('Please add at least 1 rep!');
			return;
		}

		this.api.postApiAsync(this.getUrl('workout'), { exercise_type_id: addExerciseTypeId, workout_sets, workout_comment: addComment }, { name: 'addWorkout' });
	}

	addEditWorkout() {
		const reps = this.store?.getProperty('editExerciseReps');
		const weight = this.store?.getProperty('editExerciseWeight');
		if (!reps || !weight) {
			this.errorNotification('Add weight or reps!');
			return;
		}
		// set list new list
		this.store?.addGridRow('editWorkingList', [
			{
				set_weight: weight,
				set_reps: reps,
			}
		]);
	}

	addWorkingWorkout() {
		const reps = this.store?.getProperty('addExerciseReps');
		const weight = this.store?.getProperty('addExerciseWeight');
		if (!reps || !weight) {
			this.errorNotification('Add weight or reps!');
			return;
		}
		// set list new list
		this.store?.addGridRow('workoutWorkingList', [
			{
				set_weight: weight,
				set_reps: reps,
			}
		]);
		// clear working
		// this.store?.setProperty('addExerciseReps', 0);
		// this.store?.setProperty('addExerciseWeight', 0);
	}

	loadData() {
		this.api.getApiAsync(this.getUrl('workout'), { }, { name: 'loadWorkout' });
	}

	async loadExercises() {
		// load exercise data for select list
		await this.api.getApiAsync(this.getUrl('exercise'), { }, { name: 'loadExercise' });
	}

	loadRoutine() {
		// load routine data for select list
		this.api.getApiAsync(this.getUrl('routine'), { }, { name: 'loadRoutine' });
	}

	loadRoutineData() {
		this.api.getApiAsync(this.getUrl('allroutine'), { }, { name: 'loadAllRoutine' });
	}
	

	deleteEditWorkout(item) {
		const {
			id,
		} = item;
		console.log('id', item);
		this.store.deleteGridRow('editWorkingList', id);
	}
	

	deleteWorkingWorkout(item) {
		const {
			id,
		} = item;
		console.log('id', item);
		this.store.deleteGridRow('workoutWorkingList', id);
	}


	deleteWorkout(item) {
		const {
			workout_log_id,
		} = item;
		this.api.deleteApiAsync(this.getUrl('workout'), { workout_log_id }, { name: 'deleteWorkout' });
	}

	/**
     * get api data before api call
     * @param options 
     * @returns 
     */
	getSubmitData(options: CustomOptions) {
		const { name } = options;
		return {};
	}

	calculateTitle(data) {
		const {
			exercise_duration,
			exercise_info,
			exercise_type_id,
			exercise_weight_unit,
			log_date,
			workout_comment,
		} = data;

		const formattedDate = log_date;

		return `${formattedDate} - ${exercise_info}`;
	}

	/**
     * transform response function after api call 
     * @param data 
     * @param options 
     */
	transformData(data: any, options: CustomOptions) {
		const { name } = options;
		if (name === 'loginApi') {
            
			return data;
		}
		if (name === 'loadWorkout') {
			const exerciseList = this.store.getProperty('exerciseSelectList');
			if (util.isArray(data.data)) {
				data.data.forEach((row) => {
					const {
						exercise_type_id,
						// exercise_info,
						workout_sets,
					} = row;

					const exercise = (exerciseList as any[]).find((item) => item.value === exercise_type_id);
					if (exercise) {
						row.workout_title = exercise.label;
						row.exercise_muscles = exercise.exercise_muscles;
					}

					let infoStr: string;
					if (util.isArray(workout_sets)) {
						infoStr = workout_sets.map(({ set_weight: weight, set_reps: reps }) => `${reps} x ${weight}lbs.`).join('\n');
					}
					const date = util.formatDate(row.log_date);
					row.workout_subTitle = date + '\n' + infoStr;
					
				});
			}
			return data;
		}
		if (name === 'loadAllRoutine') {
			if (util.isArray(data.data)) {
				data.data.forEach((row) => {
					const {
						log_date,
					} = row;
					if (log_date) row.log_date = util.formatDate(log_date);
				});
			}
		}
		return data;
	}

	/**
     * onClick fn handler
     * @param value 
     * @param data 
     * @param props 
     */
	onClick(value: any, data: any, props: any) {
		const { name } = props;
		switch (name) {
		case 'loadBtn':
			this.loadData();
			break;
		case 'workoutList':
			this.calculateMuscle(data);
			break;
		case 'workoutList-editbtn': {
			this.store?.setProperty('workout_log_id', data.workout_log_id);
			this.store?.setProperty('editExerciseTypeId', data.exercise_type_id);
			this.store?.setProperty('editExerciseWeight', data.exercise_weight);
			this.store?.setProperty('editExerciseReps', data.exercise_reps);
			this.store?.setProperty('editComment', data.workout_comment);
			// construct array
			let array = [];
			if (util.isArray(data.workout_sets)) {
				array = data.workout_sets;
			}
			this.store.setGrid('editWorkingList', array);
			this.openModal('editModal');
			break;
		}
		case 'workoutList-btn':
			this.deleteWorkout(data);
			break;
		case 'workoutWorkingList-btn':
			this.deleteWorkingWorkout(data);
			break;
		case 'editWorkingList-btn':
			this.deleteEditWorkout(data);
			break;
		case 'addNewBtn':
			this.openModal('addModal');
			break;
		case 'addBtn':
			this.addWorkingWorkout();
			break;
		case 'editAddBtn':
			this.addEditWorkout();
			break;
		case 'saveBtn':
			this.addWorkout();
			break;
		case 'createExercise':
			this.redirectTo(this.getPath('exercise'));
			break;
		case 'createRoutine':
			this.redirectTo(this.getPath('routine'));
			break;
		case 'startBtn': {
			const routine_id = this.store.getProperty('routineId');
			this.redirectTo(this.getPath('routinestart'), { routine_id, addNew: true });
			break;
		}
		case 'routineList-editbtn': {
			const {
				routine_log_id,
				routine_id,
			} = data;
			this.redirectTo(this.getPath('routinestart'), { routine_log_id, routine_id });
			break;
		}
		default:
			break;
		}
	}

	/**
     * response function after api call
     * @param data 
     * @param options 
     */
	responseData(data: any, options: CustomOptions): void {
		const { name } = options;
		if (name === 'loadWorkout') {
			if (data.success) {
				this.setWorkoutInfo(data.data);
			} else {
				this.errorNotification('error!');
			}
		}
		if (name === 'loadAllRoutine') {
			if (data.success) {
				this.setAllRoutineInfo(data.data);
			} else {
				this.errorNotification('error!');
			}
		}
		if (name === 'loadExercise') {
			if (data.success) {
				this.setExerciseInfo(data.data);
			} else {
				this.errorNotification('error!');
			}
		}
		if (name === 'loadRoutine') {
			if (data.success) {
				this.setRoutineInfo(data.data);
			} else {
				this.errorNotification('error!');
			}
		}
		if (name === 'addWorkout') {
			if (data.success) {
				// reset list
				this.store?.setProperty('addComment', '');
				this.store?.setProperty('addExerciseWeight', 25);
				this.store?.setProperty('workoutWorkingList', []);
				this.loadData();
			}
		}
		if (name === 'editWorkout' || name === 'deleteWorkout') {
			if (data.success) {
				this.loadData();
			}
		}
	}

	setWorkoutInfo(data: any) {
		// set data to input
		// this.store.setProperty('workoutTest', JSON.stringify(data));
		if (util.isArray(data)) this.store.setProperty('workoutList', data);
	}
	setAllRoutineInfo(data: any) {
		// set data to input
		if (util.isArray(data)) this.store.setProperty('routineList', data);
	}

	calculateMuscle(rowItem: any) {
		const {
			exercise_muscles
		} = rowItem;
		const hashMuscle: any = {};
		if (!exercise_muscles) return;

		if (util.isArray(exercise_muscles)) {
			exercise_muscles.forEach((muscleData) => {
				const {
					muscle_id: id,
				} = muscleData;
				if (id) {
					hashMuscle[id] = muscleData;
				}
			});
		}
		modelData.forEach((item) => {
			if (hashMuscle[item.id]) {
				const {
					intensity: value = 0,
				} = hashMuscle[item.id];
				if (util.isNumberNonStrict(value)) {
					this.updateModelColor(item.id, value, false);
				}
			}
		});
	}

	updateModelColor(id, value, moveCamera = true) {
		const color = getMuscleColor(value);
		this.callComponentHook('pageLayout', 'updateColor', id, color, moveCamera);
	}



	setExerciseInfo(data: any) {
		if (util.isArray(data)) {
			data.forEach((item) => {
				item.value = item.exercise_type_id;
				item.label = item.exercise_type_name;
			});
			// set to select store items ui name
			this.uiStore.setProperty(['input', 'addExerciseTypeId', 'items'], data);
			this.uiStore.setProperty(['input', 'editExerciseTypeId', 'items'], data);
			this.store.setProperty(['exerciseSelectList'], data);
		}
	}

	setRoutineInfo(data: any) {
		if (util.isArray(data)) {
			data.forEach((item) => {
				item.value = item.routine_id;
				item.label = item.routine_name;
			});
			// set to select store items ui name
			this.uiStore.setProperty(['input', 'routineId', 'items'], data);
			this.store.setProperty(['routineSelectList'], data);
		}
	}

	/**
     * onOk fn handler
     * @param value 
     * @param data 
     * @param props 
     */
	onOk(value: any, data: any, props: any) {
		const { name } = props;
		switch (name) {
		case 'editModal':
			this.editWorkout();
			break;
		default:
			break;
		}
	}

	/**
     * onCancel fn handler
     * @param value 
     * @param data 
     * @param props 
     */
	onCancel(value: any, data: any, props: any) {
		const { name } = props;
		switch (name) {
		case 'editModal':
			// clear
			this.store.setProperty('editExercise', '');
			this.store.setProperty('exercise_type_id', '');
			this.store.setProperty('exercise_type', '');
			break;
		case 'addModal':
			// clear
			this.store.setProperty('addExercise', '');
			break;
		default:
			break;
		}
	}
}