import { StyleSheet } from "react-native";
import { themeColors } from "./colors";

export const textStyle = StyleSheet.create({
    largeTitle:{
        fontSize: 26,
    },
    title:{
        fontSize: 24,
        fontWeight: '600',
    },
    smallTitle:{
        fontSize: 20,
    },
    h1:{
        fontSize: 18,
    },
    h2:{
        fontSize: 16,
    },
    h3:{
        fontSize: 14,
    },
    btnText_Regular: {
        fontSize: 16,
        fontWeight: '600',
    }
}) 

export const btnStyle = StyleSheet.create({
    primary:{
        backgroundColor: themeColors.primaryBase,
        Color: themeColors.secondaryBase,
        borderRadius: 30,
        fontSize: 16,
    },
    accent:{
        backgroundColor: themeColors.accent,
        Color: themeColors.secondaryBase,
        borderRadius: 30,
        fontSize: 16,
    },
    gradient:{
        backgroundColor: themeColors.gradient,
        Color: themeColors.secondaryBase,
        borderRadius: 30,
    },
    secondary:{
        backgroundColor: themeColors.secondaryBase,
        Color: themeColors.white,
        borderRadius: 30,
    },
    delete:{
        backgroundColor: themeColors.error,
        Color: themeColors.trueBlack,
        borderRadius: 30,
    },
    info_blue:{
        backgroundColor: themeColors.white,
        Color: themeColors.secondaryBase,
        border: `1px solid ${themeColors.secondaryBase}`,
        borderRadius: 30,
    },
    info_white:{
        backgroundColor: 'transparent',
        Color: themeColors.white,
        border: `1px solid ${themeColors.white}`,
        borderRadius: 30,
    }
}) 

