import React from 'react';
import { Heading } from 'native-base';
import { FATypographyProps } from './FATypographyProps';

export const FAHeaderComponent: React.FC<FATypographyProps> = (props: FATypographyProps) => {
	const {
		name,
		children,
		italic,
		bold,
		underline,
		strikeThrough,
		sub,
		highlight,
		style,
		color,
		getValue,
		size,
		...otherProps
	} = props;
    
	const getStoreValue = () => {
		return (getValue && getValue(props)) || children;
	};

	const attributes: any = {
		name,
		children,
		italic,
		bold,
		underline,
		strikeThrough,
		sub,
		color,
		highlight,
		style,
		size,
		...otherProps,
	};

	attributes.children = getStoreValue();
    
	console.log('Render Header', name);
	return (
		<Heading {...attributes} />
	);
};

export const FAHeader = React.memo(FAHeaderComponent);