import React from 'react';
import { View, StyleSheet } from 'react-native';
import { useSupportService } from './service';
import { uiState } from './support.ui';
import { SupportStore } from './support.store';
import { FAHeader, FALink, FAStoreButton, FAStoreInput, FAText, PageLayout } from '../../components';
import { useServiceRouter } from '../../service';

export const Support: React.FC = () => {
	const screenName = 'SupportPage';
	const service = useSupportService(screenName, SupportStore, uiState);
	useServiceRouter(service);

	console.log('Render Support screen');
	return (
		<PageLayout>
			<View style={styles.layout}>
				<View style={styles.item}>
					<FAText name='header'>Please write down the question, issue, or problem you are facing along with your email so we can get back to you! Thank you!</FAText>
				</View>
				<View style={styles.item}>
					<FAStoreInput name='email' />
				</View>
				<View style={styles.item}>
					<FAStoreInput name='supportDescription' />
				</View>
				<View style={styles.item}>
					<FAStoreButton name='submitBtn' />
				</View>
			</View>
		</PageLayout>
	);
};
const styles = StyleSheet.create({
	layout: {
		height: '100%',
		display: 'flex',
		flexDirection: 'column',
		margin: 5,
	},
	item: {
		padding: 5,
	},
});