export enum ModelMuscles {
    Calves,
    Hamstrings,
    Quads,
    Glutes,
    Abs,
    Chest,
    UpperBack,
    LowerBack,
    Traps,
    Shoulders,
    Biceps,
    Triceps,
    Forearms
}
