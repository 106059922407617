import { modelData } from "../../components/threejs/modelData";
import { ModelMuscles } from "../../enums";

export const ExerciseGoalStore = {
	uselast: false,
	routine_exercise_id: '',
	exerciseName: '',
	exercise_goal_id: '',
	exercise_goal_type: 0,
	exercise_weight_goal: 0,
	exercise_weight_date: '',
	progression_rate: 0,
	numOfWorkouts: 0,
	progression_message: '',
	predictionList: [],
	exerciseList: [],
	// exercise info
	repFrom: 1,
	repTo: 10,
	setCount: 3,
	initialWeight: 10,
	increment: 10,
};
