import React from 'react';
import { Slider, View } from "native-base";
import { StyleSheet } from 'react-native';
import { useComponentRefresh } from "../../hooks";
import { FAText } from "../typography";
import { FAInputProps } from './FAInputProps';
import { formatNumber } from '../../util';

export const FASlider: React.FC<FAInputProps> = (props: FAInputProps) => {
	const {
        name,
		// index,
		// data,
		label,
		// subtitle,
		// setValue,
		// readOnly = false,
		setValue,
		getValue,
		uiReadOnly,
        onChange,
        minValue = 0,
        maxValue = 100,
        step = 10,
		showSliderSummary = false,
		value = 0,
	} = props;

	const [refresh] = useComponentRefresh();
    
	const getStoreValue = () => {
		let storeValue = (getValue && getValue(props)) || value;
        
        storeValue = formatNumber(storeValue);
            
		return storeValue;
	};

	const setStoreValue = (value: any) => {
        onChange(value, value, props);
		return setValue && setValue(value, props);
	};

	const readOnly = uiReadOnly ? uiReadOnly(name, props) : false;
	
	const storeValue = getStoreValue();

	return (
		<View style={styles.row}>
			{label && <FAText name='label' style={styles.label}>{label}</FAText>}
			<View style={styles.infoSection}>
				<View style={styles.slider}>
					<Slider 
						value={storeValue}
						minValue={minValue}
						maxValue={maxValue}
						accessibilityLabel="hello world"
						step={step}
						onChange={setStoreValue}
						isReadOnly={readOnly}
					>
						<Slider.Track>
						<Slider.FilledTrack />
						</Slider.Track>
						<Slider.Thumb />
					</Slider>
				</View>
				{showSliderSummary && (
					<View style={styles.summary}>
						<FAText name='summary'>{`${storeValue} / ${maxValue}`}</FAText>
					</View>
				)}
			</View>
		</View>
	);
};

const styles = StyleSheet.create({
	container: {
		margin: 10,

	},
	rowItem: {
		flex: 1,
		flexDirection: 'row',
		borderBottomColor: 'grey',
		borderBottomWidth: 1,
		justifyContent: 'center',
		alignContent: 'center',
	},
	infoSection: {
		flex: 1,
		marginHorizontal: 10,
		marginVertical: 4,
		display: 'flex',
		flexDirection: 'row',
	},
	slider: {
		flexGrow: 1,
	},
	summary: {
		paddingLeft: 5,
	},
	title: {
	},
	button: {
		justifyContent: 'center',
		alignContent: 'center',
	},
	subtitle: {
	},
	row: {
		flex: 1,
		flexDirection: 'row',
		padding: 5,
	},
	label: {
		minWidth: 60,
	},
})