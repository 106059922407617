import { getService, useService } from '../../../service';
import { UIStoreInit } from '../../../store';
import { MarketingService } from './MarketingService';

/**
 * get service - create if not created yet
 * @param name 
 * @param store 
 * @param uiState 
 * @returns 
 */
export const useMarketingService = (name: string, store: any, uiState: UIStoreInit) => {
	const service = getService(name) || new MarketingService(name, store, uiState);
	useService(name, service);
	return service as MarketingService;
};
