
export const RoutineScheduleStore = {
	routine_description: '',
	routine_name: '',
	exercises: [],
	exerciseSelectList: [],
	exerciseTypeId: '',
	routine_id: '', // selected routine to start
	routineId: '', // for input

	// days of the week
	monday: [
	], // { routine_name, routine_id }
	tuesday: [
	], // { routine_name, routine_id }
	wednesday: [
	],
	thursday: [],
	friday: [],
	saturday: [],
	sunday: [],

	// routine schedule modal store
	editSchedule: {
		monday: false,
		tuesday: false,
		wednesday: false,
		thursday: false,
		friday: false,
		saturday: false,
		sunday: false,
	},
	// api info
	routineSchedules: [],
	//
	is_onboarded: false,
};
