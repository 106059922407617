import { Spinner } from 'native-base';
import React from 'react';
import { View, StyleSheet } from 'react-native';
import { FAText } from '../components';
import { themeColors } from '../enums';

/**
 * mask component
 * @returns 
 */
export const Mask: React.FC = () => {
	return (
		<View style={styles.mask}>
			<Spinner color={themeColors.primaryBase} size='lg' />
		</View>
	);
};
const styles = StyleSheet.create({
	mask: {
		justifyContent: 'center',
		alignContent: 'center',
		width: '100%',
		flex: 1,
	},
});
