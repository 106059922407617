import React, { useEffect, useState } from 'react';
import { FAModalProps } from './FAModalProps';
import { Actionsheet, Box, Button, Modal, Pressable, View } from 'native-base';
import { Dimensions, StyleSheet } from 'react-native';
import { FAButton, FAIconButton } from '../button';
import { icons } from '../../enums';

/**
 * this will be a action sheet (special because will be used for model purpose)
 * when closed the children will be displayed on the top left corner,
 * otherwise children will show in the action sheet
 * @param props 
 * @returns 
 */
export const FAActionSheet: React.FC<FAModalProps> = (props: FAModalProps) =>{
	const {
		name,
		title,
		cancelText = 'Cancel',
		cancelColor='blueGray',
		okColor,
		okText = 'Ok',
		hideDragIndicator,
		disableOverlay,
		// special
		childrenStyle,
		component: Component,
		componentProps = {},
		// service props
		children = <></>,
		onOk,
		onCancel,
		addComponentHook,
		// 
		forceUmmount = true,
	} = props;

	const [isOpen, setIsOpen] = useState(false);

	const openModal = () => {
		setIsOpen(true);
	}

	const onOkFn = () => {
		onOk?.call(null, null, null, props);
		setIsOpen(false);
	};

	const onCancelFn = () => {
		onCancel?.call(null, null, null, props);
		setIsOpen(false);
	};

	useEffect(() => {
		// add component hook
		addComponentHook?.call(null, name, 'open', openModal);
	}, []);
	
	const attributes: React.ComponentProps<typeof Actionsheet> = {
		hideDragIndicator,
		disableOverlay,
	};

	attributes.isOpen = isOpen;
	attributes.onClose = onCancelFn;

	console.log('Render Action Sheet', name);

	if (forceUmmount && !isOpen) {
		return null;
	}
	return (
		<Actionsheet {...attributes}>
			<Actionsheet.Content style={{ height: '100%' }}>
				<Box style={styles.actionsheet}>
					{Component && <Component {...componentProps} />}
				</Box>
				<Box style={styles.footer}>
					<Box style={styles.buttons}>
						<FAButton name='cancel' variant='ghost' onClick={onCancelFn}>{cancelText}</FAButton>
						<FAButton name='ok' variant='ghost' onClick={onOkFn}>{okText}</FAButton>
					</Box>
				</Box>
			</Actionsheet.Content>
		</Actionsheet>
	);
};

const styles = StyleSheet.create({
	container: {
		flexDirection: 'row',
		height: '100%',
		width: '100%',
	},
	childrenStyle: {
		width: 100,
		height: '100%',
	},
	actionsheet: {
		width: '100%',
		height: Dimensions.get('window').height / 2,
	},
	footer: {
		flexDirection: 'column',
		flex: 1,
		width: '100%',
	},
	buttons: {
		flexDirection: 'row',
		justifyContent: 'flex-end',
		flex: 1,
		width: '100%',
		margin: 5,
	},
});
