import { paths } from '../../../enums';
import { serviceBase } from '../../../service';
import { CustomOptions } from '../../../service/api';
import { getAppStateData, getAppStateStore, setAppStateStore, UIStoreInit } from '../../../store';

export class DeleteUserService extends serviceBase {
	constructor(name: string, store: any, uiState: UIStoreInit) {
		super(name, store, uiState);

		this.responseData = this.responseData.bind(this);
		this.getSubmitData = this.getSubmitData.bind(this);
		this.transformData = this.transformData.bind(this);

		this.login = this.login.bind(this);
		this.setLoginInfo = this.setLoginInfo.bind(this);
	}

	startPage() {
		// if username exist set default
		const username = getAppStateData('username');
		if (username) {
			this.store.setProperty('loginId', username);
		} else {
			this.store.setProperty('loginId', '');
		}
	}

	login() {
		// validate first
		const loginId = this.store.getProperty('loginId');
		const password = this.store.getProperty('password');

		if (!loginId) {
			this.errorNotification('Please enter username/email');
			return;
		}

		if (!password) {
			this.errorNotification('Please enter password');
			return;
		}

		// startLoading
		this.startBtnLoading('loginBtn');
		this.api.postApiAsync(this.getUrl('login'), { loginId, password }, { name: 'loginApi', otherOptions: { onErrorFn: () => { this.endBtnLoading('loginBtn'); }} });
	}

	/**
     * get api data before api call
     * @param options 
     * @returns 
     */
	getSubmitData(options: CustomOptions) {
		const { name } = options;
		return {};
	}

	/**
     * transform response function after api call 
     * @param data 
     * @param options 
     */
	transformData(data: any, options: CustomOptions) {
		const { name } = options;
		if (name === 'loginApi') {
            
			return data;
		}
		return data;
	}

	/**
     * onOk fn handler
     * @param value 
     * @param data 
     * @param props 
     */
	onOk(value: any, data: any, props: any) {
		const { name } = props;
		switch (name) {
		case 'confirmDelete':
			this.deleteData();
			break;
		default:
			break;
		}
	}

	deleteData() {
		const username = this.store.getProperty('username');
		const email = this.store.getProperty('email');
		const token = this.store.getProperty('token');
		const validPassword = this.store.getProperty('validPassword');

		// call some api
		this.api.deleteApiAsync(this.getUrl('deleteUser'), { password: validPassword, token: token }, { name: 'deleteUser' });
	}

	/**
     * onCancel fn handler
     * @param value 
     * @param data 
     * @param props 
     */
	onCancel(value: any, data: any, props: any) {
		const { name } = props;
		switch (name) {
		case 'confirmDelete': {
			// clear login
			break;
		}
		default:
			break;
		}
	}

	/**
     * onClick fn handler
     * @param value 
     * @param data 
     * @param props 
     */
	onClick(value: any, data: any, props: any) {
		const { name } = props;
		switch (name) {
		case 'loginBtn':
			this.login();
			break;
		default:
			break;
		}
	}

	/**
     * response function after api call
     * @param data 
     * @param options 
     */
	responseData(data: any, options: CustomOptions): void {
		const { name } = options;
		if (name === 'loginApi') {
			if (data.success) {
				this.setLoginInfo(data.data);
			} else {
				this.errorNotification('Invalid login details!');
			}
			// end loading
			this.endBtnLoading('loginBtn');
		}
		if (name === 'deleteUser') {
			console.log(data);
			if (data.success) {
				this.successNotification('User was deleted!');
				// remove app state
				setAppStateStore('Authorization', '');
				setAppStateStore('email', '');
				setAppStateStore('username', '');
				setAppStateStore('type', '');
			} else {
				console.log('delete user fail');
			}
			this.successNotification('Successfully deleted data!');
		}
	}

	setLoginInfo(data: any) {
		const {
			user: {
				token,
				user_email: email,
				user_name: username,
				user_type: type,
			}
		} = data;
		this.store.setProperty('email', email);
		this.store.setProperty('username', username);
		const password = this.store.getProperty('password');
		this.store.setProperty('validPassword', password);
		this.store.setProperty('token', token);
		// clear
		this.store.setProperty('loginId', '');
		this.store.setProperty('password', '');

		this.openModal('confirmDelete');
	}
}