import { Dimensions } from 'react-native';
import { ComponentEnum, icons, themeColors } from '../../enums';
import { UIStoreInit } from '../../store';
import { addNewBtn, saveBtn, addBtn } from '../common';

export const uiState: UIStoreInit = {
	button: {
		saveBtn,
		addBtn,
		loadLastBtn: {
			name: 'loadLastBtn',
			componentType: ComponentEnum.ButtonTypeEnum.Button,
			children: 'Load Last',
			size: 'md',
		},
		prevExercise: {
			name: 'prevExercise',
			componentType: ComponentEnum.ButtonTypeEnum.Icon,
			icon: icons.arrowback,
			colorScheme: themeColors.thirdColorScheme,
			style: {
				flexGrow: 1,
			},
		},
		nextExercise: {
			name: 'nextExercise',
			componentType: ComponentEnum.ButtonTypeEnum.Icon,
			icon: icons.arrowforward,
			colorScheme: themeColors.thirdColorScheme,
			style: {
				flexGrow: 1,
			},
		},
		workoutAddBtn: {
			name: 'workoutAddBtn',
			children: 'Add+',
			size: 'md',
		},
		editRoutine: {
			name: 'editRoutine',
			componentType: ComponentEnum.ButtonTypeEnum.Icon,
			icon: icons.edit,
			style: {
				padding: 0,
				paddingLeft: 5,
			},
		},
		editExercise: {
			name: 'editExercise',
			componentType: ComponentEnum.ButtonTypeEnum.Icon,
			icon: icons.edit,
			style: {
				padding: 0,
				paddingLeft: 5,
			},
		},
		editGoal: {
			name: 'editGoal',
			componentType: ComponentEnum.ButtonTypeEnum.Icon,
			icon: icons.edit,
			style: {
				padding: 0,
				paddingLeft: 5,
			},
		},
		btnDeleteAllSet: {
			name: 'btnDeleteAllSet',
		},
		btnAddSets: {
			name: 'btnAddSets',
			children: 'Add Sets',
			backgroundColor: 'white',
			_text: {
				color: 'gray.600'
			},
			// icon: icons.add
			borderColor: 'gray.600',
			borderWidth: 4,
			borderRadius: 20,
			width: '50%',
		},
		btnFinishSets: {
			name: 'btnFinishSets',
			children: 'Finish Sets',
			backgroundColor: 'white',
			_text: {
				color: 'gray.600'
			},
			// icon: icons.add
			borderColor: 'gray.600',
			borderWidth: 4,
			borderRadius: 20,
			width: '50%',
		},
	},
	input: {
		exerciseTypeId: {
			name: 'exerciseTypeId',
			componentType: ComponentEnum.InputTypeEnum.Select,
			routes: 'exerciseTypeId',
			placeholder: 'Exercise',
			label: 'Exercise',
			items: [],
		},
		editExerciseWeight: {
			name: 'editExerciseWeight',
			routes: 'editExerciseWeight',
			placeholder: 'Weight',
			label: 'Weight',
			format: 'number',
		},
		editExerciseReps: {
			name: 'editExerciseReps',
			routes: 'editExerciseReps',
			placeholder: 'Reps',
			label: 'Reps',
			format: 'number',
		},
		workoutExerciseWeight: {
			name: 'workoutExerciseWeight',
			routes: 'workoutExerciseWeight',
			placeholder: 'Weight',
			label: 'Weight',
			format: 'number',
			style: {
				flexGrow: 1,
				width: '100%'
			},
		},
		workoutExerciseReps: {
			name: 'workoutExerciseReps',
			routes: 'workoutExerciseReps',
			placeholder: 'Reps',
			label: 'Reps',
			format: 'number',
			style: {
				flexGrow: 1,
				width: '100%'
			},
		},
		workoutComment: {
			name: 'workoutComment',
			routes: 'workoutComment',
			placeholder: 'Workout Comment',
			label: 'Workout Comment',
		},
		routineComment: {
			name: 'routineComment',
			routes: 'routine_comment',
			placeholder: 'Routine Comment',
			label: 'Routine Comment',
		},
	},
	typography: {
		routine_name: {
			name: 'routine_name',
			componentType: ComponentEnum.TypographyTypeEnum.Header,
			routes: 'routine_name',
		},
		routine_description: {
			name: 'routine_description',
			routes: 'routine_description',
			italic: true,
		},
		exercise_type_name: {
			name: 'exercise_type_name',
			componentType: ComponentEnum.TypographyTypeEnum.Text,
			routes: 'exercise_type_name',
			bold: true,
		},
	},
	layout: {
		// menuEnabled: true,
		// modelEnabled: true,
		// header: ' ',
		// backEnabled: true,
		// settingsEnabled: true,
		// btns: ['saveBtn'],
	},
	grid: {
		exercises: {
			name: 'exercises',
			routes: 'exercises',
			titleData: 'exercise_type_name',
			subtitleData: 'exercise_type_description',
		},
		progressList: {
			name: 'progressList',
			routes: 'progressList',
			matchName: 'routine_exercise_id',
			currentFocus: 'routine_exercise_id',
		},
		workoutWorkingList: {
			name: 'workoutWorkingList',
			componentType: ComponentEnum.GridTypeEnum.Simple,
			routes: 'workoutWorkingList',
			titleData: 'set_reps',
			titleUnits: 'Reps',
			subtitleData: 'set_weight',
			subtitleUnits: 'lbs.',
			editBtn: true,
		},
		simpleList: {
			name: 'simpleList',
			componentType: ComponentEnum.GridTypeEnum.Simple,
			routes: 'simpleList',
			idData: 'startWorkout_log_id',
		},
	},
	modal: {
		selectExercise: {
			name: 'selectExercise',
			componentType: ComponentEnum.ModalTypeEnum.Full,
			forceUmmount: true,
			height: Dimensions.get('window').height,
		},
	},
};
