import React, { useEffect, useState } from 'react';
import { FAModalProps } from './FAModalProps';
import { Button, Modal, ScrollView, View } from 'native-base';
import { Motion } from "@legendapp/motion";
import { Dimensions, StyleSheet } from 'react-native';
import { FAButton, FAIconButton, FAStoreButton } from '../button';
import { ComponentEnum, icons, themeColors } from '../../enums';
import { modelData } from '../threejs/modelData';
import { FAText } from '../typography';
import { ModelItemType } from '../threejs/ModelItem';

export const margin = 25;
export const width = Dimensions.get('window').height / 12;
export const height = width * 7;

export const FAModalMenu: React.FC<FAModalProps> = (props: FAModalProps) =>{
	const {
		name,
		title,
		cancelText = 'Cancel',
		cancelColor='blueGray',
		okColor,
		okText = 'Ok',
		// service props
		children = <></>,
		onOk,
		onCancel,
		addComponentHook,
	} = props;

	const [isOpen, setIsOpen] = useState(false);

	const openModal = () => {
		setIsOpen(true);
	}

	const onOkFn = () => {
		onOk?.call(null, null, null, props);
		setIsOpen(false);
	};

	const onCancelFn = () => {
		onCancel?.call(null, null, null, props);
		setIsOpen(false);
	};

	useEffect(() => {
		// add component hook
		addComponentHook?.call(null, name, 'open', openModal);
	}, []);
	

	const Component: React.FC<any> = (muscle: ModelItemType) => {

		const buttonText = (muscle.id as string).slice(0, 3).toUpperCase();
		return (
			<FAStoreButton
				componentType={ComponentEnum.ButtonTypeEnum.Button}
				name={muscle.id}
				style={styles.buttonStyle}
				children={buttonText}
				fontSize={'20pt'}
				fontWeight={700}
				variant='ghost'
				size='lg'
				textColor='white'
			/>
		);
	}

	console.log('Render FA Modal Menu', name);
	return (
		<Motion.View
			style={styles.container}
			animate={{
				height: isOpen ? height: width
			}}
		>
			<ScrollView style={styles.scroll} borderRadius={20}>
				{modelData.filter((item) => !item.notMuscle).map((md) => <Component {...md} key={md.id} />)}
			</ScrollView>
			<FAIconButton 
				name='menuOpen'
				onClick={() => {
					// toggle button
					if (isOpen) return onCancelFn();
					return openModal();
				}}
				style={styles.openBtn}
				color={themeColors.accent}
				icon={isOpen ? icons['minus'] : icons['add']}
			/>
		</Motion.View>
	);
};

const styles = StyleSheet.create({
	container: {
		position: 'absolute',
		width: width,
		bottom: margin,
		right: margin,
		display: 'flex',
		flexDirection: 'column',
		justifyContent: 'flex-end',
		overflow: 'scroll',
	},
	scroll: {
		borderRadius: 20,
	},
	buttonStyle: {
		height: width,
		width: width,
		backgroundColor: themeColors.secondaryBase,
		borderRadius: 0,
		opacity: .8,
	},
	openBtn: {
		height: width,
		width: width,
		marginTop: 10,
		borderRadius: 15,
		backgroundColor: themeColors.accent,
	}
});

