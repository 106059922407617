
// will contain all app info sharable to whole app

import { MobxStore } from './store';

// token info, settings info
const appStateStoreEnum = {
	Authorization: 'Authorization',
	username: 'username',
	email: 'email',
	type: 'type',
} as const;

export type AppStateStoreInit = Partial<Record<keyof typeof appStateStoreEnum, any>>;

const storeInit: AppStateStoreInit = {
	Authorization: '',
	username: '',
	email: '',
	type: '',
};

let AppStateStore: MobxStore;

export const getAppStateStore = () => {
	if (!AppStateStore) AppStateStore = new MobxStore('appState', storeInit, true);
	return AppStateStore;
};

export const setAppStateStore = (routes: string | string[], value) => {
	const store = getAppStateStore();
	store.setProperty(routes, value);
};

export const getAppStateData = (routes: string | string[]) => {
	const store = getAppStateStore();
	return store.getProperty(routes);
};

export const useAppStateData = (routes: string | string[]) => {
	const store = getAppStateStore();
	return store.useDataProperty(routes);
};
