import { ComponentEnum } from '../../enums';
import { UIStoreInit } from '../../store';

export const uiState: UIStoreInit = {
	button: {
		workoutPage: {
			name: 'workoutPage',
			children: 'redirect to workout',
		},
		loginBtn: {
			name: 'loginBtn',
			children: 'Delete info from this User',
			size: 'lg',
		},
	},
	input: {
		loginId: {
			componentType: ComponentEnum.InputTypeEnum.Input,
			name: 'loginId',
			placeholder: 'Login ID',
			label: 'Login ID',
			routes: 'loginId',
			size: 'lg',
		},
		password: {
			componentType: ComponentEnum.InputTypeEnum.Input,
			placeholder: 'Password',
			name: 'password',
			label: 'Password',
			routes: 'password',
			btnName: 'Login',
			size: 'lg',
			type: 'password', 
		},
		passwordBtn: {
			name: 'passwordBtn',
			label: 'passwordBtn',
			routes: 'passwordBtn',
			btnName: 'Login',
			type: 'passwordBtn', 
		},
	},
	typography: {},
	layout: {
		header: 'Delete all user info',
		headerEnabled: true,
		backEnabled: true,
	},
	modal: {
		confirmDelete: {
			title: 'Are you sure you want delete? This is irreversible!',
			okText: 'Yes Delete',
		},
	},
};