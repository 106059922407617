import React, { useEffect } from 'react';
import { StyleSheet, Dimensions } from 'react-native';
import { useRoutineDetailService } from './service';
import { uiState } from './routineDetail.ui';
import { RoutineDetailStore } from './routineDetail.store';
import { FAStoreTypography, FAStoreInput, PageLayout, FAHeader, FAStoreGrid, FAStoreButton, FAStoreModal, FAText, FAButton } from '../../components';
import { FAInput } from '../../components/input/FAInput';
import { getService, useServiceRouter } from '../../service';
import { StoreThreeHumanModel, ThreeHumanModel } from '../../components/threejs';
import { View, ScrollView, Pressable } from 'native-base';
import { FASwitchList } from '../../components/grid/FASwitchList';
import * as util from '../../util';
import { FAModalInput } from '../../components/modal/FAModalInput';
import { useComponentRefresh } from '../../hooks';
import { useStoreGridHooks } from '../../hooks/component';
import { ComponentEnum, icons } from '../../enums';
import { ExerciseSelect } from '../Exercise/Exercise';
import { RoutineScheduleModal } from './extra';
import { ExerciseGoalType } from '../../enums/enums';

const RenderSimpleItem: React.FC<any> = (props: any) => {
	const {
		index,
		item = {},
		item: {
			id = '',
		},
		seperators,
	} = props;

	const [refresh] = useComponentRefresh();
	

	const gridProps = useStoreGridHooks('exercises', id);

	// get grid row
	const service = getService();

	// get row
	const row = service.store.getGridRow('exercises', id);
	
	const {
		open = false,
		workout_sets = [],
		goalData = {},
	} = row || {};

	const goalExist = util.isObject(goalData);

	const {
		exercise_info: {
			repFrom = 0,
			repTo = 0,
			setCount = 0,
		} = {},
		exercise_weight_goal = 0,
		exercise_goal_type = 0,
	} = goalExist ? goalData : {};

	const useLast = goalExist && exercise_goal_type === ExerciseGoalType.UseLast;

	const onClick = (evt) => {
		if (open) {
			// already open
			return;
		}
		service.onClick(evt, id, { name: 'exercises', rowClick: true });
	};

	if (open) {
		return (
			<Pressable
				style={styles.gridRowOpen}
				shadow='9'
				borderRadius={20}
				margin={1}
				padding={2}
				backgroundColor={'white'}
				maxH={150}
				onPress={(evt) => onClick(evt)}
			>
				<View style={styles.openLeft}>
					<FAStoreTypography
						name='exercise_type_name'
						bold
						fontWeight={20}
						{...gridProps}
					/>
					<FAStoreTypography
						name='exercise_type_description'
						{...gridProps}
					/>
				</View>
				<View
					style={styles.openRight}
					backgroundColor={'gray.200'}
					borderRadius={20}
					padding={2}
					margin={1}
				>
					<View style={styles.col1}>
						<View style={styles.goalContainer}>
							<FAStoreButton name='goalIcon' {...gridProps} />
							<FAHeader name='rightHeader' >Goal</FAHeader>
						</View>
						<FAHeader name='rightHeader'>{
							goalExist ? (
								useLast ? (
									'Use last.'
								) :
								(
									'Weight-Rep'
								)
							) : (
								'No goal.'
							)
						}</FAHeader>
					</View>
					<View style={styles.col2}>
						{
							goalExist && !useLast && (
								<>
									<View style={styles.input}>
										<View style={styles.inputValue}>
											<FAStoreTypography
												name='exercise_weight_goal'
												{...gridProps}
												style={{
												}}
											/>
										</View>
										<View style={styles.inputSuffix}>
											<FAText name='suffix'>lbs</FAText>
										</View>
									</View>
									<View style={styles.input}>
										<View style={styles.inputValue}>
											<FAStoreTypography
												name='setCount'
												{...gridProps}
												style={{
												}}
											/>
										</View>
										<View style={styles.inputSuffix}>
											<FAText name='suffix'>Sets</FAText>
										</View>
									</View>
									<View style={styles.input}>
										<View style={styles.inputValue}>
											<FAStoreTypography
												name='reps'
												{...gridProps}
												style={{
												}}
											/>
										</View>
										<View style={styles.inputSuffix}>
											<FAText name='suffix'>Reps</FAText>
										</View>
									</View>
								</>
							)
						}
					</View>
					<View style={styles.col3}>
						<FAStoreButton
							name='saveRow'
							backgroundColor={'blue.300'}
							borderRadius={50}
							width={50}
							height={50}
							borderColor={'white'}
							borderWidth={1}
							{...gridProps} />
						<FAStoreButton
							name='cancelRow'
							backgroundColor={'white'}
							borderRadius={50}
							width={50}
							height={50}
							borderColor={'blue.300'}
							borderWidth={1}
							{...gridProps}
						/>
					</View>
				</View>
				{/* <View style={styles.gridRowOpenTitle}>
					<FAStoreTypography
						name='description'
						color='gray.500'
						fontWeight={600}
						{...gridProps}
						marginLeft={5}
					/>
					<FAStoreButton
						name='btnDeleteAllSet'
						children={'x'}
						backgroundColor={'white'}
						_text={{
							color: 'gray.600'
						}}
						buttonProps={{
							// icon: icons.add
							borderColor: 'gray.600',
							borderWidth: 4,
							borderRadius: 20,
							width: 50,
							height: 50,
						}}
						{...{
							...gridProps,
						}}
					/>
				</View> */}
				{/* <FAStoreInput name='test' {...gridProps} /> */}
					{
					// util.isArrayNonStrict(workout_sets) && (
					// 	<View 
					// 		style={styles.sets}
					// 		backgroundColor={'white'}
					// 		borderRadius={20}
					// 		padding={5}
					// 	>
					// 		{/* header */}
					// 		<View style={styles.singleSet} borderBottomColor={'gray.500'} borderBottomWidth={1}>
					// 			<View style={styles.setNumber}>
					// 				<FAText name='setNumber'>Set</FAText>
					// 			</View>
					// 			<View style={styles.setPreviousWeight}>
					// 				<FAText name='setNumber'>Previous</FAText>
					// 			</View>
					// 			<View style={styles.setWeight}>
					// 				<FAText name='setNumber'>lbs</FAText>
					// 			</View>
					// 			<View style={styles.setReps}>
					// 				<FAText name='setNumber'>Reps</FAText>
					// 			</View>
					// 		</View>
					// 		{workout_sets.map((set, idx) => {
					// 			const {
					// 				number,
					// 				previous,
					// 				// set_reps,
					// 				// set_weight,
					// 			} = set;
	
					// 			return (
					// 				<View style={styles.singleSet}>
					// 					<View style={styles.setNumber}>
					// 						<FAText name='setNumber'>{number}</FAText>
					// 					</View>
					// 					<View style={styles.setPreviousWeight}>
					// 						<FAText name='setNumber'>{previous}</FAText>
					// 					</View>
					// 					<View style={styles.setWeight}>
					// 						<FAStoreInput
					// 							name='set_weight'
					// 							getValue={(props) => set.set_weight}
					// 							setValue={(value, props) => {
					// 								set.set_weight = value;
					// 								refresh();
					// 							}}
					// 							format='number'
					// 						/>
					// 					</View>
					// 					<View style={styles.setReps}>
					// 						<FAStoreInput
					// 							name='set_reps'
					// 							getValue={(props) => set.set_reps}
					// 							setValue={(value, props) => {
					// 								set.set_reps = value;
					// 								refresh();
					// 							}}
					// 							format='number'
					// 						/>
					// 					</View>
					// 					<View style={styles.button}>
					// 						<FAStoreButton
					// 							name='btnDeleteSet'
					// 							children={'x'}
					// 							backgroundColor={'white'}
					// 							_text={{
					// 								color: 'gray.600'
					// 							}}
					// 							buttonProps={{
					// 								// icon: icons.add
					// 								borderColor: 'gray.600',
					// 								borderWidth: 4,
					// 								borderRadius: 20,
					// 								width: 50,
					// 								height: 50,
					// 							}}
					// 							{...{
					// 								...gridProps,
					// 								idx,
					// 							}}
					// 						/>
					// 					</View>
					// 				</View>	
					// 			)
					// 		})}
					// 		<FAStoreButton
					// 			name='btnAddSets'
					// 			children={'Add Sets'}
					// 			backgroundColor={'white'}
					// 			_text={{
					// 				color: 'gray.600'
					// 			}}
					// 			buttonProps={{
					// 				// icon: icons.add
					// 				borderColor: 'gray.600',
					// 				borderWidth: 4,
					// 				borderRadius: 20,
					// 				width: '50%',
					// 			}}
					// 			{...gridProps}
					// 		/>
					// 	</View>
					// )
					}
			</Pressable>
		);
	}
	
	return (
		<Pressable
			style={styles.gridRowClosed}
			backgroundColor={'gray.200'}
			onPress={(evt) => onClick(evt)}
		>
			<View style={styles.gridRowClosedSection}>
				<FAStoreTypography
					name='exercise_type_name'
					bold
					style={{
						textWrap: 'nowrap',
						maxWidth: 50,
						textOverflow: 'ellipsis',
					}}
					{...gridProps}

				/>
				<FAStoreTypography
					name='exercise_type_description'
					color='gray.500'
					fontWeight={600}
					maxHeight={20}
					style={{
						textWrap: 'nowrap',
						maxWidth: 50,
						textOverflow: 'ellipsis',
					}}
					{...gridProps}
				/>
			</View>
			{
				goalExist ? (
					exercise_goal_type === ExerciseGoalType.UseLast ? (
						<>
							<View style={styles.gridRowClosedSectionNoGoal}>
								<FAText name='noGoal' bold>Use last.</FAText>
							</View>
						</>
					) : (
						<>
							<View style={styles.gridRowClosedSection2}>
								<View style={styles.iconGoal}>
									<FAStoreButton
										name='goalIcon'
										{...gridProps}
									/>
								</View>
								<View style={styles.goal}>
									<View style={styles.value}>
										<FAStoreTypography name='exercise_weight_goal' {...gridProps} />
									</View>
									<View style={styles.label}>
										<FAText name='label'>lb</FAText>
									</View>
								</View>
							</View>
							<View style={styles.gridRowClosedSection3}>
								<View style={styles.sets}>
									<View style={styles.value}>
										<FAStoreTypography name='setCount' {...gridProps} />
									</View>
									<View style={styles.label}>
										<FAText name='label'>Sets</FAText>
									</View>
								</View>
								<View style={styles.reps}>
									<View style={styles.value}>
										<FAStoreTypography name='reps' {...gridProps} />
									</View>
									<View style={styles.label}>
										<FAText name='label'>Reps</FAText>
									</View>
								</View>
							</View>
						</>
					)
				) : (
					<View style={styles.gridRowClosedSectionNoGoal}>
						<FAText name='noGoal' bold>No goal.</FAText>
					</View>
				)
			}
			<View style={styles.gridRowClosedSection4}>
				<FAStoreButton name='btnDelete' {...gridProps} />
			</View>
			<View style={styles.gridRowClosedSection5}>
				<FAStoreButton name='btnEdit' {...gridProps} />
			</View>
		</Pressable>
	);
};

export const RoutineDetail: React.FC = () => {
	const screenName = 'RoutineDetailPage';
	const service = useRoutineDetailService(screenName, RoutineDetailStore, uiState);
	useServiceRouter(service);
	const [refresh] = useComponentRefresh();

	useEffect(() => {
		const load = async () => {
			await service.loadExercises()
			service.initialLoad();
		};
		load();
	}, []);

	console.log('Render RoutineDetail screen');
	return (
		<PageLayout>
			<View style={styles.header}>
				<View style={styles.backBtn}>
					<FAStoreButton name='backBtn' />
				</View>
				<View style={styles.title}>
					<FAStoreTypography
						name='routine_name'
					/>
					<FAStoreButton name='editRoutineBtn' />
				</View>
			</View>
			<View
				style={styles.goal}
				backgroundColor={'blue.200'}
				borderRadius={5}
				padding={3}
				margin={2}
			>
				<View style={styles.left}>
					<View style={styles.leftTitle}>
						<FAHeader name='header'>Description</FAHeader>
					</View>
					<View style={styles.value}>
						<FAStoreTypography
							name='routine_description'
						/>
					</View>
				</View>
				<View style={styles.right}>
					<FAStoreButton name='editGoalBtn' />
				</View>
			</View>
			<View
				style={styles.frequency}
				backgroundColor={'blue.200'}
				borderRadius={5}
				padding={3}
				margin={2}
			>
				<View style={styles.left}>
					<View style={styles.leftTitle}>
						<FAHeader name='header'>Frequency</FAHeader>
					</View>
					<View style={styles.value}>
						<FAStoreTypography
							name='routine_frequency'
						/>
					</View>
				</View>
				<View style={styles.right}>
					<FAStoreButton name='editFrequencyBtn' />
				</View>
			</View>
			<View style={styles.grid}>
				<FAStoreGrid
					name='exercises'
					renderComponent={RenderSimpleItem}
				/>
				<FAStoreButton
					name='addNewRow'
					componentType={ComponentEnum.ButtonTypeEnum.Icon}
					icon={icons.add}
					backgroundColor={'gray.200'}
					borderRadius={25}
				/>
			</View>
			<View style={styles.bottom} backgroundColor={'indigo.400'}>
				<FAStoreButton
					name='cancelBtn'
					backgroundColor={'gray.400'}
					children='Cancel'
					_text={{
						color: 'white'
					}}
					{...{
						borderRadius: 25,
						borderColor: 'white',
						borderWidth: 2,
					}}
				/>
				<FAStoreButton
					name='saveBtn'
					backgroundColor={'blue.600'}
					children='Complete'
					_text={{
						color: 'black'
					}}
					{...{
						borderRadius: 25,
					}}
				/>
			</View>
			<FAStoreModal
				name='exerciseInfo'
				component={() => {
					return (
						<View>
							<FAStoreInput name='repFrom' />
							<FAStoreInput name='repTo' />
							<FAStoreInput name='setCount' />
						</View>
					);
				}}
			/>
			<FAModalInput
				name='descriptionModal'
				inputName='routine_description'
				label='Description'
			/>
			<FAModalInput
				name='nameModal'
				inputName='routine_name'
				label='Routine Name'
			/>
			<FAModalInput
				name='frequencyModal'
				inputName='routine_frequency'
				label='Frequency'
			/>
			<FAStoreModal
				name='selectExercise'
				component={ExerciseSelect}
				componentProps={{
					selectMode: true,
					navigate: service.routing.navigate,
					location: service.routing.location,
					params: service.routing.params,
					searchParams: service.routing.searchParams,
				}}
				onCancel={() => {
					useRoutineDetailService(screenName, RoutineDetailStore, uiState);
					refresh();
				}}
				onOk={(evt, data, props) => {
					useRoutineDetailService(screenName, RoutineDetailStore, uiState);
					refresh();
					service.onOk(evt, data, props);
				}}
			/>
			<FAStoreModal 
				name='addSchedule'
				component={RoutineScheduleModal}
			/>
		</PageLayout>
	);
};

const styles = StyleSheet.create({
	addNewBtn: {
		alignSelf: 'flex-end',
		width: '25px',
	},
	model: {
		height: util.getDim('35vh'),
	},
	other: {
	},
	formContainer: {
		display: 'flex',
		width: '100%',
		flexDirection: 'row',
	},
	form: {
		flex: 4,
		margin: 5,
		justifyContent: 'space-between',
	},
	button: {
		flex: 1,
		margin: 5,
		flexDirection: 'row',
		justifyContent: 'space-between',
	},
	header: {
		display: 'flex',
		flexDirection: 'row',
	},
	backBtn: {

	},
	title: {
		display: 'flex',
		flexDirection: 'row',
		flexGrow: 1,
		justifyContent: 'center',
		minHeight: 40,
	},
	detail: {
		flexGrow: 1,
	},
	footer: {

	},
	bottom: {
		width:'100%',
		display: 'flex',
		flexDirection: 'row',
		justifyContent: 'space-between',
		padding: 30,
		borderTopLeftRadius: 20,
		borderTopRightRadius: 20,
	},
	goal: {
		display: 'flex',
		flexDirection: 'row',
	},
	left: {
		display: 'flex',
		flexDirection: 'column',
		flexGrow: 1,
		flex: 1,
	},
	leftTitle: {

	},
	value: {
		minHeight: 10,
	},
	right: {
		display: 'flex',
		flexDirection: 'column',
		justifyContent: 'center',
	},
	frequency: {
		display: 'flex',
		flexDirection: 'row',
	},
	grid: {
		margin: 5,
		flexGrow: 1,
		flexShrink: 1,
		flexBasis: 0,
		overflow: 'scroll',
	},
	gridRowOpen: {
		display: 'flex',
		flexDirection: 'row',
	},
	openLeft: {
		display: 'flex',
		flexDirection: 'column',
		flex: 1,
	},
	openRight: {
		display: 'flex',
		flexDirection: 'row',
		flex: 3,

	},
	input: {
		display: 'flex',
		flexDirection: 'row',
		justifyContent: 'center',
		alignItems: 'center',
		flex: 1,
		marginBottom: 2,
	},
	inputValue: {
		flex: 3,
		overflow: 'hidden',
		margin: 3,
	},
	inputSuffix: {
		justifyContent: 'center',
		alignItems: 'center',
		flex: 1,
		marginHorizontal: 5,
	},
	gridRowOpenTitle: {

	},
	gridRowClosed: {
		display: 'flex',
		flexDirection: 'row',
		flex: 1,
		borderRadius: 10,
		margin: 5,
	},
	gridRowClosedSection: {
		display: 'flex',
		flexDirection: 'column',
		flex: 3,
		overflow: 'hidden',
		textOverflow: 'ellipsis',
		padding: 5,
	},
	gridRowClosedSection2: {
		display: 'flex',
		flexDirection: 'row',
		justifyContent: 'center',
		alignItems: 'center',
		flex: 3,
	},
	gridRowClosedSectionNoGoal: {
		display: 'flex',
		flexDirection: 'row',
		justifyContent: 'center',
		alignItems: 'center',
		flex: 6,
	},
	iconGoal: {

	},
	gridRowClosedSection3: {
		display: 'flex',
		flexDirection: 'column',
		justifyContent: 'center',
		alignItems: 'center',
		flex: 3,
	},
	sets: {
		display: 'flex',
		flexDirection: 'row',
		margin: 3,
	},
	label: {
		marginLeft: 5,
	},
	reps: {
		display: 'flex',
		flexDirection: 'row',
		margin: 3,
	},
	setNumber: {

	},
	gridRowClosedSection4: {
		display: 'flex',
		flexDirection: 'column',
		justifyContent: 'center',
		alignItems: 'center',
		flex: 1,
	},
	gridRowClosedSection5: {
		display: 'flex',
		flexDirection: 'column',
		justifyContent: 'center',
		alignItems: 'center',
		flex: 1,
	},
	col1: {
		flex: 3,
		// fit content not work on react native
		// width: 'fit-content',
	},
	col2: {
		display: 'flex',
		flexDirection: 'column',
		justifyContent: 'center',
		alignItems: 'center',
		flex: 3,
		marginHorizontal: 5,
	},
	col3: {
		display: 'flex',
		flexDirection: 'column',
		justifyContent: 'space-between',
		marginVertical: 5,
	},
	goalContainer: {
		display: 'flex',
		flexDirection: 'row',
	},
});