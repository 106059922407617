import { ComponentEnum } from '../../enums';
import { UIStoreInit } from '../../store';

export const uiState: UIStoreInit = {
	button: {
		submitBtn: {
			name: 'submitBtn',
			children: 'Submit',
			size: 'lg',
		},
	},
	input: {
		email: {
			componentType: ComponentEnum.InputTypeEnum.Input,
			name: 'email',
			placeholder: 'Email',
			label: 'Email',
			routes: 'email',
			size: 'lg',
		},
		supportDescription: {
			componentType: ComponentEnum.InputTypeEnum.TextArea,
			name: 'supportDescription',
			placeholder: 'Write a description of the problem/question you are facing here!',
			label: 'Description',
			routes: 'supportDescription',
			size: 'lg',
		},
	},
	typography: {},
	layout: {
		header: 'Support',
		backEnabled: true,
		headerEnabled: true,
	},
};