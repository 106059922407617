import { paths } from '../../../enums';
import { serviceBase } from '../../../service';
import { CustomOptions } from '../../../service/api';
import { setAppStateStore, UIStoreInit } from '../../../store';
import * as util from '../../../util';

export class ResetPasswordService extends serviceBase {
	constructor(name: string, store: any, uiState: UIStoreInit) {
		super(name, store, uiState);

		this.responseData = this.responseData.bind(this);
		this.getSubmitData = this.getSubmitData.bind(this);
		this.transformData = this.transformData.bind(this);
	}

	/**
     * get api data before api call
     * @param options 
     * @returns 
     */
	getSubmitData(options: CustomOptions) {
		const { name } = options;
		return {};
	}

	/**
     * transform response function after api call 
     * @param data 
     * @param options 
     */
	transformData(data: any, options: CustomOptions) {
		const { name } = options;
		if (name === 'resetPasswordApi') {
            
			return data;
		}
		return data;
	}

	/**
     * onClick fn handler
     * @param value 
     * @param data 
     * @param props 
     */
	onClick(value: any, data: any, props: any) {
		const { name } = props;
		switch (name) {
		case 'submitBtn':
			this.sendPasswordResetEmail();
			break;
		case 'resetBtn':
			this.resetPasswordWithToken();
			break;
		default:
			break;
		}
	}
	
	async sendPasswordResetEmail() {
		// get email
		const email = this.store.getProperty('email');

		// clear 

		this.store.setProperty('email', '');
		
		await this.api.getApiAsync(this.getUrl('sendPasswordResetEmail'), { email }, { name: 'sendPasswordResetEmail' });
	}
	
	async resetPasswordWithToken() {
		// get token
		const token = this.store.getProperty('token');
		
		if (!token) {
			// shouldn't go here, btn should not show on ui if token not exist
			return;
		}
		// validatae password
		const resetPassword = this.store.getProperty('resetPassword');
		const confirmPassword = this.store.getProperty('confirmPassword');

		if (!resetPassword) {
			this.errorNotification('Please add your new password!');
			return;
		}
		if (resetPassword !== confirmPassword) {
			this.errorNotification('Reset password and confirmed password do not match!');
			return;
		}
		
		await this.api.patchApiAsync(this.getUrl('resetPasswordWithToken'), { token, newPassword: resetPassword }, { name: 'resetPasswordWithToken' });
	}

	startPage() {
		// get exercise type id
		if (this.routing.searchParams) {
			const token = this.routing.searchParams.get('token') || '';
	
			this.store.setProperty('token', token);
		} else {
			this.store.setProperty('token', '');
		}
		// this.loadData();
	}

	/**
     * response function after api call
     * @param data 
     * @param options 
     */
	responseData(data: any, options: CustomOptions): void {
		const { name } = options;
		if (name === 'sendPasswordResetEmail') {
			if (data.success) {
				this.successNotification('Sent Email!');	
			}
		} else if (name === 'resetPasswordWithToken') {
			if (data.success) {
				this.successNotification('Reset Password!');
			} else if (data.message && util.isString(data.message)) {
				this.errorNotification(data.message);
			}
		}
	}
	
	uiHide(name: string, data: any): boolean {
		
		const token = this.store.getProperty('token');
		if (token) {
			/** token is defined */
			if (
				name === 'email'
				|| name === 'submitBtn'
				|| name === 'forgotHeader'
			) {
				return true;
			}
		} else {
			/** token is not defined */
			if (
				name === 'resetPassword'
				|| name === 'confirmPassword'
				|| name === 'resetHeader'
				|| name === 'resetBtn'
			) {
				return true;
			}
		}

		
		return false;
	}
}