import React from 'react';
import { FASwitchProps } from './FASwitchProps';
import { HStack, Switch, Text } from 'native-base';
import { StyleSheet, View } from 'react-native';

export const FASwitch: React.FC<FASwitchProps> = (props: FASwitchProps) =>{
	const {
		name,

		size,
		isDisabled,
		isHovered,
		inputName,
		// onToggle,
		// isChecked,
		defaultIsChecked,
		isInvalid,
		onTrackColor,
		offTrackColor,
		onThumbColor,
		offThumbColor,
		colorScheme,
		_hover,
		value,
		setValue,
		getValue,
		label,
		onClick,
		uiReadOnly,
	} = props;
    
	const getStoreValue = () => {
		return (getValue && getValue(props)) || value;
	};

	const setStoreValue = (value: any) => {
		return setValue && setValue(value, props);
	};

	const readOnly = uiReadOnly ? uiReadOnly(name, props) : isDisabled;

	const attributes: any = {
		name,

		size,
		isHovered,
		inputName,
		// onToggle,
		// isChecked,
		defaultIsChecked,
		isInvalid,
		onTrackColor,
		offTrackColor,
		onThumbColor,
		offThumbColor,
		colorScheme,
		_hover,
		isDisabled: readOnly,
	};

	attributes.isChecked = getStoreValue();

	const handleToggle = (checked: boolean) => {
		setStoreValue(checked);
		if (onClick) onClick(checked, checked, props);
	};
	attributes.onToggle = handleToggle;
    

	let Wrapper = ({ children }: any) => <>{children}</>;

	if (label) {
		Wrapper = ({ children }: any) => (
			<View
				style={styles.container}
			>
				<View style={styles.label}>
					<Text>{label}</Text>
				</View>
				<View style={styles.children}>
					{children}
				</View>

			</View>
		)
	}
	console.log('Render FA Switch', name);
	return (
		<Wrapper>
			<Switch {...attributes}/>
		</Wrapper>
	);
};

const styles = StyleSheet.create({
	container: {
		display: 'flex',
		flexDirection: 'row',
	},
	label: {
		padding: 5,
	},
	children: {
		padding: 5,
	},
});