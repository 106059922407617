import { modelData } from '../../../components/threejs/modelData';
import { getMuscleColor } from '../../../enums/colors';
import { getService, serviceBase } from '../../../service';
import { CustomOptions } from '../../../service/api';
import { getAppStateData, UIStoreInit } from '../../../store';
import * as util from '../../../util';
import { ExerciseService } from '../../Exercise/service';

export class RoutineStartService extends serviceBase {
	addMode: boolean;

	constructor(name: string, store: any, uiState: UIStoreInit) {
		super(name, store, uiState);

		this.responseData = this.responseData.bind(this);
		this.getSubmitData = this.getSubmitData.bind(this);
		this.transformData = this.transformData.bind(this);

		this.loadData = this.loadData.bind(this);
		this.setRoutineStartInfo = this.setRoutineStartInfo.bind(this);
		this.updateModelColor = this.updateModelColor.bind(this);
	}

	saveDetail() {
		const routine_id = this.store?.getProperty('routine_id');
		const routine_log_id = this.store?.getProperty('routine_log_id');
		// const routine_name = this.store?.getProperty('routine_name');
		// const routine_description = this.store?.getProperty('routine_description');
		const routine_comment = this.store?.getProperty('routine_comment');
		let exercises = this.store?.getProperty('workoutWorkingList');
		// if (!routine_name) {
		// 	this.errorNotification('Enter Routine Name!');
		// 	return;
		// }
		// if (!routine_description) {
		// 	this.errorNotification('Enter Routine Description!');
		// 	return;
		// }
		if (util.isArray(exercises)) {
			const filteredExercises = [];
			exercises.forEach((item) => {
				const {
					workout_sets,
				} = item;
				// only send exercises with defined weight/ sets
				if (util.isArray(workout_sets)) {
					filteredExercises.push(item);
				}
			});
			exercises = filteredExercises;
		}
		if (!routine_log_id) {
			// startLoading
			this.startBtnLoading('saveBtn');
			this.api.postApiAsync(this.getUrl('routineStart'), {
				routine_id,
				// routine_name,
				// routine_description,
				exercises,
				routine_comment
			}, { name: 'addRoutineStart' });
		} else {
			// startLoading
			this.startBtnLoading('saveBtn');
			this.api.patchApiAsync(this.getUrl('routineStart'), {
				routine_id,
				routine_log_id,
				// routine_name,
				// routine_description,
				exercises,
				routine_comment,
			}, { name: 'editRoutineStart' });
		}
	}

	async initialLoad() {

		const state = this.routing.location.state || {};
		const {
			routine_log_id,
			routine_id,
			addNew,
			goBack,
		} = state;
		// if returning do another initial behavior
		if (goBack) {
			return;
		}
		const currentRoutine = this.store.getProperty('routine_id');

		if (!addNew && currentRoutine && routine_id === currentRoutine) {
			return;
		}
		if (addNew) {
			// load exercises
			await this.loadExercises();
			// routine_id should always be defined to retreive exercises
			if (routine_id) this.store.setProperty('routine_id', routine_id);
			await this.loadRoutineExercise();
			await this.loadAllLastRoutine();
			// in edit mode routine_log_id should be provided
			this.addMode = true;
			this.store.setProperty('routine_log_id', '');
			this.store.setProperty('routine_comment', '');
		}
		else {
			// load exercises
			await this.loadExercises();
			// routine_id should always be defined to retreive exercises
			if (routine_id) this.store.setProperty('routine_id', routine_id);
			await this.loadRoutineExercise();
			// in edit mode routine_log_id should be provided
			this.addMode = false;
			this.store.setProperty('routine_log_id', routine_log_id);
			await this.loadData();
		}
	}

	async loadData() {
		// get exercise type id
		const routine_log_id = this.store.getProperty('routine_log_id');
		if (!routine_log_id) return;
		await this.api.getApiAsync(this.getUrl('routineStart'), { routine_log_id }, { name: 'loadRoutineStart' });
	}


	deleteRoutineStart(item) {
		const {
			exercise_type_id,
			exercise_type,
		} = item;
		const url = exercise_type === 'SYSTEM' ? 'exerciseSystem' : 'exercise';
		this.api.deleteApiAsync(this.getUrl(url), { exercise_type_id }, { name: 'deleteRoutineStart' });
	}

	/**
     * get api data before api call
     * @param options 
     * @returns 
     */
	getSubmitData(options: CustomOptions) {
		const { name } = options;
		return {};
	}

	/**
     * transform response function after api call 
     * @param data 
     * @param options 
     */
	transformData(data: any, options: CustomOptions) {
		const { name } = options;
		if (name === 'loginApi') {
            
			return data;
		}
		if (name === 'loadRoutineExercise') {
			const exerciseSelectList = this.store.getProperty('exerciseSelectList');
			if (util.isArray(data.data?.exercises)) {
				data.data.exercises.forEach((row) => {
					const {
						exercise_type_id,
					} = row;

					const exercise = (exerciseSelectList as any[]).find((item) => item.exercise_type_id === exercise_type_id);
					if (exercise) {
						row.exercise_type_name = exercise.exercise_type_name;
						row.exercise_type_description = exercise.exercise_type_description;
						row.exercise_muscles= exercise.exercise_muscles;
					}
				});
			}
		}
		if (name === 'loadRoutineStart') {
			const exerciseSelectList = this.store.getProperty('exerciseSelectList');
			if (util.isArray(data.data?.exercises)) {
				data.data.exercises.forEach((row) => {
					const {
						exercise_type_id,
					} = row;


					const exercise = (exerciseSelectList as any[]).find((item) => item.exercise_type_id === exercise_type_id);
					if (exercise) {
						row.exercise_type_name = exercise.exercise_type_name;
						row.exercise_type_description = exercise.exercise_type_description;
						row.exercise_muscles= exercise.exercise_muscles;
					}
				});
			}
		}
		return data;
	}

	/**
     * onClick fn handler
     * @param value 
     * @param data 
     * @param props 
     */
	onClick(value: any, data: any, props: any) {
		const { name, buttonName } = props;
		switch (name) {
		case 'loadBtn':
			this.loadData();
			break;
		case 'saveBtn':
			this.saveDetail();
			break;
		case 'loadLastBtn':
			this.loadLast();
			break;
		case 'editRoutine':
			this.editRoutine();
			break;
		case 'editGoal':
			this.editGoal();
			break;
		case 'editExercise':
			this.editExercise();
			break;
		case 'addNewRow': {
			this.selectExercise();
			break;
		}
		case 'workoutWorkingList':
			this.selectRow(data);
			break;
		case 'btnAddSets': {
			const { id, gridName } = props;
			if (gridName && id) {
				this.addSets(id);
			}
			break;
		}
		case 'btnFinishSets': {
			const { id, gridName } = props;
			if (gridName && id) {
				this.finishSets(id);
			}
			break;
		}
		case 'btnDeleteSet': {
			const { id, gridName, idx } = props;
			if (gridName && id && util.isNumber(idx)) {
				this.deleteSets(id, idx);
			}
			break;
		}
		case 'btnDeleteAllSet': {
			const { id, gridName, idx } = props;
			this.deleteAllSets(id);
			break;
		}
		case 'completeWorkout': {
			this.saveDetail();
			break;
		}
		case 'stopWorkout': {
			this.redirectTo(this.getPath('startWorkoutMenu'));
			break;
		}
		default:
			break;
		}
	}

	addSets(id) {
		const simpleList = this.store.getProperty('workoutWorkingList');

		// update sets of that row
		const row = simpleList.find((item) => item.id === id);

		if (row) {
			if (!util.isStrictArray(row.workout_sets)) {
				row.workout_sets = [];
			}
			row.workout_sets.push({
				number: row.workout_sets.length + 1,
			});

			// rebuild and set for mobx purpose
			const newList = [...simpleList];
			this.store.setGrid('workoutWorkingList', newList);
		}
	}

	finishSets(id) {
		const simpleList = this.store.getProperty('workoutWorkingList');

		// update sets of that row
		const row = simpleList.find((item) => item.id === id);

		if (row) {
			row.isDone = true;
			row.open = false;
			// rerender
			this.store.setDataVersion('workoutWorkingList');
		}
	}

	deleteSets(id, idx) {
		const simpleList = this.store.getProperty('workoutWorkingList');

		// update sets of that row
		const row = simpleList.find((item) => item.id === id);

		if (row) {
			if (!util.isStrictArray(row.workout_sets)) {
				row.workout_sets = [];
			}
			const newSets = row.workout_sets.filter((_, currentIdx) => currentIdx !== idx);
			row.workout_sets = newSets;
			row.workout_sets.forEach((item, idx) => item.number = idx + 1);

			// rebuild and set for mobx purpose
			const newList = [...simpleList];
			this.store.setGrid('workoutWorkingList', newList);
		}
	}

	deleteAllSets(id) {
		const simpleList = this.store.getProperty('workoutWorkingList');

		// update sets of that row
		const newList = simpleList.filter((item) => item.id !== id);
		this.store.setGrid('workoutWorkingList', newList);
	}

	selectRow(data) {
		const simpleList = this.store.getProperty('workoutWorkingList');

		const newSimpleList = util.isArray(simpleList) ? simpleList.map((item) => {
			if (item.id === data) {
				item.open = true;
			} else {
				item.open = false;
			}
			return item;
		}) : [];

		// set to store
		this.store.setGrid('workoutWorkingList', newSimpleList);
	}

	selectExercise() {
		// open modal to select an exercise
		this.openModal('selectExercise');
	}

	onSelectExercise() {
		// grab exercises service
		const service = getService('ExercisePage') as ExerciseService;

		if (!service) {
			return;
		}
		const selectedExercises = service.retrieveSelectedExercises();

		if (util.isArray(selectedExercises)) {
			this.addExercises(selectedExercises);
		}
	}

	addExercises(exercises: any[]) {
		const simpleList = this.store.getProperty('workoutWorkingList');

		// update sets of that row
		simpleList.forEach((item) => {
			item.open = false;
		});

		const addExercises: any[] = [];

		exercises.forEach((item) => {
			const {
				exercise_type_id,
				exercise_type_name,
				// description,
				// open,
				// sets,
			} = item;
			if (!exercise_type_id) {
				return;
			}
			addExercises.push({
				exercise_type_id,
				exercise_type_name,
				sets: [],
			});
		});

		if (addExercises.length) {
			addExercises[addExercises.length - 1].open = true;
			this.store.addGridRow('workoutWorkingList', addExercises);
		}
	}


	editGoal() {
		const routine_exercise_id = this.store.getProperty('routine_exercise_id');

		if (!routine_exercise_id) {
			return;
		}

		this.redirectTo(this.getPath('exerciseGoal'), { routine_exercise_id });
	}

	editRoutine() {
		const routine_id = this.store.getProperty('routine_id');

		if (!routine_id) {
			return;
		}

		this.redirectTo(this.getPath('routinedetail'), { routine_id });
	}

	editExercise() {
		const exercise_type_id = this.store.getProperty('exercise_type_id');

		if (!exercise_type_id) {
			return;
		}

		this.redirectTo(this.getPath('exercisedetail'), { exercise_type_id });
		
	}

	/**
	 * load last routine exercise data
	 */
	loadLast() {
		// get current routine exercise id
		const routine_exercise_id = this.store.getProperty('routine_exercise_id');
		// call api
		if (routine_exercise_id) {
			this.api.getApiAsync(this.getUrl('progression'), { routine_exercise_id }, { name: 'lastRoutine'} );
		}
	}

	loadAllLastRoutine() {
		// get current routine id
		const routine_id = this.store.getProperty('routine_id');
		// call api
		if (routine_id) {
			this.api.getApiAsync(this.getUrl('progressionAll'), { routine_id }, { name: 'lastAllRoutine'} );
		}
	}

	/**
	 * 
	 * @param data assume data is ok
	 */
	setLastRoutine(data: any[]) {

		// push data to working list
		const gridData: any = [];
		data.forEach((item) => {
			const {
				set_reps,
				set_weight,
			} = item;
			if (set_reps && set_weight) {
				gridData.push({
					set_reps,
					set_weight,
				});
			}
		});
		// set data
		// this.store.setGrid('workoutWorkingList', gridData);
	}

	/**
	 * 
	 * @param routineData assume routineData is ok
	 */
	setLastAllRoutine(exercises: any) {

		// push routineData to working list
		const gridData: any = [];
		const routineExercisesList = this.store?.getProperty('workoutWorkingList');
		if (util.isArray(routineExercisesList) && util.isArray(exercises)) {
			routineExercisesList.forEach((row) => {
				// change information match by routine_exercise_id
				const data = exercises.find((item) => item.routine_exercise_id === row.routine_exercise_id);
				if (data) {
					row.workout_sets = data.workout_sets;
				}
			});
		}
		// set routineData
		this.store.setGrid('workoutWorkingList', [...routineExercisesList]);
	}

	updateModelColor(id, value, moveCamera = true) {
		const color = getMuscleColor(value);
		this.callComponentHook('routineStart', 'updateColor', id, color, moveCamera);
	}

	/**
     * onCancel fn handler
     * @param value 
     * @param data 
     * @param props 
     */
	onCancel(value: any, data: any, props: any) {
		const { name } = props;
		switch (name) {
			case 'editModal':
				// clear
				this.store.setProperty('editExerciseWeight', 0);
				this.store.setProperty('editExerciseReps', 0);
				this.store.setProperty('editId', '');
				break;
			default:
				break;
		}
	}

	/**
     * onOk fn handler
     * @param value 
     * @param data 
     * @param props 
     */
	onOk(value: any, data: any, props: any) {
		const { name } = props;
		switch (name) {
			case 'selectExercise':
				this.onSelectExercise();
				break;
			default:
				break;
		}
	}

	/**
     * response function after api call
     * @param data 
     * @param options 
     */
	responseData(data: any, options: CustomOptions): void {
		const { name } = options;
		if (name === 'loadRoutineStart') {
			if (data.success) {
				this.setRoutineStartInfo(data.data);
			} else {
				this.errorNotification('error!');
			}
		}
		if (name === 'addRoutineStart') {
			if (data.success) {
				// const {
				// 	routine_log_id,
				// } = data.data || {};
				// this.store.setProperty('routine_log_id', routine_log_id);
				// change to edit mode
				this.setRoutineStartInfo(data.data);
				this.addMode = false;
				// this.loadData();
				this.successNotification('good job');
			}
			// end loading
			this.endBtnLoading('saveBtn');
		}
		if (name === 'lastRoutine') {
			if (data.success) {
				if (util.isArray(data.data)) {
					this.setLastRoutine(data.data);
				}
			}
		}
		if (name === 'lastAllRoutine') {
			if (data.success) {
				if (util.isArray(data.data)) {
					this.setLastAllRoutine(data.data);
				}
			}
		}
		if (name === 'editRoutineStart') {
			if (data.success) {
				this.loadData();
				this.successNotification('good job');
			}
			// end loading
			this.endBtnLoading('saveBtn');
		}
		if (name === 'loadExercise') {
			if (data.success) {
				this.setExerciseInfo(data.data);
			} else {
				this.errorNotification('error!');
			}
		}
		if (name === 'loadRoutineExercise') {
			if (data.success) {
				this.setRoutineExerciseInfo(data.data);
			} else {
				this.errorNotification('error!');
			}
		}
	}


	async loadExercises() {
		// load exercise data for select list
		await this.api.getApiAsync(this.getUrl('exercise'), { }, { name: 'loadExercise' });
	}

	async loadRoutineExercise() {
		const routine_id = this.store.getProperty('routine_id');
		if (!routine_id) {
			this.errorNotification('routine not defined!');
			return;
		}
		// load routine exercise list for this page for comparison
		await this.api.getApiAsync(this.getUrl('routineDetail'), { routine_id }, { name: 'loadRoutineExercise' });
	}

	setExerciseInfo(data: any) {
		if (util.isArray(data)) {
			this.store.setProperty(['exerciseSelectList'], data);
			data.forEach((item) => {
				item.value = item.exercise_type_id;
				item.label = item.exercise_type_name;
			});
			// set to select store items ui name
			this.uiStore.setProperty(['input', 'exerciseTypeId', 'items'], data);
		}
	}

	setRoutineExerciseInfo(data: any) {
		const {
			routine_description,
			routine_name,
			exercises,
		} = data;
		this.store.setProperty('routine_description', routine_description);
		this.store.setProperty('routine_name', routine_name);
		if (util.isArray(exercises)) {
			// initial set
			this.store.setGrid(['workoutWorkingList'], exercises);
		}
	}

	setRoutineStartInfo(data: any) {
		const {
			log_date,
			routine_comment,
			routine_completed,
			exercises = [],
			routine_log_id,
		} = data;
		// only really important info from this api is
		// exercise_info, workout_comment, log_date, (workout_log_id) maybe
		this.store.setProperty('routine_log_id', routine_log_id);
		this.store?.setProperty('log_date', log_date);
		this.store?.setProperty('routine_comment', routine_comment || '');
		this.store?.setProperty('routine_completed', routine_completed);
		this.store?.setGrid('exercises', exercises);
		// set routineExercisesList data here
		const routineExercisesList = this.store?.getProperty('workoutWorkingList');
		if (util.isArray(routineExercisesList) && util.isArray(exercises)) {
			// exercises.forEach((row) => 
			exercises.forEach((row) => {
				const data = routineExercisesList.find((item) => ((row.routine_exercise_id && item.routine_exercise_id === row.routine_exercise_id) || (!row.routine_exercise_id && row.exercise_type_id === item.exercise_type_id)));
				if (data) {
					data.workout_sets = row.workout_sets;
					data.workout_comment = row.workout_comment;
					data.log_date = row.log_date;
					data.workout_log_id = row.workout_log_id;
				} else {
					// add it
					routineExercisesList.push(row);
				}
			});

			this.store.setDataVersion('workoutWorkingList');
			// routineExercisesList.forEach((row) => {
			// 	// change information match by routine_exercise_id
			// 	const data = exercises.find((item) => item.routine_exercise_id === row.routine_exercise_id);
			// 	if (data) {
			// 		row.workout_sets = data.workout_sets;
			// 		row.workout_comment = data.workout_comment;
			// 		row.log_date = data.log_date;
			// 		row.workout_log_id = data.workout_log_id;
			// 	} else {
			// 		// add it
			// 		routineExercisesList.push(row);
			// 	}
			// 	row.open = false;
			// 	// rerender
			// 	this.store.setDataVersion('workoutWorkingList');
			// });
		}
		// this.calculateMuscles();
		this.updateExerciseStatus();
	}

	calculateMuscles() {
		// get exercises
		const exercises = this.store.getProperty('workoutWorkingList');
		const currentExerciseIndex = this.store.getProperty('currentExerciseIndex');
		if (!util.isArray(exercises)) return;
		if (currentExerciseIndex >= exercises.length) return;
		const exerciseItem = exercises[currentExerciseIndex];
		const {
			exercise_muscles
		} = exerciseItem;
		if (!exercise_muscles) return;

		// muscles parse
		const asyncFunctions: any = [];
		const hashMuscle: any = {};
		
		if (util.isArray(exercise_muscles)) {
			exercise_muscles.forEach((muscleData) => {
				const {
					muscle_id: id,
					intensity,
				} = muscleData;
				if (id && (!hashMuscle[id] || hashMuscle[id].intensity < intensity)) {
					hashMuscle[id] = muscleData;
				}
			});
		}
		modelData.forEach((item) => {
			if (hashMuscle[item.id]) {
				const {
					intensity: value,
				} = hashMuscle[item.id];
				if (util.isNumber(value)) {
					asyncFunctions.push(() => {
						this.updateModelColor(item.id, value, false);
					});
				}
			}
		});
		if (asyncFunctions.length) {
			setTimeout(() => {
				asyncFunctions.forEach((itemFn) => {
					itemFn();
				})
			}, 0);
		}
	}

	/**
	 * update btn ui for status
	 */
	updateExerciseStatus() {
		// equal true if workout_sets is defined
		const exercises = this.store.getProperty('workoutWorkingList');
		if (util.isArray(exercises)) {
			const progressList = exercises.map((item) => {
				const {
					workout_sets,
					exercise_type_name,
					routine_exercise_id,
				} = item;
				return (
					{
						routine_exercise_id,
						name: exercise_type_name,
						status: util.isArray(workout_sets),
					}
				)
			});
			this.store.setGrid('progressList', progressList);
		} else {
			this.errorNotification('routine error! no exercises!');
		}
	}

	uiHide(name: string, data: any): boolean {
		return false;
	}
}