import React, { useEffect, useState } from 'react';
import { StyleSheet, Text } from 'react-native';
import { Pressable, View } from 'native-base';
import { StartWorkoutMenuService, useStartWorkoutMenuService } from './service';
import { uiState } from './startWorkoutMenu.ui';
import { StartWorkoutMenuStore } from './startWorkoutMenu.store';
import { FAStoreTypography, FAStoreInput, PageLayout, FAHeader, FAStoreGrid, FAStoreButton, FAStoreModal, Tabs, FAButton, FAText } from '../../components';
import { getService, useServiceRouter } from '../../service';
import * as util from '../../util';
import { observer } from 'mobx-react-lite';
import { ComponentEnum, themeColors } from '../../enums';
import { btnStyle, textStyle } from '../../enums/styling';

const StartWorkoutGrid: React.FC<{ service: StartWorkoutMenuService }> = observer(({ service }) => {
	const todaysSchedules = service.store.getProperty('todaysSchedules');
	return (
		<>
			{
				util.isArray(todaysSchedules) ? (
					todaysSchedules.map((schedule) => {
						return (
							<View style={styles.startWorkoutContainer} key={schedule.routine_id || util.getUuid()}>
								<FAStoreTypography style={textStyle.h1} fontWeight={600}  name='routine_name'>{schedule.routine_name}</FAStoreTypography>
								<FAStoreButton name='startRoutineBtn' clickProps={schedule} />
							</View>
						)
					})
				) : (
					<View style={styles.startWorkoutContainer}>
						<FAStoreTypography style={textStyle.h1} fontWeight={500} color={themeColors.secondaryBase} name='message'>You do not have any routine</FAStoreTypography>
						<FAStoreButton name='addRoutineBtn' />
					</View>
				)
			}
		</>
	);
});


export const StartWorkoutMenu: React.FC = () => {
	const screenName = 'StartWorkoutMenuPage';
	const service = useStartWorkoutMenuService(screenName, StartWorkoutMenuStore, uiState);
	useServiceRouter(service);

	useEffect(() => {
		const run = async () => {
			await service.initPage();
		}
		run();
	}, []);

	console.log('Render StartWorkoutMenu screen');
	return (
		<PageLayout>
			{/* <FAButton name='t' onClick={refresh}>refresh</FAButton> */}
			<View style={styles.welcome}>
				<View style={styles.welcomeInside}>
					<FAStoreTypography 
						name='dateDayFull'
						// marginRight={1}
						style={textStyle.title}
						fontWeight={600}
					/>
					{/* <FAHeader style={textStyle.title} fontWeight={600} name='!'>Workout</FAHeader> */}
				</View>
				{/* date */}
				<View style={styles.welcomeInside}>
					<FAStoreTypography 
						name='dateMonth'
						color='gray.500'
						fontWeight={500}
						style={textStyle.h2}
						// marginRight={10}
					/>
					{/* <FAStoreTypography 
						name='dateHour'
						color='gray.500'
						fontWeight={500}
						style={textStyle.h2}
						// marginRight={10}
					/> */}
					{/* <FAStoreTypography 
						name='dateDay'
						color='gray.500'
						fontWeight={600}
						fontSize={15}
					/> */}
				</View>
			</View>
			{/* todays routine section */}
			<View style={styles.routinesection}>
				<FAHeader name='title' style={textStyle.smallTitle} color={themeColors.secondaryDark} fontWeight={600} >My Today's Routine</FAHeader>
				<StartWorkoutGrid service={service} />
			</View>
			<View style={styles.emptyWorkout}>
				<FAStoreButton name='startEmptyBtn' />
			</View>
		</PageLayout>
	);
};

const styles = StyleSheet.create({
	routinesection: {
		padding: 15,
		borderRadius: 20,
		backgroundColor: themeColors.gray,
		margin: 20,
		marginTop: 60,
		display: 'flex',
		flexDirection: 'column',
		gap: 20
	},
	emptyWorkout: {
		margin: 20,
		marginTop: 60,
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
	},
	startWorkoutContainer: {
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
		gap: 12,
	},
	welcome: {
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'flex-start',
		margin: 20,
	},
	welcomeInside: {
		display: 'flex',
		flexDirection: 'row',
		gap: 8,
	},
})
