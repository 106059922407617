import { serviceBase } from '../../../service';
import { CustomOptions } from '../../../service/api';
import { getAppStateData, UIStoreInit } from '../../../store';
import * as util from '../../../util';

export class RoutineService extends serviceBase {
	constructor(name: string, store: any, uiState: UIStoreInit) {
		super(name, store, uiState);

		this.responseData = this.responseData.bind(this);
		this.getSubmitData = this.getSubmitData.bind(this);
		this.transformData = this.transformData.bind(this);

		this.loadData = this.loadData.bind(this);
		this.setRoutineInfo = this.setRoutineInfo.bind(this);
		this.deleteRoutine = this.deleteRoutine.bind(this);
	}


	loadData() {
		this.api.getApiAsync(this.getUrl('routine'), { }, { name: 'loadRoutine' });
	}

	/**
     * get api data before api call
     * @param options 
     * @returns 
     */
	getSubmitData(options: CustomOptions) {
		const { name } = options;
		return {};
	}

	/**
     * transform response function after api call 
     * @param data 
     * @param options 
     */
	transformData(data: any, options: CustomOptions) {
		const { name } = options;
		if (name === 'loginApi') {
            
			return data;
		}
		return data;
	}

	/**
     * onClick fn handler
     * @param value 
     * @param data 
     * @param props 
     */
	onClick(value: any, data: any, props: any) {
		const { name, id } = props;
		switch (name) {
		case 'loadBtn':
			this.loadData();
			break;
		case 'routineList-btn':
			this.deleteRoutine(data);
			break;
		case 'btnEditRoutine': {
			if (id) {
				const row = this.store.getGridRow('routineList', id);
				if (row) {
					const {
						routine_id
					} = row;

					if (routine_id) {
						this.redirectTo(this.getPath('routinedetail'), { routine_id });
					}
				}
			}
			break;
		}
		case 'routineList-editbtn':
			this.redirectTo(this.getPath('routinedetail'), { routine_id: data.routine_id });
			break;
		case 'addNewBtn':
			this.redirectTo(this.getPath('routinedetail'), { addNew: true });
			break;
		default:
			break;
		}
	}

	/**
     * response function after api call
     * @param data 
     * @param options 
     */
	responseData(data: any, options: CustomOptions): void {
		const { name } = options;
		if (name === 'loadRoutine') {
			if (data.success) {
				this.setRoutineInfo(data.data);
			} else {
				this.errorNotification('error!');
			}
		}
		if (name === 'deleteRoutine') {
			if (data.success) {
				this.loadData();
			}
		}
	}

	deleteRoutine(item) {
		const {
			routine_id,
		} = item;
		this.api.deleteApiAsync(this.getUrl('deleteroutine'), { routine_id }, { name: 'deleteRoutine' });
	}

	setRoutineInfo(data: any) {
		// set data to input
		// this.store.setProperty('routineTest', JSON.stringify(data));
		if (util.isArray(data)) this.store.setGrid('routineList', data);
	}
}