import React, { SVGProps } from 'react';
import Svg, { Path } from 'react-native-svg';

// const menuStatsIcon: React.FC<SVGProps<SVGAElement>> = ({
//     height = '1.5em',
//     width = '1.5em',
//     viewBox = '0 0 24 24',
//     color = ''
// }) => {
//     return (
//         <svg width={width} height={height} viewBox={viewBox} fill="none" xmlns="http://www.w3.org/2000/svg">
//             <path d="M4.66675 6.24996C4.66675 5.09937 5.59949 4.16663 6.75008 4.16663H19.2501C20.4007 4.16663 21.3334 5.09937 21.3334 6.24996V18.75C21.3334 19.9006 20.4007 20.8333 19.2501 20.8333H6.75008C5.59949 20.8333 4.66675 19.9006 4.66675 18.75V6.24996Z" fill="white"/>
//             <path d="M17.1667 8.33329V16.6666M13.0001 11.4583V16.6666M8.83342 14.5833V16.6666M6.75008 20.8333H19.2501C20.4007 20.8333 21.3334 19.9006 21.3334 18.75V6.24996C21.3334 5.09937 20.4007 4.16663 19.2501 4.16663H6.75008C5.59949 4.16663 4.66675 5.09937 4.66675 6.24996V18.75C4.66675 19.9006 5.59949 20.8333 6.75008 20.8333Z" stroke="black" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
//         </svg>
//     );
// };
/**
 * FOR REACT NATIVE to work with style: need to use Svg, Path from react-native-svg
 */
const menuStatsIcon = ({
    // em does not work, only number values, px, and dp values
    height = '100%',
    width = '100%',
    viewBox = '0 0 24 24',
    color = 'white'
}) => {
    return (
        <Svg width={width} height={height} viewBox={viewBox} fill={color} color={color}>
            <Path d="M4.66675 6.24996C4.66675 5.09937 5.59949 4.16663 6.75008 4.16663H19.2501C20.4007 4.16663 21.3334 5.09937 21.3334 6.24996V18.75C21.3334 19.9006 20.4007 20.8333 19.2501 20.8333H6.75008C5.59949 20.8333 4.66675 19.9006 4.66675 18.75V6.24996Z" fill="white"/>
            <Path d="M17.1667 8.33329V16.6666M13.0001 11.4583V16.6666M8.83342 14.5833V16.6666M6.75008 20.8333H19.2501C20.4007 20.8333 21.3334 19.9006 21.3334 18.75V6.24996C21.3334 5.09937 20.4007 4.16663 19.2501 4.16663H6.75008C5.59949 4.16663 4.66675 5.09937 4.66675 6.24996V18.75C4.66675 19.9006 5.59949 20.8333 6.75008 20.8333Z" stroke="black" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
        </Svg>
    );
};

export default menuStatsIcon;