import { useNotification, useNotificationType } from '../hooks';
import { paths, urlRoutes } from '../enums';
import { NavigateFunction, Location, SetURLSearchParams } from '../layout/Routing';
import { MobxStore, UIStoreInit } from '../store';
import { ApiClass, CustomOptions } from './api';
import * as util from '../util';
import { getDataVersionStore } from '../store/dataVersion';

/**
 * service component
 * controller between view and model
 */
export class serviceBase {
	storeInit: any;
	store: MobxStore;
	uiStoreInit: UIStoreInit;
	uiStore: MobxStore;
	api: ApiClass;
	name: string;
	navigate: NavigateFunction;
	notification: useNotificationType;
	currentPath: string;
	componentHooks: any; // attach component hooks by name, action, fn here
	routing: {
		navigate?: NavigateFunction;
		location?: Location;
		params?: any;
		searchParams?: URLSearchParams;
		setSearchParams?: SetURLSearchParams;
	};
	backPath: string | undefined;

	cache: any;

	constructor(name: string, storeInit: any, uiStateInit: UIStoreInit) {
		this.name = name;
		this.storeInit = storeInit;
		this.uiStoreInit = uiStateInit;
		this.api = new ApiClass(this);
		this.componentHooks = {};

		this.transformData = this.transformData.bind(this);
		this.getSubmitData = this.getSubmitData.bind(this);
		this.responseData = this.responseData.bind(this);
		this.responseError = this.responseError.bind(this);

		// event fn handlers
		this.onClick = this.onClick.bind(this);
		this.onBlur = this.onBlur.bind(this);
		this.onChange = this.onChange.bind(this);
		this.onOk = this.onOk.bind(this);
		this.onCancel = this.onCancel.bind(this);

		// ui hooks
		this.uiHide = this.uiHide.bind(this);
		this.uiReadOnly = this.uiReadOnly.bind(this);

		// component hook
		this.addComponentHook = this.addComponentHook.bind(this);
		this.callComponentHook = this.callComponentHook.bind(this);
		this.openModal = this.openModal.bind(this);
		this.startBtnLoading = this.startBtnLoading.bind(this);
		this.endBtnLoading = this.endBtnLoading.bind(this);

		// redirect fn
		this.redirectTo = this.redirectTo.bind(this);
		this.goBack = this.goBack.bind(this);
		this.getPath = this.getPath.bind(this);

		// notifications fn
		this.errorNotification = this.errorNotification.bind(this);
		this.warningNotification = this.warningNotification.bind(this);
		this.successNotification = this.successNotification.bind(this);

		// store connect
		this.useStores = this.useStores.bind(this);

		// cache fn
		this.setCache = this.setCache.bind(this);
		this.getCache = this.getCache.bind(this);
		this.cache = {};

		this.routing = {};
		this.notification = useNotification();
		this.currentPath = window?.location?.pathname;
		this.useStores();
	}

	private useStores() {
		const dataVersionStore = getDataVersionStore(this.uiStoreInit);
		this.store = new MobxStore(this.name, this.storeInit, undefined, dataVersionStore);
		this.uiStore = new MobxStore(this.name, this.uiStoreInit);
	}

	/**
     * transform data api fn
     * @param data 
     * @param options 
     * @returns 
     */
	transformData(data: any, options: CustomOptions): any {
		return data;
	}

	/**
     * get submit data api fn
     * @param options 
     * @returns 
     */
	getSubmitData(options: CustomOptions) {
		return {};
	}
    
	/**
     * response api data fn
     * @param data 
     * @param options 
     */
	responseData(data: any, options: CustomOptions) {
		return data;
	}
    
	/**
     * response api data fn
     * @param data 
     * @param options 
     */
	responseError(data: any, options: CustomOptions) {
		const { message } = data;
		const {
			otherOptions: {
				onErrorFn = undefined,
			} = {},
		} = options;
		if (message) {
			this.errorNotification(message);
			console.log(message);
		}
		if (util.isFunction(onErrorFn)) {
			onErrorFn();
		}
		return data;
	}

	/**
     * get url from urlRoutes for api
     * @param name 
     * @returns 
     */
	getUrl(name: keyof typeof urlRoutes) {
		return urlRoutes[name];
	}

	/**
     * get url from paths for react router path
     * @param name 
     * @returns 
     */
	getPath(name: keyof typeof paths) {
		return paths[name];
	}

	/**
     * onClick fn handler
     * @param value 
     * @param data 
     * @param props 
     */
	onClick(value: any, data: any, props: any) {
		const { name } = props;
		switch (name) {
		default:
			break;
		}
	}

	/**
     * onBlur fn handler
     * @param value 
     * @param data 
     * @param props 
     */
	onBlur(value: any, data: any, props: any) {
		const { name } = props;
		switch (name) {
		default:
			break;
		}
	}

	/**
     * onChange fn handler
     * @param value 
     * @param data 
     * @param props 
     */
	onChange(value: any, data: any, props: any) {
		const { name } = props;
		switch (name) {
		default:
			break;
		}
	}

	/**
     * onOk fn handler
     * @param value 
     * @param data 
     * @param props 
     */
	onOk(value: any, data: any, props: any) {
		const { name } = props;
		switch (name) {
		default:
			break;
		}
	}

	/**
     * onCancel fn handler
     * @param value 
     * @param data 
     * @param props 
     */
	onCancel(value: any, data: any, props: any) {
		const { name } = props;
		switch (name) {
		default:
			break;
		}
	}

	/**
	 * ui readonly fn
	 * @param name component name
	 * @param data component data
	 */
	uiReadOnly(name: string, data: any): boolean {
		switch (name) {
		default:
			break;
		}
		return false;
	}

	/**
	 * ui hide fn
	 * @param name component name
	 * @param data component data
	 */
	uiHide(name: string, data: any): boolean {
		switch (name) {
		default:
			break;
		}
		return false;
	}

	/**
	 * attach fn to service to call
	 * @param name component name
	 * @param action action name
	 * @param fn 
	 */
	addComponentHook(name: string, action: string, fn: any) {
		if (!this.componentHooks[name]) this.componentHooks[name] = {};
		this.componentHooks[name][action] = fn;
	}

	/**
	 * call component hook
	 * @param name component name
	 * @param action action name
	 * @param parameters fn parameters
	 */
	callComponentHook(name: string, action: string, ...parameters: any) {
		if (this.componentHooks[name]?.[action]) {
			this.componentHooks[name][action].call(null, ...parameters);
		}
	}

	/**
	 * component hook for open modal by service
	 */
	openModal(name) {
		this.callComponentHook(name, 'open');
	}

	/**
	 * component hook for start loading button by service
	 */
	startBtnLoading(name) {
		this.callComponentHook(name, 'startLoading');
	}

	/**
	 * component hook for end loading button by service
	 */
	endBtnLoading(name) {
		this.callComponentHook(name, 'endLoading');
	}

	/**
     * error
     * @param message 
     */
	errorNotification(...message: string[]) {
		this.notification.error(message.join(' '));
	}

	/**
     * warning
     * @param message 
     */
	warningNotification(...message: string[]) {
		this.notification.warning(message.join(' '));
	}

	/**
     * success
     * @param message 
     */
	successNotification(...message: string[]) {
		this.notification.success(message.join(' '));
	}

	/**
	 * redirect to function
	 * @param url path to redirect
	 * @param state state to pass b/w components
	 * @param options other options variables
	 */
	redirectTo(url: string, state?: any, options?: any) {
		if (this.routing.navigate) {
			state = {
				...state,
				backPath: this.routing.location.pathname,
			};
			options = {
				...options,
				state,
			};
			this.routing.navigate(url, options);
		}
	}

	/**
	 * redirect to a url
	 * @param url path to redirect
	 * @param state state to pass b/w components
	 * @param options other options variables
	 */
    openWindow(url: string, name = '', samePage = false, windowFeatures?: undefined | string) {
        if (samePage) {
            window.open(url, '_top', windowFeatures);
        } else if (util.isString(name)) {
            window.open(url, name, windowFeatures);
        } else {
            window.open(url, undefined, windowFeatures);
        }
        return true;
    }

	/**
	 * redirect to page that redirected previously to this page
	 */
	goBack() {
		if (this.backPath) {
			this.redirectTo(this.backPath, { goBack: true });
		} else {
			this.redirectTo(this.getPath('home'), { goBack: true })
		}
	}

	readServiceState() {
		const state = this.routing.location.state || {};
		const {
			goBack = false,
		} = state;
		if (!goBack && util.isString(this.routing.location.state?.backPath)) {
			this.backPath = this.routing.location.state.backPath;
		}
	}

	/**
	 * use hooks to set routing tools
	 */
	useRouter(navigate: NavigateFunction, location: Location, params: any, searchParamsObj: [URLSearchParams, SetURLSearchParams]) {
		const [searchParams, setSearchParams] = searchParamsObj;
		this.routing = {
			navigate,
			location,
			params,
			searchParams,
			setSearchParams,
		}
		this.readServiceState();
	}

	/**
	 * get cache from async storage
	 * constraint only store objects and list
	 * @param cacheType a cache id attached per type
	 * @param cacheName a cache id attached per instance
	 */
	getCache(cacheType: string, cacheName: string, backup: any = []) {
		const key = `cache-${cacheType}-${cacheName}`;
		return this.cache[key];
	};

	/**
	 * set cache for async storage
	 * constraint only store objects and list
	 * @param cacheType a cache id attached per type
	 * @param cacheName a cache id attached per instance
	 * @param cacheValue value
	 */
	setCache(cacheType: string, cacheName: string, cacheValue: any) {
		const key = `cache-${cacheType}-${cacheName}`;
		this.cache[key] = cacheValue;
	};
}

