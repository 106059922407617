import React, { useEffect } from 'react';
import { StyleSheet } from 'react-native';
import { ExerciseService, useExerciseService } from './service';
import { uiState } from './exercise.ui';
import { ExerciseStore } from './exercise.store';
import { FAStoreTypography, FAStoreInput, PageLayout, FAHeader, FAStoreGrid, FAStoreButton, FAStoreModal, FAText } from '../../components';
import { FAInput } from '../../components/input/FAInput';
import { useServiceRouter } from '../../service';
import { ThreeHumanModel } from '../../components/threejs';
import { CheckCircleIcon, CheckIcon, CircleIcon, Pressable, ScrollView, View } from 'native-base';
import { observer } from 'mobx-react-lite';
import { isArray, isString } from '../../util';
import { useComponentRefresh } from '../../hooks';
import * as util from '../../util';
import { runInAction } from 'mobx';
import { textStyle } from '../../enums/styling';
import { themeColors } from '../../enums';

export const Exercise: React.FC = () => {
	const screenName = 'ExercisePage';
	const service = useExerciseService(screenName, ExerciseStore, uiState);
	useServiceRouter(service);

	useEffect(() => {
		service.loadData();
		service.selectMode = false;
	}, []);

	console.log('Render Exercise screen');
	return (
		<PageLayout menuEnabled>
			<FAStoreInput name='systemSwitch' />
			<FAStoreButton name='addNewBtn' />
			<FAStoreGrid
				name='exerciseList'
				style={{
					margin: 2,
				}}
			/>
			<FAStoreModal name='editModal'
				component={() => {
					return (
						<>
							<FAStoreInput name='editName' />
							<FAStoreInput name='editDescription' />
						</>
					);
				}}
			/>
			<FAStoreModal name='addModal'
				component={() => {
					return (
						<>
						<FAStoreInput name='addName' />
						<FAStoreInput name='addDescription' />
						</>
					);
				}}
			/>
		</PageLayout>
	);
};

const CustomGrid: React.FC<{ service: ExerciseService}> = observer((props) => {
	const {
		service,
	} = props;
	const data = service.store.getProperty('exerciseList');
	const muscleSelected = service.store.getProperty('legend');
	const search = service.store.getProperty('search');
	const [refresh] = useComponentRefresh();

	let filteredData = data;

	if (search && isString(search) && isArray(filteredData)) {
		filteredData = filteredData.filter((item) => {
			const {
				exercise_type_name,
			} = item;
			if (
				isString(exercise_type_name)
				&& exercise_type_name.toLowerCase().includes(search.toLowerCase())
			) {
				return true;
			}
			return false;
		});
	}

	if (!isArray(filteredData)) {
		return null;
	}

	// group array by first letter
	const group: Record<string, {
		label: string;
		data: any[];
	}> = {};

	filteredData.forEach((item) => {
		// get first letter
		const {
			exercise_type_name,
			muscle,
		} = item;
		if (!exercise_type_name) {
			return;
		}
		if (muscleSelected && (muscleSelected !== 'All') && muscle !== muscleSelected) {
			return;
		}
		let firstLetter: string | undefined;
		if (isString(exercise_type_name)) {
			for (let i = 0; i < exercise_type_name.length; i++) {
				if (/[a-zA-Z]/.test(exercise_type_name.charAt(i))) {
					firstLetter = exercise_type_name.charAt(i).toLowerCase();
					break;
				}
			}
		}

		if (!group[firstLetter]) {
			const label = firstLetter.toUpperCase();
			group[firstLetter] = {
				label,
				data: [],
			};
		}
		group[firstLetter].data.push(item);
	});

	const keys = Object.keys(group).sort();

	return (
		<View style={styles.containerCustom}>
			{keys.map((key) => {
				const {
					label,
					data,
				} = group[key];
				return (
					<View 
						key={key}
						paddingRight={'10px'}
					>
						<View style={styles.letter} key={label}>
							<FAText name='letter' style={[textStyle.h3, {fontWeight:'500', color: themeColors.darkGray}]}>{label}</FAText>
						</View>
						{
							data.map((item) => {
								const {
									selected = false,
									exercise_type_name,
									exercise_type_description,
									exercise_type_id,
									muscle,
								} = item;

								return (
									<Pressable
										onPress={() => {
											runInAction(() => {
												item.selected = !Boolean(selected);
											})
											refresh();
										}}
										// shadow={'6'}
										style={styles.content}
										key={exercise_type_id}

									>
										<View style={styles.image}>
			
										</View>
										<View style={styles.description}>
											<FAText
												name='top'
												fontWeight={600}
												style={textStyle.h2}
												lineHeight={'normal'}
											>{exercise_type_name}</FAText>
											<FAText 
												name='bottom'
												fontWeight={500}
												style={textStyle.h3}
												color={themeColors.darkGray}
											>{muscle}</FAText>
										</View>
										<View style={styles.select}>
											{selected ? (
												<CheckCircleIcon  
													color={themeColors.primaryBase}
												/>
											) : (
												<CircleIcon 
													color='transparent'
													strokeWidth='1.5px'
													stroke={themeColors.darkGray}
													overflow='visible'
												/>
											)}
										</View>
									</Pressable>
								);
							})
						}
					</View>
				);
			})}
		</View>
	)
});

export const ExerciseSelect: React.FC = (props: any) => {
	const {
		navigate,
		location,
		params,
		searchParams,
	} = props;
	const screenName = 'ExercisePage';
	const service = useExerciseService(screenName, ExerciseStore, uiState);

	useEffect(() => {
		service.loadData();
		service.selectMode = true;
	}, []);

	
    service?.useRouter(navigate, location, params, searchParams);
    if (location.pathname) service.currentPath = location.pathname;

	console.log('Render Exercise Select screen');
	return (
		<View style={styles.exerciseSelect}>
			<FAHeader name='title' style={textStyle.title}>Select Exercises</FAHeader>
			{/* header */}
			<View style={styles.search}>
				<View style={styles.searchOne}>
					<FAStoreInput
						name='search'
						borderRadius={20}
					/>
				</View>
				<FAStoreButton name='addNewBtn' />
			</View>
			<FAStoreInput name='systemSwitch' />
			{/* <FAStoreGrid name='exerciseList'/> */}
			<View style={styles.gridContainer}>
				<View style={styles.legend}>
					<ScrollView>
						<FAStoreInput name='legend' />
					</ScrollView>
				</View>
				<View style={styles.grid}>
					<ScrollView>
						<CustomGrid service={service} />
					</ScrollView>
				</View>
			</View>
		</View>
	);
};

const styles = StyleSheet.create({
	exerciseSelect: {
		height: '100%',
		overflow: 'scroll',
		width: '100%',
		paddingTop: 20,
		display: 'flex',
		flexDirection: 'column',
		alignItems:'center',
	},
	search: {
		display: 'flex',
		flexDirection: 'row',
		width: '100%',
		alignItems: 'center',
		gap: 8,
		padding: 10
	},
	searchOne: {
		flex: 'auto',
		display: 'flex',
	},
	containerCustom: {
		display: 'flex',
		flexDirection: 'column',
		gap: 16
	},
	letter: {
		display: 'flex',
		flexDirection: 'row',
		borderBottomWidth: 1,
		borderBottomColor: themeColors.gray
	},
	content: {
		display: 'flex',
		flexDirection: 'row',
		height: 60,
		margin: 4,
		padding: 4,
		gap: 6,
		// justifyContent: 'center',
		// alignContent: 'center',
		alignItems: 'center',
	},
	image: {
		// flex: 1,
		height: 60,
		width: 60
	},
	description: {
		flex: 'auto',
		display: 'flex',
		flexDirection: 'column'
	},
	top: {
		
	},
	middle: {

	},
	bottom: {
		
	},
	select: {
		// flex: 1,
		height: 18,
		width: 18
	},
	gridContainer: {
		display: 'flex',
		flexDirection: 'row',
		overflow: 'scroll',
		flexGrow: 1,
		flexShrink: 1,
		flexBasis: 0,
		width: '100%',
		gap:10
	},
	grid: {
		flex: 1,
		overflow: 'scroll',
	},
	legend: {
		// fit content not work on react native
		// width: 'fit-content',
	},
});
