import React from 'react';
import { ComponentEnum } from '../../enums';
import { useComponentProps } from '../../service';
import { observer } from 'mobx-react-lite';
import { FAButtonProps } from './FAButtonProps';
import { FAIconButtonProps } from './FAIconButtonProps';
import { FALinkProps } from './FALinkProps';
import { FAButton } from './FAButton';
import { FAIconButton } from './FAIconButton';
import { FALink } from './FALink';
import { useDataVersion } from '../../store/hooks';


export type FAStoreButtonProps = (FAButtonProps | FAIconButtonProps | FALinkProps);

export const FAStoreButton: React.FC<FAStoreButtonProps> = observer((props: FAStoreButtonProps) => {
	const attributes = useComponentProps(props, 'button', false);
	const [dataVersion, getDataVersion, setDataVersion] = useDataVersion();
	
	const {
		componentType,
		uiHide,
		name,
	} = attributes;

	// hide by name
	if (uiHide(name, props)) return null;

	let Component;
	switch (componentType) {
	case ComponentEnum.ButtonTypeEnum.Button:
		Component = FAButton;
		break;
	case ComponentEnum.ButtonTypeEnum.Icon:
		Component = FAIconButton;
		break;
	case ComponentEnum.ButtonTypeEnum.Link:
		Component = FALink;
		break;
	default:
		Component = FAButton;
		break;

	}

	console.log('Render FA Store Button', name);
	return <Component {...attributes}/>;
});
