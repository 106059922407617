import React, { createRef, useEffect, useImperativeHandle, useRef, useState } from "react";
import { Color, MeshBasicMaterial } from "three";
import { isArray } from "../../util";
import { ModelRefType } from "./Model";
import { useGLTF } from "./modelExports";

/**
 * camera definition to scroll to muscle
 */
export interface PositionType {
  angleY: number;
  angleX: number;
}

export interface ModelItemType {
  id: string; // unique id
  file: string; // gltf location
  color?: string;
  position?: PositionType;
  innerRef?: ModelRefType;
  notMuscle?: boolean; // if not muscle
}

const useColor = (file, name, color, opacity = .5) => {
  const [gltf] = useGLTF(file, name);
  if (!gltf) return [];
  if (!color) return [gltf];
  if (isArray(gltf.scene?.children)) {
    const ColorMaterial = new Color(color);
    // apply opacity
    ColorMaterial.r *= opacity;
    ColorMaterial.g *= opacity;
    ColorMaterial.b *= opacity;
    gltf.scene.children.forEach((child: any) => {
      if (child?.material) {
        child.material.color = ColorMaterial;}
    });
  }
  return [gltf];
}
 
export const ModelItem: React.FC<ModelItemType> = (props: ModelItemType) => {
  const {
    file,
    color: colorProp,
    id,
    innerRef,
  } = props;
  
  const [opacity, setOpacity] = useState(.5);
  const [color, setColor] = useState(colorProp);

  const [gltf] = useColor(file, id, color, opacity);

  const focusMuscle = () => {
    setOpacity(1);
  };

  const unfocusMuscle = () => {
    setOpacity(.5);
  };

  const updateColor = (color: string) => {
    setColor(color);
  };

  useEffect(() => {
    if (!innerRef) return;
    // attach innerRef
    innerRef.current = {
      focusMuscle: (persist?: boolean) => {
        if (persist) {
          focusMuscle();
        } else {
          focusMuscle();
          // highlight for 2 secs
          setTimeout(unfocusMuscle, 2000);
        }
      },
      // for persist true situations
      unfocusMuscle,
      updateColor,
    }
  }, []);

  if (!gltf) return null;

  console.log('Render Model Item', id);
  return (
    <>
      <primitive object={gltf.scene} scale={1} />
    </>
  );
}
