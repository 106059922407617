import { serviceBase } from '../../../service';
import { CustomOptions } from '../../../service/api';
import { UIStoreInit } from '../../../store';
import * as util from '../../../util';

export class HomeService extends serviceBase {

	constructor(name: string, store: any, uiState: UIStoreInit) {
		super(name, store, uiState);

		this.responseData = this.responseData.bind(this);
		this.getSubmitData = this.getSubmitData.bind(this);
		this.transformData = this.transformData.bind(this);

		this.loadData = this.loadData.bind(this);

		this.checkOnboarded();
	}


	loadData() {
		this.api.getApiAsync(this.getUrl('load'), {  }, { name: 'loadData', method: 'GET' });
	}

	/**
     * get api data before api call
     * @param options 
     * @returns 
     */
	getSubmitData(options: CustomOptions) {
		return {};
	}

	/**
     * transform response function after api call 
     * @param data 
     * @param options 
     */
	transformData(data: any, options: CustomOptions) {
		const { name } = options;
		if (name === 'loadData') {
            
			return data;
		}
		return data;
	}

	/**
     * onClick fn handler
     * @param value 
     * @param data 
     * @param props 
     */
	onClick(value: any, data: any, props: any) {
		const { name, routes } = props;
		switch (name) {
		case 'test':
			this.redirectTo(this.getPath('login'));
			break;
		case 'redirectIOS':
			this.openWindow('https://apps.apple.com/us/app/fit-guru/id1660291607');
			break;
		case 'redirectAndroid':
			this.openWindow('https://play.google.com/store/apps/details?id=com.gulex.fitnessapp');
			break;
		case 'redirectSignup':
			this.onboard();
			this.redirectTo(this.getPath('signup'));
			break;
		case 'btnSkip':
		case 'redirectLogin':
			this.onboard();
			this.redirectTo(this.getPath('login'));
			break;
		case 'test2':
			this.store.setProperty(['test2', 'test2'], 'clickButtontest2!');
			break;
		case 'label_test':
			this.uiStore.setProperty(['input', 'test', 'label'], 'labeltest!');
			break;
		case 'label_test2':
			this.uiStore.setProperty(['input', 'test2', 'label'], 'labeltest2!');
			break;
		case 'test3':
			if (routes) {
				const value = this.store.getProperty(routes);
				if (value === 'true') {
					this.store.setProperty(routes, 'false');
				} else {
					this.store.setProperty(routes, 'true');
				}
			}
			break;
		default:
			break;
		}
	}

	/**
     * response function after api call
     * @param data 
     * @param options 
     */
	responseData(data: any, options: CustomOptions): void {
		const { name } = options;
		if (name === 'loadData') {
			if (data.success) {
				this.resetData(data);
			} else {
				this.errorNotification('error!');
			}
		}
	}

	resetData(data: any) {
		this.store.loadStore(data);
	}

	get isonboardedkey() {
		return 'isonboarded';
	}

	async isOnBoarded() {
		const onboarded = await util.getCache('general', this.isonboardedkey, false);

		return Boolean(onboarded);
	}

	async checkOnboarded() {
		const onboarded = await this.isOnBoarded();
		this.store.setProperty('onboarded', onboarded);
	}
	
	async onboard() {
		await util.setCache('general', this.isonboardedkey, true);
		this.checkOnboarded();
	}
}