import { BASE_URL, urlRoutes } from "../../enums";
import { getAppStateData } from "../../store/appStateStore";

export const authorizeToken = async (Authorization: string) => {
	// get token 
	// const Authorization = getAppStateData('Authorization');

	try {
		const result = await (await fetch(BASE_URL + urlRoutes.authtoken, {
			method: 'GET',
			headers: {
				'Content-Type': 'application/json',
				Authorization,
			},
		})).json();
		const {
			success = false
		} = result;
		return success === true;
	} catch(err) {
		console.log('auth error', err);
		return false;	
	}
}