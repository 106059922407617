import React, { useEffect, useState } from 'react';
import { StyleSheet, Dimensions } from 'react-native';
import { useRoutineScheduleService } from './service';
import { uiState } from './routineSchedule.ui';
import { RoutineScheduleStore } from './routineSchedule.store';
import { PageLayout, FAStoreButton, FAText, FAStoreModal, FAStoreInput, FAHeader } from '../../components';
import { getService, useServiceRouter } from '../../service';
import { Button, Pressable, View } from 'native-base';
import * as util from '../../util';
import { observer } from 'mobx-react-lite';
import { RoutineScheduleModal, Week, Day, Month } from './extra';

const DayComponent: React.FC<any> = (props: any) => {
	const {
		currentRoutine,
	} = props
	return (
		<>
		<Day currentRoutine={currentRoutine} />
		</>
	);
};

const WeekComponent: React.FC<any> = (props: any) => {
	const {
		currentRoutine,
	} = props
	return (
		<>
			<Week currentRoutine={currentRoutine} />
		</>
	);
};

const MonthComponent: React.FC<any> = (props: any) => {
	const {
		currentRoutine,
	} = props
	return (
		<>
			<Month currentRoutine={currentRoutine} />
		</>
	);
};


export const RoutineSchedule: React.FC = observer(() => {
	const screenName = 'RoutineSchedulePage';
	const service = useRoutineScheduleService(screenName, RoutineScheduleStore, uiState);
	useServiceRouter(service);

	useEffect(() => {
		const load = async () => {
			service.initialLoad();
		};
		load();
	}, []);

	const [mode, setMode] = useState<'day' | 'week' | 'month'>('day');

	const currentRoutine = getService('RoutineStartPage')?.store.getProperty('routine_id');
	
	let Component = DayComponent;

	switch (mode) {
		case 'day':
			Component = DayComponent;
			break;
		case 'week':
			Component = WeekComponent;
			break;
		case 'month':
			Component = MonthComponent;
			break;
		default:
			break;
	};

	const attributes = {
		currentRoutine,
	};

	console.log('Render RoutineSchedule screen');
	return (
		<PageLayout>
			{/* Title  */}
			<View style={styles.header}>
				<View style={styles.title}>
					<FAHeader name='title'>Routine</FAHeader>
				</View>
				<FAStoreButton name='addBtn' />
				<FAStoreButton name='editBtn' />
			</View>
			{/* mode  */}
			<View style={styles.modeContainer} backgroundColor={'gray.200'}>
				<View style={styles.mode}>
					<Button
						backgroundColor={mode === 'day' ? 'blue.200' : 'gray.200'}
						_text={{
							color: 'black',
							fontWeight: 600,
						}}
						style={styles.btn}
						onPress={() => setMode('day')}
					>
						Day
					</Button>
				</View>
				<View style={styles.mode}>
					<Button
						backgroundColor={mode === 'week' ? 'blue.200' : 'gray.200'}
						_text={{
							color: 'black',
							fontWeight: 600,
						}}
						style={styles.btn}
						onPress={() => setMode('week')}
					>
						Week
					</Button>
				</View>
				<View style={styles.mode}>
					<Button
						backgroundColor={mode === 'month' ? 'blue.200' : 'gray.200'}
						_text={{
							color: 'black',
							fontWeight: 600,
						}}
						style={styles.btn}
						onPress={() => setMode('month')}
					>
						Month
					</Button>
				</View>
			</View>
			<Component {...attributes} />
			<FAStoreModal 
				name='addSchedule'
				component={RoutineScheduleModal}
			/>
			<FAStoreModal 
				name='scheduleModal'
				component={() => (
					<FAText name='warningText'>
						No Routine For this Day.
						Do you want to add/edit your routine schedule?
					</FAText>
				)}
			/>
		</PageLayout>
	);
});

const styles = StyleSheet.create({
	header: {
		flex: 1,
		display: 'flex',
		flexDirection: 'row',
		justifyContent: 'center',
		maxHeight: 70,
		textAlignVertical: 'center',
		textAlign: 'center',
		alignItems: 'center',
	},
	title: {
		// border: '1pt black solid',
		flexGrow: 1,
		justifyContent: 'center',
		textAlign: 'center',
		marginLeft: 50,
	},
	modeContainer: {
		width: '100%',
		display: 'flex',
		flexDirection: 'row',
		justifyContent: 'flex-end',
		maxHeight: 50,
		overflow: 'hidden',
		borderRadius: 30,
	},
	mode: {
		flex: 1,
		flexGrow: 1,
		justifyContent: 'center',
		textAlign: 'center',
		borderRadius: 30,
		overflow: 'hidden',
		margin: 3,
	},
	btn: {
		width: '100%',
		height: '100%',
	},
	week: {
		flex: 10,
	},
});