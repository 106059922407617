import React from 'react';
import { Link, Text } from 'native-base';
import { FATypographyProps } from './FATypographyProps';

export const FATypographyLinkComponent: React.FC<FATypographyProps> = (props: FATypographyProps) => {
	const {
		name,
		children,
		italic,
		bold,
		underline,
		strikeThrough,
		sub,
		highlight,
		style,
		getValue,
		color,
		fontSize,
		flex,
	} = props;
    
	const getStoreValue = () => {
		return (getValue && getValue(props)) || children;
	};

	const attributes: any = {
		name,
		children,
		italic,
		bold,
		underline,
		strikeThrough,
		sub,
		highlight,
		style,
		color,
		fontSize,
		flex,
	};

	attributes.children = getStoreValue();
    
	console.log('Render Text', name);
	return (
		<Link {...attributes} />
	);
};

export const FATypographyLink = React.memo(FATypographyLinkComponent);
