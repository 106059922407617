import { modelData } from '../../../components/threejs/modelData';
import { getMuscleColor } from '../../../enums/colors';
import { getService, serviceBase } from '../../../service';
import { CustomOptions } from '../../../service/api';
import { getAppStateData, UIStoreInit } from '../../../store';
import * as util from '../../../util';
import { ExerciseService } from '../../Exercise/service';

export class RoutineDetailService extends serviceBase {
	addMode: boolean;

	constructor(name: string, store: any, uiState: UIStoreInit) {
		super(name, store, uiState);

		this.responseData = this.responseData.bind(this);
		this.getSubmitData = this.getSubmitData.bind(this);
		this.transformData = this.transformData.bind(this);

		this.loadData = this.loadData.bind(this);
		this.setRoutineDetailInfo = this.setRoutineDetailInfo.bind(this);
		this.updateModelColor = this.updateModelColor.bind(this);
	}

	saveDetail() {
		const routine_id = this.store?.getProperty('routine_id');
		const routine_name = this.store?.getProperty('routine_name');
		const routine_description = this.store?.getProperty('routine_description');
		const exercises = this.store?.getProperty('exercises');
		if (!routine_name) {
			this.errorNotification('Enter Routine Name!');
			return;
		}
		if (!routine_description) {
			this.errorNotification('Enter Routine Description!');
			return;
		}
		if (this.addMode) {
			// startLoading
			this.startBtnLoading('saveBtn');
			this.api.postApiAsync(this.getUrl('routineDetail'), { routine_name, routine_description, exercises }, { name: 'addRoutineDetail' });
		} else {
			if (!routine_id) {
				this.errorNotification('Choose routine to edit!');
				return;
			}
			// startLoading
			this.startBtnLoading('saveBtn');
			this.api.patchApiAsync(this.getUrl('routineDetail'), { routine_id, routine_name, routine_description, exercises }, { name: 'editRoutineDetail' });
		}
	}


	async initialLoad() {
		const state = this.routing.location.state || {};
		const {
			routine_id,
			addNew,
		} = state;
		this.store.setProperty('routine_id', routine_id);
		if (addNew) {
			this.addMode = true;
			this.store.setProperty('routine_id', '');
			this.store.setProperty('exerciseTypeId', '');
			this.store.setProperty('routine_name', '');
			this.store.setProperty('routine_frequency', '');
			this.store.setProperty('routine_description', '');
			this.store.setProperty('exercises', []);
			// open routine name
			this.openModal('nameModal');
		}
		else {
			this.addMode = false;
			await this.loadData();
			this.loadRoutineSchedules();
		}
	}

	loadData() {
		// get exercise type id
		const routine_id = this.store.getProperty('routine_id');
		if (!routine_id) return;
		this.api.getApiAsync(this.getUrl('routineDetail'), { routine_id }, { name: 'loadRoutineDetail' });
	}


	deleteRoutineDetail(item) {
		const {
			exercise_type_id,
			exercise_type,
		} = item;
		const url = exercise_type === 'SYSTEM' ? 'exerciseSystem' : 'exercise';
		this.api.deleteApiAsync(this.getUrl(url), { exercise_type_id }, { name: 'deleteRoutineDetail' });
	}

	/**
     * get api data before api call
     * @param options 
     * @returns 
     */
	getSubmitData(options: CustomOptions) {
		const { name } = options;
		return {};
	}

	/**
     * transform response function after api call 
     * @param data 
     * @param options 
     */
	transformData(data: any, options: CustomOptions) {
		const { name } = options;
		if (
			name === 'loginApi'
			|| name === 'loadRoutineSchedules'
			|| name === 'editRoutineSchedule'
		) {
            
			return data;
		}
		if (name === 'loadRoutineDetail') {
			const exerciseSelectList = this.store.getProperty('exerciseSelectList');
			if (util.isArray(data.data?.exercises)) {
				data.data.exercises.forEach((row) => {
					const {
						exercise_type_id,
					} = row;

					const exercise = (exerciseSelectList as any[]).find((item) => item.exercise_type_id === exercise_type_id);
					if (exercise) {
						row.exercise_type_name = exercise.exercise_type_name;
						row.exercise_type_description = exercise.exercise_type_description;
						row.exercise_muscles= exercise.exercise_muscles;
					}
				});
			}
		}
		return data;
	}

	/**
     * onClick fn handler
     * @param value 
     * @param data 
     * @param props 
     */
	onClick(value: any, data: any, props: any) {
		const { name, id } = props;
		switch (name) {
		case 'loadBtn':
			this.loadData();
			break;
		case 'cancelBtn':
		case 'backBtn':
			this.goBack();
			break;
		case 'btnDelete': {
			if (id) {
				this.deleteRow(id);
			}
			break;
		}
		case 'btnEdit': {
			if (id) {
				this.selectRow(id);
			}
			break;
		}
		case 'cancelRow':
		case 'saveRow': {
			if (id) {
				this.unselectRow(id);
			}
			break;
		}
		case 'saveScheduleBtn':
			this.saveScheduleDetail();
			break;
		case 'goalIcon':
			this.updateGoal(id);
			break;
		case 'exercises-btn': {
			const {
				id
			} = data;
			if (id) {
				this.store?.deleteGridRow('exercises', id);
				this.calculateMuscles();
				this.setSelectOptions();
			}
			break;
		}
		case 'exercises-editbtn': {
			const {
				id
			}  = data;
			if (id) {
				this.openExerciseInfo(id);
			}
			break;
		}
		/**
		 * open routine exercise
		 */
		case 'exercises-infobtn': {
			const {
				id
			}  = data;
			if (id) {
				this.openExerciseGoal(id);
			}
			break;
		}
		case 'addBtn': {
			const exerciseID = this.store?.getProperty('exerciseTypeId');
			const exerciseList = this.store?.getProperty('exerciseSelectList');
			const exercise = exerciseList.find((item) => item.exercise_type_id === exerciseID);
			if (exercise) {
				// append to grid
				this.store.addGridRow('exercises', exercise);
				this.calculateMuscles();
				this.setSelectOptions();
			} else {
				this.errorNotification('Please choose an exercise to add!');
			}
			break;
		}
		case 'exercises':
			this.selectRow(data);
			break;
		case 'saveBtn':
			this.saveDetail();
			break;
		case 'editFrequencyBtn':
			this.openModal('addSchedule');
			// this.openModal('frequencyModal');
			// this.cache['routine_frequency'] = this.store.getProperty('routine_frequency');
			break;
		case 'editRoutineBtn':
			this.openModal('nameModal');
			this.cache['routine_name'] = this.store.getProperty('routine_name');
			break;
		case 'addNewRow': {
			this.selectExercise();
			break;
		}
		case 'editGoalBtn':
			this.openModal('descriptionModal');
			this.cache['routine_description'] = this.store.getProperty('routine_description');
			break;
		default:
			break;
		}
	}

	updateGoal(id) {
		if (!id) {
			return;
		}
		const exercises = this.store.getProperty('exercises');

		const {
			routine_exercise_id = undefined,
		} = util.isArray(exercises) ? exercises.find((item) => {
			if (item.id === id) {
				// do not include (deleting this row)
				return true;
			}
			
			return false;
		}) || {} : {};

		if (routine_exercise_id) {
			this.redirectTo(this.getPath('exerciseGoal'), { routine_exercise_id });
		} else {
			// throw warning
			this.errorNotification('Save the routine first before adding goal to this new exercise!');
		}
	}

	selectExercise() {
		// open modal to select an exercise
		this.openModal('selectExercise');
	}

	deleteRow(id: string) {
		if (!id) {
			return;
		}
		const exercises = this.store.getProperty('exercises');

		const newSimpleList = util.isArray(exercises) ? exercises.filter((item) => {
			if (item.id === id) {
				// do not include (deleting this row)
				return false;
			}
			
			return true;
		}) : [];

		// set to store
		this.store.setGrid('exercises', newSimpleList);
	}

	unselectRow(data) {
		const exercises = this.store.getProperty('exercises');

		const newSimpleList = util.isArray(exercises) ? exercises.map((item) => {
			if (item.id === data) {
				item.open = false;
			}
			return item;
		}) : [];

		// set to store
		this.store.setGrid('exercises', newSimpleList);
	}

	selectRow(data) {
		const exercises = this.store.getProperty('exercises');

		const newSimpleList = util.isArray(exercises) ? exercises.map((item) => {
			if (item.id === data) {
				item.open = true;
			} else {
				item.open = false;
			}
			return item;
		}) : [];

		// set to store
		this.store.setGrid('exercises', newSimpleList);
	}

	updateModelColor(id, value, moveCamera = true) {
		const color = getMuscleColor(value);
		this.callComponentHook('routineDetail', 'updateColor', id, color, moveCamera);
	}

	openExerciseGoal(id) {
		// get exercise info from id
		const row = this.store.getGridRow('exercises', id);

		const {
			routine_exercise_id = '',
		} = row;

		if (routine_exercise_id) {
			this.redirectTo(this.getPath('exerciseGoal'), { routine_exercise_id });
		}
	}

	openExerciseInfo(id) {
		// get exercise info from id
		const row = this.store.getGridRow('exercises', id);

		const {
			exercise_info = '',
		} = row;

		const {
			repFrom = 1,
			repTo = 10,
			setCount = 3,
		} = util.tryParse(exercise_info || '', {});

		// set current

		this.store.setProperty('repFrom', repFrom);
		this.store.setProperty('repTo', repTo);
		this.store.setProperty('setCount', setCount);
		this.store.setProperty('editId', id);

		this.openModal('exerciseInfo');
	}

	/**
     * onCancel fn handler
     * @param value 
     * @param data 
     * @param props 
     */
	onCancel(value: any, data: any, props: any) {
		const { name } = props;
		switch (name) {
			case 'frequencyModal':
				// reset
				this.store.setProperty('routine_frequency', this.cache['routine_frequency']);
				break;
			case 'nameModal':
				// reset
				this.store.setProperty('routine_name', this.cache['routine_name']);
				break;
			case 'descriptionModal':
				// reset
				this.store.setProperty('routine_description', this.cache['routine_description']);
				break;
			default:
				break;
		}
	}

	/**
     * onOk fn handler
     * @param value 
     * @param data 
     * @param props 
     */
	onOk(value: any, data: any, props: any) {
		const { name } = props;
		switch (name) {
			case 'exerciseInfo': {
				this.saveExerciseInfo();
			}
			case 'selectExercise':
				this.onSelectExercise();
				break;
			default:
				break;
		}
	}

	onSelectExercise() {
		// grab exercises service
		const service = getService('ExercisePage') as ExerciseService;

		if (!service) {
			return;
		}
		const selectedExercises = service.retrieveSelectedExercises();

		if (util.isArray(selectedExercises)) {
			this.addExercises(selectedExercises);
		}
	}

	addExercises(selectedExercises: any[]) {
		const exercises = this.store.getProperty('exercises');

		// update sets of that row
		exercises.forEach((item) => {
			item.open = false;
		});

		const addExercises: any[] = [];

		selectedExercises.forEach((item) => {
			const {
				exercise_type_id,
				exercise_type_name,
				// description,
				// open,
				// sets,
			} = item;
			if (!exercise_type_id) {
				return;
			}
			addExercises.push({
				exercise_type_id,
				exercise_type_name,
				sets: [],
			});
		});

		if (addExercises.length) {
			addExercises[addExercises.length - 1].open = true;
			this.store.addGridRow('exercises', addExercises);
		}
	}

	saveExerciseInfo() {
		// get modal props
		const repFrom = this.store.getProperty('repFrom');
		const repTo = this.store.getProperty('repTo');
		const setCount = this.store.getProperty('setCount');

		// get id
		const id = this.store.getProperty('editId');

		if (id) {
			// set exerciseInfo
			const exerciseInfo = {
				repFrom,
				repTo,
				setCount,
			};
			this.store.updateGridRowColumn('exercises', id, 'exercise_info', JSON.stringify(exerciseInfo));
		}

		// clear
		this.store.setProperty('editId', null);
	}

	/**
     * response function after api call
     * @param data 
     * @param options 
     */
	responseData(data: any, options: CustomOptions): void {
		const { name } = options;
		if (name === 'loadRoutineSchedules') {
			if (data.success) {
				this.setRoutineScheduleInfo(data.data);
			} else {
				this.errorNotification('error!');
			}
		}
		if (name === 'loadExerciseGoalInfo') {
			if (data.success) {
				this.resetExerciseGoals(data.data);
			} else {
				this.errorNotification('error loading goal info!');
			}
		}
		if (name === 'editRoutineSchedule') {
			if (data.success) {
				this.successNotification('success');
				this.loadRoutineSchedules();
			} else {
				this.errorNotification('error!');
			}
			// end loading
			this.endBtnLoading('saveScheduleBtn');
		}
		if (name === 'loadRoutineDetail') {
			if (data.success) {
				this.setRoutineDetailInfo(data.data);
			} else {
				this.errorNotification('error!');
			}
		}
		if (name === 'addRoutineDetail') {
			if (data.success) {
				const {
					routine_id,
				} = data.data || {};
				this.store.setProperty('routine_id', routine_id);
				// change to edit mode
				this.addMode = false;
				this.successNotification('added');
				this.loadData();
			}
			// end loading
			this.endBtnLoading('saveBtn');
		}
		if (name === 'editRoutineDetail' || name === 'deleteRoutineDetail') {
			if (data.success) {
				this.successNotification('saved');
				this.loadData();
			}
			// end loading
			this.endBtnLoading('saveBtn');
		}
		if (name === 'loadExercise') {
			if (data.success) {
				this.setExerciseInfo(data.data);
			} else {
				this.errorNotification('error!');
			}
		}
	}


	async loadExercises() {
		// load exercise data for select list
		await this.api.getApiAsync(this.getUrl('exercise'), { }, { name: 'loadExercise' });
	}

	setExerciseInfo(data: any) {
		if (util.isArray(data)) {
			data.forEach((item) => {
				item.value = item.exercise_type_id;
				item.label = item.exercise_type_name;
			});
			this.store.setProperty('exerciseSelectList', data);
			this.setSelectOptions();
		}
	}

	setSelectOptions() {
		const data = this.store.getProperty('exerciseSelectList');
		// filter with current exercises
		const exercises = this.store.getProperty('exercises');
		if (util.isStrictArray(exercises)) {
			const filteredData = data.filter((option) => {
				const idx = exercises.findIndex((item) => item.exercise_type_id === option.exercise_type_id);
				const notExist = idx === -1;
				return notExist;
			});
			// set to select store items ui name
			this.uiStore.setProperty(['input', 'exerciseTypeId', 'items'], filteredData);
		}

	}

	setRoutineDetailInfo(data: any) {
		const {
			routine_description,
			routine_name,
			routine_id,
			exercises = [],
		} = data;
		// set store property
		this.store?.setProperty('routine_description', routine_description);
		this.store?.setProperty('routine_id', routine_id);
		this.store?.setProperty('routine_name', routine_name);
		this.store?.setGrid('exercises', exercises);

		this.calculateMuscles();
		this.loadExerciseGoals();
	}

	async loadExerciseGoals() {
		const exercises = this.store.getProperty('exercises') || [];
		const routine_exercise_id_list: any[] = [];
		if (util.isArray(exercises)) {
			exercises.forEach((item) => {
				const {
					routine_exercise_id,
				} = item;

				if (routine_exercise_id) {
					routine_exercise_id_list.push(routine_exercise_id);
				}
			});
		}
		if (routine_exercise_id_list.length) {
			await this.api.postApiAsync(this.getUrl('loadExerciseGoalMultiple'), { routine_exercise_id: routine_exercise_id_list }, { name: 'loadExerciseGoalInfo' });
		}
	}

	resetExerciseGoals(data) {
		const exercises = this.store.getProperty('exercises') || [];
		if (util.isArray(data) && util.isArray(exercises)) {
			let updated = false;
			data.forEach((item) => {
				const {
					routine_exercise_id,
				} = item;

				if (routine_exercise_id) {
					const row = exercises.find((item) => item.routine_exercise_id === routine_exercise_id);
					if (row) {
						// transform item
						if (item.exercise_info) {
							item.exercise_info = util.tryParse(item.exercise_info, {});
							const {
								exercise_weight_goal = 0,
								exercise_info: {
									repFrom = 0,
									repTo = 0,
									setCount = 0,
								} = {},
							} = item;

							row.exercise_weight_goal = exercise_weight_goal;
							row.setCount = setCount;
							row.reps = `${repFrom} - ${repTo}`;
						}
						row.goalData = item;
						updated = true;
					}
				}
			});
			if (updated) {
				this.store.setGrid('exercises', [...exercises]);
			}
		}
	}

	calculateMuscles() {
		// get exercises
		const exercises = this.store.getProperty('exercises');
		// muscles parse
		const asyncFunctions: any = [];
		const hashMuscle: any = {};
		if (util.isArray(exercises)) {
			// create hash map -> id to value
			exercises.forEach((exercise) => {
				const {
					exercise_muscles,
				} = exercise;
				if (!exercise_muscles) return;
				
				if (util.isArray(exercise_muscles)) {
					exercise_muscles.forEach((muscleData) => {
						const {
							muscle_id: id,
							intensity,
						} = muscleData;
						if (id && (!hashMuscle[id] || hashMuscle[id].intensity < intensity)) {
							hashMuscle[id] = muscleData;
						}
					});
				}
			});
		}
		modelData.forEach((item) => {
			if (hashMuscle[item.id]) {
				const {
					intensity: value,
				} = hashMuscle[item.id];
				if (util.isNumber(value)) {
					asyncFunctions.push(() => {
						this.updateModelColor(item.id, value, false);
					});
				}
			}
		});
		if (asyncFunctions.length) {
			setTimeout(() => {
				asyncFunctions.forEach((itemFn) => {
					itemFn();
				})
			}, 500);
		}
	}

	uiHide(name: string, data: any): boolean {
		return false;
	}

	//#region routine schedule fn

	saveScheduleDetail() {
		const routine_id = this.store?.getProperty('routine_id');
		if (!routine_id) {
			this.errorNotification('choose a routine!');
			return;
		}
		const {
			monday,
			tuesday,
			wednesday,
			thursday,
			friday,
			saturday,
			sunday,
		} = this.store?.getProperty('editSchedule') || {};
		const schedules = [];
		const addSchedule = (date: string) => {
			schedules.push({
				schedule_start_date: date,
				recurring: true,
			});
		}

		if (monday) {
			addSchedule(util.getDate(util.DateDayofWeek.Monday));
		}
		if (tuesday) {
			addSchedule(util.getDate(util.DateDayofWeek.Tuesday));
		}
		if (wednesday) {
			addSchedule(util.getDate(util.DateDayofWeek.Wednesday));
		}
		if (thursday) {
			addSchedule(util.getDate(util.DateDayofWeek.Thursday));
		}
		if (friday) {
			addSchedule(util.getDate(util.DateDayofWeek.Friday));
		}
		if (saturday) {
			addSchedule(util.getDate(util.DateDayofWeek.Saturday));
		}
		if (sunday) {
			addSchedule(util.getDate(util.DateDayofWeek.Sunday));
		}

		// startLoading
		this.startBtnLoading('saveScheduleBtn');
		this.api.patchApiAsync(this.getUrl('saveRoutineSchedules'), { routine_id, schedules }, { name: 'editRoutineSchedule' });
	}

	updateEditScheduleInfo(routine_id?: string) {
		routine_id = routine_id || this.store.getProperty('routine_id');
		if (!routine_id) {
			return;
		}
		// based on routine id
		// update editSchedule info
		// get schedule info from store
		const scheduleInfo = this.store.getProperty('routineSchedules');
		if (util.isArray(scheduleInfo)) {
			const scheduleItem = scheduleInfo.find((item) => item.routine_id === routine_id);
			const days: string[] = [];
			if (scheduleItem) {
				// set from here
				const {
					schedule,
				} = scheduleItem;
				if (util.isStrictArray(schedule)) {
					// check each if each day of week exists

					schedule.forEach((item) => {
						const {
							schedule_start_date,
						} = item;
						// get day
						item.dayOfWeek = util.getDay(schedule_start_date);
					});

					const checkDayofWeek = (day: util.DateDayofWeek) => {
						const exist = schedule.findIndex((item) => item.dayOfWeek === day) !== -1;

						let storeName = '';
						let dayOfWeek = '';
						switch (day) {
							case util.DateDayofWeek.Monday:
								storeName = 'monday';
								dayOfWeek = 'M';
								break;
							case util.DateDayofWeek.Tuesday:
								storeName = 'tuesday';
								dayOfWeek = 'Tu';
								break;
							case util.DateDayofWeek.Wednesday:
								storeName = 'wednesday';
								dayOfWeek = 'W';
								break;
							case util.DateDayofWeek.Thursday:
								storeName = 'thursday';
								dayOfWeek = 'Th';
								break;
							case util.DateDayofWeek.Friday:
								storeName = 'friday';
								dayOfWeek = 'F';
								break;
							case util.DateDayofWeek.Saturday:
								storeName = 'saturday';
								dayOfWeek = 'Sa';
								break;
							case util.DateDayofWeek.Sunday:
								storeName = 'sunday';
								dayOfWeek = 'Su';
								break;
							default:
								break;
						}
						if (storeName) {
							this.store.setProperty(['editSchedule', storeName], exist);
							if (exist) {
								days.push(dayOfWeek);
							}
						}
					}

					checkDayofWeek(util.DateDayofWeek.Monday);
					checkDayofWeek(util.DateDayofWeek.Tuesday);
					checkDayofWeek(util.DateDayofWeek.Wednesday);
					checkDayofWeek(util.DateDayofWeek.Thursday);
					checkDayofWeek(util.DateDayofWeek.Friday);
					checkDayofWeek(util.DateDayofWeek.Saturday);
					checkDayofWeek(util.DateDayofWeek.Sunday);
					if (days.length) {
						// set frequency ez to read message
						const message = `${days.length}/Wk ${days.join(', ')}`;
						this.store.setProperty('routine_frequency', message);
					} else {
						// set empty
						this.store.setProperty('routine_frequency', '');
					}
				}
			}
		}
	}
	
	async loadRoutineSchedules() {
		// load exercise data for select list
		await this.api.getApiAsync(this.getUrl('routineSchedules'), { }, { name: 'loadRoutineSchedules' });
	}

	/**
	 * allow reset without pass data
	 * @param data 
	 */
	setRoutineScheduleInfo(data?: any[]) {
		data = data || this.store.getProperty('routineSchedules');
		if (util.isStrictArray(data)) {
			// set initial
			this.store.setProperty('monday', []);
			this.store.setProperty('tuesday', []);
			this.store.setProperty('wednesday', []);
			this.store.setProperty('thursday', []);
			this.store.setProperty('friday', []);
			this.store.setProperty('saturday', []);
			this.store.setProperty('sunday', []);
			data.forEach((item) => {
				const {
					schedule,
					routine_name,
					routine_id,
				} = item;
				// push each schedule into 

				if (util.isArray(schedule)) {
					schedule.forEach((single) => {
						if (single.schedule_start_date && single.recurring) {
							const dayOfWeek = util.getDay(single.schedule_start_date);

							let storeName = '';
							switch (dayOfWeek) {
								case util.DateDayofWeek.Monday:
									storeName = 'monday';
									break;
								case util.DateDayofWeek.Tuesday:
									storeName = 'tuesday';
									break;
								case util.DateDayofWeek.Wednesday:
									storeName = 'wednesday';
									break;
								case util.DateDayofWeek.Thursday:
									storeName = 'thursday';
									break;
								case util.DateDayofWeek.Friday:
									storeName = 'friday';
									break;
								case util.DateDayofWeek.Saturday:
									storeName = 'saturday';
									break;
								case util.DateDayofWeek.Sunday:
									storeName = 'sunday';
									break;
								default:
									break;
							}

							// add to store
							this.store.addGridRow(storeName, {
								routine_name,
								routine_id,
							});
						}
					});
				}
			});

			// set inside store as well
			this.store.setProperty('routineSchedules', data);
		}
		// try to load current routine_id
		this.updateEditScheduleInfo();
	}
	//#endregion routine schedule fn
}