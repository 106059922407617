import React from "react";

import { 
	AddIcon,
	ArrowBackIcon, 
	ArrowDownIcon, 
	ArrowForwardIcon,
	ArrowUpIcon,
	CheckCircleIcon,
	CheckIcon,
	CloseIcon,
	DeleteIcon,
	FavouriteIcon,
	HamburgerIcon,
	InfoIcon,
	MinusIcon,
	PlayIcon,
	SearchIcon,
	ShareIcon,
	SmallCloseIcon,
	ThreeDotsIcon,
	Icon,
	ChevronDownIcon,
} from "native-base";

import menuMeIcon from "../assets/icon/menuMe";
import menuExerciseIcon from "../assets/icon/menuExercise";
import menuRoutineIcon from "../assets/icon/menuRoutine";
import menuStartIcon from "../assets/icon/menuStart";
import menuStatsIcon from "../assets/icon/menuStats";


import { 
	MaterialCommunityIcons,
	// AntDesign,
	// Entypo,
	Feather
} from "@expo/vector-icons";
import { themeColors } from "./colors";

const EditIcon = ({ backgroundColor=themeColors.secondaryBase }) => {
	return (
		<Icon 
			as={Feather} 
			name="edit-2" 
			color="white" 
			backgroundColor={backgroundColor}
			borderRadius={'4px'}
		/>
	);
}


export const icons = {
	'add': AddIcon,
	'arrowback': ArrowBackIcon,
	'arrowforward': ArrowForwardIcon,
	'arrowup': ArrowUpIcon,
	'arrowdown': ArrowDownIcon,
	'check': CheckIcon,
	'checkcircle': CheckCircleIcon,
	'menu': HamburgerIcon,
	'close': CloseIcon,
	'smallclose': SmallCloseIcon,
	'info': InfoIcon,
	'minus': MinusIcon,
	'search': SearchIcon,
	'share': ShareIcon,
	'play': PlayIcon,
	'favourite': FavouriteIcon,
	'threedots': ThreeDotsIcon,
	'delete': DeleteIcon,
	'edit': EditIcon,
	'down': ChevronDownIcon,
	'menuMe': menuMeIcon,
	// 'menuMe': ChevronDownIcon,
	'menuStats': menuStatsIcon,
	// 'menuStats': ChevronDownIcon,
	'menuStart' : menuStartIcon,
	// 'menuStart': ChevronDownIcon,
	'menuRoutine': menuRoutineIcon,
	// 'menuRoutine': ChevronDownIcon,
	'menuExercise': menuExerciseIcon,
	// 'menuExercise': ChevronDownIcon,
} as const;