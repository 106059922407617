import { ThemeComponentSizeType } from 'native-base/lib/typescript/components/types';
import React, { useEffect, useState } from 'react';
import { View, StyleSheet } from 'react-native';
import { FAButton } from '../button';
import * as util from '../../util';
import { serviceBase } from '../../service';

interface TabsPane {
	content: JSX.Element;
	title: string;
}

interface TabsProps {
	tabs: TabsPane[];
	name: string;
	service?: serviceBase;
	size?: ThemeComponentSizeType<'Button'>;
}

/**
 * this layout component to be used in each page and control certain qualities through ui store
 * @param props 
 * @returns 
 */
export const Tabs: React.FC<TabsProps> = (props: TabsProps) => {
	const {
		tabs,
		name,
		size,
		service,
	} = props;

	const getDefaultTab = () => {
		if (service) {
			const serviceCache = service.getCache('tabs', name);
			if (serviceCache) {
				return serviceCache;
			}
		}
		return tabs.length ? tabs[0].title : undefined;
	};

	const [currentTab, setCurrentTab] = useState(getDefaultTab());

	const [tabCache] = useState({ [currentTab]: true, });
	useEffect(() => {
		// on mount get cache
		// const getCache = async () => {
		// 	const cache = await util.getCache('tabs', name);
		// 	if (cache && tabs.some((item) => item.title === cache)) {
		// 		tabCache[cache] = true;
		// 		setCurrentTab(cache);
		// 	}
		// };

		// getCache();

		return () => {
			// set current cache
			setCurrentTab((currentTab) => {
				// util.setCache('tabs', name, currentTab);
				if (service) {
					service.setCache('tabs', name, currentTab);
				}
				return currentTab;
			});
		};
	}, []);

	console.log('Render Tabs');
	return (
		<View style={styles.container} key={name}>
			<View style={styles.menu}>
				{tabs.map((tab) => {
					return (
						<View style={styles.menuItem} key={tab.title}>
							<FAButton 
								name='menuItem' 
								variant={tab.title === currentTab ? 'solid' : 'subtle'} 
								onClick={() => { tabCache[tab.title] = true; setCurrentTab(tab.title); }}
								size={size}
								width={'100%'}
							>
								{tab.title}
							</FAButton>
						</View>
					);
				})}
			</View>
			{tabs.map((tab) => {
				if (!tabCache[tab.title]) {
					return null
				}
				return (
					<View style={[styles.tabContent, { display: tab.title === currentTab ? 'flex' : 'none' }]} key={tab.title}>
						{tab.content}
					</View>
				);
			})}
		</View>
	);
};

const styles = StyleSheet.create({
	container: {
		flex: 1,
		overflow: 'visible'
	},
	menu: {
		display: 'flex',
		flexDirection: 'row',
	},
	menuItem: {
		flex: 1,
	},
	tabContent: {
		flex: 1,
		overflow: 'scroll',
	},
});
