import { serviceBase } from '../../../service';
import { CustomOptions } from '../../../service/api';
import { getAppStateData, getAppStateStore, setAppStateStore, UIStoreInit } from '../../../store';

export class SettingsService extends serviceBase {
	constructor(name: string, store: any, uiState: UIStoreInit) {
		super(name, store, uiState);

		this.responseData = this.responseData.bind(this);
		this.getSubmitData = this.getSubmitData.bind(this);
		this.transformData = this.transformData.bind(this);

		this.loadData = this.loadData.bind(this);
	}


	loadData() {
		// set app state data username to local store
		const username = getAppStateData('username');
		const email = getAppStateData('email');
		this.store.setProperty('username', username);
		this.store.setProperty('email', email);
	}

	logout() {
		setAppStateStore('username', '');
		setAppStateStore('email', '');
		setAppStateStore('Authorization', '');
	}

	/**
     * get api data before api call
     * @param options 
     * @returns 
     */
	getSubmitData(options: CustomOptions) {
		return {};
	}

	/**
     * transform response function after api call 
     * @param data 
     * @param options 
     */
	transformData(data: any, options: CustomOptions) {
		const { name } = options;
		return data;
	}

	/**
     * onClick fn handler
     * @param value 
     * @param data 
     * @param props 
     */
	onClick(value: any, data: any, props: any) {
		const { name, routes } = props;
		switch (name) {
		case 'routineBtn':
			this.redirectTo(this.getPath('routine'));
			break;
		case 'exerciseBtn':
			this.redirectTo(this.getPath('exercise'));
			break;
		case 'workoutBtn':
			this.redirectTo(this.getPath('workout'));
			break;
		case 'workoutHistory':
			this.redirectTo(this.getPath('workout'));
			break;
		case 'workoutHistory':
			this.redirectTo(this.getPath('workout'));
			break;
		case 'about':
			this.redirectTo(this.getPath('about'));
			break;
		case 'weightBtn':
			this.redirectTo(this.getPath('weight'));
			break;
		case 'routineScheduleBtn':
			this.redirectTo(this.getPath('routineschedule'));
			break;
		case 'systemSettingsBtn':
			this.redirectTo(this.getPath('systemsettings'));
			break;
		case 'logoutBtn':
			this.logout();
			break;
		default:
			break;
		}
	}

	/**
     * response function after api call
     * @param data 
     * @param options 
     */
	responseData(data: any, options: CustomOptions): void {
		const { name } = options;
	}

	resetData(data: any) {
		
	}
}