import { Dimensions } from 'react-native';
import { ComponentEnum, icons } from '../../enums';
import { UIStoreInit } from '../../store';
import { addNewBtn, saveBtn, addBtn } from '../common';

export const uiState: UIStoreInit = {
	button: {
		saveBtn,
		addBtn: {
			componentType: ComponentEnum.ButtonTypeEnum.Button,
			name: 'addBtn',
			// color: 'indigo.600',
			style: {
				width: 120,
				height: 40,
				borderRadius: 40,
			},
			backgroundColor: 'gray.400',
			_text: {
				color: 'black',
				fontWeight: 600,
			},
			icon: icons.add,
			children: 'Schedule'
		},
		editBtn: {
			componentType: ComponentEnum.ButtonTypeEnum.Button,
			name: 'editBtn',
			// color: 'indigo.600',
			style: {
				width: 100,
				height: 40,
				borderRadius: 40,
			},
			backgroundColor: 'gray.400',
			_text: {
				color: 'black',
				fontWeight: 600,
			},
			icon: icons.add,
			children: 'Edit'
		},
		addIconBtn: {
			componentType: ComponentEnum.ButtonTypeEnum.Icon,
			name: 'addIconBtn',
			// color: 'indigo.600',
			// style: {
			// 	width: 60,
			// 	height: 40,
			// 	borderRadius: 40,
			// },
			// backgroundColor: 'gray.400',
			// _text: {
			// 	color: 'black',
			// 	fontWeight: 600,
			// },
			icon: icons.add,
			children: 'Edit'
		},
		startRoutineBtn: {
			componentType: ComponentEnum.ButtonTypeEnum.Button,
			name: 'startRoutineBtn',
			color: 'indigo.600',
			style: {
				width: 80,
			},
			children: 'Start',
			icon: icons.play,
		},
		continueRoutineBtn: {
			componentType: ComponentEnum.ButtonTypeEnum.Button,
			name: 'continueRoutineBtn',
			color: 'indigo.600',
			style: {
				width: 100,
			},
			icon: icons.play,
			children: 'Continue'
		},
	},
	input: {
		routine_name: {
			name: 'routine_name',
			routes: 'routine_name',
			placeholder: 'Name',
			label: 'Name',
		},
		routine_description: {
			name: 'routine_description',
			routes: 'routine_description',
			placeholder: 'Description',
			label: 'Description',
		},
		exerciseTypeId: {
			name: 'exerciseTypeId',
			componentType: ComponentEnum.InputTypeEnum.Select,
			routes: 'exerciseTypeId',
			placeholder: 'Exercise',
			label: 'Exercise',
			items: [],
		},
		routineId: {
			name: 'routineId',
			componentType: ComponentEnum.InputTypeEnum.Select,
			routes: 'routineId',
			placeholder: 'Routine',
			label: 'Routine',
			items: [],
		},
	},
	typography: {
	},
	layout: {
		menuEnabled: true,
		modelEnabled: true,
		header: 'Schedule',
		headerEnabled: false,
		menuBackground: '#FFFFFF',
		// backEnabled: true,
		settingsEnabled: true,
	},
	grid: {
		exercises: {
			name: 'exercises',
			routes: 'exercises',
			titleData: 'exercise_type_name',
			subtitleData: 'exercise_type_description',
		},
	},
	modal: {
		addSchedule: {
			name: 'addSchedule',
			title: 'Add Schedule',
			width: Dimensions.get('window').width * .9,
			height: Dimensions.get('screen').height,
		},
		scheduleModal: {
			name: 'scheduleModal',
			componentType: ComponentEnum.ModalTypeEnum.Modal,
			title: 'No routine',
			okText: 'Yes',
			cancelText: 'No',
		},
	},
};
