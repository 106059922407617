import React, { useEffect } from 'react';
import { View, StyleSheet } from 'react-native';
import { useWeightService } from './service';
import { uiState } from './weight.ui';
import { WeightStore } from './weight.store';
import { FAStoreTypography, FAStoreInput, PageLayout, FAHeader, FAStoreGrid, FAStoreButton, FAStoreModal, FAStoreChart } from '../../components';
import { useServiceRouter } from '../../service';

export const Weight: React.FC = () => {
	const screenName = 'WeightPage';
	const service = useWeightService(screenName, WeightStore, uiState);
	useServiceRouter(service);

	useEffect(() => {
		service.loadData();
	}, []);

	console.log('Render Weight screen');
	return (
		<PageLayout>
			<FAStoreModal name='editModal'
				component={() => {
					return <FAStoreInput name='editWeight' />;
				}}
			/>
			<FAStoreModal name='addModal'
				component={() => {
					return <FAStoreInput name='addWeight' />;
				}}
			/>
			<FAStoreChart name='weightList' />
			<FAStoreButton name='addNewBtn' />
			<FAStoreGrid name='weightList'/>
		</PageLayout>
	);
};
