import { getService } from './servicePool';
import * as util from '../util';
import { uiStoreEnum, useStoreProps, useUiProps } from '../store';

/**
 * add service props
 * @param props 
 */
export const useServiceProps = (props: any) => {
	const service = getService();
	if (service) {
		if (!props.onClick) props.onClick = service.onClick;
		if (!props.onBlur) props.onBlur = service.onBlur;
		if (!props.onChange) props.onChange = service.onChange;
		if (!props.onOk) props.onOk = service.onOk;
		if (!props.onCancel) props.onCancel = service.onCancel;
		if (!props.uiReadOnly) props.uiReadOnly = service.uiReadOnly;
		props.addComponentHook = service.addComponentHook;
		props.uiHide = service.uiHide; // hide component
		props.getService = () => service;
	}

	return props;
};

/**
 * connect ui state for layout only (entire object)
 * @param props 
 */
 export const useLayoutProps = (props: any) => {
	let attributes = { ...props };
	attributes = useUiProps('layout', attributes);
	
	return attributes;
};

/**
 * connect service, store and ui state
 * @param props 
 */
export const useComponentProps = (props: any, type: keyof typeof uiStoreEnum = 'input', store = true, grid = false) => {
	const { 
		name
	} = props;
	let attributes = useServiceProps(util.cloneDeep(props));
	attributes = useUiProps([type, name], attributes);
	const {
		routes
	} = attributes;
	if (routes && store) attributes = useStoreProps(routes, attributes);
	return attributes;
};

/**
 * connect ui state for chart only
 * @param props 
 */
 export const useChartProps = (props: any) => {
	const { 
		name
	} = props;
	let attributes = useUiProps(['chart', name], util.cloneDeep(props));
	const {
		routes
	} = attributes;
	if (routes) {
		attributes = useStoreProps(routes, attributes);
	}
	return attributes;
};
