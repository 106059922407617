import React, { useEffect } from 'react';
import { StyleSheet, View } from 'react-native';
import { useSystemSettingsService } from './service';
import { uiState } from './systemSettings.ui';
import { SystemSettingsStore } from './systemSettings.store';
import { FAButton, FAHeader, FAStoreButton, FAStoreInput, FAStoreModal, FAStoreTypography, PageLayout } from '../../components';
import { useServiceRouter } from '../../service';
import { useComponentRefresh } from '../../hooks';

export const SystemSettings: React.FC = (props: any) => {
	const screenName = 'SystemSettingsPage';
	const service = useSystemSettingsService(screenName, SystemSettingsStore, uiState);
	useServiceRouter(service);

	useEffect(() => {
		service.loadData();
	}, []);

	console.log('Render SystemSettings screen');
	return (
		<PageLayout>
			{/* update username */}
			<View style={styles.username}>
				<View style={styles.input}>
					<FAStoreInput name='newUsername' />
				</View>
				<View style={styles.btn}>
					<FAStoreButton name='updateUsernameBtn' />
				</View>
			</View>
			{/* update email */}
			<View style={styles.email}>
				<View style={styles.input}>
					<FAStoreInput name='newEmail' />
				</View>
				<View style={styles.btn}>
					<FAStoreButton name='updateEmailBtn' />
				</View>
			</View>
			{/* update password */}
			<View style={styles.password}>
				<View style={styles.input}>
					<FAStoreInput name='newPassword' />
					<FAStoreInput name='confirmPassword' />
				</View>
				<View style={styles.btn}>
					<FAStoreButton name='updatePassBtn' />
				</View>
			</View>
			<View style={styles.btn}>
				<FAStoreButton name='deleteAccountBtn' />
			</View>
			<FAStoreModal name='passModal'
				component={() => {
					return (
						<FAStoreInput name='currentPassword' />
					);
				}}
			/>
		</PageLayout>
	);
};

const styles = StyleSheet.create({
	username: {

	},
	email: {

	},
	password: {

	},
	input: {

	},
	btn: {

	},
});
