import React, { useEffect, useRef } from 'react';
import { FAStoreModal } from './FAStoreModal';
import { View } from 'native-base';
import { FAStoreInput } from '../input';
import { getService } from '../../service';

interface Props {
    name: string;
    inputName: string;
    label: string;
}

export const FAModalInput: React.FC<Props> = (props: Props) => {
    const {
        name,
        inputName,
        label,
    } = props;

    return (
        <FAStoreModal
            name={name}
            title={label}
            component={() => {
                return (
                    <View
                    >
                        <FAStoreInput name={inputName} />
                    </View>
                );
            }}
        />
    );
};

