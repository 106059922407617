export const WorkoutStore = {
	workoutTest: 'workoutTest',
	workoutList: [],
	exerciseList: [],
	routineList: [],
	exerciseSelect: '',
	addExerciseTypeId: '',
	addExerciseWeight: 0,
	addComment: '',
	addExerciseReps: 0,
	editExerciseTypeId: '',
	editExerciseWeight: 0,
	editExerciseReps: 0,
	editComment: '',
	workout_log_id: '',
	workoutWorkingList: [],
	editWorkingList: [],
	exerciseSelectList: [],
	routineSelectList: [],
	routineId: '',
};
