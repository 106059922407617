import { Dimensions } from 'react-native';
import { ComponentEnum, themeColors } from '../../enums';
import { UIStoreInit } from '../../store';
import { addNewBtn, saveBtn, addBtn } from '../common';
import { btnStyle, textStyle } from '../../enums/styling';

export const uiState: UIStoreInit = {
	button: {
		addNewBtn,
		saveBtn,
		addBtn,
		editAddBtn: {
			name: 'editAddBtn',
			children: 'Add+',
			size: 'sm',
		},
		createExercise: {
			name: 'createExercise',
			children: 'Or Create',
			size: 'sm',
		},
		createRoutine: {
			name: 'createRoutine',
			children: 'Or Create',
			size: 'sm',
		},
		startBtn: {
			name: 'startBtn',
			children: 'Start',
			size: 'sm',
		},
		btnDeleteAllSet: {
			name: 'btnDeleteAllSet',
		},
		btnAddSets: {
			name: 'btnAddSets',
			children: 'Add Sets',
			style: {...btnStyle.info_blue, padding: 8, backgroundColor: 'transparent', marginTop: 2},
			_text: {
				color: themeColors.secondaryBase,
				...textStyle.btnText_Regular
				
			},
			// icon: icons.add
			// borderColor: 'gray.600',
			// borderWidth: 4,
			// borderRadius: 20,
			// width: '50%',
		},
	},
	input: {
		startWorkoutTest: {
			componentType: ComponentEnum.InputTypeEnum.TextArea,
			name: 'startWorkoutTest',
			placeholder: 'StartWorkout Test',
			label: 'StartWorkout Test',
			routes: 'startWorkoutTest',
		},
		exerciseSelect: {
			componentType: ComponentEnum.InputTypeEnum.Select,
			name: 'exerciseSelect',
			placeholder: 'StartWorkout Test',
			label: 'StartWorkout Test',
			items: ['test'],
			routes: 'exerciseSelect',
		},
		addExerciseTypeId: {
			componentType: ComponentEnum.InputTypeEnum.Select,
			routes: 'addExerciseTypeId',
			placeholder: 'Exercise',
			label: 'Exercise',
			items: [],
		},
		addExerciseWeight: {
			routes: 'addExerciseWeight',
			placeholder: 'Weight',
			label: 'Weight',
			format: 'number',
		},
		addComment: {
			routes: 'addComment',
			placeholder: 'Comment',
			label: 'Comment',
		},
		addExerciseReps: {
			routes: 'addExerciseReps',
			placeholder: 'Reps',
			label: 'Reps',
			format: 'number',
		},
		editExerciseTypeId: {
			componentType: ComponentEnum.InputTypeEnum.Select,
			routes: 'editExerciseTypeId',
			placeholder: 'Exercise',
			label: 'Exercise',
			items: [],
		},
		editExerciseWeight: {
			routes: 'editExerciseWeight',
			placeholder: 'Weight',
			label: 'Weight',
			format: 'number',
		},
		editComment: {
			routes: 'editComment',
			placeholder: 'Comment',
			label: 'Comment',
		},
		editExerciseReps: {
			routes: 'editExerciseReps',
			placeholder: 'Reps',
			label: 'Reps',
			format: 'number',
		},
		routineId: {
			componentType: ComponentEnum.InputTypeEnum.Select,
			routes: 'routineId',
			placeholder: 'Routine',
			label: 'Routine',
			items: [],
		},
	},
	typography: {
		startWorkoutTest: {
			componentType: ComponentEnum.TypographyTypeEnum.Text,
			name: 'startWorkoutTest',
			placeholder: 'StartWorkout Test',
			label: 'StartWorkout Test',
			routes: 'startWorkoutTest',
		},
		username: {
			componentType: ComponentEnum.TypographyTypeEnum.Header,
			routes: 'username',
		},
		dateMonth: {
			componentType: ComponentEnum.TypographyTypeEnum.Text,
			routes: 'dateMonth',
		},
		dateHour: {
			componentType: ComponentEnum.TypographyTypeEnum.Text,
			routes: 'dateHour',
		},
		dateDay: {
			componentType: ComponentEnum.TypographyTypeEnum.Text,
			routes: 'dateDay',
		},
		// GRID TYPOGRAPHY
		routine_exercise_name: {
			componentType: ComponentEnum.TypographyTypeEnum.Header,
			routes: 'routine_exercise_name',
			// placeholder: 'Reps',
			label: 'Name',
		},
	},
	layout: {
		menuEnabled: true,
		// modelEnabled: true,
		// header: 'StartWorkout',
		// settingsEnabled: true,
		// backEnabled: true,
		bg: 'indigo.400',
	},
	grid: {
		simpleList: {
			componentType: ComponentEnum.GridTypeEnum.Simple,
			routes: 'simpleList',
			idData: 'startWorkout_log_id',
		},
		startWorkoutList: {
			routes: 'startWorkoutList',
			titleData: 'startWorkout_title',
			subtitleData: 'startWorkout_subTitle',
			// subtitleData: 'startWorkout_comment',
			editBtn: true,
			idData: 'startWorkout_log_id',
		},
		routineList: {
			routes: 'routineList',
			titleData: 'routine_name',
			subtitleData: 'routine_completed',
			editBtn: true,
			idData: 'routine_log_id',
		},
		startWorkoutWorkingList: {
			routes: 'startWorkoutWorkingList',
			titleData: 'set_reps',
			titleUnits: 'Reps',
			subtitleData: 'set_weight',
			subtitleUnits: 'lbs.',
		},
		editWorkingList: {
			routes: 'editWorkingList',
			titleData: 'set_reps',
			titleUnits: 'Reps',
			subtitleData: 'set_weight',
			subtitleUnits: 'lbs.',
		},
	},
	modal: {
		editModal: {
			componentType: ComponentEnum.ModalTypeEnum.Modal,
			title: 'Edit StartWorkout',
			okText: 'Save Edit',
		},
		addModal: {
			title: 'Add StartWorkout',
			okText: 'Add',
		},
		selectExercise: {
			componentType: ComponentEnum.ModalTypeEnum.Full,
			forceUmmount: true,
			height: Dimensions.get('window').height,
			style: {
				// borderColor: 'black',
				// borderWidth: 1,
			}
		},
	},
};