import React, { useEffect, useState } from 'react';
import { View, StyleSheet } from 'react-native';
import { useOnboardingService } from './service';
import { uiState } from './onboarding.ui';
import { OnboardingStore } from './onboarding.store';
import { FAButton, FAHeader, FAIconButton, FAStoreButton, FAStoreGrid, FAStoreInput, FAText, PageLayout } from '../../components';
import { useServiceRouter } from '../../service';
import { icons } from '../../enums';
import { FASlider } from '../../components/input/FASlider';
import { getDim } from '../../util';

interface PageDefine {
	title: string;
	subtitle: string;
	other: React.FC<any>;
}

const pages: PageDefine[] = [
	{
		title: 'How old are you?',
		subtitle: 'Help us create your personalized plan!',
		other: () => {
			return (
				<>
					<FAStoreInput name='age' />
				</>
			);
		},
	},
	{
		title: 'What is your weight?',
		subtitle: 'Help us create your personalized plan!',
		other: () => {
			return (
				<>
					<FAStoreInput name='weight' />
					<FAStoreInput name='weightUnit' />
				</>
			);
		},
	},
	{
		title: 'What is your height?',
		subtitle: 'Help us create your personalized plan!',
		other: () => {
			return (
				<>
					<FAStoreInput name='height' />
					<FAStoreInput name='heightUnit' />
				</>
			);
		},
	},
	{
		title: 'What is your goal?',
		subtitle: 'Help us create your personalized plan!',
		other: () => {
			return (
				<>
					{/* select option with buttons */}
					<FAStoreInput name='selectGoal' />
				</>
			);
		},
	},
	{
		title: 'What is your physical activity level?',
		subtitle: 'Help us create your personalized plan!',
		other: () => {
			return (
				<>
					{/* select option with buttons */}
					<FAStoreInput name='selectLevel' />
				</>
			);
		},
	},
	{
		title: 'Download some default or community routines!',
		subtitle: 'Help us create your personalized plan!',
		other: () => {
			return (
				<>
				<FAHeader name='welcome'>Download some routines to help you get started!</FAHeader>
				<FAText name='default'>Default Routines</FAText>
				<View style={styles.grid}>
					<FAStoreGrid name='defaultRoutines' />
				</View>
				<FAText name='default'>Top Shared Routines</FAText>
				<View style={styles.grid}>
					<FAStoreGrid name='sharedRoutines' />
				</View>
				</>
			);
		},
	},
];

export const Onboarding: React.FC = () => {
	const screenName = 'OnboardingPage';
	const service = useOnboardingService(screenName, OnboardingStore, uiState);
	const [page, setPage] = useState(0);
	useServiceRouter(service);

	const initialLoad = () => {
		service.initialLoad();
	};

	const increment = () => {
		if (page < pages.length - 1) {
			setPage((page) => page + 1);
		}
	};
	
	const decrement = () => {
		if (page > 0) {
			setPage((page) => page - 1);
		} else if (page === 0) {
			// does not matter success or not
			service.redirectTo(service.getPath('home'), { is_onboarded: true });
		}
	};

	useEffect(initialLoad, []);

	const {
		other: OtherComponent,
		title,
		subtitle,
	} = pages[page];
	console.log('Render Onboarding screen');
	return (
		<PageLayout>
			<View style={styles.layout}>
				{/* 
					slider
				*/}
				<View style={styles.slider}>
					{/* back btn */}
					<FAIconButton
						name='back'
						icon={icons.arrowback}
						onClick={decrement}
					/>
					{/* slider */}
					<FASlider
						name='slider'
						value={page}
						maxValue={pages.length - 1}
						minValue={0}
						showSliderSummary
					/>
				</View>
				<View style={styles.content}>
					{/* 
						title
					*/}
					<View style={styles.text}>
						<FAHeader name='title' bold>{title}</FAHeader>
					</View>
					<View style={styles.text}>
						<FAText name='subtitle'>{subtitle}</FAText>
					</View>
					{/* other component -> inputs */}
					{
						OtherComponent && <OtherComponent />
					}
				</View>
				{/* 
					bottom
				*/}
				{
					page === pages.length - 1 ? (
						// last page
						<>
							<FAStoreButton name='goToAppBtn' />
						</>
					) : (
						// not last page
						<>
							<FAButton
								name='increment'
								onClick={increment}
								children='Next'
								style={{
									borderRadius: 20,
								}}
							/>
						</>
					)
				}
			</View>
		</PageLayout>
	);
};
const styles = StyleSheet.create({
	text: {
		padding: 10,
	},
	layout: {
		flexGrow: 1,
		display: 'flex',
		flexDirection: 'column',
		width: '100%',
		height: '100%',
		justifyContent: 'space-between',
		paddingVertical: 20,
		paddingHorizontal: 10,
	},
	grid: {
		flexGrow: 1,
		width: '100%',
	},
	slider: {
		display: 'flex',
		flexDirection: 'row',
	},
	content: {
		display: 'flex',
		flexDirection: 'column',
		justifyContent: 'center',
		width: '100%',
		alignItems: 'center',
	},
});