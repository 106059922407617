import { getService, useService } from '../../../service';
import { UIStoreInit } from '../../../store';
import { WeightService } from './WeightService';

/**
 * get service - create if not created yet
 * @param name 
 * @param store 
 * @param uiState 
 * @returns 
 */
export const useWeightService = (name: string, store: any, uiState: UIStoreInit) => {
	const service = getService(name) || new WeightService(name, store, uiState);
	useService(name, service);
	return service as WeightService;
};
