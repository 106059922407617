import { modelData } from "../../components/threejs/modelData";
import { ModelMuscles } from "../../enums";

export const ExerciseDetailStore = {
	exerciseTest: 'exerciseTest',
	exerciseList: [],
	systemSwitch: false,
	addName: '',
	addDescription: '',
	exercise_type_id: '',
	exercise_type: '',
	exerciseMuscles: modelData.filter((item) => !item.notMuscle).map((item) => {
		return ({
			muscleName: item.id,
			value: 0,
			id: item.id,
		});
	}),
	iscalisthenics: false,
	isbarbell: false,
};
