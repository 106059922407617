const muscleColors = {
    0: '#e8d8da',
    1: '#e8bcc1',
    2: '#e6aab1',
    3: '#e89ba4',
    4: '#e88994',
    5: '#e87986',
    6: '#e66a78',
    7: '#e35b6a',
    8: '#e04f5f',
    9: '#e64052',
    10: '#ff4052',
};


export const getMuscleColor = (num: number) => {
    num = Math.floor(num / 10); 
    return muscleColors[num];
};

export const themeColors = {
    primaryBase: '#5cc0be',
    primaryDark: '#00827f',
    primaryLight: '#acdfde',
    primaryLighter: '#ddf2f2',
    secondaryBase: '#3b506b',
    accent: '#6fffe9',
    accentLight: '#d0f0f0',
    secondaryDark: '#1c2541',
    gradient: 'linear-gradient(274deg, #5CC0BE 0%, #6FFFE9 124.45%)',
    thirdColorScheme: 'teal',
    secondaryColorScheme: 'darkBlue',
    black: '#0b132b',
    trueBlack: '#000',
    white: '#fff',
    gray: '#eaeaea',
    darkGray: '#5a5a5a',
    error: '#ffa3a3'
}


export const scheduleColors = [
    '#BF1A2F',
    '#F00699',
    '#454E9E',
    '#018E42',
    '#F7D002',
    '#F6AF65',
    '#0A1045',
    '#00C2D1',
];
