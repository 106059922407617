import { paths } from '../../../enums';
import { serviceBase } from '../../../service';
import { CustomOptions } from '../../../service/api';
import { setAppStateStore, UIStoreInit } from '../../../store';
import * as util from '../../../util';

export class OnboardingService extends serviceBase {
	constructor(name: string, store: any, uiState: UIStoreInit) {
		super(name, store, uiState);

		this.responseData = this.responseData.bind(this);
		this.getSubmitData = this.getSubmitData.bind(this);
		this.transformData = this.transformData.bind(this);

	}


	finishOnboarding() {

		// startLoading
		this.startBtnLoading('goToAppBtn');
		// pass some onboarding info
		const age = this.store.getProperty('age');
		const height = this.store.getProperty('height');
		const heightUnit = this.store.getProperty('heightUnit');
		const weight = this.store.getProperty('weight');
		const weightUnit = this.store.getProperty('weightUnit');
		const selectGoal = this.store.getProperty('selectGoal');
		const selectLevel = this.store.getProperty('selectLevel');
		this.api.patchApiAsync(this.getUrl('finishOnboarding'), {
			user_age: age,
			user_height: height,
			user_height_unit: heightUnit,
			user_weight: weight,
			user_weight_unit: weightUnit,
			user_goal: selectGoal,
			user_level: selectLevel,
		}, { name: 'finishOnboarding' });
	}

	initialLoad() {
		// load default data
		const state = this.routing.location.state || {};
		const {
			onboardingInfo = {},
		} = state;

		if (util.isObject(onboardingInfo)) {
			const {
				user_age,
				user_height,
				user_height_unit,
				user_weight,
				user_weight_unit,
				user_goal,
				user_level,
			} = onboardingInfo;

			if (user_age) this.store.setProperty('age', user_age);
			if (user_height) this.store.setProperty('height', user_height);
			if (user_height_unit) this.store.setProperty('heightUnit', user_height_unit);
			if (user_weight) this.store.setProperty('weight', user_weight);
			if (user_weight_unit) this.store.setProperty('weightUnit', user_weight_unit);
			if (user_goal) this.store.setProperty('selectGoal', user_goal);
			if (user_level) this.store.setProperty('selectLevel', user_level);
		}

		// load routines data
		this.api.getApiAsync(this.getUrl('onboardingRoutines'), {}, { name: 'onboardingRoutines' });
	}

	/**
     * get api data before api call
     * @param options 
     * @returns 
     */
	getSubmitData(options: CustomOptions) {
		const { name } = options;
		return {};
	}

	/**
     * transform response function after api call 
     * @param data 
     * @param options 
     */
	transformData(data: any, options: CustomOptions) {
		const { name } = options;
		if (name === 'onboardingRoutines') {
            
			return data;
		}
		return data;
	}

	/**
     * onClick fn handler
     * @param value 
     * @param data 
     * @param props 
     */
	onClick(value: any, data: any, props: any) {
		const { name } = props;
		switch (name) {
		case 'goToAppBtn':
			this.finishOnboarding();
			break;
		case 'workoutPage':
			this.redirectTo(paths.workout);
			break;
		case 'sharedRoutines-infobtn':
		case 'defaultRoutines-infobtn':
			this.downloadRoutine(data);
		default:
			break;
		}
	}

	downloadRoutine(data: any) {
		const {
			routine_id,
			routine_name,
		} = data;

		if (routine_id) {
			this.api.getApiAsync(this.getUrl('downloadRoutine'), { routine_id }, { name: 'downloadRoutine', otherOptions: { routine_name } });
		}
	}

	/**
     * response function after api call
     * @param data 
     * @param options 
     */
	responseData(data: any, options: CustomOptions): void {
		const { name, otherOptions: { routine_name = '' } = {} } = options;
		if (name === 'onboardingRoutines') {
			if (data.success) {
				this.setOnboardingRoutines(data.data);
			}
		}
		if (name === 'downloadRoutine') {
			if (data.success) {
				if (routine_name) {
					this.successNotification(`Downloaded ${routine_name}!`);
				}
			} else if (data.message) {
				this.warningNotification(data.message);
			}
		}
		if (name === 'finishOnboarding') {
			this.endBtnLoading('goToAppBtn');
			if (data.success) {
				this.successNotification('Thank you!');
			}
			// does not matter success or not
			this.redirectTo(this.getPath('home'), { is_onboarded: true });
		}
	}

	setOnboardingRoutines(data: any) {
		const {
			'default': Default = [],
			topShared = [],
		} = data;

		if (util.isArray(Default)) {
			this.store.setGrid('defaultRoutines', Default);
		}

		if (util.isArray(topShared)) {
			this.store.setGrid('sharedRoutines', topShared);
		}
	}
}