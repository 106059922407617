import moment from 'moment';
export enum DateDayofWeek {
	Monday = 1,
	Tuesday = 2,
	Wednesday = 3,
	Thursday = 4,
	Friday = 5,
	Saturday = 6,
	Sunday = 0,
}

/**
 * return weekday by date
 * @param dateStr 
 * @returns 
 */
export const getDay = (dateStr: moment.MomentInput) => {
	const date = moment(dateStr);
	const day: DateDayofWeek = date.day();
	return day;
}

/**
 * get current week day
 * @returns 
 */
export const getCurrentDay = () => {
	const date = moment();
	const day: DateDayofWeek = date.day();
	return day;
}

/**
 * get current week month
 * @returns 
 */
export const getCurrentMonth = () => {
	const date = moment();
	const month = date.month();
	return month;
}

/**
 * get current year
 * @returns 
 */
export const getCurrentYear = () => {
	const date = moment();
	const year = date.year();
	return year;
}

/**
 * get current week
 * @returns 
 */
export const getCurrentWeek = () => {
	const date = moment();
	const week = date.week();
	return week;
}

/**
 * 
 * @param day of week using enum
 * @returns string date
 */
export const getDate = (day: DateDayofWeek) => {
	return moment().day(day).format();
}

export const sortDateFn = (firstDate: string, secondDate: string) => {
	if (firstDate === secondDate) return 0;
	const firstMoment = moment(firstDate);
	const secondMoment = moment(secondDate);

	return firstMoment.isBefore(secondMoment) ? -1 : 1;
};

export const getMoment = (value: moment.MomentInput) => {
	return moment(value);
};

export const today = () => {
    return moment();
};

export const getDaysUntil = (toDate: moment.MomentInput, fromDate = today() as moment.MomentInput) => {
    return getMoment(toDate).diff(fromDate, 'day');
};


export const addDays = (date: moment.MomentInput, amount: number) => {
    return getMoment(date).add(amount, 'day');
};

export const subtractDays = (date: moment.MomentInput, amount: number) => {
    return getMoment(date).subtract(amount, 'day');
};

