import React, { useCallback, useMemo } from 'react';
import { FAGridProps } from './FAGridProps';
import { Pressable, ScrollView, View } from 'native-base';
import { StyleSheet } from 'react-native';
import { icons, themeColors } from '../../enums';
import { FAHeader } from '../typography/FAHeader';
import { FAText } from '../typography/FAText';
import { FAIconButton } from '../button/FAIconButton';
import * as util from '../../util';
import { FAButton } from '../button';

interface ScrollListRenderProps {
    item: any;
    index: any;
    separators: any;
}

interface CustomProps {
    titleData: string;
    subtitleData: string;
    idData: string;
    onClick: any;
    props: any;
    titleUnits: any;
    subtitleUnits: any;
    editBtn: any;
    viewBtn: any;
    uiHide: any;
	name: string;
	deleteBtn: boolean;
	size: any;
	infoBtn: boolean;
	infoBtnIcon: string;
}

type RenderProps = ScrollListRenderProps & CustomProps;

const RenderItemComponent: React.FC<RenderProps> = (dataItem: RenderProps) => {
	const {
		item,
		index,
		separators,
		// passed props
		titleData,
		subtitleData,
		idData,
		onClick,
		props,
		titleUnits,
		subtitleUnits,
		editBtn,
		infoBtn,
		viewBtn,
		infoBtnIcon = 'info',
		uiHide,
		name,
		deleteBtn = true,
		size,
	} = dataItem;
	
	const {
		[titleData]: title,
		[subtitleData]: subtitle,
		[idData]: idKey,
	} = item;

	const onClickFn = useCallback(() => {
		// value data props
		onClick?.call(null, item, item, props);
	}, [onClick, item, props]);

	const onClickFnBtn = useCallback(() => {
		onClick?.call(null, item, item, { ...props, name: `${name}-btn` });
	}, [onClick, item, props]);

	const onClickFnEditBtn = useCallback(() => {
		onClick?.call(null, item, item, { ...props, name: `${name}-editbtn` });
	}, [onClick, item, props]);

	const onClickFnInfoBtn = useCallback(() => {
		onClick?.call(null, item, item, { ...props, name: `${name}-infobtn` });
	}, [onClick, item, props]);

	// control button render through service

	const hideBtn = uiHide(`${name}-renderItem`, item);
	const hideDeleteBtn = uiHide(`${name}-deleteItem`, item);
	const hideEditBtn = uiHide(`${name}-editItem`, item);
	const hideViewBtn = uiHide(`${name}-viewItem`, item);

	return (
		<Pressable onPress={onClickFn} key={util.getUuid()}>
			<View style={styles.rowItem}>
				<View style={styles.infoSection}>
					<View style={styles.title}>
						<FAHeader name='titleGrid' size={size}>
							{title + (titleUnits ? ` - ${titleUnits}` : '')}
						</FAHeader>
					</View>
					<View style={styles.subtitle}>
						<FAText name='subtitleGrid' italic fontSize={size}>
							{subtitle + (subtitleUnits ? ` - ${subtitleUnits}` : '')}
						</FAText>
					</View>
				</View>
				{
				!hideBtn && 
				<>
					{
					infoBtn && 
					<View style={styles.button}>
						<FAIconButton
							name='infoBtn'
							id={`${index}infoBtn`}
							icon={icons[infoBtnIcon]}
							onClick={onClickFnInfoBtn} 
							size={size}
						/>
					</View>
					}
					{
					editBtn && !(hideEditBtn) &&
					<View style={styles.button}>
						<FAButton
							name='editBtn'
							id={`${index}editBtn`}
							// icon={icons.edit}
							children='Edit'
							onClick={onClickFnEditBtn} 
							size={size}
							backgroundColor={themeColors.primaryBase}
							borderRadius={20}
						/>
					</View>
					}
					{
					viewBtn && !(hideViewBtn) &&
					<View style={styles.button}>
						<FAButton
							name='viewBtn'
							id={`${index}viewBtn`}
							// icon={icons.edit}
							children='View'
							onClick={onClickFnEditBtn} 
							size={size}
							backgroundColor={themeColors.primaryBase}
							borderRadius={20}
						/>
					</View>
					}
				{
					deleteBtn && !(hideDeleteBtn) &&

					<View style={styles.button}>
						<FAIconButton
							name='clickBtn'
							id={`${index}clickBtn`}
							icon={icons.delete}
							onClick={onClickFnBtn}
							size={size}
						/>
					</View>
					}
				</>
				}
			</View>
		</Pressable>
	);
};

const RenderItem = React.memo(RenderItemComponent);

export const FAScrollList: React.FC<FAGridProps> = (props: FAGridProps) =>{
	const {
		name,
		dataSource,
		btnIcon = 'delete',
		// data info
		titleData,
		titleUnits,
		subtitleData,
		subtitleUnits,
		editBtn,
		deleteBtn,
		infoBtn = false,
		infoBtnIcon,
		idData,
		viewBtn,
		style,
		// service info
		onClick,
		uiHide,
		size,
	} = props;

	const attributes = {
		name,
		children: dataSource.map((item, index) => {
			return (
				<RenderItem
					key={util.getUuid()}
					{...{
						item,
						index,
						separators: '',
						// grid props
						titleData,
						subtitleData,
						idData,
						onClick,
						props,
						titleUnits,
						subtitleUnits,
						editBtn,
						viewBtn,
						deleteBtn,
						infoBtn,
						uiHide,
						name,
						size,
						infoBtnIcon,
					}}/>
			);
		}),
		style,
	};

	console.log('Render ScrollList', name);
	return <ScrollView {...attributes} />;
};

const styles = StyleSheet.create({
	rowItem: {
		flex: 1,
		flexDirection: 'row',
		borderBottomColor: 'grey',
		borderBottomWidth: 1,
		justifyContent: 'center',
		alignContent: 'center',
	},
	infoSection: {
		flex: 1,
		flexDirection: 'column',
		height: '100%',
	},
	title: {
	},
	button: {
		justifyContent: 'center',
		alignContent: 'center',
		padding: 2,
	},
	subtitle: {
	},
})