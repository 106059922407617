import React, { Fragment } from 'react';
import { Router, Route, Routes, RouteProps, Navigate } from './Routing';
import { 
	Home,
	Login,
	Workout,
	Signup,
	Exercise,
	Routine,
	Weight,
	Settings,
	Model,
	ExerciseDetail,
	RoutineDetail,
	RoutineStart,
	RoutineSchedule,
	ExerciseChart,
	SystemSettings,
	Onboarding,
	Privacy,
	Support,
	Marketing,
} from '../screens';
import { paths } from '../enums';
import { ExerciseGoal } from '../screens/ExerciseGoal';
import { DeleteUser } from '../screens/DeleteUser';
import { ResetPassword } from '../screens/ResetPassword';
import { About } from '../screens/About';
import { UpdateAvailable } from '../screens/UpdateAvailable';
import { UpdateNews } from '../screens/UpdateNews';
import { StartWorkout } from '../screens/StartWorkout';
import { StartWorkoutMenu } from '../screens/StartWorkoutMenu';

interface NavProps {
	authorized: boolean;
}

/**
 * define navigation here routes + screen component
 * @param {*} props 
 * @returns 
 */
export const Navigation: React.FC<NavProps> = (props: NavProps) => {

	// this route should be used for routes that required authorization
	const PrivateRoute: React.FC<RouteProps> = ({ children }: any) => {
		if (!props.authorized) return <Navigate to={paths.home} />;
		return children;
	}

	// this route should be used for routes that are not allowed if authorized
	const PublicRoute: React.FC<RouteProps> = ({ children }: any) => {
		if (props.authorized) return <Navigate to={paths.home} />;
		return children;
	}

	return (
		<Router>
			<Routes>
				{/* Home/dashboard route */}
				<Route path={paths.home} element={(props.authorized) ? <StartWorkoutMenu /> : <Home />} />
				{/** public routes (user cannot be logged in) */}
				<Route path={paths.login} element={<PublicRoute><Login /></PublicRoute>} />
				<Route path={paths.signup} element={<PublicRoute><Signup /></PublicRoute>} />
				{/** private routes (user must be logged in to use) */}
				<Route path={paths.workout} element={<PrivateRoute><Workout /></PrivateRoute>} />
				<Route path={paths.startWorkout} element={<PrivateRoute><StartWorkout /></PrivateRoute>} />
				<Route path={paths.routineschedule} element={<PrivateRoute><RoutineSchedule /></PrivateRoute>} />
				<Route path={paths.startWorkoutMenu} element={<PrivateRoute><StartWorkoutMenu /></PrivateRoute>} />
				<Route path={paths.exercise} element={<PrivateRoute><Exercise /></PrivateRoute>} />
				<Route path={paths.exercisedetail} element={<PrivateRoute><ExerciseDetail /></PrivateRoute>} />
				<Route path={paths.exerciseGoal} element={<PrivateRoute><ExerciseGoal /></PrivateRoute>} />
				<Route path={paths.routine} element={<PrivateRoute><Routine /></PrivateRoute>} />
				<Route path={paths.weight} element={<PrivateRoute><Weight /></PrivateRoute>} />
				<Route path={paths.settings} element={<PrivateRoute><Settings /></PrivateRoute>} />
				<Route path={paths.routinedetail} element={<PrivateRoute><RoutineDetail /></PrivateRoute>} />
				<Route path={paths.routinestart} element={<PrivateRoute><RoutineStart /></PrivateRoute>} />
				<Route path={paths.model} element={<PrivateRoute><Model /></PrivateRoute>} />
				<Route path={paths.exerciseChart} element={<PrivateRoute><ExerciseChart /></PrivateRoute>} />
				<Route path={paths.systemsettings} element={<PrivateRoute><SystemSettings /></PrivateRoute>} />
				<Route path={paths.onboarding} element={<PrivateRoute><Onboarding /></PrivateRoute>} />
				{/* all routes (user can access if logged in or not logged in) */}
				<Route path={paths.about} element={<About />} />
				<Route path={paths.updateAvailable} element={<UpdateAvailable />} />
				<Route path={paths.updateNews} element={<UpdateNews />} />
				<Route path={paths.privacy} element={<Privacy />} />
				<Route path={paths.support} element={<Support />} />
				<Route path={paths.marketing} element={<Marketing />} />
				<Route path={paths.deleteUser} element={<DeleteUser />} />
				<Route path={paths.resetPassword} element={<ResetPassword />} />
			</Routes>	
		</Router>
	);
};
