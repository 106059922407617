import { MobxStore } from './store';
import * as util from '../util';
import { getService } from '../service/servicePool';
import { getDataKey } from './dataVersion';

/**
 * get current or by name ui store
 * @param name 
 * @returns 
 */
export const getUIStore = (name?: string): MobxStore | null => {
	const service = getService(name);
	if (service) {
		return service.uiStore;
	}
	return null;
};

/**
 * get current or by name store
 * @param name 
 * @returns 
 */
export const getStore = (name?: string): MobxStore | null => {
	const service = getService(name);
	if (service) {
		return service.store;
	}
	return null;
};

/**
 * hook to add ui props to component
 * @param routes 
 * @param props 
 * @returns 
 */
export const useUiProps = (routes: string | string[], props: any) => {
	const ui = getUIStore().getProperty(routes) || {};
	util.mergeDeep(props, ui);
	return props;
};

/**
 * get current or by name ui store
 * @param name 
 * @returns 
 */
export const useStoreProps = (routes: string | string[], props: any) => {
	const store = getStore();
	const [value, getValue, setValue] = store.useDataProperty(routes);
	if (!util.isFunction(props.getValue)) {
		props.getValue = getValue;
	}
	if (!util.isFunction(props.setValue)) {
		props.setValue = setValue;
	}
	if (util.isFunction(props.getValue)) {
		props.value = props.getValue();
	}
	return props;
};


/**
 * get current or by name ui store
 * @param name 
 * @returns 
 */
export const useDataVersion = (routes?: string | string[]) => {
	const store = getStore();
	const [value, getValue, setValue] = store.useDataVersion(routes);
	return [value, getValue, setValue] as const;
};
