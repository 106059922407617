import dayjs from "dayjs";
import customParseFormat from 'dayjs/plugin/customParseFormat'
import timezone from 'dayjs/plugin/timezone'
import { isNumber, isString } from "./typeUtil";
dayjs.extend(customParseFormat);
dayjs.extend(timezone);


/**
 * format number
 * @param value 
 * @returns 
 */
export const formatNumber = (value) => {
    if (isNumber(value)) return value;
    if (isString(value)) {
        if (!value) return 0;
        value = (value as string).replace('^[0-9]*$', '');
        return parseInt(value);
    }
    return 0;
};

/**
 * format date
 * @param value 
 */
export const formatDate = (value: string, format = 'MMM D, h:mm A') => {
    return dayjs(value).format(format);
};
