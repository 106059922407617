import { ComponentEnum } from '../../enums';
import { UIStoreInit } from '../../store';

export const uiState: UIStoreInit = {
	button: {
		workoutPage: {
			name: 'workoutPage',
			children: 'redirect to workout',
		},
		onboardingBtn: {
			name: 'onboardingBtn',
			children: 'LOGIN',
			size: 'lg',
		},
		goToAppBtn: {
			name: 'goToAppBtn',
			children: 'Go To App!',
		},
		prevBtn: {
			name: 'prevBtn',
			children: 'Next',
		},
		nextBtn: {
			name: 'nextBtn',
			children: 'Previous',
		},
		gainWeight: {
			name: 'gainWeight',
			children: 'Gain Weight',
			style: {
				borderRadius: 20,
				width: '100%',
			},
		},
		loseWeight: {
			name: 'loseWeight',
			children: 'Lose Weight',
			style: {
				borderRadius: 20,
				width: '100%',
			},
		},
		buildMuscle: {
			name: 'buildMuscle',
			children: 'Build Muscle',
			style: {
				borderRadius: 20,
				width: '100%',
			},
		},
		endurance: {
			name: 'endurance',
			children: 'Endurance',
			style: {
				borderRadius: 20,
				width: '100%',
			},
		},
		learnTheBasics: {
			name: 'learnTheBasics',
			children: 'Learn The Basics',
			style: {
				borderRadius: 20,
				width: '100%',
			},
		},
		beginner: {
			name: 'beginner',
			children: 'Beginner',
			style: {
				borderRadius: 20,
				width: '100%',
			},
		},
		intermediate: {
			name: 'intermediate',
			children: 'Intermediate',
			style: {
				borderRadius: 20,
				width: '100%',
			},
		},
		advanced: {
			name: 'advanced',
			children: 'Advanced',
			style: {
				borderRadius: 20,
				width: '100%',
			},
		},
	},
	input: {
		weight: {
			componentType: ComponentEnum.InputTypeEnum.Input,
			name: 'weight',
			placeholder: 'Weight',
			label: 'Weight',
			routes: 'weight',
			format: 'number',
		},
		weightUnit: {
			componentType: ComponentEnum.InputTypeEnum.SelectButton,
			name: 'weightUnit',
			placeholder: 'Weight',
			label: 'Weight',
			routes: 'weightUnit',
			items: [
				{
					value: 'kg',
					label: 'kg',
				},
				{
					value: 'lb',
					label: 'lb',
				},
			],
		},
		age: {
			componentType: ComponentEnum.InputTypeEnum.Input,
			name: 'age',
			placeholder: 'Age',
			label: 'Age',
			routes: 'age',
			format: 'number',
		},
		height: {
			componentType: ComponentEnum.InputTypeEnum.Input,
			name: 'height',
			placeholder: 'Height',
			label: 'Height',
			routes: 'height',
			format: 'number',
		},
		heightUnit: {
			componentType: ComponentEnum.InputTypeEnum.SelectButton,
			name: 'heightUnit',
			placeholder: 'Weight',
			label: 'Weight',
			routes: 'heightUnit',
			items: [
				{
					value: 'cm',
					label: 'cm',
				},
				{
					value: 'ft',
					label: 'ft',
				},
			],
		},
		selectGoal: {
			componentType: ComponentEnum.InputTypeEnum.SelectButton,
			name: 'selectGoal',
			placeholder: 'Weight',
			label: 'Weight',
			routes: 'selectGoal',
			items: [
				'Gain Weight',
				'Lose Weight',
				'Build Muscle',
				'Endurance',
				'Learn the Basics',
			],
		},
		selectLevel: {
			componentType: ComponentEnum.InputTypeEnum.SelectButton,
			name: 'selectLevel',
			placeholder: 'Weight',
			label: 'Weight',
			routes: 'selectLevel',
			items: [
				'Beginner',
				'Intermediate',
				'Advanced',
			],
		},
	},
	typography: {},
	layout: {
		// header: 'Onboarding',
		// backEnabled: true,
	},
	grid: {
		sharedRoutines: {
			routes: 'sharedRoutines',
			titleData: 'routine_name',
			subtitleData: 'routine_description',
			deleteBtn: false,
			infoBtn: true,
			infoBtnIcon: 'down',
		},
		defaultRoutines: {
			routes: 'defaultRoutines',
			titleData: 'routine_name',
			subtitleData: 'routine_description',
			deleteBtn: false,
			infoBtn: true,
			infoBtnIcon: 'down',
		},
	},
};