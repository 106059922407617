/**
 * paths of screens in application
 */
export const paths = {
	'home': '/',
	'login': '/login',
	'signup': '/signup',
	'routine': '/routine',
	'exercise': '/exercise',
	'workout': '/workout',
	'weight': '/weight',
	'settings': '/settings',
	'exercisedetail': '/exercisedetail',
	'routinedetail': '/routinedetail',
	'routinestart': '/routinestart',
	'routineschedule': '/routineschedule',
	'exerciseChart': '/exerciseChart',
	'systemsettings': '/systemSettings',
	'model': '/model',
	'exerciseGoal': '/exerciseGoal',
	'onboarding': '/onboarding',
	'privacy': '/privacy',
	'support': '/support',
	'marketing': '/marketing',
	'instructions': '/instructions',
	'deleteUser': '/deleteUser',
	'resetPassword': '/resetPassword',
	'about': '/about',
	'updateNews': '/updateNews',
	'updateAvailable': '/updateAvailable',
	'startWorkout': '/startWorkout',
	'startWorkoutMenu': '/startWorkoutMenu',
};
