import { observer } from 'mobx-react-lite';
import { NativeBaseProvider, View } from 'native-base';
import React, { useEffect, useState } from 'react';
import { Dimensions, SafeAreaView, StyleSheet, StatusBar, Platform } from 'react-native';
import { themeColors } from './src/enums';
import { Mask, Layout, Navigation } from './src/layout';
import { authorizeToken } from './src/service';
import { getAppStateStore, useAppStateData } from './src/store';


const MyStatusBar = ({backgroundColor, ...props}) => (
	<View style={[styles.statusBar, { backgroundColor }]}>
	  <SafeAreaView>
		<StatusBar translucent backgroundColor={backgroundColor} {...props} />
	  </SafeAreaView>
	</View>
  )

const App: React.FC = observer(() => {
	const [authorized, setAuthorized] = useState<'loading' | true | false>('loading');
	
	const appStateStore = getAppStateStore();
	const [authorization] = appStateStore.useDataProperty('Authorization');
	useEffect(() => {
		// let app state persist store load first
		if (!appStateStore.loaded) return;
		if (!authorization) setAuthorized(false);
		// check authorize token first to see if user is logged in or not
		authorizeToken(authorization).then((result) => {
			setAuthorized(result);
		});
	}, [authorization, appStateStore.loaded]);

	return (
		<NativeBaseProvider>
			{/* <MyStatusBar backgroundColor={themeColors.primaryBase} barStyle="light-content" /> */}
			<SafeAreaView style={styles.appLayout}>

			{
				authorized === 'loading'
					? 	<Mask />
					: 	<Layout>
						<Navigation authorized={authorized} />
					</Layout>
			}
			</SafeAreaView>
			<View style={styles.footer} />
		</NativeBaseProvider>
	);
});

const STATUSBAR_HEIGHT = StatusBar.currentHeight;
const FOOTER_HEIGHT = Platform.OS === 'ios' ? 34 : 0;

const styles = StyleSheet.create({
	appLayout: {
		flex: 1,
	},
	statusBar: {
	  height: STATUSBAR_HEIGHT,
	},
	footer: {
		backgroundColor: themeColors.primaryBase,
		height: FOOTER_HEIGHT,
	}
});

export default App;